import request from '@/utils/request'
//获取分类列表
export function getCateList(params) {
  return request({
    url: '/psyeval/cate',
    method: 'get',
    params,
  })
}

//获取量表列表
export function getEvalList(params) {
  return request({
    url: '/psyeval/paper-list',
    method: 'get',
    params,
  })
}

//获取某个渠道的所有量表
export function getEvalListBySid(params) {
  return request({
    url: '/router/get-eval-data',
    method: 'get',
    params,
  })
}

//新增分类
export function addCate(data) {
  return request({
    url: '/psyeval/cate-add',
    method: 'post',
    data,
  })
}

//编辑分类
export function doEdit(data) {
  return request({
    url: '/psyeval/cate-edit',
    method: 'post',
    data,
  })
}

//上架量表
export function doPaperAdd(data) {
  return request({
    url: '/psyeval/paper-add',
    method: 'post',
    data,
  })
}

//编辑量表
export function doPaperEdit(data) {
  return request({
    url: '/psyeval/paper-edit',
    method: 'post',
    data,
  })
}

//删除分类
export function doDelete(data) {
  return request({
    url: '/psyeval/cate-del',
    method: 'post',
    data,
  })
}

//获取所有分类
export function getEvalTypeList(params) {
  return request({
    url: '/router/get-eval-type',
    method: 'get',
    params,
  })
}

//获取测评记录
export function getTestRecordList(params) {
  return request({
    url: '/psyevallist/test-record',
    method: 'get',
    params,
  })
}

//获取测评记录
export function getTestAnswer(params) {
  return request({
    url: '/psyevallist/ques',
    method: 'get',
    params,
  })
}

//获取单个用户的测评结果
export function getMmptRecord(params) {
  return request({
    url: '/psyevallist/test-result',
    method: 'get',
    params,
  })
}

//简版测评结果编辑
export function editResult(data) {
  return request({
    url: '/psyevallist/report-save',
    method: 'post',
    data,
  })
}

//心理测评列表
export function getPsyEval(params) {
  return request({
    url: '/psyevallist/paper-list',
    method: 'get',
    params,
  })
}

//添加心理测评
export function addEval(data) {
  return request({
    url: '/psyevallist/paper-add',
    method: 'post',
    data,
  })
}
//删除测评
export function delEval(data) {
  return request({
    url: '/psyevallist/paper-del',
    method: 'post',
    data,
  })
}
//获取量表题目
export function getPaperList(data) {
  return request({
    url: '/psyevallist/eval-ques',
    method: 'post',
    data,
  })
}
//提交答案
export function saveAnswer(data) {
  return request({
    url: '/psyevallist/eval-answer',
    method: 'post',
    data,
  })
}
