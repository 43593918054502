var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "team-list-container" },
    [
      _c(
        "vab-query-form",
        [
          _c("vab-query-form-left-panel", { attrs: { span: 8 } }, [
            _c("span", { staticStyle: { color: "#999" } }, [
              _vm._v(" 温馨提示：若团测进度为0，则表示没有报告可以生成 "),
            ]),
          ]),
          _c(
            "vab-query-form-right-panel",
            { attrs: { span: 16 } },
            [
              _c(
                "el-form",
                {
                  attrs: { inline: true, model: _vm.queryForm },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入标题" },
                        model: {
                          value: _vm.queryForm.title,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryForm,
                              "title",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-search", type: "primary" },
                          on: { click: _vm.queryData },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-refresh", type: "primary" },
                          on: { click: _vm.refreshData },
                        },
                        [_vm._v(" 刷新 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { border: "", data: _vm.list },
          on: { "selection-change": _vm.setSelectRows },
        },
        [
          _vm.isSid === "all"
            ? _c("el-table-column", {
                attrs: { label: "渠道", prop: "studio" },
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: "团测标题", prop: "title" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "进度", prop: "rate" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("el-progress", {
                      attrs: {
                        percentage: row.rate * 1,
                        "stroke-width": 16,
                        "text-inside": true,
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "测评时间", prop: "start_at" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("div", [_vm._v(_vm._s("开始时间：" + row.start_at))]),
                    _c("div", [_vm._v(_vm._s("结束时间：" + row.end_at))]),
                  ]
                },
              },
            ]),
          }),
          false
            ? _c("el-table-column", {
                attrs: { label: "生成时间", prop: "report_at" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            {
                              style: {
                                color:
                                  row.report_at === "未生成"
                                    ? "red"
                                    : row.report_at === "生成中"
                                    ? "#38c738"
                                    : "",
                              },
                            },
                            [_vm._v(" " + _vm._s(row.report_at) + " ")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  147412162
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.rate !== 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(row)
                              },
                            },
                          },
                          [_vm._v(" 团体报告 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: "",
                              title:
                                row.rate === 0 ? "团测进度为0，暂无报告" : "",
                              type: "text",
                            },
                          },
                          [_vm._v(" 团体报告 ")]
                        ),
                    _c(
                      "el-dropdown",
                      { on: { command: _vm.handleCommand } },
                      [
                        _c("span", { staticClass: "el-dropdown-link" }, [
                          _vm._v(" 更多 "),
                          _c("i", { staticClass: "el-icon-arrow-down" }),
                        ]),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  command: _vm.beforeHandleCommand(row.id, 1),
                                  disabled: !_vm.button.download,
                                },
                              },
                              [_vm._v(" 个人报告 ")]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  command: _vm.beforeHandleCommand(row.id, 2),
                                  disabled: !_vm.button.download,
                                },
                              },
                              [_vm._v(" 原始数据 ")]
                            ),
                            !_vm.isNJ
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      command: _vm.beforeHandleCommand(
                                        row.id,
                                        3
                                      ),
                                      disabled: !_vm.button.download,
                                    },
                                  },
                                  [_vm._v(" 预警数据 ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: "下载",
            visible: _vm.dialogFormVisible,
            width: "450px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _vm.downloadStatus
            ? _c("div", { staticClass: "download-team" }, [
                _c("div", { staticClass: "item" }, [
                  _c("div", [_vm._v("打包时间")]),
                  _c("div", [_vm._v(_vm._s(_vm.downloadStatus.time))]),
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("div", [_vm._v("操作")]),
                  _c(
                    "div",
                    [
                      _vm.downloadStatus.pack === 2
                        ? _c(
                            "el-button",
                            { attrs: { disabled: "", type: "text" } },
                            [_vm._v(" 打包中 ")]
                          )
                        : _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.packTeam("pack")
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.downloadStatus.pack === 1
                                      ? "打包"
                                      : "重新打包"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                      _vm.downloadStatus.download === 1
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.packTeam("download")
                                },
                              },
                            },
                            [_vm._v(" 下载 ")]
                          )
                        : _c(
                            "el-button",
                            { attrs: { disabled: "", type: "text" } },
                            [_vm._v("下载")]
                          ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.close },
                },
                [_vm._v(" 关闭 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("origin", { ref: "origin" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }