<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="650px"
      @close="close"
    >
      <el-form ref="form" label-width="120px" :model="form" :rules="rules">
        <el-form-item v-if="isSid === 'all'" label="渠道" prop="sid">
          <el-select
            v-model="form.sid"
            filterable
            placeholder="选择渠道"
            @change="bindChange"
          >
            <el-option
              v-for="item in studioList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" placeholder="请输入咨询师姓名" />
        </el-form-item>
        <el-form-item label="资质" prop="credent_id">
          <el-select
            v-model="form.credent_id"
            clearable
            filterable
            placeholder="请选择资质"
          >
            <el-option
              v-for="item in credentList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="头像" prop="avatar">
          <div class="img-uploader">
            <el-upload
              accept="image/png, image/jpeg"
              :action="UploadUrl()"
              :auto-upload="false"
              :on-change="fileChange"
              :on-remove="handleRemove"
              :show-file-list="false"
            >
              <img v-if="form.avatar" class="img" :src="form.avatar" />
              <i v-else class="el-icon-plus"></i>
            </el-upload>
            <div
              v-if="false"
              class="el-upload el-upload__text"
              @click="imgDialog"
            >
              <img v-if="form.avatar" class="img" :src="form.avatar" />
              <i v-else class="el-icon-plus"></i>
            </div>
            <div slot="tip" class="el-upload__tip">
              <div>
                只能上传
                <span>.jpg、.png</span>
                文件，且
                <span>不超过1M</span>
              </div>
              <div>(图片尺寸1:1)</div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="一级组织结构" prop="orgid">
          <el-select
            v-model="form.orgid"
            clearable
            filterable
            placeholder="请选择一级组织结构"
          >
            <el-option
              v-for="item in schoolList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="form.sort" placeholder="数字越大越靠前" />
        </el-form-item>
        <el-form-item label="个人简介" prop="intro">
          <el-input
            v-model="form.intro"
            maxlength="800"
            placeholder="请输入个人简介，如需换行，请用中文的分号“；”分隔"
            :rows="3"
            show-word-limit
            type="textarea"
          />
        </el-form-item>
        <el-form-item label="受训背景" prop="background">
          <el-input
            v-model="form.background"
            maxlength="800"
            placeholder="请输入受训背景，如需换行，请用中文的分号“；”分隔"
            :rows="3"
            show-word-limit
            type="textarea"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
    <vab-cropper
      v-if="false"
      ref="vabCropper"
      append-to-body
      :imgurl="form.avatar || require('@/assets/login_images/login_img.png')"
      @checkedImg="getImg"
    />
  </div>
</template>

<script>
  import {
    getCounselorDetail,
    CounselorAdd,
    CounselorEdit,
    getSchoolList,
  } from '@/api/appointment'
  import { mapGetters } from 'vuex'
  import VabCropper from '@/extra/VabCropper'
  import { isNumber } from '@/utils/validate'

  export default {
    name: 'CounselorEdit',
    components: { VabCropper },
    props: {
      list: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      const validateNum = (rule, value, callback) => {
        if (value !== '' && !isNumber(value)) {
          callback(new Error('请输入正整数'))
        } else callback()
      }
      return {
        studioList: [],
        schoolList: [], //学校列表
        credentList: [], //资质列表
        form: {
          name: '',
          credent_id: '',
          avatar: '',
          intro: '',
          background: '',
          orgid: '',
          sort: '',
        },
        rules: {
          sid: [{ required: true, trigger: 'change', message: '请选择渠道' }],
          name: [
            { required: true, trigger: 'blur', message: '请输入咨询师姓名' },
          ],
          credent_id: [
            { required: true, trigger: 'change', message: '请选择资质' },
          ],
          orgid: [
            {
              required: true,
              trigger: 'change',
              message: '请选择一级组织结构',
            },
          ],
          intro: [
            { required: true, trigger: 'blur', message: '请输入个人简介' },
          ],
          background: [
            { required: true, trigger: 'blur', message: '请输入受训背景' },
          ],
          sort: [{ required: false, trigger: 'blur', validator: validateNum }],
        },
        title: '',
        dialogFormVisible: false,
        hideUpload: false,
        hasImg: '',
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    watch: {
      list: {
        handler(newData) {
          if (this.isSid === 'all') this.studioList = newData.studioList
          this.credentList = newData.credentList
        },
        immediate: true,
        deep: true,
      },
    },
    created() {},
    methods: {
      async getSchool(sid) {
        const { data } = await getSchoolList(
          this.isSid === 'all' ? { sid: sid } : ''
        )
        this.schoolList = data
      },
      showEdit(row) {
        if (!row) {
          this.title = '添加'
          if (this.isSid !== 'all') this.getSchool()
        } else {
          this.title = '编辑'
          getCounselorDetail({ id: row.id }).then((res) => {
            this.form = res.data
            this.getSchool(res.data.sid)
          })
        }
        this.dialogFormVisible = true
      },
      imgDialog() {
        this.$refs['vabCropper'].dialogVisible = true
      },
      getImg(val) {
        this.form.avatar = val
        console.log(val)
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.hasImg = ''
        this.hideUpload = false
        this.dialogFormVisible = false
      },
      bindChange(val) {
        if (val) {
          this.getSchool(val)
        } else {
          this.form.orgid = ''
        }
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let p = this.form
            let formData = new FormData()
            formData.append('sid', p.sid)
            formData.append('name', p.name)
            formData.append('credent_id', p.credent_id)
            formData.append('intro', p.intro)
            formData.append('background', p.background)
            formData.append('orgid', p.orgid)
            formData.append('sort', p.sort === '' ? 0 : p.sort)
            if (this.hasImg && this.hasImg.raw)
              formData.append(
                'image_file',
                this.hasImg.raw,
                this.hasImg.raw.name
              )
            if (this.title === '编辑') formData.append('id', p.id)
            const { msg } =
              this.title === '编辑'
                ? await CounselorEdit(formData)
                : await CounselorAdd(formData)
            this.$baseMessage(msg, 'success')
            this.$emit('fetch-data')
            this.close()
          } else {
            return false
          }
        })
      },
      UploadUrl: function () {
        return ''
      },
      fileChange(file, fileList) {
        if (fileList.length >= 1) {
          this.handleRemove(fileList)
        }
        const isLt2M = file.size / 1024 / 1024 < 1
        if (file.raw.type !== 'image/jpeg' && file.raw.type !== 'image/png') {
          this.$message.error('上传图片只能是 JPG、PNG 格式!')
          return false
        } else if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 1MB!')
          fileList.map((item, index) => {
            if (item === file) {
              fileList.splice(index, 1)
            }
          })
        } else {
          this.form.avatar = URL.createObjectURL(file.raw)
          this.hasImg = file
          this.hideUpload = fileList.length > 0
        }
      },
      handleRemove(fileList) {
        this.hideUpload = fileList.length > 0
      },
    },
  }
</script>
<style lang="scss" scoped>
  .el-select {
    width: 100%;
  }
  .img-uploader {
    display: flex;
    ::v-deep.el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 3px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    ::v-deep.el-upload--picture-card,
    ::v-deep.el-upload-list--picture-card .el-upload-list__item {
      width: 100px;
      height: 100px;
    }
    ::v-deep.el-list-enter-active,
    ::v-deep.el-list-leave-active {
      transition: none;
    }

    ::v-deep.el-list-enter,
    ::v-deep.el-list-leave-active {
      opacity: 0;
    }
    .hide ::v-deep.el-upload--picture-card {
      display: none;
    }
    .el-upload__tip {
      flex: 1;
      margin-left: 10px;
      color: #999;
      line-height: normal;
      span {
        color: #e64e1f;
      }
    }
    ::v-deep.el-upload:hover {
      border-color: #92e6db;
    }
    .img,
    .el-icon-plus {
      width: 100px;
      height: 100px;
      display: block;
      line-height: 100px;
    }
  }
</style>
