<template>
  <div class="personal-center-container">
    <el-card v-if="info" class="boxs" shadow="never">
      <div slot="header" class="header">个人信息</div>
      <div class="info">
        <div class="item">
          <label>头像：</label>
          <el-avatar :size="80" :src="info.avatar" />
        </div>
        <div class="item">
          <label>账号：</label>
          {{ info.username }}
        </div>
        <div class="item">
          <label>联系方式：</label>
          {{ info.phone }}
        </div>
        <div class="item">
          <label>所属角色：</label>
          {{ info.role }}
        </div>
      </div>
      <el-button class="btn" size="medium" type="primary" @click="editPass">
        修改密码
      </el-button>
    </el-card>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogFormVisible"
      width="400px"
      @close="close"
    >
      <el-form ref="form" label-width="80px" :model="form" :rules="rules">
        <el-form-item label="旧密码" prop="oldpass">
          <el-input
            v-model="form.oldpass"
            autocomplete="new-password"
            placeholder="请输入原始密码"
            type="password"
          />
        </el-form-item>
        <el-form-item label="新密码" prop="newpass">
          <el-input
            v-model="form.newpass"
            placeholder="请输入新密码"
            type="password"
          />
        </el-form-item>
        <el-form-item label="确认密码" prop="repass">
          <el-input
            v-model="form.repass"
            placeholder="再次输入新密码"
            type="password"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getUserInfo2, editPassword } from '@/api/user'
  import { isPassword } from '@/utils/validate'
  import { mapActions } from 'vuex'
  import { toLoginRoute } from '@/utils/routes'
  export default {
    name: 'PersonalCenter',
    components: {},
    data() {
      const new_password = (rule, value, callback) => {
        if (!isPassword(value)) {
          callback(new Error('密码不能少于6位'))
        } else if (value.length > 12) {
          callback(new Error('密码不能超过12位'))
        } else {
          callback()
        }
      }
      const userPassWord = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入新密码'))
        } else if (value !== this.form.newpass) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        info: null,
        dialogFormVisible: false,
        form: {
          oldpass: '',
          newpass: '',
          repass: '',
        },
        rules: {
          oldpass: [
            { required: true, trigger: 'blur', message: '请输入旧密码' },
          ],
          newpass: [
            { required: true, trigger: 'blur', message: '请输入新密码' },
            { validator: new_password, trigger: 'blur' },
          ],
          repass: [
            { required: true, trigger: 'blur', message: '请再次输入密码' },
            { validator: userPassWord, trigger: 'blur' },
          ],
        },
      }
    },
    created() {
      this.getUserInfo()
    },
    mounted() {},
    methods: {
      ...mapActions({
        _logout: 'user/logout',
      }),
      async logout() {
        await this._logout()
        await this.$router.push(toLoginRoute(this.$route.fullPath))
      },
      getUserInfo() {
        getUserInfo2().then((res) => {
          this.info = res.data
        })
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg, code } = await editPassword(this.form)
            if (code === 200) {
              this.$baseMessage(msg, 'success')
              this.close()
              this.logout()
            } else {
              this.$baseMessage(msg, 'error')
            }
          } else {
            return false
          }
        })
      },
      editPass() {
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
    },
  }
</script>
<style lang="scss">
  .personal-center-container {
    .el-card__header {
      padding-left: 0 !important;
      padding-top: 0 !important;
    }
    .boxs {
      border: 0 !important;
      .btn {
        margin: 20px 0;
      }
      .header {
        position: relative;
        font-size: 16px;
        font-weight: bold;
        padding: 0 20px;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 6px;
          background: #26cdb7;
          border-radius: 3px 0 0 3px;
        }
      }
    }
    .info {
      .item {
        label {
          width: 100px;
          color: #999;
          font-weight: bold;
        }
        display: flex;
        line-height: 40px;
        font-size: 16px;
      }
    }
  }
</style>
