<template>
  <footer class="vab-footer">
    <div v-if="hiddenSupport == 0" class="beian">
      <img src="@/assets/beian.png" style="width: 20px" />
      湘公网安备43019002002402号
    </div>
    Copyright
    <vab-icon icon="copyright-line" />
    {{ title }} {{ fullYear }}
  </footer>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'VabFooter',
    data() {
      return {
        fullYear: null,
      }
    },
    computed: {
      ...mapGetters({
        title: 'settings/title',
        hiddenSupport: 'settings/hiddenSupport',
      }),
    },
    created() {
      this.fullYear = new Date().getFullYear()
    },
  }
</script>

<style lang="scss" scoped>
  .vab-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 55px;
    padding: 0 $base-padding 0 $base-padding;
    color: rgba(0, 0, 0, 0.45);
    background: $base-color-white;
    border-top: 1px dashed $base-border-color;
    .beian {
      font-size: 12px;
      margin-right: 20px;
      line-height: 24px;
      img {
        vertical-align: middle;
      }
    }

    i {
      margin: 0 5px;
    }
  }
</style>
