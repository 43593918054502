var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "结案",
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-alert",
        {
          attrs: {
            closable: false,
            "show-icon": "",
            title: "温馨提示",
            type: "warning",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: { "line-height": "1.5" },
              attrs: { slot: "default" },
              slot: "default",
            },
            [
              _vm._v(
                " 结案指该用户心理危机干预流程已完成，请勿随意操作【结案】功能，【结案】后系统只能查看该用户所记录的档案以及相关数据，不能进行修改！ "
              ),
            ]
          ),
        ]
      ),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "80px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "结案原因", prop: "reason" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.reason,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "reason", $$v)
                    },
                    expression: "form.reason",
                  },
                },
                _vm._l(_vm.resList, function (item, i) {
                  return _c("el-radio", { key: i, attrs: { label: item.id } }, [
                    _vm._v(" " + _vm._s(item.name) + " "),
                  ])
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }