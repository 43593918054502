<template>
  <div class="schel-container">
    <vab-query-form>
      <div class="tips">
        温馨提示：创建团测后，如有用户或组织结构调整，请手动
        <el-link type="primary" :underline="false" @click="syncData">
          同步数据 >>
        </el-link>
      </div>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-cascader
              ref="orCas"
              clearable
              :options="treeData"
              placeholder="组织结构"
              :props="defaultProps"
              @change="handleChange"
            />
          </el-form-item>
          <el-form-item>
            <el-select v-model="queryForm.type" placeholder="是否完成">
              <el-option label="全部" :value="4" />
              <el-option label="已完成" :value="1" />
              <el-option label="未完成" :value="2" />
              <el-option label="无效报告" :value="3" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入姓名"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.account"
              clearable
              placeholder="请输入账号"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
            <el-button icon="el-icon-download" @click="exportExcel">
              导出
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <div class="count-user">
      <div class="u-item">
        <div class="txt">
          总人数(人)
          <el-tooltip
            v-if="false"
            content="总人数 = 未加入 + 已完成 + 未完成"
            effect="dark"
            placement="top"
          >
            <span class="el-icon-question"></span>
          </el-tooltip>
        </div>
        <div class="num">{{ all }}</div>
      </div>
      <div v-if="false" class="u-item">
        <div class="txt">未加入(人)</div>
        <div class="num">{{ unjoin }}</div>
      </div>
      <div class="u-item" style="background: #e4f9df; color: #37b219">
        <div class="txt">已完成(人)</div>
        <div class="num">{{ comp }}</div>
      </div>
      <div class="u-item" style="background: #f9f5d4; color: #b28d12">
        <div class="txt">未完成(人)</div>
        <div class="num">{{ uncomp }}</div>
      </div>
      <div class="u-item" style="background: #f5f7fa; color: #999">
        <div class="txt">无效报告(份)</div>
        <div class="num">{{ untrue }}</div>
      </div>
    </div>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      :expand-row-keys="expands"
      :row-key="getRowKeys"
      @row-click="clickRowHandle"
      @selection-change="setSelectRows"
    >
      <el-table-column type="expand">
        <template #default="{ row }">
          <div class="expand-box">
            <div v-for="(item, index) in row.list" :key="index" class="item">
              <div class="left">
                <span v-if="item.type === 'survey'">问卷：</span>
                <span>{{ item.paper }}</span>
                <el-progress :percentage="item.rate" />
              </div>
              <div class="right">
                <el-button
                  v-if="item.type !== 'survey'"
                  :disabled="item.rate !== 100"
                  icon="el-icon-document"
                  size="mini"
                  type="primary"
                  @click="toNext(item.eval_id, '/other/teamEvalRecord')"
                >
                  测评报告
                </el-button>
                <el-button
                  :disabled="item.rate === 0"
                  icon="el-icon-reading"
                  size="mini"
                  type="primary"
                  @click="
                    toNext(
                      item.eval_id,
                      item.type !== 'survey'
                        ? '/other/answerNotes'
                        : '/other/surveyAnswerNotes',
                      'team'
                    )
                  "
                >
                  答题记录
                </el-button>
                <el-button
                  :disabled="item.rate === 0"
                  icon="el-icon-edit"
                  size="mini"
                  type="primary"
                  @click="redo(item.eval_id, item.type)"
                >
                  重做
                </el-button>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="姓名" prop="name" />
      <el-table-column align="center" label="账号" prop="account" />
      <el-table-column align="center" label="组织结构" prop="origin" />
      <el-table-column align="center" label="完成度" prop="rate">
        <template #default="{ row }">
          <el-tag
            :type="
              row.rate * 1 === 100
                ? 'success'
                : row.rate * 1 === 0
                ? 'danger'
                : 'warning'
            "
          >
            {{ row.rate * 1 + '%' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="加入计划时间" prop="created_at">
        <template #default="{ row }">
          <el-tag v-if="row.created_at === '未加入'" type="info">
            {{ row.created_at }}
          </el-tag>
          <template v-else>{{ row.created_at }}</template>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="160">
        <template #default="{ row }">
          <el-button type="text">查看</el-button>
          <el-button v-if="row.to_free" type="text" @click.stop="setFree(row)">
            免支付
          </el-button>
          <el-popover v-else trigger="hover" width="180">
            <div><img :src="row.code.code" width="100%" /></div>
            <el-button
              slot="reference"
              style="margin-left: 10px"
              type="text"
              @click.stop=""
            >
              团测码
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <sync-data ref="syncData" @fetch-data="getPath" />
  </div>
</template>

<script>
  import { baseURL } from '@/config'
  import {
    getSchelList,
    getSchelTotal,
    getTeamTestOrgin,
    redoEval,
    setFreeOne,
  } from '@/api/teamList'
  import SyncData from './components/SyncData'
  import { isNJ } from '@/utils/utils'

  export default {
    name: 'TeamSchel',
    components: { SyncData },
    data() {
      return {
        isNJ: isNJ(),
        list: [],
        treeData: [], //获取组织结构
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          id: '',
          pageNo: 1,
          pageSize: 10,
          name: '',
          type: 4,
          origin: '',
          account: '',
        },
        expands: [],
        all: 0,
        comp: 0,
        uncomp: 0,
        untrue: 0,
        unjoin: 0,
        button: {},
        defaultProps: {
          children: 'children',
          label: 'name',
          value: 'id',
          checkStrictly: true,
        },
      }
    },
    created() {
      this.getPath()
    },
    methods: {
      setFree(row) {
        this.$baseConfirm(
          '确定对【' + row.name + '】开启免支付吗？',
          null,
          () => {
            setFreeOne({
              uid: row.uid,
              tid: this.$route.query.id,
            }).then(async (res) => {
              if (res.code === 200) {
                this.$baseMessage(res.msg, 'success')
                await this.fetchData()
              } else {
                this.$baseMessage(res.msg, 'error')
              }
            })
          }
        )
      },
      getPath() {
        const route = this.$route
        this.$nextTick(async () => {
          let rid = route.query.id
          this.queryForm.id = rid
          this.fetchData()
          if (!this.isNJ) {
            const { stat, origin } = await getSchelTotal(this.queryForm)
            this.all = stat.total
            this.comp = stat.completed
            this.uncomp = stat.uncompleted
            this.untrue = stat.invalid
            this.treeData = origin
          }
        })
      },
      syncData() {
        this.$refs['syncData'].showEdit(this.queryForm.id)
      },
      handleChange(value) {
        let id = this.$refs.orCas.getCheckedNodes(true)
        this.queryForm.origin = id.length ? id[0].value : ''
      },
      async exportExcel() {
        this.listLoading = true
        let queryForm = this.queryForm
        queryForm.pageNo = 1
        queryForm.excel = 'excel'

        const { data, header, filename } = await getSchelList(queryForm)
        import('@/utils/excel').then((excel) => {
          excel.export_json_to_excel({
            header,
            data,
            filename: filename,
            autoWidth: true,
            bookType: 'xlsx',
          })
          this.listLoading = false
        })
      },
      exportExcel1() {
        let p = this.queryForm
        window.open(
          baseURL +
            '/teamtest/schel?id=' +
            p.id +
            '&name=' +
            p.name +
            '&type=' +
            p.type +
            '&origin=' +
            p.origin +
            '&excel=excel'
        )
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      getRowKeys(row) {
        return row.uid
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      async queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
        if (!this.isNJ) {
          const { stat, origin } = await getSchelTotal(this.queryForm)
          this.all = stat.total
          this.comp = stat.completed
          this.uncomp = stat.uncompleted
          this.untrue = stat.invalid
          this.treeData = origin
        }
      },
      async fetchData() {
        this.listLoading = true
        this.queryForm.excel = 'search'
        if (this.isNJ) {
          const {
            data,
            totalCount,
            all,
            comp,
            uncomp,
            untrue,
            button,
            origin,
          } = await getSchelList(this.queryForm)
          this.list = data
          this.total = totalCount
          this.all = all
          this.comp = comp
          this.uncomp = uncomp
          this.untrue = untrue
          this.button = button
          this.treeData = origin
        } else {
          const { data, totalCount, stat, button } = await getSchelList(
            this.queryForm
          )
          this.list = data
          this.total = totalCount
          this.button = button
        }
        this.listLoading = false
      },
      clickRowHandle(row, column, event) {
        // 展开收起
        if (this.expands.includes(row.uid)) {
          this.expands = this.expands.filter((val) => val !== row.uid)
        } else {
          this.expands = []
          this.expands.push(row.uid)
        }
      },
      toNext(id, url, type) {
        let routeData = this.routeTo({ id: id, type: type }, url)
        window.open(routeData.href, '_blank')
      },
      routeTo(id, url) {
        let routeData = this.$router.resolve({
          path: url,
          query: id,
        })
        return routeData
      },
      redo(id, type) {
        this.$baseConfirm(
          '重做将删除该用户本次测评数据，是否确认？',
          null,
          () => {
            redoEval({
              eval_id: id,
              type: type,
            }).then(async (res) => {
              this.$baseMessage(res.msg, 'success')
              await this.fetchData()
            })
          }
        )
      },
    },
  }
</script>
<style lang="scss">
  .schel-container {
    .count-user {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .u-item {
        width: 200px;
        border-radius: 5px;
        background-color: #f0fcfa;
        color: #26cdb7;
        margin-right: 20px;
        text-align: center;
        padding: 20px 0;
        .num {
          font-size: 30px;
          margin-top: 8px;
        }
      }
    }
    .expand-box {
      max-width: 700px;
      .item {
        display: flex;
        padding: 30px 20px;
        background: #f9f9f9;
        border-radius: 5px;
        &:not(:first-child) {
          margin-top: 15px;
        }
        .left {
          flex: 1;
          > span {
            display: inline-block;
            margin-bottom: 5px;
            font-size: 14px;
          }
        }
        .right {
          width: 260px;
          text-align: right;
        }
      }
    }
  }
  .tips {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: bold;
  }
</style>
