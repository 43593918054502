var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "setting-container" },
    [
      _vm.form
        ? _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "div",
                {
                  staticClass: "header",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [_vm._v("系统设置")]
              ),
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { "label-width": "80px", model: _vm.form },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "平台名称", prop: "sysname" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入平台名称" },
                        model: {
                          value: _vm.form.sysname,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sysname", $$v)
                          },
                          expression: "form.sysname",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客服电话", prop: "custphone" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "13",
                          placeholder: "请输入客服电话",
                        },
                        model: {
                          value: _vm.form.custphone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "custphone", $$v)
                          },
                          expression: "form.custphone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "封面", prop: "banner" } },
                    [
                      _c(
                        "div",
                        { staticClass: "img-uploader" },
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                accept: "image/png, image/jpeg",
                                action: _vm.UploadUrl(),
                                "auto-upload": false,
                                "on-change": _vm.fileChange,
                                "on-remove": _vm.handleRemove,
                                "show-file-list": false,
                              },
                            },
                            [
                              _vm.form.banner
                                ? _c("img", {
                                    staticClass: "img",
                                    attrs: { src: _vm.form.banner },
                                  })
                                : _c("i", { staticClass: "el-icon-plus" }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [
                              _c("div", [
                                _vm._v(" 只能上传 "),
                                _c("span", [_vm._v(".jpg、.png")]),
                                _vm._v(" 文件，且 "),
                                _c("span", [_vm._v("不超过1M")]),
                              ]),
                              _c("div", [_vm._v("(图片尺寸：750*360)")]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "medium", type: "primary" },
                          on: { click: _vm.save },
                        },
                        [_vm._v("保存")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }