var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "no-data" },
    [
      _c("el-image", {
        staticStyle: { width: "180px", height: "155px" },
        attrs: { src: _vm.img },
      }),
      _c("div", { staticClass: "txt" }, [_vm._v(_vm._s(_vm.txt))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }