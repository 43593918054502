var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "80px", model: _vm.form, rules: _vm.rules },
        },
        [
          _vm.isSid === "all"
            ? _c(
                "el-form-item",
                { attrs: { label: "渠道", prop: "sid" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "选择渠道" },
                      on: { change: _vm.bindChange },
                      model: {
                        value: _vm.form.sid,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "sid", $$v)
                        },
                        expression: "form.sid",
                      },
                    },
                    _vm._l(_vm.studioList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "姓名", prop: "name" } },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", placeholder: "请输入姓名" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "性别", prop: "sex" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.sex,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "sex", $$v)
                    },
                    expression: "form.sex",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("男")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("女")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "账号", prop: "account" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.optionType === "edit",
                  maxlength: "20",
                  minlength: "1",
                  placeholder: "可使用字母/数字/数字与字母组合，长度1~20位",
                },
                model: {
                  value: _vm.form.account,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "account", $$v)
                  },
                  expression: "form.account",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "密码", prop: "password" } },
            [
              _c("el-input", {
                attrs: {
                  autocomplete: "new-password",
                  maxlength: "12",
                  minlength: "6",
                  placeholder:
                    _vm.optionType === "edit"
                      ? "密码长度6~12位，不输入则使用原密码"
                      : "密码长度6~12位",
                  type: "password",
                },
                model: {
                  value: _vm.form.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "password", $$v)
                  },
                  expression: "form.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "组织结构", prop: "orgid" } },
            [
              _c("el-cascader", {
                ref: "orCas",
                staticStyle: { width: "100%" },
                attrs: { options: _vm.treeData, props: _vm.defaultProps },
                on: {
                  change: _vm.handleChange,
                  "visible-change": _vm.cascaderClicked,
                },
                model: {
                  value: _vm.form.orgid,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "orgid", $$v)
                  },
                  expression: "form.orgid",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系方式", prop: "phone" } },
            [
              _c("el-input", {
                attrs: {
                  maxlength: "11",
                  placeholder: "手机号码",
                  type: "tel",
                },
                model: {
                  value: _vm.form.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "phone", $$v)
                  },
                  expression: "form.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "第三方账号", prop: "third_uid" } },
            [
              _c("el-input", {
                attrs: {
                  maxlength: "18",
                  minlength: "1",
                  placeholder: "可使用字母/数字/数字与字母组合，长度1~18位",
                },
                model: {
                  value: _vm.form.third_uid,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "third_uid", $$v)
                  },
                  expression: "form.third_uid",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }