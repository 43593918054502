<template>
  <swiper ref="mySwiper" class="swiper-container" :options="swiper">
    <!--<div class="swiper-wrapper">-->
    <swiper-slide
      v-for="(item, index) in list"
      :key="index"
      class="swiper-slide"
    >
      <div
        class="content"
        :class="item.is_check ? 'select' : ''"
        @click.stop="itemClick(item)"
      >
        <div class="title">
          <span class="t">{{ item.name }}</span>
          <el-popover
            :content="item.descr"
            placement="top-start"
            trigger="hover"
            width="300"
          >
            <span slot="reference" style="cursor: pointer; font-size: 12px">
              <i class="el-icon-question"></i>
              说明
            </span>
          </el-popover>
        </div>
        <div class="detail">
          <div>
            <label class="lbl">【适用】：</label>
            <span>{{ item.range }}</span>
          </div>
          <div>
            <label class="lbl">【量表】：</label>
            <span>
              {{
                item.evals_name.length > 20
                  ? item.evals_name.substr(0, 11) + '...'
                  : item.evals_name
              }}
            </span>
            <el-tooltip
              v-if="item.evals_name.length > 20"
              effect="dark"
              placement="bottom"
            >
              <div slot="content" style="max-width: 300px; line-height: 1.6">
                <div
                  v-for="(subitem, j) in item.evals_name.split('、')"
                  :key="j"
                >
                  {{ j + 1 + '、' + subitem }}
                </div>
              </div>
              <el-button style="padding: 0" type="text">查看>></el-button>
            </el-tooltip>
          </div>
          <div>
            <label class="lbl">【开放报告】：</label>
            <span @click.stop="">
              <el-switch
                v-model="item.is_open"
                active-text="开"
                active-value="10"
                inactive-text="关"
                inactive-value="20"
                @change="switchChange(item)"
              />
            </span>
          </div>
          <div>
            <label class="lbl">【重复测试】：</label>
            <span @click.stop="">
              <el-switch
                v-model="item.is_repeat"
                active-text="开"
                active-value="10"
                inactive-text="关"
                inactive-value="20"
                @change="switchChange(item)"
              />
            </span>
          </div>
        </div>
      </div>
    </swiper-slide>
    <!--</div>-->
    <div slot="button-prev" class="swiper-button-prev">
      <i class="el-icon-arrow-left"></i>
    </div>
    <div slot="button-next" class="swiper-button-next">
      <i class="el-icon-arrow-right"></i>
    </div>
    <div slot="pagination" class="swiper-pagination"></div>
  </swiper>
</template>

<script>
  import { swiper, swiperSlide } from 'vue-awesome-swiper'
  import 'swiper/dist/css/swiper.css'
  export default {
    components: { swiper, swiperSlide },
    props: {
      list: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        swiper: {
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          pagination: '.swiper-pagination',
          initialSlide: 0,
          slideToClickedSlide: true,
          slidesPerView: 'auto',
          slideWidth: 360,
          paginationClickable: true,
          spaceBetween: 10,
          freeMode: true,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          runCallbacksOnInit: true,
        },
        curIndex: 0,
      }
    },
    watch: {
      list: {
        handler(newData) {
          let idx = newData.findIndex((o) => o.is_check === true)
          this.curIndex = idx
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {
      setTimeout(async () => {
        this.$refs.mySwiper && this.goToPage(this.curIndex)
      }, 1000)
    },
    methods: {
      goToPage(index) {
        this.$refs.mySwiper.swiper.slideTo(index, 1, true)
      },
      switchChange(item) {
        if (item.is_check) {
          this.itemClick(item)
        }
      },
      itemClick(item) {
        this.list.map((o) => {
          if (item.id !== o.id) {
            o.is_check = false
          }
        })
        this.$set(item, 'is_check', true)
        this.$emit('click', item)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .swiper-container {
    overflow: hidden;
    width: 100%;
    height: 100%;
    .swiper-button-prev,
    .swiper-button-next {
      width: 24px;
      font-size: 24px;
      line-height: 44px;
      color: #fff;
      background: rgba(78, 78, 78, 0.7);
    }
    .swiper-button-prev {
      left: 0;
    }
    .swiper-button-next {
      right: 0;
    }
  }

  .swiper-slide {
    width: 400px !important;
    height: 100%;
    padding: 30px 20px;
    .content {
      height: 170px;
      padding: 20px;
      border: 3px solid rgb(247, 248, 250);
      box-shadow: 0 0 5px 15px rgba(0, 0, 0, 0.02);
      backdrop-filter: blur(20px);
      background: url('~@/assets/card-bg.png') no-repeat;
      background-size: cover;
      border-radius: 7px;
      &.select {
        border-color: rgba(38, 205, 183, 0.98);
      }
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      > .t {
        font-size: 16px;
        font-weight: bold;
        color: rgba(38, 205, 183, 0.98);
      }
    }
    .detail {
      line-height: 1.6;
      .lbl {
        font-weight: bold;
      }
      > div {
        margin-bottom: 3px;
      }
    }
  }
</style>
