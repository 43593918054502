<template>
  <div class="mmpt-category-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-button
          v-if="button.add"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit"
        >
          添加
        </el-button>
        <el-button
          v-else
          disabled
          icon="el-icon-plus"
          title="暂无权限，如有需要，请联系管理员"
          type="primary"
        >
          添加
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" label="ID" prop="cate_id" />
      <el-table-column align="center" label="分类" prop="name" />
      <el-table-column align="center" label="排序" prop="sort" />
      <el-table-column
        align="center"
        label="添加时间"
        prop="add_at"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="操作" width="85">
        <template #default="{ row }">
          <el-button v-if="button.edit" type="text" @click="handleEdit(row)">
            编辑
          </el-button>
          <el-button
            v-else
            disabled
            title="暂无权限，如有需要，请联系管理员"
            type="text"
          >
            编辑
          </el-button>
          <el-button
            v-if="button.delete"
            type="text"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
          <el-button
            v-else
            disabled
            title="暂无权限，如有需要，请联系管理员"
            type="text"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getCateList, doDelete } from '@/api/mmptCategory'
  import Edit from './components/MmptCategoryEdit'

  export default {
    name: 'MmptCategory',
    components: { Edit },
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
        },
        button: {},
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        if (row.cate_id) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      handleDelete(row) {
        this.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg, code } = await doDelete({ id: row.cate_id })
          this.$baseMessage(msg, code === 200 ? 'success' : 'error')
          await this.fetchData()
        })
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getCateList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>
