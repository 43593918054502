<template>
  <div class="mmpt-eval-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="4">
        <el-button
          v-if="button.add"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit"
        >
          分发测评
        </el-button>
        <el-button
          v-else
          disabled
          icon="el-icon-plus"
          title="暂无权限，如有需要，请联系管理员"
          type="primary"
        >
          分发测评
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="20">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item v-if="isSid === 'all'">
            <el-select
              v-model="queryForm.sid"
              clearable
              filterable
              placeholder="选择渠道"
              @change="handCheckStudio"
            >
              <el-option
                v-for="item in studioList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.title"
              clearable
              placeholder="请输入标题"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入姓名"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.account"
              clearable
              placeholder="请输入账号"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.pid"
              clearable
              filterable
              placeholder="选择量表"
            >
              <el-option
                v-for="item in evalList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="queryForm.status" placeholder="状态">
              <el-option label="全部" value="0" />
              <el-option label="已完成" value="10" />
              <el-option label="未完成" value="20" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column label="标题" prop="eval_name" />
      <el-table-column label="姓名" min-width="120" prop="name">
        <template #default="{ row }">
          <div class="user">
            <img v-if="row.sex * 1 === 1" src="@/assets/male.png" />
            <img v-else-if="row.sex * 1 === 2" src="@/assets/female.png" />
            <img v-else src="@/assets/unknow.png" />
            <span>{{ row.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="账号" prop="account" />
      <el-table-column label="测评量表" prop="paper_name" />
      <el-table-column align="center" label="开放报告" prop="is_open">
        <template #default="{ row }">
          <el-tag :type="row.is_open === '是' ? '' : 'info'">
            {{ row.is_open }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="分发测评" prop="" width="120">
        <template #default="{ row }">
          <el-button type="text" @click="joinT(row)">链接和二维码</el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态" prop="status">
        <template #default="{ row }">
          <el-tag :type="row.status === '已完成' ? 'success' : 'warning'">
            {{ row.status }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="创建时间" prop="created_at" />
      <el-table-column align="center" label="完成时间" prop="complete_at" />
      <el-table-column align="center" label="操作" width="120">
        <template #default="{ row }">
          <el-button v-if="row.status === '已完成'" disabled type="text">
            {{ row.status }}
          </el-button>
          <el-button v-else type="text" @click="toDo(row)">去测评</el-button>
          <el-button
            v-if="button.delete"
            class="del"
            type="text"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
          <el-button
            v-else
            disabled
            title="暂无权限，如有需要，请联系管理员"
            type="text"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
    <join-eval ref="join" />
  </div>
</template>

<script>
  import { getPsyEval, getEvalListBySid, delEval } from '@/api/mmptCategory'
  import Edit from './components/MmptEvalEdit'
  import JoinEval from '@/views/mmpt/eval/components/JoinEval'
  import { mapGetters } from 'vuex'
  import { getStudioList } from '@/api/platformChannelManagement'

  export default {
    name: 'MmptEval',
    components: { Edit, JoinEval },
    data() {
      return {
        list: [],
        evalList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          title: '',
          name: '',
          pid: '',
          status: '',
          account: '',
        },
        button: {},
        studioList: [],
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    created() {
      if (this.isSid === 'all') {
        this.getStudio()
      }
      this.getEvalList()
      this.fetchData()
    },
    methods: {
      async getStudio() {
        const { data } = await getStudioList()
        this.studioList = data
      },
      async getEvalList() {
        const { data } = await getEvalListBySid()
        this.evalList = data
      },
      toDo(row) {
        let routeData = this.$router.resolve({
          path: '/other/paper',
          query: {
            id1: row.id,
            id2: row.paper_id,
            id3: row.eval_id,
          },
        })
        window.open(routeData.href, '_blank')
      },
      joinT(row) {
        this.$refs['join'].showEdit(row)
      },
      handCheckStudio(val) {
        this.queryForm.sid = val
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        if (row.id) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      handleDelete(row) {
        this.$baseConfirm(
          '删除后无法恢复，确定要删除当前项吗',
          null,
          async () => {
            const { msg, code } = await delEval({
              id: row.id,
              eval_id: row.eval_id,
            })
            this.$baseMessage(msg, code === 200 ? 'success' : 'error')
            await this.fetchData()
          }
        )
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getPsyEval(this.queryForm)
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>
<style lang="scss">
  .mmpt-eval-container {
    .user {
      display: flex;
      align-items: center;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      span {
        margin-left: 6px;
      }
    }
    .price {
      color: #999;
      text-decoration-line: line-through;
    }
    .sale-price {
      color: #e64e1f;
    }
  }
</style>
