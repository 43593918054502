import request from '@/utils/request'

//获取团测列表
export function getList(params) {
  return request({
    url: '/teamtest/team-list',
    method: 'get',
    params,
  })
}

//获取渠道下单个组织结构下的全部信息(团测/包含是否勾选)
export function getEvalOriginList(params) {
  return request({
    url: '/router/get-eval-origin',
    method: 'get',
    params,
  })
}

//获取团测进度
export function getSchelList(params) {
  return request({
    timeout: 180000,
    url: '/teamtest/schel',
    method: 'get',
    params,
  })
}

//获取团测进度总数
export function getSchelTotal(params) {
  return request({
    timeout: 180000,
    url: '/teamtest/schel-total',
    method: 'get',
    params,
  })
}

//获取团测进度组织结构
export function getTeamTestOrgin(params) {
  return request({
    url: '/router/get-team-origin',
    method: 'get',
    params,
  })
}
//获取团测进度下载状态
export function getTeamTestUploadStatus(params) {
  return request({
    url: '/teamtest/download-report-list',
    method: 'get',
    params,
  })
}
//打包、下载
export function packReport(params) {
  return request({
    url: '/teamtest/pack-report',
    method: 'get',
    params,
  })
}

//获取答题记录
export function getAnswerNote(params) {
  return request({
    url: '/teamtest/ques',
    method: 'get',
    params,
  })
}

//获取个人测评报告
export function getSingleRecord(params) {
  return request({
    url: '/teamtest/team-record',
    method: 'get',
    params,
  })
}

//获取团体测评报告
export function getTeamRecord(params) {
  return request({
    url: '/teamtest/team-stat',
    method: 'get',
    params,
  })
}
export function getTeamRecord2(data) {
  return request({
    timeout: 600000,
    url: '/teamtest/report',
    method: 'post',
    data,
  })
}
export function checkReport(data) {
  return request({
    url: '/teamtest/check-report',
    method: 'post',
    data,
  })
}

//新增、编辑
export function doEdit(data) {
  if (data.id > 0) {
    return request({
      url: '/teamtest/team-edit',
      method: 'post',
      data,
    })
  } else {
    return request({
      url: '/teamtest/team-add',
      method: 'post',
      data,
    })
  }
}

//获取单个团测详情
export function getDetail(data) {
  return request({
    url: '/teamtest/team-detail',
    method: 'post',
    data,
  })
}

//编辑团测计划量表
export function setEvals(data) {
  return request({
    url: '/teamtest/edit-evals',
    method: 'post',
    data,
  })
}

//状态设置
export function setStatus(data) {
  return request({
    url: '/teamtest/change-test',
    method: 'post',
    data,
  })
}

//个体预警列表
export function getPersonWarningList(params) {
  return request({
    url: '/teamtest/person-warning',
    method: 'get',
    params,
  })
}

//个人预警详情
export function getPersonWarningDetail(params) {
  return request({
    url: '/teamtest/person-warning-detail',
    method: 'get',
    params,
  })
}

//团测计划筛选
export function getTeamTest(params) {
  return request({
    url: '/teamtest/get-team-test',
    method: 'get',
    params,
  })
}

//团测量表筛选
export function getTeamPaper(params) {
  return request({
    url: '/teamtest/get-team-paper',
    method: 'get',
    params,
  })
}

//团体预警
export function getTeamWarningDetail(params) {
  return request({
    url: '/teamtest/team-warning',
    method: 'get',
    params,
  })
}
//http://edutest.haotupsy.com/teamtest/warn-list
export function getTeamWarningList(data) {
  return request({
    url: '/teamtest/warn-list',
    method: 'post',
    data,
  })
}

//获取文章分类
export function getNormalType(params) {
  return request({
    url: '/router/get-normal-type',
    method: 'get',
    params,
  })
}

//获取团测列表
export function getTeamTestList(params) {
  return request({
    url: '/teamtest/report-list',
    method: 'get',
    params,
  })
}
//生成团测报告
export function createReport(params) {
  return request({
    url: '/teamtest/create-report',
    method: 'get',
    params,
  })
}
//下载团测报告
export function downLoadReport(params) {
  return request({
    url: '/teamtest/download-report',
    method: 'get',
    params,
  })
}
//查看团测报告
export function viewReport(params) {
  return request({
    url: '/teamtest/view-report',
    method: 'get',
    params,
  })
}
//下载原始数据
export function downTeamExcel(params) {
  return request({
    url: '/teamtest/get-team-excel',
    method: 'get',
    params,
  })
}
//下载预警数据
export function downWarnExcel(params) {
  return request({
    url: '/teamtest/get-warn-excel',
    method: 'get',
    params,
  })
}

export function doDelete(data) {
  return request({
    url: '/teamtest/change-test',
    method: 'post',
    data,
  })
}

//自定义字段
export function getExpandList(params) {
  return request({
    url: '/setting/custom-list',
    method: 'get',
    params,
  })
}

//新增、编辑字段
export function expandEdit(data) {
  return request({
    url: '/setting/custom-edit',
    method: 'post',
    data,
  })
}

//删除自定义字段
export function expandDel(data) {
  return request({
    url: '/setting/custom-del',
    method: 'post',
    data,
  })
}

//获取生成团测报告的组织结构
export function getTeamOrigin(params) {
  return request({
    url: '/teamtest/report-origin',
    method: 'get',
    params,
  })
}

//检测生成团测报告时是否要选择组织结构
export function checkToReport(params) {
  return request({
    url: '/teamtest/report-check',
    method: 'get',
    params,
  })
}

//问卷列表
export function getSurveyList(params) {
  return request({
    url: '/survey',
    method: 'get',
    params,
  })
}
//问卷详情
export function getSurveyDetail(params) {
  return request({
    url: '/survey/info',
    method: 'get',
    params,
  })
}

//添加问卷
export function addSurvey(data) {
  return request({
    url: '/survey/add',
    method: 'post',
    data,
  })
}

//更新问卷
export function editSurvey(id, data) {
  return request({
    url: '/survey/edit?id=' + id,
    method: 'post',
    data,
  })
}

//问卷详情
export function getSurveyAnalyse(params) {
  return request({
    url: '/survey/analyse',
    method: 'get',
    params,
  })
}

//重做量表
export function redoEval(data) {
  return request({
    url: '/teamtest/redo',
    method: 'post',
    data,
  })
}

//团测同步数据验证
export function syncDataCheck(data) {
  return request({
    url: '/teamtest/sync-data-check',
    method: 'post',
    data,
  })
}

//提交团测同步数据
export function syncDataSave(data) {
  return request({
    url: '/teamtest/sync-data-save',
    method: 'post',
    data,
  })
}

//提交团测同步数据teamtest
export function updatePlan(data) {
  return request({
    url: '/teamtest/team-plan-update',
    method: 'post',
    timeout: 300000,
    data,
  })
}

//团测设置某人免支付
export function setFreeOne(data) {
  return request({
    url: '/teamtest/team-free',
    method: 'post',
    data,
  })
}
