<template>
  <div class="organization-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="isSid === 'all' ? 8 : 24">
        <el-button
          :disabled="!button.add"
          icon="el-icon-plus"
          type="primary"
          @click="addOrg('', 'first')"
        >
          添加
        </el-button>
        <el-button
          v-if="button.add"
          icon="el-icon-document-add"
          title="导入组织结构"
          type="primary"
          @click="handleEdit"
        >
          导入
        </el-button>
        <el-button
          v-else
          disabled
          icon="el-icon-document-add"
          title="暂无权限，如有需要，请联系管理员"
          type="primary"
        >
          导入
        </el-button>
        <div style="margin-bottom: 10px">
          为避免导入组织结构失败，请先
          <el-button title="下载Excel模版" type="text" @click="downLoadDoc">
            下载模版
          </el-button>
        </div>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel v-if="isSid === 'all'" :span="16">
        <el-form
          v-if="isSid === 'all'"
          :inline="true"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item>
            <el-select
              v-model="queryForm.sid"
              filterable
              placeholder="渠道名称"
            >
              <el-option
                v-for="item in studioList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      ref="refTable"
      v-loading="listLoading"
      border
      :data="list"
      row-key="id"
      :tree-props="{ children: 'children' }"
      @row-click="clickRow"
      @selection-change="setSelectRows"
    >
      <el-table-column v-if="false" align="center" type="selection" />
      <el-table-column label="名称" prop="name">
        <template #default="{ row }">
          {{ row.name }}
          <el-tooltip
            v-if="row.pid == 0"
            :content="`ID：${row.id}`"
            effect="dark"
            placement="top-start"
          >
            <i class="el-icon-info" style="color: #ccc" />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" label="创建时间" prop="created_at" />
      <el-table-column align="center" label="操作" width="180">
        <template #default="{ row }">
          <el-button v-if="button.edit" type="text" @click.stop="addOrg(row)">
            新增下级
          </el-button>
          <el-button
            v-else
            disabled
            title="暂无权限，如有需要，请联系管理员"
            type="text"
          >
            新增下级
          </el-button>
          <el-button
            v-if="button.edit"
            type="text"
            @click.stop="handleEdit(row)"
          >
            编辑
          </el-button>
          <el-button
            v-else
            disabled
            title="暂无权限，如有需要，请联系管理员"
            type="text"
          >
            编辑
          </el-button>
          <el-button
            v-if="button.delete"
            class="del"
            type="text"
            @click.stop="handleDelete(row)"
          >
            删除
          </el-button>
          <el-button
            v-else
            disabled
            title="暂无权限，如有需要，请联系管理员"
            type="text"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <or-import ref="orImport" :list="studioList" @fetch-data="fetchData" />
    <add ref="add" @click-row="clickRow" @fetch-data="fetchData" />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getStudioList } from '@/api/platformChannelManagement'
  import {
    getOriginList,
    doDelete,
    getOrginDoc,
  } from '@/api/userManagementOrganization'
  import OrImport from './components/OrganizationImport'
  import Add from './components/OrganizationAdd'
  import Edit from './components/OrganizationEdit'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Organization',
    components: { OrImport, Add, Edit },
    data() {
      return {
        list: [],
        studioList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          sid: '',
        },
        button: {},
        docUrl: '', //文档地址
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    created() {
      this.getDoc()
      this.getStudio()
      this.fetchData()
    },
    methods: {
      async getStudio() {
        const { data } = await getStudioList()
        this.studioList = data
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        if (row.id) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['orImport'].showEdit()
        }
      },
      addOrg(row, type) {
        this.$refs['add'].showEdit(row, type)
      },
      handleDelete(row) {
        this.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await doDelete({ id: row.id })
          this.$baseMessage(msg, 'success')
          await this.fetchData()
        })
      },
      clickRow(row, index, e) {
        this.$refs.refTable.toggleRowExpansion(row)
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      getDoc() {
        getOrginDoc({ type: 'origin' }).then((res) => {
          if (res.code === 200) {
            this.docUrl = res.data
          }
        })
      },
      downLoadDoc() {
        window.location.href = this.docUrl
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getOriginList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>
