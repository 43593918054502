<template>
  <div class="archives-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="用户档案" name="person">
        <person :uid="uid" />
      </el-tab-pane>
      <el-tab-pane label="心理测评" name="eval">
        <eval :datas="evalList" />
      </el-tab-pane>
      <el-tab-pane label="团体测评" name="teamtest">
        <teamtest :datas="teamtestList" />
      </el-tab-pane>
      <el-tab-pane label="预警记录" name="mental">
        <mental v-if="activeName === 'mental'" :type="uid" />
      </el-tab-pane>
      <el-tab-pane label="访谈评估" name="assess">
        <assess v-if="activeName === 'assess'" :uid="uid" />
      </el-tab-pane>
      <el-tab-pane label="危机干预" name="meddle">
        <meddle v-if="activeName === 'meddle'" :uid="uid" />
      </el-tab-pane>
      <el-tab-pane label="咨询记录" name="psy">
        <psy v-if="activeName === 'psy'" :datas="psyList" />
      </el-tab-pane>
      <el-tab-pane label="心理曲线" name="line">
        <chart :uid="uid" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import { memberRecord } from '@/api/userManagementOrganization'
  import Person from './components/person'
  import Eval from './components/eval'
  import Teamtest from './components/teamtest'
  import Meddle from './components/meddle'
  import Assess from './components/assess'
  import Psy from './components/psy'
  import Chart from './components/userChart'
  import Mental from '@/views/mental/personList'
  export default {
    name: 'Archives',
    components: {
      Person,
      Eval,
      Teamtest,
      Assess,
      Meddle,
      Psy,
      Chart,
      Mental,
    },
    data() {
      return {
        activeName: 'person',
        personList: {}, //个人资料
        evalList: [], //单体测评
        teamtestList: [], //团测
        meddleList: [], //干预
        psyList: [], //咨询
        uid: '',
      }
    },
    created() {
      const route = this.$route
      this.$nextTick(() => {
        let uid = route.query.id
        this.uid = uid
        //this.getData(uid, 'person')
        this.getData(uid, 'eval')
        this.getData(uid, 'teamtest')
        //this.getData(uid, 'meddle')
        this.getData(uid, 'psy')
      })
    },
    mounted() {},
    methods: {
      getData(uid, type) {
        memberRecord({ uid: uid, type: type }).then((res) => {
          if (type === 'person') {
            this.personList = res.data
          } else if (type === 'eval') {
            this.evalList = res.data.basic
          } else if (type === 'teamtest') {
            this.teamtestList = res.data.basic
          } else if (type === 'meddle') {
            this.meddleList = res.data.basic
          } else if (type === 'psy') {
            this.psyList = res.data.basic
          }
        })
      },
      handleClick(tab, event) {},
    },
  }
</script>
<style lang="scss" scoped>
  .archives-container {
    :deep .el-tabs {
      .el-tabs__header {
        margin-bottom: 30px;
      }
      .el-tabs__item.is-active {
        font-weight: bold !important;
      }
    }
  }
</style>
