var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "keep-alive",
    { attrs: { include: _vm.cachedRoutes, max: _vm.keepAliveMaxNum } },
    [_c("router-view", { key: _vm.key })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }