var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "list-container" },
    [
      _c(
        "vab-query-form",
        [
          _vm.button.search
            ? _c(
                "vab-query-form-right-panel",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: { inline: true, model: _vm.queryForm },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: "请选择工作室",
                              },
                              model: {
                                value: _vm.queryForm.sid,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "sid", $$v)
                                },
                                expression: "queryForm.sid",
                              },
                            },
                            _vm._l(_vm.studioList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入用户昵称",
                            },
                            model: {
                              value: _vm.queryForm.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "queryForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入电话号码",
                            },
                            model: {
                              value: _vm.queryForm.phone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm,
                                  "phone",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "queryForm.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-search",
                                type: "primary",
                              },
                              on: { click: _vm.queryData },
                            },
                            [_vm._v(" 查询 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.list },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", prop: "id" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "工作室",
              prop: "nickname",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "咨询师",
              prop: "name",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " + _vm._s(row.name + "(" + row.phone + ")") + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "头像",
              prop: "psy_avatar",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("el-image", {
                      attrs: { lazy: "", src: row.psy_avatar },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "等级/擅长领域",
              prop: "areas",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " + _vm._s(row.psy_level + "/" + row.type_name) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "二维码",
              prop: "psy_ewm",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("el-image", { attrs: { lazy: "", src: row.psy_ewm } }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "咨询费用",
              prop: "cost_range",
              "show-overflow-tooltip": "",
            },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }