var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "appointment-con-record-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-left-panel",
            { attrs: { span: 8 } },
            [
              _vm.button.add
                ? _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-plus", type: "primary" },
                      on: { click: _vm.handleEdit },
                    },
                    [_vm._v(" 添加 ")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: "",
                        icon: "el-icon-plus",
                        title: "暂无权限，如有需要，请联系管理员",
                        type: "primary",
                      },
                    },
                    [_vm._v(" 添加 ")]
                  ),
            ],
            1
          ),
          _vm.button.search
            ? _c(
                "vab-query-form-right-panel",
                { attrs: { span: 16 } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: { inline: true, model: _vm.queryForm },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _vm.isSid === "all"
                        ? _c(
                            "el-form-item",
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "请选择渠道",
                                  },
                                  on: { change: _vm.bindChange },
                                  model: {
                                    value: _vm.queryForm.sid,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryForm, "sid", $$v)
                                    },
                                    expression: "queryForm.sid",
                                  },
                                },
                                _vm._l(_vm.studioList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入咨询用户",
                            },
                            model: {
                              value: _vm.queryForm.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "queryForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择咨询师",
                              },
                              model: {
                                value: _vm.queryForm.counsellor_id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm,
                                    "counsellor_id",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "queryForm.counsellor_id",
                              },
                            },
                            _vm._l(_vm.teacherList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择咨询问题",
                              },
                              model: {
                                value: _vm.queryForm.question_id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm,
                                    "question_id",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "queryForm.question_id",
                              },
                            },
                            _vm._l(_vm.questList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.title, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-search",
                                type: "primary",
                              },
                              on: { click: _vm.queryData },
                            },
                            [_vm._v(" 查询 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { border: "", data: _vm.list },
          on: { "selection-change": _vm.setSelectRows },
        },
        [
          _c("el-table-column", {
            attrs: { label: "咨询用户", prop: "user.name" },
          }),
          _c("el-table-column", {
            attrs: { label: "咨询时段", prop: "start_time" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("div", [_vm._v(_vm._s(row.start_time))]),
                    _c("div", [_vm._v(_vm._s(row.end_time))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "咨询师", prop: "counsellor.name" },
          }),
          _c("el-table-column", {
            attrs: { label: "咨询问题", prop: "question.title" },
          }),
          _c("el-table-column", {
            attrs: { label: "主诉", prop: "user_complain" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "top-start",
                          trigger: "hover",
                          width: "300",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "reference",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "ellipsis" }, [
                                    _vm._v(
                                      " " + _vm._s(row.user_complain) + " "
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_vm._v(" " + _vm._s(row.user_complain) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "咨询师分析", prop: "counsellor_explain" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "top-start",
                          trigger: "hover",
                          width: "300",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "reference",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "ellipsis" }, [
                                    _vm._v(
                                      " " + _vm._s(row.counsellor_explain) + " "
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_vm._v(" " + _vm._s(row.counsellor_explain) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "备注",
              prop: "remark",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm.button.edit
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: "",
                              title: "暂无权限，如有需要，请联系管理员",
                              type: "text",
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                    _vm.button.delete
                      ? _c(
                          "el-button",
                          {
                            staticClass: "del",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: "",
                              title: "暂无权限，如有需要，请联系管理员",
                              type: "text",
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("edit", {
        ref: "edit",
        attrs: { studio: _vm.studioList },
        on: { "fetch-data": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }