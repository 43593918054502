/**
 * @description 所有全局配置的状态管理，如无必要请勿修改
 */
import { isJson } from '@/utils/validate'
import {
  columnStyle,
  fixedHeader,
  i18n,
  layout,
  logo,
  showFullScreen,
  showLanguage,
  showNotice,
  showProgressBar,
  showRefresh,
  showSearch,
  showTabs,
  showTabsBarIcon,
  showTheme,
  showThemeSetting,
  tabsBarStyle,
  themeName,
  title,
} from '@/config'
import { getCompanyInfo } from '@/api/router'

const defaultTheme = {
  layout,
  themeName,
  columnStyle,
  fixedHeader,
  showProgressBar,
  showTabs,
  tabsBarStyle,
  showTabsBarIcon,
  showLanguage,
  showRefresh,
  showSearch,
  showTheme,
  showNotice,
  showFullScreen,
  showThemeSetting,
}
const getLocalStorage = (key) => {
  const value = localStorage.getItem(key)
  if (isJson(value)) {
    return JSON.parse(value)
  } else {
    return false
  }
}
const { collapse } = getLocalStorage('collapse')
const { language } = getLocalStorage('language')
const state = () => ({
  logo,
  title,
  pageTitle: '',
  loginName: '',
  loginSlogan: '',
  hiddenSupport: 0,
  device: 'desktop',
  collapse: collapse || false,
  language: language || i18n,
  theme: getLocalStorage('theme') || { ...defaultTheme },
  extra: { first: '', transferRouteName: '' },
  version: '',
})
const getters = {
  logo: (state) => state.logo,
  title: (state) => state.title,
  pageTitle: (state) => state.pageTitle,
  loginName: (state) => state.loginName,
  loginSlogan: (state) => state.loginSlogan,
  hiddenSupport: (state) => state.hiddenSupport,
  device: (state) => state.device,
  collapse: (state) => state.collapse,
  language: (state) => state.language,
  theme: (state) => state.theme,
  extra: (state) => state.extra,
  version: (state) => state.version,
}
const mutations = {
  openSideBar(state) {
    state.collapse = false
  },
  foldSideBar(state) {
    state.collapse = true
  },
  toggleDevice(state, device) {
    state.device = device
  },
  toggleCollapse(state) {
    state.collapse = !state.collapse
    localStorage.setItem('collapse', `{"collapse":${state.collapse}}`)
  },
  changeLanguage(state, language) {
    state.language = language
    localStorage.setItem('language', `{"language":"${language}"}`)
  },
  saveTheme(state) {
    localStorage.setItem('theme', JSON.stringify(state.theme))
  },
  resetTheme(state) {
    state.theme = { ...defaultTheme }
    localStorage.removeItem('theme')
    document.getElementsByTagName(
      'body'
    )[0].className = `vab-theme-${state.theme.themeName}`
  },
  setTitle(state, title) {
    state.title = title
  },
  setLogo(state, logo) {
    state.logo = logo
  },
  setPageTitle(state, pageTitle) {
    state.pageTitle = pageTitle
  },
  setLoginName(state, loginName) {
    state.loginName = loginName
  },
  setLoginSlogan(state, loginSlogan) {
    state.loginSlogan = loginSlogan
  },
  setHiddenSupport(state, hiddenSupport) {
    state.hiddenSupport = hiddenSupport
  },
  setVersion(state, version) {
    state.version = version
  },
}
const actions = {
  openSideBar({ commit }) {
    commit('openSideBar')
  },
  foldSideBar({ commit }) {
    commit('foldSideBar')
  },
  toggleDevice({ commit }, device) {
    commit('toggleDevice', device)
  },
  toggleCollapse({ commit }) {
    commit('toggleCollapse')
  },
  changeLanguage: ({ commit }, language) => {
    commit('changeLanguage', language)
  },
  saveTheme({ commit }) {
    commit('saveTheme')
  },
  resetTheme({ commit }) {
    commit('resetTheme')
  },
  async getCompany({ commit }) {
    let directory = window.document.location.href.match(/\/\?(\S*)\/#/)
    directory = directory ? directory[1] : ''
    const { data } = await getCompanyInfo({ directory: directory })
    commit('setVersion', data.edition)
    if (data.name) commit('setTitle', data.name)
    if (data.logo) {
      // 动态更改浏览器ico
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      link.href = data.logo
      document.getElementsByTagName('head')[0].appendChild(link)
      commit('setLogo', data.logo)
    }

    if (data.page_title) commit('setPageTitle', data.page_title)
    if (data.login_name) commit('setLoginName', data.login_name)
    if (data.login_slogan) commit('setLoginSlogan', data.login_slogan)
    if (data.hidden_support) commit('setHiddenSupport', data.hidden_support)
  },
}
export default { state, getters, mutations, actions }
