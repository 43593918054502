<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="450px"
    @close="close"
  >
    <div class="join-team">
      <div class="img">
        <el-image :src="params.code" />
      </div>
      <div class="btn">
        <el-button type="primary" @click="saveImg">下载二维码</el-button>
        <el-button type="primary" @click="copy(params.code_url)">
          复制链接
        </el-button>
      </div>
    </div>
    <div
      v-if="params.m_type * 1 === 2 || params.m_type * 1 === 3"
      class="join-team"
    >
      团测码：
      <el-input
        disabled
        style="width: 120px; margin-right: 10px"
        :value="params.clique_code"
      />
      <el-button type="text" @click="copy(params.clique_code)">
        点击复制
      </el-button>
    </div>
    <div class="tips">参与团测：1.手机扫码参与团测；2.复制链接进入团测</div>
  </el-dialog>
</template>

<script>
  import clip from '@/utils/clipboard'

  export default {
    name: 'JoinTeam',
    data() {
      return {
        params: {},
        title: '链接和二维码',
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      showEdit(row) {
        this.params = row
        this.dialogFormVisible = true
      },
      close() {
        this.dialogFormVisible = false
      },
      downloadIamge: function (imgsrc, name) {
        let image = new Image()
        image.setAttribute('crossOrigin', 'anonymous')
        image.onload = function () {
          let canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          let context = canvas.getContext('2d')
          context.drawImage(image, 0, 0, image.width, image.height)
          let url = canvas.toDataURL('image/png') //得到图片的base64编码数据
          let a = document.createElement('a') // 生成一个a元素
          let event = new MouseEvent('click') // 创建一个单击事件
          a.download = name || 'picture' // 设置图片名称没有设置则为默认
          a.href = url // 将生成的URL设置为a.href属性
          a.dispatchEvent(event) // 触发a的单击事件
        }
        image.src = imgsrc
      },
      saveImg: function () {
        this.downloadIamge(this.params.code, this.params.paper_name)
      },
      copy(val) {
        clip(val, event)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .join-team {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .img {
      width: 140px;
      height: 140px;
      margin-right: 30px;
      border: 3px solid $base-color-blue;
      border-radius: 10px;
      overflow: hidden;
    }
    .btn {
      flex: 1;
      text-align: right;
      .el-button {
        width: 120px;
        margin: 15px 0 !important;
      }
    }
  }
  .tips {
    color: #999;
    line-height: 40px;
  }
</style>
