import request from '@/utils/request'
//权限角色列表
export function getList(params) {
  return request({
    url: '/setting/role-list',
    method: 'get',
    params,
  })
}

//管理员列表
export function getAdminList(params) {
  return request({
    url: '/setting/admin-list',
    method: 'get',
    params,
  })
}

//角色 新增/编辑
export function addRole(data) {
  return request({
    url: '/setting/role-add',
    method: 'post',
    data,
  })
}

//获取渠道下所有菜单
export function getMenuList(params) {
  return request({
    url: '/router/get-role-menus',
    method: 'get',
    params,
  })
}

//获取渠道下所有角色
export function getAllRole(params) {
  return request({
    url: '/router/get-role-list',
    method: 'get',
    params,
  })
}

//管理员 新增/编辑
export function editRole(data) {
  return request({
    url: '/setting/admin-add',
    method: 'post',
    data,
  })
}

//权限角色类 删除&禁用
export function doDelete(data) {
  return request({
    url: '/setting/set-del',
    method: 'post',
    data,
  })
}

//管理员启用/停用
export function doSuspend(data) {
  return request({
    url: '/setting/set-suspend',
    method: 'post',
    data,
  })
}

//获取预警方案列表
export function getWarnPlan(params) {
  return request({
    url: '/warning/plan-lists',
    method: 'get',
    params,
  })
}
//操作预警方案
export function doOpration(data) {
  return request({
    url: '/warning/plan-change',
    method: 'post',
    data,
  })
}

//编辑预警方案
export function doPlanEdit(data) {
  return request({
    url: '/warning/plan-detail',
    method: 'post',
    data,
  })
}

//预警逻辑列表
export function getRuleList(data) {
  return request({
    url: '/warning/plan-rule',
    method: 'post',
    data,
  })
}

//操作预警逻辑、预警项列表
export function doRuleEdit(data) {
  return request({
    url: '/warning/plan-rule-detail',
    method: 'post',
    data,
  })
}

//预警项需要获取的字段内容
export function getWarnType(params) {
  return request({
    url: '/warning/plan-warning-type',
    method: 'get',
    params,
  })
}

//预警项的信息获取 编辑 新增
export function doRuleItemEdit(data) {
  return request({
    url: '/warning/plan-warning-item',
    method: 'post',
    data,
  })
}

export function getSystemMenuList(params) {
  return request({
    url: '/setting/menu-list',
    method: 'get',
    params,
  })
}

export function addSystemMenu(data) {
  return request({
    url: '/setting/menu-add',
    method: 'post',
    data,
  })
}

export function doDeleteSystemMenu(data) {
  return request({
    url: '/setting/menu-del',
    method: 'post',
    data,
  })
}

export function getRemixIconList(params) {
  return request({
    url: '/router/remix-icon-list',
    method: 'get',
    params,
  })
}
