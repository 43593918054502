<template>
  <div class="info">
    <el-timeline v-if="psyList && psyList.length">
      <el-timeline-item
        v-for="(activity, index) in psyList"
        :key="index"
        :color="'#1cc512'"
        :timestamp="activity.time"
      >
        <div class="block green">
          <div class="content">{{ activity.quest }}</div>
          <div class="btn">查看详情</div>
        </div>
      </el-timeline-item>
    </el-timeline>
    <no-data v-else :txt="'暂无记录'" />
  </div>
</template>

<script>
  import NoData from '@/components/noData/index'
  export default {
    name: 'Psy',
    components: { NoData },
    props: {
      datas: {
        type: Array,
        default: function () {
          return []
        },
      },
    },
    data() {
      return {
        psyList: [],
      }
    },
    watch: {
      datas: {
        handler(newData) {
          this.psyList = newData
        },
        deep: true,
        immediate: true,
      },
    },
    created() {},
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .info {
    margin-top: 20px;
    .info-title {
      font-weight: bold;
      padding: 0 10px;
      line-height: 40px;
      background-color: #f9f9f9;
      margin-bottom: 20px;
    }
    .info-content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px;
      .item {
        width: 50%;
        line-height: 40px;
        padding-left: 20px;
      }
    }
    .block {
      position: relative;
      width: 500px;
      display: flex;
      align-items: center;
      color: #fff;
      border-radius: 6px;
      padding: 20px;
      &:before {
        content: '';
        position: absolute;
        left: -10px;
        top: 8px;
        width: 0;
        height: 0;
        border-top: 10px solid;
        border-left: 10px solid transparent;
      }
      &.green {
        background: #1cc512;
        &:before {
          border-top-color: #1cc512;
        }
      }
      &.red {
        background: #f62b24;
        &:before {
          border-top-color: #f62b24;
        }
      }
      .content {
        flex: 1;
      }
      .btn {
        width: 100px;
        text-align: right;
      }
    }
  }
</style>
