<template>
  <div class="team-list-container">
    <vab-query-form>
      <vab-query-form-right-panel v-if="button.search" :span="24">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入预警对象"
            />
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="queryForm.time"
              align="center"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              range-separator="-"
              start-placeholder="开始日期"
              type="daterange"
              unlink-panels
              value-format="yyyy-MM-dd"
              @change="selectDate"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column label="预警对象" prop="user_name" />
      <el-table-column label="预警程度" prop="is_warn">
        <template #default="{ row }">
          <div class="warning-box">
            <span
              class="circle"
              :class="row.is_warn === '10' ? 'red' : 'green'"
            ></span>
            {{ row.is_warn === '20' ? '正常' : '高分预警' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="团测计划" prop="team" />
      <el-table-column label="量表名称" prop="paper_name" />
      <el-table-column label="测评时间" prop="test_at" />
      <el-table-column label="答题时长" prop="use_at" />
      <el-table-column label="操作" width="340">
        <template #default="{ row }">
          <el-button
            icon="el-icon-reading"
            size="mini"
            type="primary"
            @click="toNext(row.id, '/other/answerNotes', 'team')"
          >
            答题记录
          </el-button>
          <el-button
            icon="el-icon-document"
            size="mini"
            type="primary"
            @click="toNext(row.id, '/other/teamEvalRecord')"
          >
            测评报告
          </el-button>
          <el-button
            icon="el-icon-document"
            size="mini"
            type="warning"
            @click="toNext(row.id, '/other/singleWarnRecord')"
          >
            预警报告
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getPersonWarningList } from '@/api/teamList'
  import Edit from './components/TeamSingleEdit'

  export default {
    name: 'TeamSingle',
    components: { Edit },
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          name: '',
          time: [],
        },
        pickerOptions: {
          shortcuts: [
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近一个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近三个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                picker.$emit('pick', [start, end])
              },
            },
          ],
        },
        button: {},
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      setSelectRows(val) {
        this.selectRows = val
      },
      selectDate(val) {
        this.queryForm.time = val
      },
      toNext(id, url, type) {
        let routeData = this.routeTo({ id: id, type: type }, url)
        window.open(routeData.href, '_blank')
      },
      routeTo(id, url) {
        let routeData = this.$router.resolve({
          path: url,
          query: id,
        })
        return routeData
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getPersonWarningList(
          this.queryForm
        )
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .warning-box {
    .circle {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      margin-right: 4px;
      animation: move 2s ease-in-out infinite;
      &.red {
        background-color: red;
        box-shadow: 0 0 4px red;
      }
      &.green {
        background-color: #1cc512;
        box-shadow: 0 0 4px #1cc512;
      }
      @keyframes move {
        0% {
          transform: scale(0.6);
        }
        50% {
          transform: scale(1);
        }
        100% {
          transform: scale(0.6);
        }
      }
    }
  }
</style>
