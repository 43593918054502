var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add-form-container" },
    [
      _c("div", { staticClass: "add-title" }, [
        _vm._v(_vm._s(_vm.form.id ? "编辑角色" : "新增角色")),
      ]),
      _c(
        "el-row",
        { attrs: { gutter: 15 } },
        [
          _c(
            "el-col",
            {
              attrs: {
                lg: { span: 12, offset: 6 },
                md: { span: 20, offset: 2 },
                sm: { span: 20, offset: 2 },
                xl: { span: 12, offset: 6 },
                xs: 24,
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "demo-form",
                  attrs: {
                    "label-position": _vm.labelPosition,
                    "label-width": "130px",
                    model: _vm.form,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "角色名称" },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      ref: "rightTree",
                      attrs: { label: "权限", prop: "rights" },
                    },
                    [
                      _c("el-tree", {
                        ref: "qxTree",
                        attrs: {
                          data: _vm.rightList,
                          "default-checked-keys": _vm.form.rights,
                          "default-expanded-keys": ["1"],
                          "node-key": "id",
                          props: _vm.defaultProps,
                          "show-checkbox": "",
                        },
                        on: {
                          check: function ($event) {
                            return _vm.treeChecked("qxTree")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  false
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "组织结构", prop: "origin" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "输入关键字进行过滤" },
                            model: {
                              value: _vm.filterTree,
                              callback: function ($$v) {
                                _vm.filterTree = $$v
                              },
                              expression: "filterTree",
                            },
                          }),
                          _c("el-tree", {
                            ref: "ozTree",
                            attrs: {
                              data: _vm.dataTree,
                              "default-checked-keys": _vm.form.origin,
                              "filter-node-method": _vm.filterTreeNode,
                              "node-key": "id",
                              props: _vm.defaultProps2,
                              "show-checkbox": "",
                            },
                            on: {
                              check: function ($event) {
                                return _vm.treeChecked("ozTree")
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序", prop: "sort" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sort", $$v)
                          },
                          expression: "form.sort",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("form")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.form.id ? "保存修改" : "立即创建") +
                              " "
                          ),
                        ]
                      ),
                      !_vm.form.id
                        ? _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.resetForm("form")
                                },
                              },
                            },
                            [_vm._v(" 重置 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }