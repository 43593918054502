var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "article-container" },
    [
      _c("div", { staticClass: "add-title" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "el-row",
        { attrs: { gutter: 15 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "demo-form",
                  attrs: {
                    "label-position": _vm.labelPosition,
                    "label-width": "130px",
                    model: _vm.form,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题", prop: "title" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入文章标题" },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类", prop: "art_type" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", placeholder: "请选择分类" },
                          model: {
                            value: _vm.form.art_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "art_type", $$v)
                            },
                            expression: "form.art_type",
                          },
                        },
                        _vm._l(_vm.articleTypeList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "文章摘要", prop: "desc" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入文章摘要" },
                        model: {
                          value: _vm.form.desc,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "desc", $$v)
                          },
                          expression: "form.desc",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "封面", prop: "pic" } },
                    [
                      _c("div", { staticClass: "img-uploader" }, [
                        _c(
                          "div",
                          {
                            staticClass: "el-upload el-upload__text",
                            on: { click: _vm.selImg },
                          },
                          [
                            _vm.hasImg
                              ? _c("img", {
                                  staticClass: "img",
                                  attrs: { src: _vm.hasImg },
                                })
                              : _c("i", { staticClass: "el-icon-plus" }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "el-upload__tip",
                            attrs: { slot: "tip" },
                            slot: "tip",
                          },
                          [
                            _c("div", [
                              _vm._v(" 只能上传 "),
                              _c("span", [_vm._v(".jpg、.png")]),
                              _vm._v(" 文件，且 "),
                              _c("span", [_vm._v("不超过1M")]),
                            ]),
                            _c("div", [_vm._v("(图片尺寸3:2)")]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "内容", prop: "content" } },
                    [
                      _c("kind-editor", {
                        ref: "editor",
                        attrs: {
                          id: "editor",
                          "after-change": _vm.afterChange(),
                          content: _vm.form.content,
                          height: "300px",
                          "load-style-mode": false,
                          width: "100%",
                        },
                        on: { "on-content-change": _vm.onContentChange },
                        model: {
                          value: _vm.form.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "content", $$v)
                          },
                          expression: "form.content",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "阅读量", prop: "focus" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.focus,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "focus", $$v)
                          },
                          expression: "form.focus",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否推荐", prop: "is_rec" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.is_rec,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "is_rec", $$v)
                            },
                            expression: "form.is_rec",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "10" } }, [
                            _vm._v("是"),
                          ]),
                          _c("el-radio", { attrs: { label: "20" } }, [
                            _vm._v("否"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否展示", prop: "is_show" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.is_show,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "is_show", $$v)
                            },
                            expression: "form.is_show",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "10" } }, [
                            _vm._v("是"),
                          ]),
                          _c("el-radio", { attrs: { label: "20" } }, [
                            _vm._v("否"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否置顶", prop: "is_up" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.is_up,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "is_up", $$v)
                            },
                            expression: "form.is_up",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "10" } }, [
                            _vm._v("是"),
                          ]),
                          _c("el-radio", { attrs: { label: "20" } }, [
                            _vm._v("否"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("form")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.form.id ? "保存修改" : "立即发布") +
                              " "
                          ),
                        ]
                      ),
                      !_vm.form.id
                        ? _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.resetForm("form")
                                },
                              },
                            },
                            [_vm._v(" 重置 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("select-img", { ref: "selectImg", on: { checkedImg: _vm.getImg } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }