var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "schel-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "div",
            { staticClass: "tips" },
            [
              _vm._v(" 温馨提示：创建团测后，如有用户或组织结构调整，请手动 "),
              _c(
                "el-link",
                {
                  attrs: { type: "primary", underline: false },
                  on: { click: _vm.syncData },
                },
                [_vm._v(" 同步数据 >> ")]
              ),
            ],
            1
          ),
          _c(
            "vab-query-form-right-panel",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form",
                {
                  attrs: { inline: true, model: _vm.queryForm },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-cascader", {
                        ref: "orCas",
                        attrs: {
                          clearable: "",
                          options: _vm.treeData,
                          placeholder: "组织结构",
                          props: _vm.defaultProps,
                        },
                        on: { change: _vm.handleChange },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "是否完成" },
                          model: {
                            value: _vm.queryForm.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "type", $$v)
                            },
                            expression: "queryForm.type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: 4 },
                          }),
                          _c("el-option", {
                            attrs: { label: "已完成", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "未完成", value: 2 },
                          }),
                          _c("el-option", {
                            attrs: { label: "无效报告", value: 3 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入姓名" },
                        model: {
                          value: _vm.queryForm.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryForm,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入账号" },
                        model: {
                          value: _vm.queryForm.account,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryForm,
                              "account",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.account",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-search", type: "primary" },
                          on: { click: _vm.queryData },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-download" },
                          on: { click: _vm.exportExcel },
                        },
                        [_vm._v(" 导出 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "count-user" }, [
        _c("div", { staticClass: "u-item" }, [
          _c(
            "div",
            { staticClass: "txt" },
            [
              _vm._v(" 总人数(人) "),
              false
                ? _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content: "总人数 = 未加入 + 已完成 + 未完成",
                        effect: "dark",
                        placement: "top",
                      },
                    },
                    [_c("span", { staticClass: "el-icon-question" })]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "num" }, [_vm._v(_vm._s(_vm.all))]),
        ]),
        false
          ? _c("div", { staticClass: "u-item" }, [
              _c("div", { staticClass: "txt" }, [_vm._v("未加入(人)")]),
              _c("div", { staticClass: "num" }, [_vm._v(_vm._s(_vm.unjoin))]),
            ])
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "u-item",
            staticStyle: { background: "#e4f9df", color: "#37b219" },
          },
          [
            _c("div", { staticClass: "txt" }, [_vm._v("已完成(人)")]),
            _c("div", { staticClass: "num" }, [_vm._v(_vm._s(_vm.comp))]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "u-item",
            staticStyle: { background: "#f9f5d4", color: "#b28d12" },
          },
          [
            _c("div", { staticClass: "txt" }, [_vm._v("未完成(人)")]),
            _c("div", { staticClass: "num" }, [_vm._v(_vm._s(_vm.uncomp))]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "u-item",
            staticStyle: { background: "#f5f7fa", color: "#999" },
          },
          [
            _c("div", { staticClass: "txt" }, [_vm._v("无效报告(份)")]),
            _c("div", { staticClass: "num" }, [_vm._v(_vm._s(_vm.untrue))]),
          ]
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            border: "",
            data: _vm.list,
            "expand-row-keys": _vm.expands,
            "row-key": _vm.getRowKeys,
          },
          on: {
            "row-click": _vm.clickRowHandle,
            "selection-change": _vm.setSelectRows,
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "expand-box" },
                      _vm._l(row.list, function (item, index) {
                        return _c("div", { key: index, staticClass: "item" }, [
                          _c(
                            "div",
                            { staticClass: "left" },
                            [
                              item.type === "survey"
                                ? _c("span", [_vm._v("问卷：")])
                                : _vm._e(),
                              _c("span", [_vm._v(_vm._s(item.paper))]),
                              _c("el-progress", {
                                attrs: { percentage: item.rate },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "right" },
                            [
                              item.type !== "survey"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        disabled: item.rate !== 100,
                                        icon: "el-icon-document",
                                        size: "mini",
                                        type: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toNext(
                                            item.eval_id,
                                            "/other/teamEvalRecord"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 测评报告 ")]
                                  )
                                : _vm._e(),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: item.rate === 0,
                                    icon: "el-icon-reading",
                                    size: "mini",
                                    type: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toNext(
                                        item.eval_id,
                                        item.type !== "survey"
                                          ? "/other/answerNotes"
                                          : "/other/surveyAnswerNotes",
                                        "team"
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 答题记录 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: item.rate === 0,
                                    icon: "el-icon-edit",
                                    size: "mini",
                                    type: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.redo(item.eval_id, item.type)
                                    },
                                  },
                                },
                                [_vm._v(" 重做 ")]
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "姓名", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "账号", prop: "account" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "组织结构", prop: "origin" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "完成度", prop: "rate" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type:
                            row.rate * 1 === 100
                              ? "success"
                              : row.rate * 1 === 0
                              ? "danger"
                              : "warning",
                        },
                      },
                      [_vm._v(" " + _vm._s(row.rate * 1 + "%") + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "加入计划时间",
              prop: "created_at",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.created_at === "未加入"
                      ? _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v(" " + _vm._s(row.created_at) + " "),
                        ])
                      : [_vm._v(_vm._s(row.created_at))],
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("el-button", { attrs: { type: "text" } }, [
                      _vm._v("查看"),
                    ]),
                    row.to_free
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.setFree(row)
                              },
                            },
                          },
                          [_vm._v(" 免支付 ")]
                        )
                      : _c(
                          "el-popover",
                          { attrs: { trigger: "hover", width: "180" } },
                          [
                            _c("div", [
                              _c("img", {
                                attrs: { src: row.code.code, width: "100%" },
                              }),
                            ]),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { slot: "reference", type: "text" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                                slot: "reference",
                              },
                              [_vm._v(" 团测码 ")]
                            ),
                          ],
                          1
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("sync-data", { ref: "syncData", on: { "fetch-data": _vm.getPath } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }