var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "warning-container" },
    [
      _vm.queryForm.uid === "list" || _vm.queryForm.uid === ""
        ? [
            _c(
              "vab-query-form",
              [
                _c(
                  "vab-query-form-left-panel",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: !_vm.button.add,
                          icon: "el-icon-plus",
                          type: "primary",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit("", "", "add")
                          },
                        },
                      },
                      [_vm._v(" 添加 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: !_vm.button.download,
                          icon: "el-icon-download",
                        },
                        on: { click: _vm.exportExcel },
                      },
                      [_vm._v(" 导出 ")]
                    ),
                    _c("el-button", { on: { click: _vm.doSomething } }, [
                      _vm._v("批量处理"),
                    ]),
                    _vm.showSend
                      ? _c(
                          "el-button",
                          {
                            attrs: { icon: "el-icon-message" },
                            on: { click: _vm.sendMessage },
                          },
                          [_vm._v(" 群发通知 ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "vab-query-form-right-panel",
                  { attrs: { span: 18 } },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "queryForms",
                        attrs: { inline: true, model: _vm.queryForm },
                        nativeOn: {
                          submit: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _vm.isSid === "all"
                          ? _c(
                              "el-form-item",
                              { attrs: { prop: "sid" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "选择渠道",
                                    },
                                    model: {
                                      value: _vm.queryForm.sid,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.queryForm, "sid", $$v)
                                      },
                                      expression: "queryForm.sid",
                                    },
                                  },
                                  _vm._l(_vm.studioList, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "name" } },
                          [
                            _c("el-input", {
                              attrs: {
                                clearable: "",
                                placeholder: "请输入姓名",
                              },
                              model: {
                                value: _vm.queryForm.name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm,
                                    "name",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "queryForm.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "account" } },
                          [
                            _c("el-input", {
                              attrs: {
                                clearable: "",
                                placeholder: "请输入账号",
                              },
                              model: {
                                value: _vm.queryForm.account,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryForm,
                                    "account",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "queryForm.account",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "origin" } },
                          [
                            _c("el-cascader", {
                              ref: "orCas",
                              attrs: {
                                clearable: "",
                                options: _vm.treeData,
                                placeholder: "组织结构",
                                props: _vm.defaultProps,
                              },
                              on: {
                                change: _vm.handleChange,
                                "visible-change": _vm.cascaderClicked,
                              },
                              model: {
                                value: _vm.queryForm.origin,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "origin", $$v)
                                },
                                expression: "queryForm.origin",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "source" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "预警来源",
                                },
                                model: {
                                  value: _vm.queryForm.source,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryForm, "source", $$v)
                                  },
                                  expression: "queryForm.source",
                                },
                              },
                              _vm._l(_vm.sourceList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.sort },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "interve_sort" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "干预级别",
                                },
                                model: {
                                  value: _vm.queryForm.interve_sort,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryForm, "interve_sort", $$v)
                                  },
                                  expression: "queryForm.interve_sort",
                                },
                              },
                              _vm._l(_vm.interveList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.sort },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "warn_sort" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "预警级别",
                                },
                                model: {
                                  value: _vm.queryForm.warn_sort,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryForm, "warn_sort", $$v)
                                  },
                                  expression: "queryForm.warn_sort",
                                },
                              },
                              _vm._l(_vm.warningList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.sort },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "warn_at" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                align: "right",
                                "end-placeholder": "结束日期",
                                "picker-options": _vm.pickerOptions,
                                "range-separator": "-",
                                "start-placeholder": "开始日期",
                                type: "daterange",
                                "unlink-panels": "",
                                "value-format": "yyyy-MM-dd",
                              },
                              on: { change: _vm.selectDate },
                              model: {
                                value: _vm.queryForm.warn_at,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "warn_at", $$v)
                                },
                                expression: "queryForm.warn_at",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-search",
                                  type: "primary",
                                },
                                on: { click: _vm.queryData },
                              },
                              [_vm._v(" 查询 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-refresh",
                                  type: "primary",
                                },
                                on: { click: _vm.refresh },
                              },
                              [_vm._v(" 刷新 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "m-tab" },
              _vm._l(_vm.status, function (tab, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "m-t-item",
                    class: _vm.queryForm.status === tab.id ? "cur" : "",
                    on: {
                      click: function ($event) {
                        return _vm.bindStatus(tab.id)
                      },
                    },
                  },
                  [
                    _c("i", { class: tab.icon }),
                    _vm._v(" " + _vm._s(tab.name) + " "),
                  ]
                )
              }),
              0
            ),
          ]
        : _vm._e(),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { border: "", data: _vm.list },
          on: { "selection-change": _vm.selectChange },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "selection" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "姓名",
              "min-width": "120",
              prop: "name",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "user" },
                      [
                        row.sex === "男"
                          ? _c("img", {
                              attrs: { src: require("@/assets/male.png") },
                            })
                          : row.sex === "女"
                          ? _c("img", {
                              attrs: { src: require("@/assets/female.png") },
                            })
                          : _c("img", {
                              attrs: { src: require("@/assets/unknow.png") },
                            }),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(
                                  row,
                                  "/userManagement/member/archives"
                                )
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(row.name) + " ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "账号", prop: "account" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "组织结构", prop: "origin" },
          }),
          _c(
            "el-table-column",
            {
              attrs: { align: "center", label: "系统预警", prop: "warn_level" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      row.warn_color
                        ? _c(
                            "span",
                            {
                              class: { b_c: row.warn_color },
                              style: {
                                "background-color": _vm.hexToRgba(
                                  row.warn_color,
                                  0.1
                                ),
                                color: row.warn_color,
                                border:
                                  "1px solid " +
                                  _vm.hexToRgba(row.warn_color, 0.3),
                              },
                            },
                            [_vm._v(" " + _vm._s(row.warn_level) + " ")]
                          )
                        : _c("span", [_vm._v(_vm._s(row.warn_level))]),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "el-tooltip",
                    { attrs: { effect: "dark", placement: "bottom" } },
                    [
                      _c("template", { slot: "content" }, [
                        _vm._v(
                          " 系统预警是通过心理测评结束后，系统会根据（系统内置或者自定义预警逻辑） "
                        ),
                        _c("br"),
                        _vm._v(
                          " 识别出具有不同程度心理健康状态的名单，此类名单是需要心理老师紧急进行心 "
                        ),
                        _c("br"),
                        _vm._v(
                          " 理个案访谈工作的。心理老师需根据访谈结果对这部分名单再次确认，并完成 "
                        ),
                        _c("br"),
                        _vm._v(" 最终的心理访谈定级。 "),
                      ]),
                      _c("span", [
                        _vm._v(" 系统预警 "),
                        _c("i", { staticClass: "el-icon-question" }),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm.isSid == 115
            ? _c("el-table-column", {
                attrs: { label: "预警内容", prop: "mental_remark" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          row.mental_remark.length > 10
                            ? _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "top",
                                    trigger: "hover",
                                    width: "180",
                                  },
                                },
                                [
                                  _c("div", {
                                    staticStyle: {
                                      "font-size": "13px",
                                      "line-height": "1.6",
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(row.mental_remark),
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "ellipsis2",
                                    attrs: { slot: "reference" },
                                    domProps: {
                                      innerHTML: _vm._s(row.mental_remark),
                                    },
                                    slot: "reference",
                                  }),
                                ]
                              )
                            : _c("span", [_vm._v(_vm._s(row.mental_remark))]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2034860707
                ),
              })
            : _vm._e(),
          false
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "干预级别",
                  prop: "interve_level",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          row.interve_color
                            ? _c(
                                "span",
                                {
                                  class: { b_c: row.interve_color },
                                  style: {
                                    "background-color": _vm.hexToRgba(
                                      row.interve_color,
                                      0.1
                                    ),
                                    color: row.interve_color,
                                    border:
                                      "1px solid " +
                                      _vm.hexToRgba(row.interve_color, 0.3),
                                  },
                                },
                                [_vm._v(" " + _vm._s(row.interve_level) + " ")]
                              )
                            : _c("span", [_vm._v(_vm._s(row.interve_level))]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  340537573
                ),
              })
            : _vm._e(),
          _c(
            "el-table-column",
            {
              attrs: {
                align: "center",
                label: "预警来源",
                prop: "warn_source",
              },
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "el-tooltip",
                    { attrs: { effect: "dark", placement: "bottom" } },
                    [
                      _c("template", { slot: "content" }, [
                        _vm._v(
                          " 预警来源主要通过系统心理普查、个人测评以及班主任或者心理老师 "
                        ),
                        _c("br"),
                        _vm._v(
                          " 等方式添加，成为关注的名单作为访谈对象进行访谈定级。 "
                        ),
                      ]),
                      _c("span", [
                        _vm._v(" 预警来源 "),
                        _c("i", { staticClass: "el-icon-question" }),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: { align: "center", label: "预警时间", prop: "warn_at" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态", prop: "is_evaluate" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-tag",
                      { attrs: { type: row.is_evaluate ? "" : "danger" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(row.is_evaluate ? "已处理" : "待处理") +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "访谈评估", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.is_evaluate
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(row, "/mental/assess")
                              },
                            },
                          },
                          [_vm._v(" 查看 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: !_vm.button.add,
                              type: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(row, "evaluate", "add")
                              },
                            },
                          },
                          [_vm._v(" 添加 ")]
                        ),
                  ]
                },
              },
            ]),
          }),
          false
            ? _c("el-table-column", {
                attrs: { align: "center", label: "干预记录" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                disabled: !row.is_interve,
                                type: "text",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit(
                                    row,
                                    "/mental/interveList"
                                  )
                                },
                              },
                            },
                            [_vm._v(" 查看 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                disabled: !_vm.button.add,
                                type: "text",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit(row, "interve", "add")
                                },
                              },
                            },
                            [_vm._v(" 添加 ")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1715635160
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { disabled: !_vm.button.add, type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.evalEdit(row)
                          },
                        },
                      },
                      [_vm._v(" 分发测评 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("edit", { ref: "edit", on: { "fetch-data": _vm.fetchData } }),
      _c("add-record", {
        ref: "addrecord",
        on: { "fetch-data": _vm.fetchData },
      }),
      _c("eval-edit", { ref: "eval-edit" }),
      _c("assess-all", {
        ref: "assess-all",
        on: { "fetch-data": _vm.fetchData },
      }),
      _c("send-message", {
        ref: "send-message",
        on: { "fetch-data": _vm.fetchData },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }