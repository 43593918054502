import Vue from 'vue'
import App from './App'
import i18n from './i18n'
import store from './store'
import router from './router'
import '@/vab/styles/variables/element-variables.scss'
import { hasAccess } from '@/utils/hasAccess'
import '@/vab'
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

import Print from '@/extra/Print'
Vue.use(Print)

Vue.prototype.checkAccess = function (ability) {
  return hasAccess(ability)
}

Vue.config.productionTip = false
store.dispatch('settings/getCompany')
new Vue({
  el: '#app',
  i18n,
  store,
  router,
  render: (h) => h(App),
})
