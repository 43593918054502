<template>
  <div class="team-list-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="8">
        <span style="color: #999">
          温馨提示：若团测进度为0，则表示没有报告可以生成
        </span>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="16">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.title"
              clearable
              placeholder="请输入标题"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-refresh"
              type="primary"
              @click="refreshData"
            >
              刷新
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column v-if="isSid === 'all'" label="渠道" prop="studio" />
      <el-table-column label="团测标题" prop="title" />
      <el-table-column align="center" label="进度" prop="rate">
        <template #default="{ row }">
          <el-progress
            :percentage="row.rate * 1"
            :stroke-width="16"
            :text-inside="true"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" label="测评时间" prop="start_at">
        <template #default="{ row }">
          <div>{{ '开始时间：' + row.start_at }}</div>
          <div>{{ '结束时间：' + row.end_at }}</div>
        </template>
      </el-table-column>
      <el-table-column v-if="false" label="生成时间" prop="report_at">
        <template #default="{ row }">
          <span
            :style="{
              color:
                row.report_at === '未生成'
                  ? 'red'
                  : row.report_at === '生成中'
                  ? '#38c738'
                  : '',
            }"
          >
            {{ row.report_at }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="150">
        <template #default="{ row }">
          <el-button v-if="row.rate !== 0" type="text" @click="handleEdit(row)">
            团体报告
          </el-button>
          <el-button
            v-else
            disabled
            :title="row.rate === 0 ? '团测进度为0，暂无报告' : ''"
            type="text"
          >
            团体报告
          </el-button>
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              更多
              <i class="el-icon-arrow-down"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="beforeHandleCommand(row.id, 1)"
                :disabled="!button.download"
              >
                个人报告
              </el-dropdown-item>
              <el-dropdown-item
                :command="beforeHandleCommand(row.id, 2)"
                :disabled="!button.download"
              >
                原始数据
              </el-dropdown-item>
              <el-dropdown-item
                v-if="!isNJ"
                :command="beforeHandleCommand(row.id, 3)"
                :disabled="!button.download"
              >
                预警数据
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />

    <el-dialog
      :close-on-click-modal="false"
      title="下载"
      :visible.sync="dialogFormVisible"
      width="450px"
      @close="close"
    >
      <div v-if="downloadStatus" class="download-team">
        <div class="item">
          <div>打包时间</div>
          <div>{{ downloadStatus.time }}</div>
        </div>
        <div class="item">
          <div>操作</div>
          <div>
            <el-button v-if="downloadStatus.pack === 2" disabled type="text">
              打包中
            </el-button>
            <el-button v-else type="text" @click="packTeam('pack')">
              {{ downloadStatus.pack === 1 ? '打包' : '重新打包' }}
            </el-button>
            <el-button
              v-if="downloadStatus.download === 1"
              type="text"
              @click="packTeam('download')"
            >
              下载
            </el-button>
            <el-button v-else disabled type="text">下载</el-button>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button style="width: 100px" type="primary" @click="close">
          关闭
        </el-button>
      </div>
    </el-dialog>
    <origin ref="origin" />
  </div>
</template>

<script>
  import {
    getTeamTestList,
    getTeamTestUploadStatus,
    packReport,
    checkToReport,
  } from '@/api/teamList'
  import { isNJ } from '@/utils/utils'
  import origin from '@/components/reportOrigin'
  import { mapGetters } from 'vuex'
  export default {
    name: 'TeamReport',
    components: { origin },
    data() {
      return {
        isNJ: isNJ(),
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          title: '',
        },
        button: {},
        downloadStatus: null,
        cur: {},
        dialogFormVisible: false,
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    created() {
      this.fetchData()
    },
    methods: {
      refreshData() {
        this.queryForm = this.$options.data().queryForm
        this.fetchData(this.queryForm)
      },
      close() {
        this.dialogFormVisible = false
        this.cur = {}
      },
      packTeam(act) {
        let param = {
          id: this.cur.id,
          type: this.cur.type,
          act: act,
        }
        let msg = '打包预计需要10分钟，完成后请刷新页面再进行下载'
        if (this.downloadStatus.pack === 3) {
          msg = '重新打包将删除旧文件，确定重新打包吗？'
        }
        if (act === 'pack') {
          this.$baseConfirm(msg, null, () => {
            packReport(param).then((res) => {
              this.downloadStatus.pack = 2
              this.downloadStatus.download = 2
            })
          })
        } else {
          packReport(param).then((res) => {
            window.open(res.data.url, '_self')
          })
        }
      },
      beforeHandleCommand(id, type) {
        return {
          id: id,
          type: type,
        }
      },
      handleCommand(command) {
        this.dialogFormVisible = true
        this.cur = command
        getTeamTestUploadStatus(command).then((res) => {
          this.downloadStatus = res.data
        })
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        checkToReport({ id: row.id }).then((res) => {
          let c = res.data
          if (c) {
            this.$refs['origin'].showEdit(row, 'first')
          } else {
            let routeData = this.routeTo({ id: row.id }, '/other/teamRecord')
            window.open(routeData.href, '_blank')
          }
        })
      },
      routeTo(id, url) {
        let routeData = this.$router.resolve({
          path: url,
          query: id,
        })
        return routeData
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getTeamTestList(
          this.queryForm
        )
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .team-list-container {
    .download-team {
      display: flex;
      align-items: center;
      justify-content: space-around;
      text-align: center;
      line-height: 30px;
      .item {
        width: 50%;
      }
    }
    .el-dropdown-link {
      cursor: pointer;
      color: #26cdb7;
      margin-left: 15px;
      font-size: 12px;
    }
    .el-icon-arrow-down {
      margin-left: 0;
    }
    ::v-deep {
      .el-progress {
        .el-progress-bar__inner {
          animation: move 1s;
        }
      }
      .el-dialog__header {
        text-align: center;
        border-bottom: 1px solid #eee;
      }
      @keyframes move {
        0% {
          width: 0%;
        }
      }
    }
  }
</style>
