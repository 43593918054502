var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kindeditor" }, [
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.outContent,
          expression: "outContent",
        },
      ],
      attrs: { id: _vm.id, name: "content", placeholder: "内容..." },
      domProps: { value: _vm.outContent },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.outContent = $event.target.value
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }