import request from '@/utils/request'
/*******************分类*********************/
//分类列表
export function getCateList(data) {
  return request({
    url: '/psyeval/cate',
    method: 'post',
    data,
  })
}
//新增分类
export function delCate(data) {
  return request({
    url: '/psyeval/cate-del',
    method: 'post',
    data,
  })
}
//编辑分类
export function editCate(data) {
  return request({
    url: '/psyeval/cate-edit',
    method: 'post',
    data,
  })
}
//支付参数
export function setPay(data) {
  return request({
    url: '/setting/wx-pay',
    method: 'post',
    data,
  })
}
//报告参数
export function setReport(data) {
  return request({
    url: '/setting/wx-report',
    method: 'post',
    data,
  })
}

/*******************量表*********************/
//量表列表
export function getPaperList(data) {
  return request({
    url: '/psyeval/paper-list',
    method: 'post',
    data,
  })
}
//新增量表
export function delPaper(data) {
  return request({
    url: '/psyeval/paper-del',
    method: 'post',
    data,
  })
}
//获取量表详情
export function getPaperDetail(data) {
  return request({
    url: '/psyeval/paper-detail',
    method: 'post',
    data,
  })
}
//编辑量表
export function editPaper(data) {
  return request({
    url: '/psyeval/paper-edit',
    method: 'post',
    data,
  })
}
//上下架
export function setStatus(data) {
  return request({
    url: '/psyeval/update-status',
    method: 'post',
    data,
  })
}
//设置推荐
export function setRec(data) {
  return request({
    url: '/psyeval/update-rec',
    method: 'post',
    data,
  })
}
//数据概览
export function paperView(data) {
  return request({
    url: '/psyeval/paper-view',
    method: 'post',
    data,
  })
}
//测评记录
export function getOrderList(data) {
  return request({
    url: '/psyeval/order-list',
    method: 'post',
    data,
  })
}
//答题记录
export function getRecord(data) {
  return request({
    url: '/psyeval/ques',
    method: 'post',
    data,
  })
}
//测评结果
export function getResult(data) {
  return request({
    url: '/psyeval/result',
    method: 'post',
    data,
  })
}
