<template>
  <div class="member-container"></div>
</template>

<script>
  export default {
    name: 'MemberSetting',
    components: {},
    data() {
      return {}
    },
    created() {},
    mounted() {},
    methods: {},
  }
</script>

<style scoped></style>
