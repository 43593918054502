<template>
  <div class="appointment-con-record-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="8">
        <el-button
          v-if="button.add"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit"
        >
          添加
        </el-button>
        <el-button
          v-else
          disabled
          icon="el-icon-plus"
          title="暂无权限，如有需要，请联系管理员"
          type="primary"
        >
          添加
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel v-if="button.search" :span="16">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item v-if="isSid === 'all'">
            <el-select
              v-model="queryForm.sid"
              clearable
              filterable
              placeholder="请选择渠道"
              @change="bindChange"
            >
              <el-option
                v-for="item in studioList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入咨询用户"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model.trim="queryForm.counsellor_id"
              clearable
              filterable
              placeholder="请选择咨询师"
            >
              <el-option
                v-for="item in teacherList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model.trim="queryForm.question_id"
              clearable
              filterable
              placeholder="请选择咨询问题"
            >
              <el-option
                v-for="item in questList"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column label="咨询用户" prop="user.name" />
      <el-table-column label="咨询时段" prop="start_time">
        <template #default="{ row }">
          <div>{{ row.start_time }}</div>
          <div>{{ row.end_time }}</div>
        </template>
      </el-table-column>
      <el-table-column label="咨询师" prop="counsellor.name" />
      <el-table-column label="咨询问题" prop="question.title" />
      <el-table-column label="主诉" prop="user_complain">
        <template #default="{ row }">
          <el-popover placement="top-start" trigger="hover" width="300">
            {{ row.user_complain }}
            <template #reference>
              <div class="ellipsis">
                {{ row.user_complain }}
              </div>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="咨询师分析" prop="counsellor_explain">
        <template #default="{ row }">
          <el-popover placement="top-start" trigger="hover" width="300">
            {{ row.counsellor_explain }}
            <template #reference>
              <div class="ellipsis">
                {{ row.counsellor_explain }}
              </div>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="备注" prop="remark" show-overflow-tooltip />
      <el-table-column align="center" label="操作" width="120">
        <template #default="{ row }">
          <el-button v-if="button.edit" type="text" @click="handleEdit(row)">
            编辑
          </el-button>
          <el-button
            v-else
            disabled
            title="暂无权限，如有需要，请联系管理员"
            type="text"
          >
            编辑
          </el-button>
          <el-button
            v-if="button.delete"
            class="del"
            type="text"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
          <el-button
            v-else
            disabled
            title="暂无权限，如有需要，请联系管理员"
            type="text"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" :studio="studioList" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import {
    getConRecordList,
    ConRecordDel,
    getPsyQuestList,
    getPsyList,
  } from '@/api/appointment'
  import Edit from './components/ConRecordEdit'
  import { mapGetters } from 'vuex'
  import { getStudioList } from '@/api/platformChannelManagement'

  export default {
    name: 'ConRecord',
    components: { Edit },
    data() {
      return {
        list: [],
        studioList: [],
        questList: [], //咨询问题
        teacherList: [], //咨询师列表
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          name: '',
          counsellor_id: '',
          question_id: '',
        },
        button: {},
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    created() {
      if (this.isSid === 'all') {
        this.getStudio()
      } else {
        this.getTeacher()
        this.getPsyQuestion()
      }
      this.fetchData()
    },
    methods: {
      async getStudio() {
        const { data } = await getStudioList()
        this.studioList = data
      },
      getTeacher(sid) {
        getPsyList(this.isSid === 'all' ? { sid: sid } : '').then((res) => {
          this.teacherList = res.data
        })
      },
      getPsyQuestion(sid) {
        getPsyQuestList(this.isSid === 'all' ? { sid: sid } : '').then(
          (res) => {
            this.questList = res.data
          }
        )
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        if (row.id) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      bindChange(val) {
        this.getTeacher(val)
        this.getPsyQuestion(val)
      },
      handleDelete(row) {
        this.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await ConRecordDel({ id: row.id })
          this.$baseMessage(msg, 'success')
          await this.fetchData()
        })
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getConRecordList(
          this.queryForm
        )
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>
<style lang="scss"></style>
