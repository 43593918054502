<template>
  <div class="user-list-container">
    <el-card shadow="never">
      <div slot="header" class="header">消息通知</div>
      <template v-if="list.length">
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item
            v-for="(item, index) in list"
            :key="index"
            :name="index"
          >
            <template slot="title">
              <div
                style="
                  width: 100%;
                  padding-right: 5px;
                  display: flex;
                  justify-content: space-between;
                "
                :style="{ color: item.is_read == 1 ? '#999' : '#303133' }"
              >
                <span>{{ item.title }}</span>
                <span>{{ item.created_at }}</span>
              </div>
            </template>
            <div v-if="item.type == 2">
              <a :href="item.content" target="_blank">点击下载</a>
            </div>
            <div
              v-else
              :style="{ color: item.is_read == 1 ? '#999' : '#303133' }"
              v-html="item.content"
            ></div>
          </el-collapse-item>
        </el-collapse>
      </template>
      <el-empty v-else description="~暂无消息~" />
    </el-card>
  </div>
</template>

<script>
  import { getNoticeList, readNotice } from '@/api/platformChannelManagement'
  import { mapGetters, mapMutations } from 'vuex'
  export default {
    name: 'NoticeList',
    data() {
      return {
        activeNames: [],
        list: [],
        listLoading: true,
        button: {},
      }
    },
    computed: {
      ...mapGetters({
        notice: 'user/notice',
      }),
    },
    created() {
      this.fetchData()
    },
    mounted() {},
    methods: {
      ...mapMutations({
        setNotice: 'user/setNotice',
      }),
      async handleChange(val) {
        if (this.list[val] && this.list[val].is_read == 0) {
          await readNotice({ id: this.list[val].id })
          this.list[val].is_read = 1
          this.setNotice(this.notice - 1)
        }
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getNoticeList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .user-list-container {
    .box {
      background: #f5f5f5;
      padding: 20px;
      .el-card {
        &.is-always-shadow {
          box-shadow: 0 2px 12px 0 rgba(187, 187, 187, 0.1);
        }
        .c_red {
          color: #e64e1f;
        }
      }
    }
    .el-card {
      border: 0 !important;
      ::v-deep.el-card__body {
        padding: 0;
      }
    }
    ::v-deep.el-card__header {
      padding-left: 0 !important;
      padding-top: 0 !important;
    }
    .header {
      position: relative;
      font-size: 16px;
      font-weight: bold;
      padding: 0 20px;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 6px;
        background: #26cdb7;
        border-radius: 3px 0 0 3px;
      }
    }
  }
</style>
<style>
  p {
    margin: 5px 0;
  }
</style>
