<template>
  <div class="article-container">
    <div class="add-title">{{ title }}</div>
    <el-row :gutter="15">
      <el-col :span="24">
        <el-form
          ref="form"
          class="demo-form"
          :label-position="labelPosition"
          label-width="130px"
          :model="form"
          :rules="rules"
        >
          <el-form-item label="标题" prop="title">
            <el-input
              v-model="form.title"
              clearable
              placeholder="请输入文章标题"
            />
          </el-form-item>
          <el-form-item label="分类" prop="art_type">
            <el-select
              v-model="form.art_type"
              filterable
              placeholder="请选择分类"
            >
              <el-option
                v-for="item in articleTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="文章摘要" prop="desc">
            <el-input
              v-model="form.desc"
              clearable
              placeholder="请输入文章摘要"
            />
          </el-form-item>
          <el-form-item label="封面" prop="pic">
            <div class="img-uploader">
              <div class="el-upload el-upload__text" @click="selImg">
                <img v-if="hasImg" class="img" :src="hasImg" />
                <i v-else class="el-icon-plus"></i>
              </div>
              <div slot="tip" class="el-upload__tip">
                <div>
                  只能上传
                  <span>.jpg、.png</span>
                  文件，且
                  <span>不超过1M</span>
                </div>
                <div>(图片尺寸3:2)</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="内容" prop="content">
            <kind-editor
              id="editor"
              ref="editor"
              v-model="form.content"
              :after-change="afterChange()"
              :content="form.content"
              height="300px"
              :load-style-mode="false"
              width="100%"
              @on-content-change="onContentChange"
            />
          </el-form-item>
          <el-form-item label="阅读量" prop="focus">
            <el-input v-model="form.focus" />
          </el-form-item>
          <el-form-item label="是否推荐" prop="is_rec">
            <el-radio-group v-model="form.is_rec">
              <el-radio label="10">是</el-radio>
              <el-radio label="20">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否展示" prop="is_show">
            <el-radio-group v-model="form.is_show">
              <el-radio label="10">是</el-radio>
              <el-radio label="20">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否置顶" prop="is_up">
            <el-radio-group v-model="form.is_up">
              <el-radio label="10">是</el-radio>
              <el-radio label="20">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('form')">
              {{ form.id ? '保存修改' : '立即发布' }}
            </el-button>
            <el-button v-if="!form.id" @click="resetForm('form')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <select-img ref="selectImg" @checkedImg="getImg" />
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import { handleActivePath } from '@/utils/routes'
  import { getDetail, doEdit, getNormalType } from '@/api/teamList'
  import KindEditor from '@/extra/kindeditor'
  import { isNumber } from '@/utils/validate'
  import SelectImg from '@/components/gallery'

  export default {
    name: 'AddArticle',
    components: {
      KindEditor,
      SelectImg,
    },
    inject: ['reload'],
    data() {
      const validateNum = (rule, value, callback) => {
        if (value !== '' && !isNumber(value)) {
          callback(new Error('请输入正整数'))
        } else callback()
      }
      return {
        title: '',
        labelPosition: 'right',
        form: {
          title: '',
          art_type: '',
          desc: '',
          content: '',
          focus: '',
          pic: '',
          is_show: '',
          is_rec: '',
          is_up: '',
        },
        rules: {
          title: [
            { required: true, message: '请输入文章标题', trigger: 'blur' },
          ],
          art_type: [
            {
              required: true,
              trigger: 'change',
              message: '请选择文章类别',
            },
          ],
          desc: [{ required: true, message: '请输入摘要', trigger: 'blur' }],
          content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
          focus: [{ required: false, trigger: 'blur', validator: validateNum }],
        },
        hasImg: '',
        articleTypeList: [], //文章分类
      }
    },
    created() {
      this.getArticleType()
      this.getPath()
    },
    methods: {
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      getPath() {
        const route = this.$route
        this.$nextTick(() => {
          let rid = route.query.id
          this.title = rid ? '编辑文章' : '新增文章'
          if (rid) {
            this.getDetailById(rid)
          } else {
            this.getDetailById(0)
          }
        })
      },
      async getArticleType() {
        const { data } = await getNormalType({ type: 'article' })
        this.articleTypeList = data
      },
      selImg() {
        this.$refs['selectImg'].showEdit()
      },
      getImg(val) {
        this.hasImg = val.url
        this.form.pic = val.url
      },
      async getDetailById(id) {
        const { data } = await getDetail({ id })
        this.form = data
        if (id) {
          this.form.id = id
        }
      },
      onContentChange(val) {
        this.form.content = val
        console.log(this.form.content)
      },
      afterChange(e) {},
      submitForm(formName) {
        //提交
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            let dataPar = this.form
            let params = {
              title: dataPar.title,
              art_type: dataPar.art_type,
              desc: dataPar.desc,
              content: dataPar.content,
              focus: dataPar.focus || '',
              is_show: dataPar.is_show || '',
              is_rec: dataPar.is_rec || '',
              is_up: dataPar.is_up || '',
              pic: dataPar.pic || '',
            }
            if (dataPar.id) {
              params.append('id', dataPar.id)
            }
            console.log(params)
            return false
            // eslint-disable-next-line
            const { msg } = await doEdit(params)
            this.$baseMessage(msg, 'success')

            //关闭当前tab，返回list页并刷新
            await this.delVisitedRoute(handleActivePath(this.$route, true))
            this.reload()
            this.$router.push('/application/article/list')
          } else {
            // eslint-disable-next-line no-console
            console.log('error submit!!')
            return false
          }
        })
      },
      resetForm(formName) {
        this.$refs[formName].resetFields()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .article-container {
    .demo-form {
      margin-top: 10px;
    }
    .add-title {
      text-align: center;
      line-height: 50px;
      background: #f0fcfa;
      margin: -20px -20px 20px;
      font-weight: bold;
      font-size: 16px;
    }
    .img-uploader {
      display: flex;
      ::v-deep.el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 3px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      .el-upload__tip {
        flex: 1;
        margin-left: 10px;
        color: #999;
        line-height: normal;
        span {
          color: #e64e1f;
        }
      }
      ::v-deep.el-upload:hover {
        border-color: #92e6db;
      }
      .img,
      .el-icon-plus {
        width: 120px;
        height: 80px;
        display: block;
        line-height: 80px;
      }
    }
  }
</style>
