var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "添加人员",
        visible: _vm.dialogFormVisible,
        width: "900px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c("div", { staticClass: "box2" }, [
        false
          ? _c(
              "div",
              { staticClass: "left" },
              [
                _c("el-tree", {
                  ref: "ozTree",
                  attrs: {
                    accordion: true,
                    "check-on-click-node": true,
                    "check-strictly": true,
                    data: _vm.originList,
                    "default-expand-all": "",
                    "highlight-current": "",
                    "node-key": "id",
                    props: _vm.defaultProps,
                  },
                  on: {
                    check: _vm.checkChange,
                    "node-click": _vm.handleNodeClick,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "right" }, [
          _c(
            "div",
            { staticClass: "search-box" },
            [
              _c("el-input", {
                staticClass: "search",
                attrs: { placeholder: "输入姓名或账号搜索", size: "middle" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.getUserList.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.queryForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryForm, "name", $$v)
                  },
                  expression: "queryForm.name",
                },
              }),
              _c(
                "el-button",
                {
                  staticClass: "btn",
                  attrs: { size: "middle", type: "primary" },
                  on: { click: _vm.getUserList },
                },
                [_vm._v(" 搜索 ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table-list" },
            [
              _c(
                "el-table",
                {
                  ref: "userTable",
                  attrs: { data: _vm.userList },
                  on: { select: _vm.selectRow, "select-all": _vm.selectAll },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "max-width": "50",
                      type: "selection",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "姓名",
                      prop: "name",
                      width: "150",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "账号",
                      prop: "account",
                      width: "200",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "组织结构",
                      prop: "origin",
                    },
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.queryForm.pageNo,
                  layout: "prev, pager, next",
                  "page-size": _vm.queryForm.pageSize,
                  total: _vm.total,
                },
                on: { "current-change": _vm.handleCurrentChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }