<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="名称" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input v-model="form.sort" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { addCate, doEdit } from '@/api/mmptCategory'
  import { isNumber } from '@/utils/validate'

  export default {
    name: 'MmptCategoryEdit',
    data() {
      const validateSort = (rule, value, callback) => {
        if (!isNumber(value)) {
          callback(new Error('请输入正整数'))
        } else {
          callback()
        }
      }
      return {
        form: {
          name: '',
          sort: '',
        },
        rules: {
          name: [
            { required: true, trigger: 'blur', message: '请输入分类名称' },
          ],
          sort: [
            { required: true, trigger: 'blur', message: '请输入排序' },
            { validator: validateSort, trigger: 'blur' },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      showEdit(row) {
        if (!row) {
          this.title = '添加'
        } else {
          this.title = '编辑'
          let param = Object.assign({}, row)
          this.form = {
            id: param.cate_id,
            name: param.name,
            sort: param.sort,
          }
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg, code } = this.form.id
              ? await doEdit(this.form)
              : await addCate(this.form)
            this.$baseMessage(msg, code === 200 ? 'success' : 'error')
            this.$emit('fetch-data')
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>
