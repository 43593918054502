var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "answer-container" }, [
    _c(
      "div",
      { staticClass: "box" },
      [
        _c(
          "el-card",
          { staticClass: "tips" },
          [
            _vm._v(" 提示：仅显示 "),
            _c("span", { staticClass: "c_red" }, [
              _vm._v(_vm._s(_vm.list.name)),
            ]),
            _vm._v(" 对 "),
            _c("span", { staticClass: "c_red" }, [
              _vm._v(_vm._s(_vm.list.paper)),
            ]),
            _vm._v(" 的最后一次答题记录。 "),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.downloadAnswer } },
              [_vm._v("导出Excel")]
            ),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.print } },
              [_vm._v("导出Pdf")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { ref: "print", staticClass: "answer-list" },
          [
            _c(
              "el-table",
              { attrs: { border: "", data: _vm.list.ques } },
              [
                _c("el-table-column", {
                  attrs: { label: "题目", prop: "title" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "span",
                            [
                              _vm._v(" " + _vm._s(row.title) + " "),
                              row.type_name
                                ? _c("el-tag", { attrs: { size: "mini" } }, [
                                    _vm._v(" " + _vm._s(row.type_name) + " "),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "选项", prop: "select" },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "答案",
                    prop: "answer",
                    width: "180",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }