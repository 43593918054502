var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex", attrs: { id: "center" } }, [
    _c(
      "div",
      { staticClass: "item w30" },
      [
        _c(
          "dv-border-box-12",
          [
            _c("Echart", {
              attrs: { id: "centerChart1", height: 200, options: _vm.pie1 },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "item w30" },
      [
        _c(
          "dv-border-box-12",
          [
            _c("Echart", {
              attrs: { id: "centerChart2", height: 200, options: _vm.pie2 },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "item w30" },
      [
        _c(
          "dv-border-box-12",
          [
            _c("Echart", {
              attrs: { id: "centerChart3", height: 200, options: _vm.pie3 },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "item flex-1" },
      [
        _c(
          "dv-border-box-12",
          [
            _c("dv-scroll-board", {
              staticStyle: { width: "100%", height: "200px" },
              attrs: { config: _vm.config },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }