<template>
  <div class="setting-authority-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="4">
        <el-button
          v-if="button.add"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit"
        >
          添加
        </el-button>
        <el-button
          v-else
          disabled
          icon="el-icon-plus"
          title="暂无权限，如有需要，请联系管理员"
          type="primary"
        >
          添加
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel v-if="button.search" :span="20">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item v-if="isSid === 'all'">
            <el-select
              v-model="queryForm.sid"
              clearable
              filterable
              placeholder="请选择渠道"
            >
              <el-option
                v-for="item in studioList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.username"
              clearable
              placeholder="请输入管理员账号"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.phone"
              clearable
              placeholder="请输入管理员电话"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.remark"
              clearable
              placeholder="请输入备注"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column label="账号" prop="username" />
      <el-table-column
        align="center"
        label="所属角色"
        min-width="160px"
        prop="role_name"
      >
        <template #default="{ row }">
          <el-tag :type="row.sid != 0 ? 'warning' : ''">
            {{ row.role_name }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="手机号" prop="phone" />
      <el-table-column align="center" label="登录IP" prop="login_ip" />
      <el-table-column align="center" label="最后登录时间" prop="last_at" />
      <el-table-column label="备注" prop="remark" />
      <el-table-column align="center" label="状态" prop="">
        <template #default="{ row }">
          <el-button
            v-if="row.status * 1 === 10"
            type="success"
            @click="setStatus(row)"
          >
            启用
          </el-button>
          <el-button
            v-else
            title="当前账号已停用，点击即可启用"
            type="danger"
            @click="setStatus(row)"
          >
            停用
          </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="85">
        <template #default="{ row }">
          <el-button
            v-if="button.edit"
            :disabled="!row.add_id"
            :title="!row.add_id ? '您没有操作该用户的权限' : ''"
            type="text"
            @click="handleEdit(row)"
          >
            编辑
          </el-button>
          <el-button v-else disabled="disabled" type="text">编辑</el-button>
          <el-button
            v-if="button.delete"
            :class="{ del: row.add_id }"
            :disabled="!row.add_id"
            :title="!row.add_id ? '您没有操作该用户的权限' : ''"
            type="text"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
          <el-button v-else disabled="disabled" type="text">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getAdminList, doDelete, doSuspend } from '@/api/settingAuthority'
  import Edit from './components/adminEdit'
  import { isPhone } from '@/utils/validate'
  import { mapGetters } from 'vuex'
  import { getStudioList } from '@/api/platformChannelManagement'

  export default {
    name: 'AuthorityAdmin',
    components: { Edit },
    data() {
      return {
        studioList: [],
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          username: '',
          phone: '',
          remark: '',
        },
        button: {},
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    created() {
      this.fetchData()
      if (this.isSid === 'all') this.getStudio()
    },
    methods: {
      setStatus(row) {
        this.$baseConfirm(
          '你确定要' + (row.status * 1 === 10 ? '停用' : '启用') + '当前账号吗',
          null,
          async () => {
            const { msg, code } = await doSuspend({ id: row.id })
            if (code === 200) {
              this.$baseMessage(msg, 'success')
              await this.fetchData()
            } else {
              this.$baseMessage(msg, 'error')
            }
          }
        )
      },
      async getStudio() {
        const { data } = await getStudioList()
        this.studioList = data
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row) {
        if (row.id) {
          this.$router.push({
            path: '/authority/addAdmin',
            query: { id: row.id },
          })
        } else {
          this.$router.push('/authority/addAdmin')
        }
      },
      handleDelete(row) {
        if (row.add_id) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg, code } = await doDelete({ id: row.id, type: 'adm' })
            if (code === 200) {
              this.$baseMessage(msg, 'success')
              await this.fetchData()
            } else {
              this.$baseMessage(msg, 'error')
            }
          })
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        let phone = this.queryForm.phone
        if (phone !== '' && !isPhone(phone)) {
          this.$baseMessage('手机号格式不正确', 'error')
          return false
        }
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getAdminList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>
