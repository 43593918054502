import request from '@/utils/request'
//咨询师列表
export function getCounselorList(params) {
  return request({
    url: '/counsellor/paper-list',
    method: 'get',
    params,
  })
}
//添加咨询师
export function CounselorAdd(data) {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/counsellor/paper-add',
    method: 'post',
    data,
  })
}
//编辑咨询师
export function CounselorEdit(data) {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/counsellor/paper-edit',
    method: 'post',
    data,
  })
}
//咨询师详情
export function getCounselorDetail(params) {
  return request({
    url: '/counsellor/paper-detail',
    method: 'get',
    params,
  })
}
//删除咨询师
export function CounselorDel(data) {
  return request({
    url: '/counsellor/paper-del',
    method: 'post',
    data,
  })
}

//预约记录列表
export function getApoRecordList(params) {
  return request({
    url: '/counsellorplan/paper-list',
    method: 'get',
    params,
  })
}
//添加预约记录
export function ApoRecordAdd(data) {
  return request({
    url: '/counsellorplan/paper-add',
    method: 'post',
    data,
  })
}
//编辑预约记录
export function ApoRecordEdit(data) {
  return request({
    url: '/counsellorplan/paper-edit',
    method: 'post',
    data,
  })
}
//预约记录详情
export function getApoRecordDetail(params) {
  return request({
    url: '/counsellorplan/paper-detail',
    method: 'get',
    params,
  })
}
//确认预约
export function ApoConfirm(data) {
  return request({
    url: '/counsellorplan/paper-confirm',
    method: 'post',
    data,
  })
}
//取消预约
export function ApoCancel(data) {
  return request({
    url: '/counsellorplan/paper-cancel',
    method: 'post',
    data,
  })
}
//删除预约记录
export function ApoRecordDel(data) {
  return request({
    url: '/counsellorplan/paper-del',
    method: 'post',
    data,
  })
}

//咨询记录列表
export function getConRecordList(params) {
  return request({
    url: '/counsellororder/paper-list',
    method: 'get',
    params,
  })
}
//添加咨询记录
export function ConRecordAdd(data) {
  return request({
    url: '/counsellororder/order-set',
    method: 'post',
    data,
  })
}
//编辑咨询记录
export function ConRecordEdit(data) {
  return request({
    url: '/counsellororder/paper-edit',
    method: 'post',
    data,
  })
}
//咨询记录详情
export function getConRecordDetail(params) {
  return request({
    url: '/counsellororder/paper-detail',
    method: 'get',
    params,
  })
}
//删除咨询记录
export function ConRecordDel(data) {
  return request({
    url: '/counsellororder/paper-del',
    method: 'post',
    data,
  })
}

//咨询师时段详情
export function getTimeDetail(data) {
  return request({
    url: '/counsellortime/paper-detail',
    method: 'post',
    data,
  })
}
//设置时间段
export function saveTime(data) {
  return request({
    url: '/counsellortime/paper-edit',
    method: 'post',
    data,
  })
}

//获取咨询问题
export function getPsyQuestList(params) {
  return request({
    url: '/router/get-psy-quest',
    method: 'get',
    params,
  })
}
//获取咨询师
export function getPsyList(params) {
  return request({
    url: '/router/get-psy',
    method: 'get',
    params,
  })
}
//获取资质
export function getCredentList(params) {
  return request({
    url: '/router/get-psy-credent',
    method: 'get',
    params,
  })
}
//渠道下的学校
export function getSchoolList(params) {
  return request({
    url: '/router/get-origin-top',
    method: 'get',
    params,
  })
}
//咨询师下的时间
export function getPsyTimes(params) {
  return request({
    url: '/router/get-psy-times',
    method: 'get',
    params,
  })
}
