<template>
  <el-dialog
    :close-on-click-modal="false"
    title="结案"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-alert :closable="false" show-icon title="温馨提示" type="warning">
      <div slot="default" style="line-height: 1.5">
        结案指该用户心理危机干预流程已完成，请勿随意操作【结案】功能，【结案】后系统只能查看该用户所记录的档案以及相关数据，不能进行修改！
      </div>
    </el-alert>
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="结案原因" prop="reason">
        <el-radio-group v-model="form.reason">
          <el-radio v-for="(item, i) in resList" :key="i" :label="item.id">
            {{ item.name }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { caseFinish, getWarnParam } from '@/api/crisis'

  export default {
    name: 'FinishCase',
    data() {
      return {
        resList: [],
        form: {
          id: '',
          reason: '',
          type: '',
        },
        rules: {
          reason: [
            { required: true, message: '请选择结案原因', trigger: 'change' },
          ],
        },
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      async getCloseType() {
        const { data } = await getWarnParam({ type: 'settle' })
        this.resList = data
      },
      showEdit(id, type) {
        this.form.id = id
        this.form.type = type
        this.dialogFormVisible = true
        this.getCloseType()
      },
      close() {
        this.$refs['form'].resetFields()
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.$baseConfirm('确定结案？', null, () => {
              caseFinish(this.form).then((res) => {
                if (res.code === 200) {
                  this.$baseMessage('操作成功', 'success')
                  this.$emit('fetch-data')
                  this.close()
                }
              })
            })
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped></style>
