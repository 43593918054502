<template>
  <div v-if="initData" class="eval-container">
    <div ref="print" class="content">
      <div class="el-card">
        <div class="print no-print" @click="print">
          <vab-icon icon="printer-line" />
          导出PDF/打印
        </div>
        <div class="title">{{ initData.paper }}预警报告</div>
        <div class="test-time">
          <span>测评时间：{{ initData.test_at }}</span>
          <span>用时：{{ initData.use_at }}</span>
        </div>
        <div class="card-name">基本信息</div>
        <div v-if="initData.basic" class="baseinfo">
          <div
            v-for="(item, index) in initData.basic"
            :key="index"
            class="base-line"
          >
            <label class="lbl">{{ item.name }}：</label>
            <span class="txt">{{ item.value }}</span>
          </div>
        </div>
      </div>
      <div class="el-card">
        <div class="card-name">量表介绍</div>
        <div v-if="initData" class="baseinfo">
          <div class="base-line">
            <span class="lbl" style="width: 100%; font-size: 15px">
              {{ initData.paper }}
            </span>
          </div>
          <div class="base-line" style="width: 100%; margin-right: 0">
            <div class="txt intro">{{ initData.intro }}</div>
          </div>
        </div>
      </div>
      <div class="el-card" style="padding-bottom: 40px">
        <div class="card-name">预警因子</div>
        <el-table border :data="warnTable.list">
          <el-table-column
            v-for="(item, i_t) in warnTable.table"
            :key="i_t"
            :align="item.value !== 'fac' ? 'center' : ''"
            :label="item.name"
            min-width="100"
          >
            <template #default="{ row }">
              <span>{{ row[item.value] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
  import { getPersonWarningDetail } from '@/api/teamList'
  import getPageTitle from '@/utils/pageTitle'
  export default {
    name: 'SingleWarnRecord',
    data() {
      return {
        initData: null,
        warnTable: null,
      }
    },
    created() {
      this.$nextTick(() => {
        this.getNotes(this.$route.query.id)
      })
    },
    methods: {
      handleMeta(name, meta) {
        document.title = getPageTitle(meta.title, true)
      },
      getNotes(id) {
        getPersonWarningDetail({ id: id }).then((res) => {
          let d = res.data
          this.initData = d
          this.warnTable = d.chart

          this.handleMeta('SingleWarnRecord', {
            title: d.basic[0].value + '的' + d.paper + '预警报告',
          })
        })
      },
      print() {
        this.$print(this.$refs.print)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .eval-container {
    padding: 20px 0;
    .canvas-img {
      display: none;
    }
  }
  .content {
    width: 800px;
    margin: auto;
    background: #fff;
    border-radius: 8px;
    .el-card {
      position: relative;
      margin-bottom: 0;
      border: 0;
      padding: 0 40px;
      .print {
        position: absolute;
        right: 15px;
        top: 15px;
        border-radius: 3px;
        background: #b3b3b3;
        padding: 5px 10px;
        font-size: 14px;
        color: #fff;
        cursor: pointer;
      }
      .title {
        font-size: 24px;
        text-align: center;
        line-height: 60px;
        padding: 10px;
        font-weight: bold;
        margin-top: 30px;
      }
      .test-time {
        text-align: center;
        padding: 10px;
        > span {
          padding: 0 10px;
        }
      }
      .card-name {
        position: relative;
        font-size: 18px;
        padding-left: 20px;
        line-height: 50px;
        font-weight: bold;
        margin-top: 20px;
        &:before {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 6px;
          height: 20px;
          border-radius: 10px;
          background: #26cdb7;
        }
      }
      .baseinfo {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 20px 30px;
        .base-line {
          width: 50%;
          line-height: 28px;
          .lbl {
            display: inline-block;
            width: 80px;
            font-weight: bold;
            &.intro {
              width: 100%;
            }
          }
          .txt {
            &.intro {
              padding: 10px 0;
              line-height: 28px;
              text-align: justify;
            }
          }
        }
      }
    }
  }
  @media print {
    @page {
      size: A4;
      margin: 10mm 0mm;
    }
    .eval-block {
      break-inside: avoid;
    }
    table,
    figure {
      page-break-inside: avoid;
    }
  }
</style>
