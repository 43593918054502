var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "analyse-container" }, [
    _vm.list.length
      ? _c("div", { ref: "print", staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "ques-title" },
            [
              _c("div", [_vm._v(_vm._s(_vm.querys.title))]),
              _c(
                "el-button",
                {
                  staticClass: "no-print",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.print("pdf")
                    },
                  },
                },
                [_vm._v(" 导出PDF ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "list" },
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "item anchor",
                  class: item.type === "text" ? "no-print" : "",
                },
                [
                  _c("div", { staticClass: "top" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _c("span", [_vm._v(_vm._s(item.typeName))]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "middle" },
                    [
                      item.type === "text"
                        ? [
                            _c(
                              "div",
                              { staticStyle: { "margin-bottom": "30px" } },
                              [_vm._v("填空题不参与分析")]
                            ),
                          ]
                        : _vm._e(),
                      item.type === "radio" || item.type === "checkbox"
                        ? [
                            _c(
                              "el-table",
                              {
                                attrs: {
                                  border: "",
                                  data: item.options,
                                  "header-cell-style": { textAlign: "center" },
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: { label: "选项", prop: "label" },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "选择人次",
                                    prop: "stat.num",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ row }) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(row.stat.num + "人")
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: { label: "占比", prop: "stat.rate" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ row }) {
                                          return [
                                            _c("span", [
                                              _vm._v(_vm._s(row.stat.rate)),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      item.type === "matrix_radio" ||
                      item.type === "matrix_checkbox"
                        ? [
                            _c(
                              "el-table",
                              { attrs: { border: "", data: item.row } },
                              [
                                _c("el-table-column", {
                                  attrs: { label: "题目\\选项" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.row[scope.$index]) +
                                                " "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._l(item.options, function (col, c) {
                                  return _c("el-table-column", {
                                    key: c,
                                    attrs: { label: col.label },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      col.stat[scope.$index]
                                                        .num +
                                                        "(" +
                                                        col.stat[scope.$index]
                                                          .rate +
                                                        ")"
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                }),
                              ],
                              2
                            ),
                          ]
                        : _vm._e(),
                      item.type !== "text"
                        ? _c(
                            "div",
                            { staticClass: "img" },
                            [
                              _c("vab-chart", {
                                ref: `charts_${index}`,
                                refInFor: true,
                                staticClass: "no-print",
                                attrs: {
                                  options: _vm.chartList[index].chartVal,
                                  theme: "vab-echarts-theme",
                                },
                              }),
                              _c("img", {
                                staticClass: "canvas-img",
                                attrs: { src: _vm.chartList[index].charImg },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              )
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }