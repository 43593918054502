var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "80px", model: _vm.form, rules: _vm.rules },
        },
        [
          _vm.isSid === "all"
            ? _c(
                "el-form-item",
                { attrs: { label: "渠道", prop: "sid" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "请选择渠道" },
                      on: { change: _vm.bindChange },
                      model: {
                        value: _vm.form.sid,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "sid", $$v)
                        },
                        expression: "form.sid",
                      },
                    },
                    _vm._l(_vm.studioList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "预约用户", prop: "uid" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addPerson } },
                [_vm._v("选择")]
              ),
              _vm.form.uid.length
                ? _c(
                    "div",
                    { staticClass: "userlist" },
                    _vm._l(_vm.form.uid, function (user, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "username" },
                        [
                          _vm._v(" " + _vm._s(user.name) + " "),
                          _c("span", {
                            staticClass: "el-icon-error",
                            on: {
                              click: function ($event) {
                                return _vm.delUserId(user.uid)
                              },
                            },
                          }),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "咨询师", prop: "counsellor_id" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择咨询师",
                  },
                  model: {
                    value: _vm.form.counsellor_id,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "counsellor_id",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.counsellor_id",
                  },
                },
                _vm._l(_vm.teacherList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "预约日期", prop: "date" } },
            [
              _c("el-date-picker", {
                attrs: {
                  placeholder: "请选择日期",
                  type: "date",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.form.date,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "date", $$v)
                  },
                  expression: "form.date",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "预约时段", prop: "time" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择时段",
                  },
                  model: {
                    value: _vm.form.time,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "time",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.time",
                  },
                },
                _vm._l(_vm.timeList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item[0] + "~" + item[1], value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "咨询问题", prop: "question_id" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择咨询问题",
                  },
                  model: {
                    value: _vm.form.question_id,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.form,
                        "question_id",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.question_id",
                  },
                },
                _vm._l(_vm.questList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.title, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  maxlength: "200",
                  rows: 3,
                  "show-word-limit": "",
                  type: "textarea",
                },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
      _c("add-stu", { ref: "addstu", on: { "fetch-data": _vm.getInfo } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }