<template>
  <div class="vab-header">
    <div class="vab-main">
      <el-row :gutter="20">
        <el-col :span="10">
          <vab-logo />
        </el-col>
        <el-col :span="14">
          <div class="right-panel">
            <el-menu
              v-if="'horizontal' === layout"
              :active-text-color="variables['menu-color-active']"
              :background-color="variables['menu-background']"
              :default-active="activeMenu"
              menu-trigger="hover"
              mode="horizontal"
              :text-color="variables['menu-color']"
            >
              <template v-for="route in handleRoutes">
                <vab-menu
                  v-if="!route.hidden"
                  :key="route.path"
                  :item="route"
                  :layout="layout"
                />
              </template>
            </el-menu>
            <!--<vab-notice />-->

            <el-popover
              v-model="userActive"
              class="item"
              placement="bottom"
              trigger="hover"
              width="260"
            >
              <div v-if="link">
                <div v-if="link.user" class="wechat-code">
                  <div class="img">
                    <el-image :src="link.user.code" />
                  </div>
                  <div class="btn">
                    <el-button
                      type="primary"
                      @click="saveImg(link.user.code, '用户端')"
                    >
                      下载二维码
                    </el-button>
                    <el-button type="primary" @click="copy(link.user.url)">
                      复制链接
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="link.user_new"
                  style="border-top: 1px solid #eee; padding: 15px 10px 0 10px"
                >
                  新版入口
                </div>
                <div v-if="link.user_new" class="wechat-code">
                  <div class="img">
                    <el-image :src="link.user_new.code" />
                  </div>
                  <div class="btn">
                    <el-button
                      type="primary"
                      @click="saveImg(link.user_new.code, '用户端')"
                    >
                      下载二维码
                    </el-button>
                    <el-button type="primary" @click="copy(link.user_new.url)">
                      复制链接
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="link.pay"
                  style="border-top: 1px solid #eee; padding: 15px 10px 0 10px"
                >
                  付费测评
                </div>
                <div v-if="link.pay" class="wechat-code">
                  <div class="img">
                    <el-image :src="link.pay.code" />
                  </div>
                  <div class="btn">
                    <el-button
                      type="primary"
                      @click="saveImg(link.pay.code, '用户端')"
                    >
                      下载二维码
                    </el-button>
                    <el-button type="primary" @click="copy(link.pay.url)">
                      复制链接
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="link.parent"
                  style="border-top: 1px solid #eee; padding: 15px 10px 0 10px"
                >
                  家长端
                </div>
                <div v-if="link.parent" class="wechat-code">
                  <div class="img">
                    <el-image :src="link.parent.code" />
                  </div>
                  <div class="btn">
                    <el-button
                      type="primary"
                      @click="saveImg(link.parent.code, '家长端')"
                    >
                      下载二维码
                    </el-button>
                    <el-button type="primary" @click="copy(link.parent.url)">
                      复制链接
                    </el-button>
                  </div>
                </div>
              </div>
              <span slot="reference">
                <vab-icon class="i_c" icon="user-3-line" />
                用户端
                <i
                  class="el-icon-arrow-down"
                  :class="{ active: userActive }"
                ></i>
              </span>
            </el-popover>
            <template v-if="!isNJ">
              <div class="item" @click="gotoNotice">
                <el-badge class="item" :hidden="notice <= 0" :value="notice">
                  <vab-icon
                    class="i_c"
                    icon="notification-line"
                    title="消息通知"
                  />
                  消息通知
                </el-badge>
              </div>
              <!--<div v-if="hiddenSupport == 0" class="item" @click="help">
                <vab-icon class="i_c" icon="question-line" title="帮助中心" />
                帮助中心
              </div>-->
            </template>
            <vab-avatar />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import variables from '@/vab/styles/variables/variables.scss'
  import { mapGetters } from 'vuex'
  import { handleActivePath } from '@/utils/routes'
  import { isNJ } from '@/utils/utils'
  import clip from '@/utils/clipboard'
  import { getNetUrl } from '@/api/user'

  export default {
    name: 'VabHeader',
    props: {
      layout: {
        type: String,
        default: 'horizontal',
      },
    },
    data() {
      return {
        activeMenu: '',
        menuTrigger: 'hover',
        isNJ: isNJ(),
        noticeNum: 0,
        userActive: false,
        counselorActive: false,
        link: null,
      }
    },
    computed: {
      ...mapGetters({
        routes: 'routes/routes',
        notice: 'user/notice',
        hiddenSupport: 'settings/hiddenSupport',
      }),
      variables() {
        return variables
      },
      handleRoutes() {
        return this.routes.flatMap((route) => {
          return route.menuHidden === true && route.children
            ? route.children
            : route
        })
      },
    },
    watch: {
      $route: {
        handler(route) {
          this.activeMenu = handleActivePath(route)
        },
        immediate: true,
      },
    },
    created() {
      this.getLink()
    },
    methods: {
      help() {
        window.open('https://docs.qq.com/doc/DTEVwa2VYdEl3d2ZK', '_blank')
      },
      gotoNotice() {
        this.$router.push({
          path: '/noticeList',
        })
      },
      async getLink() {
        const { data } = await getNetUrl()
        this.link = data
      },
      downloadIamge: function (imgsrc, name) {
        let image = new Image()
        image.setAttribute('crossOrigin', 'anonymous')
        image.onload = function () {
          let canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          let context = canvas.getContext('2d')
          context.drawImage(image, 0, 0, image.width, image.height)
          let url = canvas.toDataURL('image/png') //得到图片的base64编码数据
          let a = document.createElement('a') // 生成一个a元素
          let event = new MouseEvent('click') // 创建一个单击事件
          a.download = name || 'picture' // 设置图片名称没有设置则为默认
          a.href = url // 将生成的URL设置为a.href属性
          a.dispatchEvent(event) // 触发a的单击事件
        }
        image.src = imgsrc
      },
      saveImg(code, name) {
        this.downloadIamge(code, name)
      },
      copy(url) {
        clip(url, event)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .wechat-code {
    display: flex;
    align-items: center;
    padding: 10px;
    .img {
      width: 100px;
      height: 100px;
      margin-right: 30px;
      overflow: hidden;
      .el-image {
        border: 2px solid $base-color-blue;
        border-radius: 5px;
      }
    }
    .btn {
      flex: 1;
      text-align: right;
      .el-button {
        width: 90px;
        margin: 8px 0 !important;
      }
    }
  }
  .el-icon-arrow-down {
    transition: $base-transition;

    &.active {
      transform: rotateZ(180deg);
    }
  }
  .vab-header {
    display: flex;
    align-items: center;
    justify-items: flex-end;
    height: $base-top-bar-height;
    background: $base-menu-background;

    .vab-main {
      padding: 0 $base-padding 0 $base-padding;

      .right-panel {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: $base-top-bar-height;

        ::v-deep {
          > .el-menu--horizontal.el-menu
            > .el-submenu
            > .el-submenu__title
            > .el-submenu__icon-arrow {
            float: right;
            margin-top: ($base-top-bar-height - 11) / 2 !important;
          }

          > .el-menu--horizontal.el-menu > .el-menu-item {
            .el-tag {
              margin-top: $base-top-bar-height / 2 - 7.5 !important;
              margin-left: 5px;
            }

            .vab-dot {
              float: right;
              margin-top: ($base-top-bar-height - 6) / 2 + 1;
            }

            @media only screen and (max-width: 1199px) {
              .el-tag {
                display: none;
              }
            }
          }

          .el-menu {
            &.el-menu--horizontal {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              height: $base-top-bar-height;
              border-bottom: 0 solid transparent !important;

              .el-menu-item,
              .el-submenu__title {
                height: $base-top-bar-height/1.3;
                padding: 0 $base-padding;
                line-height: $base-top-bar-height/1.3;
              }

              > .el-menu-item,
              > .el-submenu {
                height: $base-top-bar-height;
                line-height: $base-top-bar-height;

                .el-submenu__icon-arrow {
                  float: right;
                  margin-top: ($base-menu-item-height - 16) / 2;
                }

                > .el-submenu__title {
                  height: $base-top-bar-height;
                  line-height: $base-top-bar-height;
                }
              }
            }

            [class*='ri-'] {
              margin-left: 0;
              color: rgba($base-color-white, 0.9);
              cursor: pointer;
            }

            .el-submenu,
            .el-menu-item {
              i {
                color: inherit;
              }

              &.is-active {
                border-bottom: 0 solid transparent;

                .el-submenu__title {
                  border-bottom: 0 solid transparent;
                }
              }
            }

            .el-menu-item {
              &.is-active {
                background: $base-color-blue !important;
              }
            }
          }

          .user-name {
            color: rgba($base-color-white, 0.9);
          }

          .user-name + i {
            color: rgba($base-color-white, 0.9);
          }

          [class*='ri-'] {
            margin-left: $base-margin;
            color: rgba($base-color-white, 0.9);
            cursor: pointer;
          }

          button {
            svg {
              margin-right: 0;
              color: rgba($base-color-white, 0.9);
              cursor: pointer;
              fill: rgba($base-color-white, 0.9);
            }
          }
        }
        .item {
          cursor: pointer;
          color: #999;
          .i_c {
            color: #999;
          }
          &:hover {
            color: #333;
            .i_c {
              color: #333;
            }
          }
        }
      }
    }
  }
</style>
