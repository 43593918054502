<template>
  <div class="answer-container">
    <div class="box">
      <el-card class="tips">
        提示：仅显示
        <span class="c_red">{{ list.name }}</span>
        对
        <span class="c_red">{{ list.paper }}</span>
        的最后一次答题记录。
        <el-button type="primary" @click="downloadAnswer">导出Excel</el-button>
        <el-button type="primary" @click="print">导出Pdf</el-button>
      </el-card>
      <div ref="print" class="answer-list">
        <el-table border :data="list.ques">
          <el-table-column label="题目" prop="title" />
          <el-table-column label="选项" prop="select" />
          <el-table-column
            align="center"
            label="答案"
            prop="answer"
            width="180"
          />
          <el-table-column
            align="center"
            label="得分"
            prop="score"
            width="80"
          />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
  import getPageTitle from '@/utils/pageTitle'
  import { baseURL } from '@/config'
  import { getAnwser } from '@/api/eval'
  export default {
    name: 'CommAnswer',
    data() {
      return {
        list: [],
      }
    },
    created() {
      this.getNotes(this.$route.query.id)
    },
    methods: {
      handleMeta(name, meta) {
        document.title = getPageTitle(meta.title, true)
      },
      downloadAnswer() {
        window.location.href =
          baseURL + '/papereval/test-answer?id=' + this.$route.query.id
      },
      async getNotes(id) {
        let type = this.$route.query.type
        let data = null
        data = await getAnwser({ id })
        this.list = data.data
        this.handleMeta('CommAnswer', {
          title: data.data.name + data.data.paper + '答题记录',
        })
      },
      print() {
        this.$print(this.$refs.print)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .answer-container {
    .box {
      width: 840px;
      background: #f5f5f5;
      padding: 20px;
      margin: auto;
      .el-card {
        &.is-always-shadow {
          box-shadow: 0 2px 12px 0 rgba(187, 187, 187, 0.1);
        }
        .c_red {
          color: #e64e1f;
        }
      }
      .answer-list {
        background: #fff;
        padding: 20px;
      }
    }
  }
  @media print {
    @page {
      size: auto;
      margin: 5mm;
    }
  }
</style>
