<template>
  <el-dialog
    :close-on-click-modal="false"
    title="生成报告"
    :visible.sync="dialogFormVisible"
    width="480px"
    @close="close"
  >
    <div v-loading="loading">
      <el-tree
        v-if="!loading"
        ref="ozTree"
        :data="dataTree"
        node-key="id"
        :props="defaultProps"
        show-checkbox
        @check="treeChecked"
      >
        <span slot-scope="{ node }">
          <span>{{ node.label }}</span>
          <span class="orange">{{ node.data.pep || 0 }} 人</span>
        </span>
      </el-tree>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submitForm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getTeamOrigin, checkReport } from '@/api/teamList'
  export default {
    name: 'ReportOrigin',
    data() {
      return {
        loading: true,
        tid: '',
        isFirst: '',
        dataTree: [],
        treeOz: [],
        defaultProps: {
          children: 'children',
          label: 'name',
        },
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      showEdit(row, jump) {
        this.tid = row.id
        this.isFirst = jump
        this.dialogFormVisible = true
        this.loading = true
        getTeamOrigin({ id: row.id })
          .then((res) => {
            this.dataTree = res.data
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
          })
      },
      close() {
        this.dialogFormVisible = false
      },
      treeChecked() {
        this.treeOz = this.$refs.ozTree.getCheckedKeys(true)
      },
      submitForm() {
        if (!this.treeOz.length) {
          this.$baseMessage('请选择组织结构', 'error')
          return false
        } else {
          checkReport({
            id: this.tid,
            treeOz: this.treeOz,
          }).then((res) => {
            if (res.code === 200) {
              if (this.isFirst === 'first') {
                let routeData = this.routeTo(
                  { id: this.tid },
                  '/other/teamRecord'
                )
                window.open(routeData.href, '_blank')
              } else {
                this.$emit('refresh')
              }
            }
          })

          this.close()
        }
      },
      routeTo(id, url) {
        let routeData = this.$router.resolve({
          path: url,
          query: id,
        })
        return routeData
      },
    },
  }
</script>

<style lang="scss" scoped>
  .orange {
    color: #e6a155;
    margin-left: 5px;
    font-weight: bold;
  }
</style>
