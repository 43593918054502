<template>
  <div class="kindeditor">
    <textarea
      :id="id"
      v-model="outContent"
      name="content"
      placeholder="内容..."
    ></textarea>
  </div>
</template>

<script>
  import 'kindeditor/kindeditor-all.js'
  import 'kindeditor/lang/zh-CN.js'
  import 'kindeditor/themes/default/default.css'

  export default {
    name: 'Kindeditor',
    props: {
      content: {
        type: String,
        default: '',
      },
      id: {
        type: String,
        required: true,
      },
      // eslint-disable-next-line vue/require-default-prop
      width: {
        type: String,
      },
      // eslint-disable-next-line vue/require-default-prop
      height: {
        type: String,
      },
      minWidth: {
        type: Number,
        default: 650,
      },
      minHeight: {
        type: Number,
        default: 100,
      },
      items: {
        type: Array,
        default: function () {
          return [
            'source',
            'quickformat',
            'clearhtml',
            '|',
            'image',
            '|',
            'fontname',
            'fontsize',
            'forecolor',
            'hilitecolor',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'lineheight',
            'removeformat',
            'unlink',
            'undo',
            'redo',
            '|',
            'plainpaste',
            'wordpaste',
            'justifyleft',
            'justifyfull',
            '|',
            'justifycenter',
            'justifyright',
            'fullscreen',
          ]
        },
      },
      noDisableItems: {
        type: Array,
        default: function () {
          return ['source', 'fullscreen']
        },
      },
      filterMode: {
        type: Boolean,
        default: true,
      },
      htmlTags: {
        type: Object,
        default: function () {
          return {
            font: ['color', 'size', 'face', '.background-color'],
            span: ['style'],
            div: ['class', 'align', 'style'],
            table: [
              'class',
              'border',
              'cellspacing',
              'cellpadding',
              'width',
              'height',
              'align',
              'style',
            ],
            'td,th': [
              'class',
              'align',
              'valign',
              'width',
              'height',
              'colspan',
              'rowspan',
              'bgcolor',
              'style',
            ],
            a: ['class', 'href', 'target', 'name', 'style'],
            embed: [
              'src',
              'width',
              'height',
              'type',
              'loop',
              'autostart',
              'quality',
              'style',
              'align',
              'allowscriptaccess',
              '/',
            ],
            img: [
              'src',
              'width',
              'height',
              'border',
              'alt',
              'title',
              'align',
              'style',
              '/',
            ],
            hr: ['class', '/'],
            br: ['/'],
            'p,ol,ul,li,blockquote,h1,h2,h3,h4,h5,h6': ['align', 'style'],
            'tbody,tr,strong,b,sub,sup,em,i,u,strike': [],
          }
        },
      },
      wellFormatMode: {
        type: Boolean,
        default: true,
      },
      resizeType: {
        type: Number,
        default: 2,
      },
      themeType: {
        type: String,
        default: 'default',
      },
      langType: {
        type: String,
        default: 'zh-CN',
      },
      designMode: {
        type: Boolean,
        default: true,
      },
      fullscreenMode: {
        type: Boolean,
        default: false,
      },
      // eslint-disable-next-line vue/require-default-prop
      basePath: {
        type: String,
      },
      // eslint-disable-next-line vue/require-default-prop
      themesPath: {
        type: String,
      },
      pluginsPath: {
        type: String,
        default: '',
      },
      // eslint-disable-next-line vue/require-default-prop
      langPath: {
        type: String,
      },
      minChangeSize: {
        type: Number,
        default: 5,
      },
      loadStyleMode: {
        type: Boolean,
        default: true,
      },
      urlType: {
        type: String,
        default: '',
      },
      newlineTag: {
        type: String,
        default: 'p',
      },
      pasteType: {
        type: Number,
        default: 2,
      },
      dialogAlignType: {
        type: String,
        default: 'page',
      },
      shadowMode: {
        type: Boolean,
        default: true,
      },
      zIndex: {
        type: Number,
        default: 811213,
      },
      useContextmenu: {
        type: Boolean,
        default: true,
      },
      syncType: {
        type: String,
        default: 'form',
      },
      indentChar: {
        type: String,
        default: '\t',
      },
      // eslint-disable-next-line vue/require-default-prop
      cssPath: {
        type: [String, Array],
      },
      // eslint-disable-next-line vue/require-default-prop
      cssData: {
        type: String,
      },
      // eslint-disable-next-line vue/require-default-prop
      bodyClass: {
        type: String,
        default: 'ke-content',
      },
      // eslint-disable-next-line vue/require-default-prop
      colorTable: {
        type: Array,
      },
      // eslint-disable-next-line vue/require-default-prop
      afterCreate: {
        type: Function,
      },
      // eslint-disable-next-line vue/require-default-prop
      afterChange: {
        type: Function,
      },
      // eslint-disable-next-line vue/require-default-prop
      afterTab: {
        type: Function,
      },
      // eslint-disable-next-line vue/require-default-prop
      afterFocus: {
        type: Function,
      },
      // eslint-disable-next-line vue/require-default-prop
      afterBlur: {
        type: Function,
      },
      // eslint-disable-next-line vue/require-default-prop
      afterUpload: {
        type: Function,
      },
      // eslint-disable-next-line vue/require-default-prop
      uploadJson: {
        type: String,
      },
      // eslint-disable-next-line vue/require-default-prop
      fileManagerJson: {
        type: Function,
      },
      allowPreviewEmoticons: {
        type: Boolean,
        default: true,
      },
      allowImageUpload: {
        type: Boolean,
        default: true,
      },
      allowFlashUpload: {
        type: Boolean,
        default: true,
      },
      allowMediaUpload: {
        type: Boolean,
        default: true,
      },
      allowFileUpload: {
        type: Boolean,
        default: true,
      },
      allowFileManager: {
        type: Boolean,
        default: false,
      },
      fontSizeTable: {
        type: Array,
        default: function () {
          return ['9px', '10px', '12px', '14px', '16px', '18px', '24px', '32px']
        },
      },
      imageTabIndex: {
        type: Number,
        default: 0,
      },
      formatUploadUrl: {
        type: Boolean,
        default: true,
      },
      fullscreenShortcut: {
        type: Boolean,
        default: false,
      },
      extraFileUploadParams: {
        type: Array,
        default: function () {
          return []
        },
      },
      filePostName: {
        type: String,
        default: 'imgFile',
      },
      fillDescAfterUploadImage: {
        type: Boolean,
        default: false,
      },
      // eslint-disable-next-line vue/require-default-prop
      afterSelectFile: {
        type: Function,
      },
      pagebreakHtml: {
        type: String,
        default:
          '<hr style=”page-break-after: always;” class=”ke-pagebreak” />',
      },
      allowImageRemote: {
        type: Boolean,
        default: true,
      },
      autoHeightMode: {
        type: Boolean,
        default: false,
      },
      fixToolBar: {
        type: Boolean,
        default: false,
      },
      // eslint-disable-next-line vue/require-default-prop
      tabIndex: {
        type: Number,
      },
    },
    data() {
      return {
        editor: null,
        outContent: this.content,
      }
    },
    watch: {
      content(val) {
        this.editor && val !== this.outContent && this.editor.html(val)
      },
      outContent(val) {
        this.$emit('update:content', val)
        this.$emit('on-content-change', val)
      },
    },
    mounted() {
      var _this = this
      _this.editor = window.KindEditor.create('#' + this.id, {
        width: _this.width,
        height: _this.height,
        minWidth: _this.minWidth,
        minHeight: _this.minHeight,
        items: _this.items,
        noDisableItems: _this.noDisableItems,
        filterMode: _this.filterMode,
        htmlTags: _this.htmlTags,
        wellFormatMode: _this.wellFormatMode,
        resizeType: _this.resizeType,
        themeType: _this.themeType,
        langType: _this.langType,
        designMode: _this.designMode,
        fullscreenMode: _this.fullscreenMode,
        basePath: _this.basePath,
        themesPath: _this.cssPath,
        pluginsPath: _this.pluginsPath,
        langPath: _this.langPath,
        minChangeSize: _this.minChangeSize,
        loadStyleMode: _this.loadStyleMode,
        urlType: _this.urlType,
        newlineTag: _this.newlineTag,
        pasteType: _this.pasteType,
        dialogAlignType: _this.dialogAlignType,
        shadowMode: _this.shadowMode,
        zIndex: _this.zIndex,
        useContextmenu: _this.useContextmenu,
        syncType: _this.syncType,
        indentChar: _this.indentChar,
        cssPath: _this.cssPath,
        cssData: _this.cssData,
        bodyClass: _this.bodyClass,
        colorTable: _this.colorTable,
        afterCreate: _this.afterCreate,
        afterChange: function () {
          _this.afterChange
          _this.outContent = this.html()
        },
        afterTab: _this.afterTab,
        afterFocus: _this.afterFocus,
        afterBlur: _this.afterBlur,
        afterUpload: _this.afterUpload,
        uploadJson: _this.uploadJson,
        fileManagerJson: _this.fileManagerJson,
        allowPreviewEmoticons: _this.allowPreviewEmoticons,
        allowImageUpload: _this.allowImageUpload,
        allowFlashUpload: _this.allowFlashUpload,
        allowMediaUpload: _this.allowMediaUpload,
        allowFileUpload: _this.allowFileUpload,
        allowFileManager: _this.allowFileManager,
        fontSizeTable: _this.fontSizeTable,
        imageTabIndex: _this.imageTabIndex,
        formatUploadUrl: _this.formatUploadUrl,
        fullscreenShortcut: _this.fullscreenShortcut,
        extraFileUploadParams: _this.extraFileUploadParams,
        filePostName: _this.filePostName,
        fillDescAfterUploadImage: _this.fillDescAfterUploadImage,
        afterSelectFile: _this.afterSelectFile,
        pagebreakHtml: _this.pagebreakHtml,
        allowImageRemote: _this.allowImageRemote,
        autoHeightMode: _this.autoHeightMode,
        fixToolBar: _this.fixToolBar,
        tabIndex: _this.tabIndex,
      })
    },
  }
</script>

<style lang="scss">
  body > .ke-container.ke-container-default {
    position: fixed !important;
  }
  .ke-toolbar {
    background: none;
  }
  .ke-statusbar {
    display: none;
  }
</style>
