<template>
  <div class="warning-container">
    <template v-if="queryForm.uid === 'list' || queryForm.uid === ''">
      <vab-query-form>
        <vab-query-form-left-panel :span="6">
          <el-button
            :disabled="!button.add"
            icon="el-icon-plus"
            type="primary"
            @click="handleEdit('', '', 'add')"
          >
            添加
          </el-button>
          <el-button
            :disabled="!button.download"
            icon="el-icon-download"
            @click="exportExcel"
          >
            导出
          </el-button>
          <el-button @click="doSomething">批量处理</el-button>
          <el-button
            v-if="showSend"
            icon="el-icon-message"
            @click="sendMessage"
          >
            群发通知
          </el-button>
        </vab-query-form-left-panel>
        <vab-query-form-right-panel :span="18">
          <el-form
            ref="queryForms"
            :inline="true"
            :model="queryForm"
            @submit.native.prevent
          >
            <el-form-item v-if="isSid === 'all'" prop="sid">
              <el-select
                v-model="queryForm.sid"
                clearable
                filterable
                placeholder="选择渠道"
              >
                <el-option
                  v-for="item in studioList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="name">
              <el-input
                v-model.trim="queryForm.name"
                clearable
                placeholder="请输入姓名"
              />
            </el-form-item>
            <el-form-item prop="account">
              <el-input
                v-model.trim="queryForm.account"
                clearable
                placeholder="请输入账号"
              />
            </el-form-item>
            <el-form-item prop="origin">
              <el-cascader
                ref="orCas"
                v-model="queryForm.origin"
                clearable
                :options="treeData"
                placeholder="组织结构"
                :props="defaultProps"
                @change="handleChange"
                @visible-change="cascaderClicked"
              />
            </el-form-item>
            <el-form-item prop="source">
              <el-select
                v-model="queryForm.source"
                clearable
                filterable
                placeholder="预警来源"
              >
                <el-option
                  v-for="item in sourceList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.sort"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="interve_sort">
              <el-select
                v-model="queryForm.interve_sort"
                clearable
                filterable
                placeholder="干预级别"
              >
                <el-option
                  v-for="item in interveList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.sort"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="warn_sort">
              <el-select
                v-model="queryForm.warn_sort"
                clearable
                filterable
                placeholder="预警级别"
              >
                <el-option
                  v-for="item in warningList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.sort"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="warn_at">
              <el-date-picker
                v-model="queryForm.warn_at"
                align="right"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                range-separator="-"
                start-placeholder="开始日期"
                type="daterange"
                unlink-panels
                value-format="yyyy-MM-dd"
                @change="selectDate"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                icon="el-icon-search"
                type="primary"
                @click="queryData"
              >
                查询
              </el-button>
              <el-button icon="el-icon-refresh" type="primary" @click="refresh">
                刷新
              </el-button>
            </el-form-item>
          </el-form>
        </vab-query-form-right-panel>
      </vab-query-form>
      <div class="m-tab">
        <div
          v-for="(tab, i) in status"
          :key="i"
          class="m-t-item"
          :class="queryForm.status === tab.id ? 'cur' : ''"
          @click="bindStatus(tab.id)"
        >
          <i :class="tab.icon"></i>
          {{ tab.name }}
        </div>
      </div>
    </template>
    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="selectChange"
    >
      <el-table-column align="center" type="selection" />
      <el-table-column align="center" label="姓名" min-width="120" prop="name">
        <template #default="{ row }">
          <div class="user">
            <img v-if="row.sex === '男'" src="@/assets/male.png" />
            <img v-else-if="row.sex === '女'" src="@/assets/female.png" />
            <img v-else src="@/assets/unknow.png" />

            <el-button
              type="text"
              @click="handleEdit(row, '/userManagement/member/archives')"
            >
              {{ row.name }}
            </el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="账号" prop="account" />
      <el-table-column align="center" label="组织结构" prop="origin" />
      <el-table-column align="center" label="系统预警" prop="warn_level">
        <template slot="header">
          <el-tooltip effect="dark" placement="bottom">
            <template slot="content">
              系统预警是通过心理测评结束后，系统会根据（系统内置或者自定义预警逻辑）
              <br />
              识别出具有不同程度心理健康状态的名单，此类名单是需要心理老师紧急进行心
              <br />
              理个案访谈工作的。心理老师需根据访谈结果对这部分名单再次确认，并完成
              <br />
              最终的心理访谈定级。
            </template>
            <span>
              系统预警
              <i class="el-icon-question"></i>
            </span>
          </el-tooltip>
        </template>
        <template #default="{ row }">
          <span
            v-if="row.warn_color"
            :class="{ b_c: row.warn_color }"
            :style="{
              'background-color': hexToRgba(row.warn_color, 0.1),
              color: row.warn_color,
              border: '1px solid ' + hexToRgba(row.warn_color, 0.3),
            }"
          >
            {{ row.warn_level }}
          </span>
          <span v-else>{{ row.warn_level }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isSid == 115"
        label="预警内容"
        prop="mental_remark"
      >
        <template #default="{ row }">
          <el-popover
            v-if="row.mental_remark.length > 10"
            placement="top"
            trigger="hover"
            width="180"
          >
            <div
              style="font-size: 13px; line-height: 1.6"
              v-html="row.mental_remark"
            ></div>
            <span
              class="ellipsis2"
              slot="reference"
              v-html="row.mental_remark"
            ></span>
          </el-popover>
          <span v-else>{{ row.mental_remark }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="false"
        align="center"
        label="干预级别"
        prop="interve_level"
      >
        <template #default="{ row }">
          <span
            v-if="row.interve_color"
            :class="{ b_c: row.interve_color }"
            :style="{
              'background-color': hexToRgba(row.interve_color, 0.1),
              color: row.interve_color,
              border: '1px solid ' + hexToRgba(row.interve_color, 0.3),
            }"
          >
            {{ row.interve_level }}
          </span>
          <span v-else>{{ row.interve_level }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="预警来源" prop="warn_source">
        <template slot="header">
          <el-tooltip effect="dark" placement="bottom">
            <template slot="content">
              预警来源主要通过系统心理普查、个人测评以及班主任或者心理老师
              <br />
              等方式添加，成为关注的名单作为访谈对象进行访谈定级。
            </template>
            <span>
              预警来源
              <i class="el-icon-question"></i>
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" label="预警时间" prop="warn_at" />
      <el-table-column align="center" label="状态" prop="is_evaluate">
        <template #default="{ row }">
          <el-tag :type="row.is_evaluate ? '' : 'danger'">
            {{ row.is_evaluate ? '已处理' : '待处理' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="访谈评估" width="100">
        <template #default="{ row }">
          <el-button
            v-if="row.is_evaluate"
            type="success"
            @click="handleEdit(row, '/mental/assess')"
          >
            查看
          </el-button>
          <el-button
            v-else
            :disabled="!button.add"
            type="primary"
            @click="handleEdit(row, 'evaluate', 'add')"
          >
            添加
          </el-button>
        </template>
      </el-table-column>
      <el-table-column v-if="false" align="center" label="干预记录">
        <template #default="{ row }">
          <el-button
            :disabled="!row.is_interve"
            type="text"
            @click="handleEdit(row, '/mental/interveList')"
          >
            查看
          </el-button>
          <el-button
            :disabled="!button.add"
            type="text"
            @click="handleEdit(row, 'interve', 'add')"
          >
            添加
          </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="80">
        <template #default="{ row }">
          <el-button :disabled="!button.add" type="text" @click="evalEdit(row)">
            分发测评
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <!-- 新增预警 -->
    <edit ref="edit" @fetch-data="fetchData" />
    <!-- 访谈评估 -->
    <add-record ref="addrecord" @fetch-data="fetchData" />
    <!-- 分发测评 -->
    <eval-edit ref="eval-edit" />
    <!-- 批量处理 -->
    <assess-all ref="assess-all" @fetch-data="fetchData" />
    <!-- 群发通知 -->
    <send-message ref="send-message" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getMentalWarList, getWarnParam } from '@/api/crisis'
  import { getStudioList } from '@/api/platformChannelManagement'
  import { mapGetters } from 'vuex'
  import Edit from './components/AddSingleMental'
  import AddRecord from '../team/warnList/components/TeamWarnListEdit'
  import EvalEdit from './components/EvalEdit'
  import AssessAll from './components/handleAssess'
  import SendMessage from './components/sendMessage'
  import { getCurStudioOrgin } from '@/api/userManagementOrganization'
  import { hexToRgba } from '@/utils/utils'

  export default {
    name: 'PersonList',
    components: { SendMessage, Edit, AddRecord, EvalEdit, AssessAll },
    props: {
      type: {
        type: String,
        default: 'list',
      },
    },
    data() {
      return {
        list: [],
        treeData: [], //组织结构
        studioList: [],
        selectRows: '',
        status: [
          {
            name: '全部',
            id: 0,
            icon: 'el-icon-monitor',
          },
          {
            name: '已处理',
            id: 1,
            icon: 'el-icon-collection',
          },
          {
            name: '待处理',
            id: 2,
            icon: 'el-icon-edit',
          },
        ],
        warningList: [], //预警等级列表
        interveList: [], //干预等级列表
        sourceList: [], //预警来源列表
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          name: '',
          account: '',
          sid: '',
          warn_sort: '',
          interve_sort: '',
          origin: '',
          source: '',
          warn_at: '',
          status: 0,
          uid: '',
        },
        defaultProps: {
          children: 'children',
          label: 'name',
          value: 'id',
          checkStrictly: true,
        },
        button: {},
        showSend: false,
        pickerOptions: {
          shortcuts: [
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近一个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近三个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                picker.$emit('pick', [start, end])
              },
            },
          ],
        },
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    watch: {
      type: {
        handler(newData) {
          if (newData !== 'list') {
            this.queryForm.uid = newData
          }
          this.fetchData()
        },
        deep: true,
        immediate: true,
      },
    },
    created() {
      this.getStudio()
      this.levelType('warning')
      this.levelType('interve')
      this.levelType('source')
      //this.fetchData()
    },
    methods: {
      hexToRgba,
      evalEdit(row) {
        this.$refs['eval-edit'].showEdit(row)
      },
      selectChange(val) {
        this.selectRows = val
      },
      sendMessage() {
        if (this.selectRows.length) {
          this.$refs['send-message'].showEdit(this.selectRows)
        } else {
          this.$baseMessage('请选择需要发通知的项！', 'warning')
        }
      },
      doSomething() {
        if (this.selectRows.length) {
          if (this.queryForm.status == 1) {
            this.$baseMessage('无需处理')
            return false
          } else if (this.queryForm.status == 0) {
            this.$baseMessage('请在待处理列表选择要处理的行！', 'warning')
            this.bindStatus(2)
          } else {
            this.$refs['assess-all'].showEdit(this.selectRows)
          }
        } else {
          this.$baseMessage('请在待处理列表选择要处理的行！', 'warning')
          this.bindStatus(2)
        }
      },
      bindStatus(val) {
        this.queryForm.status = val
        this.queryData()
      },
      async exportExcel() {
        //导出
        this.listLoading = true
        let qf = this.queryForm
        qf.pageNo = 1
        qf.excel = 'excel'
        const { data, header, filename } = await getMentalWarList(qf)
        import('@/utils/excel').then((excel) => {
          excel.export_json_to_excel({
            header,
            data,
            filename: filename,
            autoWidth: true,
            bookType: 'xlsx',
          })
          this.listLoading = false
        })
      },
      cascaderClicked($event) {
        if ($event) {
          this.getOriginList(this.queryForm.sid)
        }
      },
      handleChange(value) {
        let id = this.$refs.orCas.getCheckedNodes(true)
        this.queryForm.origin = id.length ? id[0].value : ''
      },
      async getStudio() {
        const { data } = await getStudioList()
        this.studioList = data
      },
      async getOriginList(sid) {
        //获取组织结构
        const { data } = await getCurStudioOrgin(
          this.isSid === 'all' ? { sid: sid } : ''
        )
        this.treeData = data
      },
      selectDate(val) {
        this.queryForm.warn_at = val
      },
      handleEdit(row, url, type) {
        if (type === 'add') {
          if (url) {
            this.$refs['addrecord'].showEdit(row, 'add')
          } else {
            this.$refs['edit'].showEdit()
          }
        } else {
          let obj = {}
          if (url === '/userManagement/member/archives') {
            obj = { id: row.uid }
          } else {
            obj = { account: row.account }
          }
          this.$router.push({
            path: url,
            query: obj,
          })
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      levelType(type) {
        getWarnParam({ type: type }).then((res) => {
          this[type + 'List'] = res.data
        })
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      refresh() {
        this.$refs.queryForms.resetFields()
        this.queryData()
      },
      async fetchData() {
        this.listLoading = true
        this.queryForm.excel = 'search'
        const { data, totalCount, button, send_status } =
          await getMentalWarList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.button = button
        this.showSend = send_status
        this.listLoading = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .warning-container {
    .m-tab {
      display: flex;
      align-items: center;
      .m-t-item {
        padding: 10px 30px;
        border-radius: 10px 10px 0 0;
        cursor: pointer;
        &:hover,
        &.cur {
          background: #26cdb7;
          color: #fff;
          mask: url('~@/assets/tabs_images/vab-tab.png');
          mask-size: 100% 100%;
        }
      }
    }
    .b_c {
      display: inline-block;
      padding: 0 7px;
      border-radius: 3px;
      font-size: 12px;
    }
    .ellipsis2 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
  }
</style>
