import request from '@/utils/request'
//渠道库列表
export function getList(params) {
  return request({
    url: '/platform/studio-list',
    method: 'get',
    params,
  })
}

//获取所有渠道
export function getStudioList(params) {
  return request({
    url: '/router/get-studio',
    method: 'get',
    params,
  })
}

//渠道 新增/编辑
export function editStudio(data) {
  return request({
    url: '/platform/studio-add',
    method: 'post',
    data,
  })
}

//渠道 增加人数
export function editStudioAddnum(data) {
  return request({
    url: '/platform/studio-add-num',
    method: 'post',
    data,
  })
}

export function doDelete(data) {
  return request({
    url: '/platform/studio-del',
    method: 'post',
    data,
  })
}

// 发布公告
export function addNotice(data) {
  return request({
    url: '/notice/add',
    method: 'post',
    data,
  })
}

// 获取公告列表
export function getNoticeList() {
  return request({
    url: '/notice/list',
    method: 'get',
  })
}

// 读取公告
export function readNotice(params) {
  return request({
    url: '/notice/read',
    method: 'get',
    params,
  })
}

//获取综合筛查量表
export function getMultiPaper(params) {
  return request({
    url: '/platform/get-topic',
    method: 'get',
    params,
  })
}
