<template>
  <div class="warn-view-container">
    <div class="top">
      <div class="t">预警数据看板</div>
      <div v-if="list.state" class="condition">
        <el-date-picker
          v-model="warn_at"
          align="right"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          range-separator="-"
          start-placeholder="开始日期"
          type="daterange"
          unlink-panels
          value-format="yyyy-MM-dd"
          @change="selectDate"
        />
        <el-tooltip effect="dark" placement="bottom">
          <template slot="content">
            默认数据为当前时间段的数据，若要查询更多的数据请选择时间范围。
          </template>
          <span class="daterange-tip" style="margin-right: 10px">
            <i class="el-icon-info" />
          </span>
        </el-tooltip>
        <el-button icon="el-icon-search" type="primary" @click="fetchData">
          查询
        </el-button>
        <el-button icon="el-icon-download" @click="exportExcel">导出</el-button>
      </div>
    </div>
    <el-tabs v-if="list.result" v-model="activeName">
      <el-tab-pane label="数据概览" name="view">
        <div class="chart-box">
          <vab-chart
            ref="source"
            class="chart"
            :height="350"
            :options="list.source"
          />
          <vab-chart
            ref="warn"
            class="chart"
            :height="350"
            :options="list.warn"
          />
          <vab-chart
            ref="evaluate"
            class="chart"
            :height="350"
            :options="list.evaluate"
          />
          <vab-chart
            ref="settle"
            class="chart"
            :height="350"
            :options="list.settle"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane label="系统预警" name="warn">
        <div class="warn">
          <div
            v-for="(item, index) in list.result.warn_result"
            :key="index"
            class="item"
            :style="`background:linear-gradient(120deg, ${
              item.color
            }, ${hexToRgba(item.color, 0.5)})`"
          >
            <div>{{ item.name }}</div>
            <div class="num">{{ item.num }}</div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="访谈评估" name="assess">
        <div class="warn">
          <div
            v-for="(item, index) in list.result.evaluate_result"
            :key="index"
            class="item"
            :style="`background:linear-gradient(120deg, ${
              item.color
            }, ${hexToRgba(item.color, 0.5)})`"
          >
            <div>{{ item.name }}</div>
            <div class="num">{{ item.num }}</div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="危机干预" name="interve">
        <div class="warn">
          <div
            v-for="(item, index) in list.result.interve_result"
            :key="index"
            class="item"
            :style="`background:linear-gradient(135deg, ${
              item.color
            }, ${hexToRgba(item.color, 0.5)})`"
          >
            <div>{{ item.name }}</div>
            <div class="num">{{ item.num }}</div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-table
      ref="refTable"
      v-loading="listLoading"
      border
      :data="list.state"
      :expand-row-keys="list.expanded"
      max-height="700"
      row-key="id"
      :tree-props="{ children: 'children' }"
      @row-click="clickRow"
    >
      <el-table-column
        v-for="(item, index) in list.title"
        :key="index"
        :align="index === 0 ? 'left' : 'center'"
        :fixed="index === 0 ? true : false"
        :label="item.name"
        :min-width="index === 0 ? 200 : 100"
      >
        <template #default="{ row }">
          <span>{{ row[item.param] }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import VabChart from '@/extra/VabChart'
  import { getWarnState } from '@/api/crisis'
  import { hexToRgba, getTime } from '@/utils/utils'
  export default {
    name: 'WarnView',
    components: { VabChart },
    data() {
      return {
        activeName: 'view',
        list: {},
        studioList: [],
        listLoading: true,
        selectRows: '',
        button: {},
        type: 'search',
        warn_at: getTime(),
        pickerOptions: {
          shortcuts: [
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近一个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近三个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                picker.$emit('pick', [start, end])
              },
            },
          ],
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      hexToRgba,
      async exportExcel() {
        //导出
        this.listLoading = true
        const { header, data } = await getWarnState({
          excel: 'excel',
          time: this.warn_at,
        })
        import('@/utils/excel').then((excel) => {
          excel.export_json_to_excel({
            header,
            data,
            filename: '预警概览',
            autoWidth: true,
            bookType: 'xlsx',
          })
          this.listLoading = false
        })
      },
      clickRow(row, index, e) {
        this.$refs.refTable.toggleRowExpansion(row)
      },
      selectDate(val) {
        this.warn_at = val
      },
      async fetchData() {
        this.listLoading = true
        const { data, button } = await getWarnState({ time: this.warn_at })
        this.list = data
        this.button = button
        this.listLoading = false
        this.list.source = this.getPieChart('预警来源', data.circle.source)
        this.list.evaluate = this.getPieChart('访谈干预', data.circle.evaluate)
        this.list.settle = this.getPieChart('结案情况', data.circle.settle)
        this.list.warn = this.getPieChart('系统预警', data.circle.warn)
      },
      getPieChart(name, datas) {
        //饼图
        let option = {
          title: {
            text: name,
            x: 'center',
            top: '10%',
          },
          tooltip: {
            trigger: 'item',
          },
          legend: {
            bottom: '15%',
            x: 'center',
            icon: 'circle',
            textStyle: {
              color: '#999',
              fontSize: '10px',
            },
            itemWidth: 8,
            itemHeight: 8,
          },
          series: {
            name: name,
            type: 'pie',
            radius: ['30%', '50%'],
            avoidLabelOverlap: false,
            label: {
              formatter: '{time|{c}}',
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 15,
              rich: {
                time: {
                  fontSize: 12,
                  color: '#999',
                },
              },
            },
            labelLine: {
              length: 5,
              length2: 15,
              maxSurfaceAngle: 80,
            },
            data: datas,
          },
        }
        if (name === '系统预警') {
          option.color = ['#73c0de', '#ff4d4f', '#26cdb7']
        } else if (name === '访谈干预') {
          option.color = ['#ffba00', '#ff4d4f', '#26cdb7']
        }
        return option
      },
    },
  }
</script>

<style lang="scss" scoped>
  .warn-view-container {
    :deep .el-tabs {
      .el-tabs__item.is-active {
        font-weight: bold !important;
      }
    }
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      margin-top: 10px;
      .t {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .chart-box {
      display: flex;
      align-items: center;
      .chart {
        width: 25%;
      }
    }
    .warn {
      display: flex;
      align-items: center;
      padding-bottom: 30px;
      .item {
        min-width: 180px;
        padding: 30px 20px;
        border-radius: 6px;
        color: #fff;
        margin: 20px;
        text-align: center;
        box-shadow: 0 0 15px 10px rgb(130 132 138 / 15%);
        &:hover {
          background: #26cdb7 !important;
        }
        .num {
          font-size: 20px;
          font-weight: bold;
          margin-top: 10px;
        }
      }
    }
  }
</style>
