<template>
  <div class="no-data">
    <el-image :src="img" style="width: 180px; height: 155px" />
    <div class="txt">{{ txt }}</div>
  </div>
</template>

<script>
  export default {
    name: 'NoData',
    props: {
      img: {
        type: String,
        default: require('@/assets/error_images/no-data.png'),
      },
      txt: {
        type: String,
        default: '',
      },
    },
    data() {
      return {}
    },
    created() {},
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .no-data {
    text-align: center;
    margin: 200px 0;
    .txt {
      color: #999;
      margin-top: 20px;
      font-size: 14px;
    }
  }
</style>
