<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="1000px"
      @close="close"
    >
      <el-form ref="form" label-width="100px" :model="form" :rules="rules">
        <el-form-item label="测评名称" prop="title">
          <el-input
            v-model="form.title"
            max="16"
            placeholder="请输入测评名称"
            style="width: 300px"
          />
        </el-form-item>
        <el-form-item label="测评对象" prop="userArr">
          <el-button type="primary" @click="addPerson">选择</el-button>
          <span style="font-size: 12px; color: #999; padding-left: 20px">
            没有想选择的用户？
          </span>
          <el-button type="text" @click="handleAdd">新增</el-button>
          <div v-if="form.userArr.length" class="userlist">
            <div
              v-for="(user, index) in form.userArr"
              :key="index"
              class="username"
            >
              {{ user.name }}
              <span class="el-icon-error" @click="delUserId(user.uid)"></span>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="测评类型" prop="type">
          <el-radio-group v-model="form.type">
            <el-radio :label="0">全部</el-radio>
            <el-radio :label="1">自评</el-radio>
            <el-radio :label="2">他评</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="测评量表" prop="evalArr">
          <el-transfer
            v-model="form.evalArr"
            :data="evalList"
            :filter-method="filterMethod"
            filter-placeholder="搜索量表"
            filterable
            :props="{ key: 'id', label: 'name' }"
            :titles="['量表库', '已选量表']"
          />
        </el-form-item>
        <el-form-item label="开放报告" prop="is_open">
          <el-switch
            v-model="form.is_open"
            active-text="是"
            active-value="1"
            inactive-text="否"
            inactive-value="0"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="middle" @click="close">取 消</el-button>
        <el-button size="middle" type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
    <add-person ref="addperson" @fetch-data="getInfo" />
    <add-user ref="add" @fetch-data="getUser" />
  </div>
</template>

<script>
  import { getEvalListBySid, addEval } from '@/api/mmptCategory'
  import AddPerson from './addPerson'
  import AddUser from '@/views/userManagement/member/components/MemberEdit'

  export default {
    name: 'MmptCategoryEdit',
    components: { AddPerson, AddUser },
    data() {
      const validateTitle = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入标题'))
        } else if (value.length < 2 || value.length > 16) {
          callback(new Error('标题长度为2~16个字符'))
        } else {
          callback()
        }
      }
      return {
        evalList: [], //获取所有量表
        form: {
          title: '',
          evalArr: [],
          userArr: [],
          is_open: '1',
          type: 0,
        },
        rules: {
          title: [
            { required: true, trigger: 'blur', validator: validateTitle },
          ],
          evalArr: [
            { required: true, trigger: 'change', message: '请选择量表' },
          ],
          userArr: [
            { required: true, trigger: 'change', message: '请选择测评对象' },
          ],
          is_open: [
            {
              required: true,
              trigger: 'change',
              message: '请选择是否开放报告',
            },
          ],
        },
        title: '',
        dialogFormVisible: false,
        treeIds: [],
      }
    },
    created() {},
    methods: {
      handleAdd() {
        this.$refs['add'].showEdit()
      },
      getUser(val) {
        this.form.userArr.push({
          name: val.name,
          uid: val.uid,
        })
      },
      addPerson() {
        let data = {
          ids: this.treeIds,
          list: this.form.userArr,
        }
        this.$refs['addperson'].showEdit(data)
      },
      getInfo(val) {
        this.form.userArr = []
        this.treeIds = []
        this.form.userArr = val.list
        this.treeIds = val.ids
      },
      async getEvalList() {
        const { data } = await getEvalListBySid()
        this.evalList = data
      },
      showEdit(row) {
        if (!row) {
          this.title = '分发测评'
        }
        this.getEvalList()
        this.dialogFormVisible = true
      },
      close() {
        this.treeIds = []
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        //this.$refs['addperson'].close()
        this.dialogFormVisible = false
      },
      filterMethod(query, item) {
        if (!query) return true
        return (
          item.name.toString().toLowerCase().indexOf(query.toLowerCase()) > -1
        )
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg, code } = await addEval(this.form)
            this.$baseMessage(msg, code === 200 ? 'success' : 'error')
            if (code === 200) {
              this.$emit('fetch-data')
              this.close()
            }
          } else {
            return false
          }
        })
      },
      delUserId(uid) {
        this.form.userArr = this.form.userArr.filter((item) => item.uid !== uid)
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-transfer-panel {
      width: 320px !important;
    }
  }
  .userlist {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    .username {
      background: #f9f9f9;
      border-radius: 4px;
      border: 1px solid #ddd;
      padding: 0 8px;
      margin: 0 10px 10px 0;
      .el-icon-error {
        color: #ddd;
        &:hover {
          color: #999;
        }
      }
    }
  }
  .dialog-footer {
    padding: 0 15px !important;
    .el-button {
      width: 100px;
      margin-left: 15px;
    }
  }
</style>
