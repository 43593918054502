import request from '@/utils/request'
//图片列表
export function getImgList(params) {
  return request({
    url: '/gallery/image-list',
    method: 'get',
    params,
  })
}

//图片分类
export function getImgType(params) {
  return request({
    url: '/gallery/type-images',
    method: 'get',
    params,
  })
}

//图片上传
export function upload(data) {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/gallery/upload-images',
    method: 'post',
    data,
  })
}

//更改图片状态
export function changeImg(data) {
  return request({
    url: '/gallery/change-images',
    method: 'post',
    data,
  })
}

//编辑分类
export function editType(data) {
  return request({
    url: '/gallery/change-image-type',
    method: 'post',
    data,
  })
}
