var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "头像裁剪",
        visible: _vm.dialogVisible,
        width: "520px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.upload } },
                    [_vm._v("确定")]
                  ),
                  _c("el-upload", {
                    attrs: {
                      accept: "image/jpeg,image/gif,image/png",
                      action: "#",
                      "auto-upload": false,
                      "list-type": "picture-card",
                      "on-change": _vm.onChange,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("el-button", { attrs: { type: "primary" } }, [
                              _vm._v("选择图片"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("vab-cropper-beta", {
                ref: "vabCropper",
                attrs: {
                  options: _vm.options,
                  "origin-img": _vm.slide.oriUrl,
                  "preview-img": _vm.slide.preUrl,
                },
                on: {
                  "get-vab-cropper": function ($event) {
                    return _vm.getCropper(arguments)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }