var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add-form-container" },
    [
      _c("div", { staticClass: "add-title" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "el-row",
        { attrs: { gutter: 15 } },
        [
          _c(
            "el-col",
            {
              attrs: {
                lg: { span: 16, offset: 4 },
                md: { span: 20, offset: 2 },
                sm: { span: 20, offset: 2 },
                xl: { span: 14, offset: 5 },
                xs: 24,
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "demo-form",
                  attrs: {
                    "label-position": _vm.labelPosition,
                    "label-width": "110px",
                    model: _vm.form,
                    rules: _vm.rules,
                  },
                },
                [
                  _vm.active === 1
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            { staticClass: "form-row" },
                            [
                              _c("div", { staticClass: "block-title" }, [
                                _vm._v("基本信息"),
                              ]),
                              _c(
                                "el-form-item",
                                { attrs: { label: "计划标题", prop: "title" } },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.form.title,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "title", $$v)
                                      },
                                      expression: "form.title",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "时间区间", prop: "times" } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      align: "center",
                                      "end-placeholder": "结束日期",
                                      "picker-options": _vm.pickerOptions,
                                      "range-separator": "-",
                                      "start-placeholder": "开始日期",
                                      type: "daterange",
                                      "unlink-panels": "",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.form.times,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "times", $$v)
                                      },
                                      expression: "form.times",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "计划简述", prop: "descr" } },
                                [
                                  _c("el-input", {
                                    attrs: { type: "textarea" },
                                    model: {
                                      value: _vm.form.descr,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "descr", $$v)
                                      },
                                      expression: "form.descr",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "封面", prop: "cover" } },
                                [
                                  _c("div", { staticClass: "img-uploader" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "el-upload el-upload__text",
                                        on: { click: _vm.selImg },
                                      },
                                      [
                                        _vm.hasImg
                                          ? _c("img", {
                                              staticClass: "img",
                                              attrs: { src: _vm.hasImg },
                                            })
                                          : _c("i", {
                                              staticClass: "el-icon-plus",
                                            }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "el-upload__tip",
                                        attrs: { slot: "tip" },
                                        slot: "tip",
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(" 只能上传 "),
                                          _c("span", [_vm._v(".jpg、.png")]),
                                          _vm._v(" 文件，且 "),
                                          _c("span", [_vm._v("不超过1M")]),
                                        ]),
                                        _c("div", [_vm._v("(图片尺寸1:1)")]),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "补充信息",
                                    prop: "base_check",
                                  },
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "label" },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            content:
                                              "勾选则表示用户加入计划前需要填写对应的信息（原始数据会记录相关数据），配置更多的用户个人信息字段请前往【团体测评 > 扩展字段】中设置",
                                            placement: "top-start",
                                            title: "补充信息说明",
                                            trigger: "hover",
                                            width: "300",
                                          },
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "el-icon-question",
                                            attrs: { slot: "reference" },
                                            slot: "reference",
                                          }),
                                        ]
                                      ),
                                      _vm._v(" 补充信息 "),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      model: {
                                        value: _vm.form.base_check,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "base_check", $$v)
                                        },
                                        expression: "form.base_check",
                                      },
                                    },
                                    _vm._l(
                                      _vm.form.base,
                                      function (item, index) {
                                        return _c(
                                          "el-checkbox",
                                          {
                                            key: index,
                                            attrs: {
                                              label: item.id,
                                              name: "baseinfo",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.value) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                2
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "预警方案", prop: "plan" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "预警方案" },
                                      model: {
                                        value: _vm.form.plan,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "plan", $$v)
                                        },
                                        expression: "form.plan",
                                      },
                                    },
                                    _vm._l(_vm.planList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _c("el-button", {
                                    staticStyle: {
                                      "font-size": "15px",
                                      "padding-left": "10px",
                                    },
                                    attrs: {
                                      icon: "el-icon-refresh",
                                      type: "text",
                                    },
                                    on: { click: _vm.levelType },
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: { click: _vm.addWarnPlan },
                                    },
                                    [_vm._v("新增")]
                                  ),
                                  _c(
                                    "el-link",
                                    {
                                      staticStyle: {
                                        "font-size": "12px",
                                        "line-height": "1",
                                        "padding-bottom": "3px",
                                      },
                                      attrs: {
                                        href: _vm.doc,
                                        target: "_blank",
                                        type: "danger",
                                      },
                                    },
                                    [_vm._v(" 【重要提示】预警方案说明 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-row switch" },
                            [
                              _c("div", { staticClass: "block-title" }, [
                                _vm._v("用户登录配置"),
                              ]),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "开启免导入",
                                    prop: "is_mian",
                                  },
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "label" },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            content:
                                              "选择开启免导入用户信息功能则不需要提前录入用户信息，开启功能后默认使用团测码（用户加入计划需要输入与计划绑定的唯一团测码），选择手机验证则用户加入计划需要验证手机号码（收取短信发送费用）真实性才能加入计划！提示：特殊场景使用，一般单位，学校建立个人心理档案的场景下，使用批量导入模式，实现人员信息的真实一致性，确保测评数据真实有效。",
                                            placement: "top-start",
                                            title: "免导入使用说明",
                                            trigger: "hover",
                                            width: "400",
                                          },
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "el-icon-question",
                                            attrs: { slot: "reference" },
                                            slot: "reference",
                                          }),
                                        ]
                                      ),
                                      _vm._v(" 开启免导入 "),
                                    ],
                                    1
                                  ),
                                  _c("el-switch", {
                                    attrs: {
                                      "active-text": "是",
                                      "active-value": 1,
                                      "inactive-text": "否",
                                      "inactive-value": 0,
                                    },
                                    model: {
                                      value: _vm.form.is_mian,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "is_mian", $$v)
                                      },
                                      expression: "form.is_mian",
                                    },
                                  }),
                                ],
                                2
                              ),
                              _vm.form.is_mian === 1
                                ? [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "登录模式",
                                          prop: "m_type",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            model: {
                                              value: _vm.form.m_type,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "m_type",
                                                  $$v
                                                )
                                              },
                                              expression: "form.m_type",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 2 } },
                                              [_vm._v("团测码")]
                                            ),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 1 } },
                                              [_vm._v("手机号验证")]
                                            ),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 3 } },
                                              [_vm._v("账号验证")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              content:
                                                "该功能适用于存在多次普查任务，且同一组织下普查用户可能分不同时间，不同批次的普查场景下，避免重复分发给同一组织下用户，实现团测任务下指定人员参与调查！",
                                              placement: "top-start",
                                              title: "账号验证说明",
                                              trigger: "hover",
                                              width: "300",
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "el-icon-question",
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.form.m_type === 1
                                      ? _c(
                                          "el-form-item",
                                          { attrs: { label: "验证方式" } },
                                          [
                                            _c(
                                              "el-radio-group",
                                              {
                                                model: {
                                                  value: _vm.form.phone_bind,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "phone_bind",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.phone_bind",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: 2 } },
                                                  [_vm._v("验证码")]
                                                ),
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: 1 } },
                                                  [_vm._v("导入手机号")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-popover",
                                              {
                                                attrs: {
                                                  content:
                                                    "该功能是选择采用手机号验证可配置的验证模式，参与的用户需要和导入系统内的手机号码一致才能加入团测（避免收集不相关的数据）！",
                                                  placement: "top-start",
                                                  title: "手机号导入说明",
                                                  trigger: "hover",
                                                  width: "300",
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "el-icon-question",
                                                  attrs: { slot: "reference" },
                                                  slot: "reference",
                                                }),
                                              ]
                                            ),
                                            _vm.form.phone_bind === 1
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                      "margin-top": "10px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-upload",
                                                      {
                                                        ref: "upload",
                                                        attrs: {
                                                          accept: ".xls,.xlsx",
                                                          action:
                                                            _vm.UploadUrl(),
                                                          "before-upload":
                                                            _vm.beforeUploadFile,
                                                          "http-request":
                                                            _vm.updFile,
                                                          limit: 1,
                                                          "on-change":
                                                            _vm.fileChange,
                                                          "on-success":
                                                            _vm.handleSuccess,
                                                          "show-file-list": false,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                          },
                                                          [_vm._v("一键导入")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          title: "下载模板",
                                                          type: "text",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.downLoadDoc,
                                                        },
                                                      },
                                                      [_vm._v(" 下载模板 ")]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm.team_pay
                            ? _c(
                                "div",
                                { staticClass: "form-row switch" },
                                [
                                  _c("div", { staticClass: "block-title" }, [
                                    _vm._v("支付配置"),
                                  ]),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "开启支付",
                                        prop: "is_pay",
                                      },
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "label" },
                                        [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                content:
                                                  "开启支付即表示用户进入团测前需支付相关费用；该功能不可随意修改，请慎用！",
                                                placement: "top-start",
                                                title: "开启支付使用说明",
                                                trigger: "hover",
                                                width: "400",
                                              },
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "el-icon-question",
                                                attrs: { slot: "reference" },
                                                slot: "reference",
                                              }),
                                            ]
                                          ),
                                          _vm._v(" 开启支付 "),
                                        ],
                                        1
                                      ),
                                      _c("el-switch", {
                                        attrs: {
                                          "active-text": "是",
                                          "active-value": 1,
                                          "inactive-text": "否",
                                          "inactive-value": 0,
                                        },
                                        model: {
                                          value: _vm.form.is_pay,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "is_pay", $$v)
                                          },
                                          expression: "form.is_pay",
                                        },
                                      }),
                                    ],
                                    2
                                  ),
                                  _vm.form.is_pay === 1
                                    ? _c(
                                        "el-form-item",
                                        { attrs: { label: "支付金额" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              oninput:
                                                "if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}",
                                              placeholder: "请输入支付金额",
                                            },
                                            model: {
                                              value: _vm.form.cost,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "cost", $$v)
                                              },
                                              expression: "form.cost",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "form-row switch" },
                            [
                              _c("div", { staticClass: "block-title" }, [
                                _vm._v("量表选择"),
                              ]),
                              _c(
                                "el-tabs",
                                {
                                  attrs: { "active-name": _vm.form.evals_type },
                                  on: { "tab-click": _vm.tabClick },
                                },
                                [
                                  !_vm.form.id
                                    ? [
                                        _c(
                                          "el-tab-pane",
                                          {
                                            attrs: {
                                              label: "自选量表组合",
                                              name: "free",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-table",
                                              {
                                                ref: "multipleTable",
                                                attrs: {
                                                  border: "",
                                                  data: _vm.form.evals,
                                                  "max-height": "500",
                                                },
                                                on: {
                                                  "selection-change":
                                                    _vm.setSelectRows,
                                                },
                                              },
                                              [
                                                _c("el-table-column", {
                                                  attrs: {
                                                    align: "center",
                                                    selectable:
                                                      _vm.checkboxInit,
                                                    type: "selection",
                                                  },
                                                }),
                                                _c("el-table-column", {
                                                  attrs: {
                                                    label: "量表名",
                                                    "min-width": "200",
                                                    prop: "name",
                                                  },
                                                }),
                                                _c("el-table-column", {
                                                  attrs: {
                                                    align: "center",
                                                    label: "题量",
                                                    prop: "num",
                                                    width: "80",
                                                  },
                                                }),
                                                _c("el-table-column", {
                                                  attrs: {
                                                    align: "center",
                                                    label: "开放报告",
                                                    prop: "is_open",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({ row }) {
                                                          return [
                                                            _c("el-switch", {
                                                              attrs: {
                                                                "active-text":
                                                                  "开",
                                                                "active-value":
                                                                  "10",
                                                                "inactive-text":
                                                                  "关",
                                                                "inactive-value":
                                                                  "20",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.setRowEvals(
                                                                      row,
                                                                      "is_open"
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  row.is_open,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      row,
                                                                      "is_open",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "row.is_open",
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3166250827
                                                  ),
                                                }),
                                                _c("el-table-column", {
                                                  attrs: {
                                                    align: "center",
                                                    label: "重复测试",
                                                    prop: "is_repeat",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({ row }) {
                                                          return [
                                                            _c("el-switch", {
                                                              attrs: {
                                                                "active-text":
                                                                  "开",
                                                                "active-value":
                                                                  "10",
                                                                "inactive-text":
                                                                  "关",
                                                                "inactive-value":
                                                                  "20",
                                                              },
                                                              model: {
                                                                value:
                                                                  row.is_repeat,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      row,
                                                                      "is_repeat",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "row.is_repeat",
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    1687561361
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-tab-pane",
                                          {
                                            attrs: {
                                              label: "系统推荐包",
                                              name: "system",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "tab-free",
                                                attrs: { slot: "label" },
                                                slot: "label",
                                              },
                                              [
                                                _vm._v(" 系统推荐包 "),
                                                _c("span", [
                                                  _vm._v("推荐使用"),
                                                ]),
                                              ]
                                            ),
                                            _c("my-swiper", {
                                              attrs: { list: _vm.pack },
                                              on: { click: _vm.swiperClick },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    : [
                                        _vm.form.evals_type === "free"
                                          ? _c(
                                              "el-tab-pane",
                                              {
                                                attrs: {
                                                  label: "自选量表组合",
                                                  name: "free",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-table",
                                                  {
                                                    ref: "multipleTable",
                                                    attrs: {
                                                      border: "",
                                                      data: _vm.form.evals,
                                                      "max-height": "500",
                                                    },
                                                    on: {
                                                      "selection-change":
                                                        _vm.setSelectRows,
                                                    },
                                                  },
                                                  [
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        align: "center",
                                                        selectable:
                                                          _vm.checkboxInit,
                                                        type: "selection",
                                                      },
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        label: "量表名",
                                                        "min-width": "200",
                                                        prop: "name",
                                                      },
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        align: "center",
                                                        label: "题量",
                                                        prop: "num",
                                                        width: "80",
                                                      },
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        align: "center",
                                                        label: "开放报告",
                                                        prop: "is_open",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              row,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "el-switch",
                                                                  {
                                                                    attrs: {
                                                                      "active-text":
                                                                        "开",
                                                                      "active-value":
                                                                        "10",
                                                                      "inactive-text":
                                                                        "关",
                                                                      "inactive-value":
                                                                        "20",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.setRowEvals(
                                                                            row,
                                                                            "is_open"
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        row.is_open,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            row,
                                                                            "is_open",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "row.is_open",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        3166250827
                                                      ),
                                                    }),
                                                    _c("el-table-column", {
                                                      attrs: {
                                                        align: "center",
                                                        label: "重复测试",
                                                        prop: "is_repeat",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              row,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "el-switch",
                                                                  {
                                                                    attrs: {
                                                                      "active-text":
                                                                        "开",
                                                                      "active-value":
                                                                        "10",
                                                                      "inactive-text":
                                                                        "关",
                                                                      "inactive-value":
                                                                        "20",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        row.is_repeat,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            row,
                                                                            "is_repeat",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "row.is_repeat",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        1687561361
                                                      ),
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.form.evals_type === "system"
                                          ? _c(
                                              "el-tab-pane",
                                              {
                                                attrs: {
                                                  label: "系统推荐包",
                                                  name: "system",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "tab-free",
                                                    attrs: { slot: "label" },
                                                    slot: "label",
                                                  },
                                                  [
                                                    _vm._v(" 系统推荐包 "),
                                                    _c("span", [
                                                      _vm._v("推荐使用"),
                                                    ]),
                                                  ]
                                                ),
                                                _c("my-swiper", {
                                                  attrs: { list: _vm.pack },
                                                  on: {
                                                    click: _vm.swiperClick,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm.surveyList
                            ? _c(
                                "div",
                                { staticClass: "form-row" },
                                [
                                  _c("div", { staticClass: "block-title" }, [
                                    _vm._v("问卷选择"),
                                  ]),
                                  _c(
                                    "el-table",
                                    {
                                      ref: "multipleSurveyTable",
                                      attrs: {
                                        border: "",
                                        data: _vm.surveyList,
                                      },
                                      on: {
                                        "selection-change":
                                          _vm.setSurveySelectRows,
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          selectable: _vm.checkboxInit,
                                          type: "selection",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "问卷名",
                                          prop: "title",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "状态",
                                          prop: "status",
                                          width: "80",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ row }) {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.status === "10"
                                                          ? "正常"
                                                          : "暂停"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2545828503
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "题量",
                                          prop: "num",
                                          width: "80",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "form-row" },
                            [
                              _c("div", { staticClass: "block-title" }, [
                                _vm._v("组织结构"),
                              ]),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择组织结构",
                                  },
                                  on: {
                                    change: _vm.selectChange,
                                    "visible-change": _vm.cascaderClicked,
                                  },
                                  model: {
                                    value: _vm.form.origin_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "origin_id", $$v)
                                    },
                                    expression: "form.origin_id",
                                  },
                                },
                                _vm._l(_vm.form.origin, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.remark,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loading,
                                      expression: "loading",
                                    },
                                  ],
                                  staticStyle: { "min-height": "100px" },
                                },
                                [
                                  !_vm.loading
                                    ? _c("el-tree", {
                                        ref: "ozTree",
                                        attrs: {
                                          data: _vm.dataTree,
                                          "default-checked-keys":
                                            _vm.form.treeOz,
                                          "node-key": "id",
                                          props: _vm.defaultProps,
                                          "show-checkbox": "",
                                        },
                                        on: { check: _vm.treeChecked },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ node }) {
                                                return _c("span", {}, [
                                                  _c("span", [
                                                    _vm._v(_vm._s(node.label)),
                                                  ]),
                                                  _c(
                                                    "span",
                                                    { staticClass: "orange" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          node.data.pep || 0
                                                        ) + " 人"
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1454607621
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _vm.isNJ
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.submitForm("form")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.id
                                              ? "保存修改"
                                              : "立即创建"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.nextStep },
                                    },
                                    [_vm._v(" 下一步 ")]
                                  ),
                              !_vm.form.id
                                ? _c(
                                    "el-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.resetForm("form")
                                        },
                                      },
                                    },
                                    [_vm._v(" 重置 ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.active === 2
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            {
                              staticClass: "form-row",
                              staticStyle: { "margin-bottom": "20px" },
                            },
                            [
                              _c(
                                "el-table",
                                { attrs: { border: "", data: _vm.sortList } },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "类型",
                                      prop: "type",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    scope.row.type === "paper"
                                                      ? "量表"
                                                      : scope.row.type ===
                                                        "pack"
                                                      ? "推荐包"
                                                      : "问卷"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1227186769
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "名称", prop: "name" },
                                  }),
                                  _c(
                                    "el-table-column",
                                    {
                                      attrs: { align: "center" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input-number", {
                                                  attrs: {
                                                    controls: false,
                                                    min: 0,
                                                  },
                                                  model: {
                                                    value: scope.row.sort,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "sort",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.sort",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3192435954
                                      ),
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "header" },
                                        [
                                          _vm._v(" 排序 "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                content:
                                                  "排序请输入数字，越小越靠前，未设置则随机排序",
                                                effect: "dark",
                                                placement: "right-start",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-info",
                                                staticStyle: {
                                                  cursor: "pointer",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c("el-button", { on: { click: _vm.prevStep } }, [
                                _vm._v("上一步"),
                              ]),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitForm("form")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.form.id ? "保存修改" : "立即创建"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("select-img", { ref: "selectImg", on: { checkedImg: _vm.getImg } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }