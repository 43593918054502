var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "archives-container" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "用户档案", name: "person" } },
            [_c("person", { attrs: { uid: _vm.uid } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "心理测评", name: "eval" } },
            [_c("eval", { attrs: { datas: _vm.evalList } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "团体测评", name: "teamtest" } },
            [_c("teamtest", { attrs: { datas: _vm.teamtestList } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "预警记录", name: "mental" } },
            [
              _vm.activeName === "mental"
                ? _c("mental", { attrs: { type: _vm.uid } })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "访谈评估", name: "assess" } },
            [
              _vm.activeName === "assess"
                ? _c("assess", { attrs: { uid: _vm.uid } })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "危机干预", name: "meddle" } },
            [
              _vm.activeName === "meddle"
                ? _c("meddle", { attrs: { uid: _vm.uid } })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "咨询记录", name: "psy" } },
            [
              _vm.activeName === "psy"
                ? _c("psy", { attrs: { datas: _vm.psyList } })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "心理曲线", name: "line" } },
            [_c("chart", { attrs: { uid: _vm.uid } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }