<template>
  <div id="centerLeft1" class="d-flex">
    <dv-border-box-7 class="top-left flex-1" :color="['#121b5a']">
      <div class="d-flex jc-around ai-center" style="color: #e2a33c">
        <div class="info">
          <div class="info-name">学校总数</div>
          <div>
            <span class="num24">2234</span>
            所
          </div>
        </div>
        <div class="info">
          <div>
            小学：
            <span>32</span>
            所
          </div>
          <div>
            初中：
            <span>32</span>
            所
          </div>
          <div>
            高中：
            <span>32</span>
            所
          </div>
        </div>
      </div>
      <Echart id="top1pie" ref="top1pie" :height="240" :options="options" />
    </dv-border-box-7>
    <dv-border-box-7 class="top-left flex-1" :color="['#121b5a']">
      <div class="d-flex jc-around ai-center" style="color: #8f4ee7">
        <div class="info">
          <div class="info-name">学生总数</div>
          <div>
            <span class="num24">2234</span>
            人
          </div>
        </div>
        <div class="info">
          <div>
            小学：
            <span>32</span>
            人
          </div>
          <div>
            初中：
            <span>32</span>
            人
          </div>
          <div>
            高中：
            <span>32</span>
            人
          </div>
        </div>
      </div>
      <Echart id="test" :height="240" :options="bar" />
    </dv-border-box-7>
    <dv-border-box-7 class="top-left flex-1" :color="['#121b5a']">
      <div class="d-flex jc-around ai-center" style="color: #2c8bdf">
        <div class="info">
          <div class="info-name">心理辅导老师数</div>
          <div>
            <span class="num24">2234</span>
            人
          </div>
        </div>
        <div class="info">
          <div class="info-name">导师配比</div>
          <div>
            <span class="num24">2234:1</span>
          </div>
        </div>
      </div>
      <Echart id="bar2" :height="240" :options="bar2" />
    </dv-border-box-7>
  </div>
</template>

<script>
  import Echart from '@/extra/VabChart'
  export default {
    components: {
      Echart,
    },
    props: {
      cdata: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        options: {},
        bar: {},
        bar2: {},
      }
    },
    watch: {
      cdata: {
        handler(newData) {
          this.options = {
            title: {
              text: '测评比例',
              textStyle: {
                fontSize: 12,
                color: '#eee',
              },
              left: 'right',
            },
            legend: {
              data: ['已测人数', '未测人数'],
              textStyle: {
                color: '#eee',
              },
              top: '10%',
            },
            color: [
              /*'#32c5e9',
              '#ffdb5c',
              '#ff9f7f',*/
              '#fb7293',
              /*'#e7bcf3',*/
              '#8378ea',
            ],
            tooltip: {
              trigger: 'item',
              formatter: '{b} : {c}人 ({d}%)',
            },
            calculable: true,
            series: [
              {
                label: {
                  formatter: '{name|{c}人 ({d}%)}',
                  position: 'inner',
                  /*minMargin: 5,
                  edgeDistance: 20,
                  lineHeight: 15,*/
                  rich: {
                    name: {
                      color: '#fff',
                    },
                  },
                },
                radius: '55%',
                type: 'pie',
                center: ['50%', '50%'],
                /*itemStyle: {
                  color: new Echart.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: '#fb7293' },
                    { offset: 0.5, color: '#8378ea' },
                    { offset: 1, color: '#8378ea' },
                  ]),
                },
                roseType: 'radius',*/
                data: newData.seriesData,
              },
            ],
          }
        },
        immediate: true,
        deep: true,
      },
    },
    mounted() {
      this.$nextTick(() => {
        let app = {
          currentIndex: -1,
        }
        let that = this
        if (this.lineChartInterval) {
          clearInterval(that.lineChartInterval)
        }
        let dataLen = this.options.series[0].data.length
        let lc = this.$refs.top1pie.chart
        this.lineChartInterval = setInterval(function () {
          // 取消之前高亮的图形
          lc.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: app.currentIndex,
          })
          app.currentIndex = (app.currentIndex + 1) % dataLen
          //console.log(app.currentIndex);
          // 高亮当前图形
          lc.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: app.currentIndex,
          })
          // 显示 tooltip
          lc.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: app.currentIndex,
          })
        }, 3000)
      })
    },
    created() {
      this.getBar()
      this.getBar2()
    },
    methods: {
      getBar() {
        this.bar = {
          title: {
            text: '档案数统计',
            textStyle: {
              fontSize: 12,
              color: '#eee',
            },
            left: 'right',
          },
          tooltip: {
            trigger: 'axis',
          },
          xAxis: {
            data: ['档案总数', '今日新增'],
            axisLabel: {
              inside: false,
              color: '#fff',
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            z: 10,
          },
          yAxis: {
            show: false,
          },
          series: [
            {
              type: 'bar',
              showBackground: true,
              itemStyle: {
                color: new Echart.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#83bff6' },
                  { offset: 0.5, color: '#188df0' },
                  { offset: 1, color: '#188df0' },
                ]),
              },
              emphasis: {
                itemStyle: {
                  color: new Echart.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: '#2378f7' },
                    { offset: 0.7, color: '#2378f7' },
                    { offset: 1, color: '#83bff6' },
                  ]),
                },
              },
              label: {
                show: true,
                position: 'top',
                color: '#fff',
              },
              data: [23536, 323],
            },
          ],
        }
      },
      getBar2() {
        this.bar2 = {
          title: {
            text: '方案数统计',
            textStyle: {
              fontSize: 12,
              color: '#eee',
            },
            left: 'right',
          },
          grid: {
            left: 80,
            bottom: 30,
            top: 30,
          },
          tooltip: {
            trigger: 'axis',
          },
          xAxis: {
            type: 'value',
            show: false,
          },
          yAxis: {
            type: 'category',
            data: [
              '2024年度高中生心理健康测评',
              '2024年度初中生心理健康测评',
              '2024年度小学生心理健康测评',
            ],
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              formatter: function (value) {
                let list = value.split('')
                let result = ''
                for (let i = 1; i <= list.length; i++) {
                  if (!(i % 8) && list[i] != undefined) {
                    result += list[i - 1] + '\n'
                  } else {
                    result += list[i - 1]
                  }
                }
                return result
              },
              fontSize: 10,
              lineHeight: 16,
            },
          },
          series: [
            {
              type: 'bar',
              showBackground: true,
              barWidth: 40,
              itemStyle: {
                color: new Echart.graphic.LinearGradient(0, 0, 1, 1, [
                  { offset: 0, color: '#83bff6' },
                  { offset: 0.5, color: '#188df0' },
                  { offset: 1, color: '#188df0' },
                ]),
              },
              emphasis: {
                itemStyle: {
                  color: new Echart.graphic.LinearGradient(0, 0, 1, 1, [
                    { offset: 0, color: '#2378f7' },
                    { offset: 0.7, color: '#2378f7' },
                    { offset: 1, color: '#83bff6' },
                  ]),
                },
              },
              label: {
                show: true,
                position: ['50%', '40%'],
                color: '#fff',
                formatter: '{c}人',
              },
              data: [23536, 323, 3232],
            },
          ],
        }
      },
    },
  }
</script>

<style lang="scss">
  #centerLeft1 {
    padding: 0.9rem 0.3rem 0.2em 0.3rem;
    .top-left {
      margin: 0.1rem;
      .info {
        text-align: center;
        padding: 0.2rem 0;
        .info-name {
          font-size: 0.2rem;
          margin-bottom: 0.2rem;
        }
        .num24 {
          font-size: 0.4rem;
        }
      }
    }
  }
</style>
