<template>
  <div class="info">
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column label="姓名" min-width="120" prop="name">
        <template #default="{ row }">
          <div class="user">
            <img v-if="row.sex === '男'" src="@/assets/male.png" />
            <img v-else-if="row.sex === '女'" src="@/assets/female.png" />
            <img v-else src="@/assets/unknow.png" />
            <span>{{ row.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="组织结构" prop="origin" />
      <el-table-column
        align="center"
        label="干预时间"
        prop="mental_time"
        sortable
      />
      <el-table-column
        align="center"
        label="评估级别"
        prop="mental_assess_level_name"
      >
        <template #default="{ row }">
          <span
            v-if="row.mental_assess_level_color"
            class="b_c"
            :style="{
              'background-color': hexToRgba(row.mental_assess_level_color, 0.1),
              color: row.mental_assess_level_color,
              border:
                '1px solid ' + hexToRgba(row.mental_assess_level_color, 0.3),
            }"
          >
            {{ row.mental_assess_level_name }}
          </span>
          <span v-else>{{ row.mental_assess_level_name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="干预级别"
        prop="mental_interve_level_name"
      >
        <template #default="{ row }">
          <span
            v-if="row.mental_interve_level_color"
            class="b_c"
            :style="{
              'background-color': hexToRgba(
                row.mental_interve_level_color,
                0.1
              ),
              color: row.mental_interve_level_color,
              border:
                '1px solid ' + hexToRgba(row.mental_interve_level_color, 0.3),
            }"
          >
            {{ row.mental_interve_level_name }}
          </span>
          <span v-else>{{ row.mental_interve_level_name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="干预内容"
        prop="mental_remark"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="状态" prop="status">
        <template #default="{ row }">
          <el-tag
            :type="
              row.end_status == 3
                ? 'primary'
                : row.end_status == 1
                ? 'warning'
                : 'danger'
            "
          >
            {{ row.end_status_name }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="120">
        <template #default="{ row }">
          <template v-if="button.edit">
            <el-button type="text" @click="handleEdit(row)">处理</el-button>
            <el-button
              :disabled="row.end_status == 3"
              type="text"
              @click="finished(row)"
            >
              结案
            </el-button>
          </template>
          <template v-else>
            <el-button disabled title="暂无权限" type="text">处理</el-button>
            <el-button title="暂无权限" type="text">结案</el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <add-mental ref="add-mental" @fetch-data="fetchData" />
    <finish-case ref="finish" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getMentalList } from '@/api/crisis'
  import { hexToRgba } from '@/utils/utils'
  import AddMental from '@/views/mental/components/AddMental'
  import FinishCase from '@/views/mental/components/finishCase'
  export default {
    name: 'Meddle',
    components: { AddMental, FinishCase },
    props: {
      uid: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          uid: '',
        },
        button: {},
      }
    },
    watch: {
      uid: {
        handler(newData) {
          this.queryForm.uid = newData
          this.fetchData()
        },
        deep: true,
        immediate: true,
      },
    },
    created() {},
    methods: {
      hexToRgba,
      finished(row) {
        this.$refs['finish'].showEdit(row.id, 'interve')
      },
      handleEdit(row) {
        this.$refs['add-mental'].showDrawer(row, 'interve')
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getMentalList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .info {
    .b_c {
      display: inline-block;
      padding: 0 7px;
      border-radius: 3px;
      font-size: 12px;
    }
  }
</style>
