<template>
  <div id="centerLeft2" class="d-flex flex-column">
    <div class="top-right">
      <dv-border-box-7 :color="['#121b5a']">
        <vab-chart id="line1" :height="150" :options="options" width="100%" />
      </dv-border-box-7>
    </div>
    <div class="top-right">
      <dv-border-box-7 :color="['#121b5a']">
        <vab-chart id="line2" ref="line2" :height="150" :options="line2" />
      </dv-border-box-7>
    </div>
  </div>
</template>

<script>
  import VabChart from '@/extra/VabChart'
  export default {
    components: {
      VabChart,
    },
    props: {
      cdata: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        options: {},
        line2: {},
      }
    },
    watch: {
      cdata: {
        handler(newData) {
          this.options = {
            title: {
              text: '测评量走势图',
              left: 'right',
              textStyle: {
                fontSize: 12,
                color: '#eee',
              },
            },
            tooltip: {
              trigger: 'axis',
              backgroundColor: '#fff',
              axisPointer: {
                type: 'shadow',
                label: {
                  show: true,
                  backgroundColor: '#7B7DDC',
                },
              },
            },
            grid: {
              top: 30,
              bottom: 30,
            },
            xAxis: {
              data: ['5.6', '5.7', '5.8', '5.9', '5.10', '5.11'],
              axisTick: {
                show: false,
              },
              splitLine: { show: false },
              /*axisLabel: { interval: 0, rotate: 30 },*/
            },
            yAxis: { show: true, splitLine: { lineStyle: { color: '#666' } } },
            series: [
              {
                type: 'line',
                barGap: '-100%',
                barWidth: 20,
                label: {
                  show: true,
                  position: 'top',
                  textStyle: {
                    color: '#eee',
                  },
                },
                itemStyle: {
                  borderRadius: 5,
                  /*color: new VabChart.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: '#956FD4' },
                    { offset: 1, color: '#3EACE5' },
                  ]),*/
                  color: function (params) {
                    let colorList = [
                      '#32c5e9',
                      '#ffdb5c',
                      '#ff9f7f',
                      '#fb7293',
                      '#e7bcf3',
                      '#8378ea',
                    ]
                    return colorList[params.dataIndex]
                  },
                },
                data: [231, 231, 531, 1234, 323, 231],
              },
            ],
          }
        },
        immediate: true,
        deep: true,
      },
    },
    mounted() {
      this.$nextTick(() => {
        let app = {
          currentIndex: -1,
        }
        let that = this
        if (this.lineChartInterval) {
          clearInterval(that.lineChartInterval)
        }
        let dataLen = this.line2.series[0].data.length
        let lc = this.$refs.line2.chart
        this.lineChartInterval = setInterval(function () {
          // 取消之前高亮的图形
          lc.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: app.currentIndex,
          })
          app.currentIndex = (app.currentIndex + 1) % dataLen
          //console.log(app.currentIndex);
          // 高亮当前图形
          lc.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: app.currentIndex,
          })
          // 显示 tooltip
          lc.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: app.currentIndex,
          })
        }, 3000)
      })
    },
    created() {
      this.getLine()
    },
    methods: {
      getLine() {
        this.line2 = {
          title: {
            text: '方案预警结构走势图',
            left: 'center',
            textStyle: {
              fontSize: 12,
              color: '#eee',
            },
          },
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            data: ['方案一', '方案二'],
            bottom: 10,
            textStyle: {
              color: '#999',
            },
          },
          grid: {
            top: 30,
            bottom: 50,
          },
          xAxis: {
            type: 'category',
            axisPointer: {
              type: 'shadow',
            },
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          },
          yAxis: {
            type: 'value',
            show: true,
            splitLine: { lineStyle: { color: '#666' } },
          },
          series: [
            {
              name: '方案一',
              type: 'line',
              smooth: true,
              stack: 'Total',
              data: [120, 132, 101, 134, 90, 230, 210],
              areaStyle: {},
            },
            {
              name: '方案二',
              type: 'line',
              smooth: true,
              stack: 'Total',
              data: [220, 182, 191, 234, 290, 330, 310],
              areaStyle: {},
            },
          ],
        }
      },
    },
  }
</script>

<style lang="scss">
  #centerLeft2 {
    padding: 0.9rem 0.3rem 0.2rem 0.3rem;
    .top-right {
      margin: 0.1rem;
    }
  }
</style>
