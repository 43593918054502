//咨询方式
export const counselorType = [
  {
    id: 1,
    name: '面询',
  },
  {
    id: 2,
    name: '线上咨询',
  },
]
//计分规则
export const scoreType = [
  {
    value: 10,
    name: '求和',
  },
  {
    value: 20,
    name: '求均值',
  },
]
//咨询类型
export const zxType = [
  {
    value: 1,
    name: '咨询',
  },
  {
    value: 2,
    name: '初谈',
  },
]
//订单状态 0 等待咨询 1 咨询中 2 完成咨询 3 申请取消 4 取消咨询
export const orderType = [
  {
    id: 0,
    name: '等待咨询',
  },
  {
    id: 1,
    name: '咨询中',
  },
  {
    id: 2,
    name: '完成咨询',
  },
  {
    id: 3,
    name: '申请取消',
  },
  {
    id: 4,
    name: '取消咨询',
  },
]
//周
export const weekend = [
  '星期一',
  '星期二',
  '星期三',
  '星期四',
  '星期五',
  '星期六',
  '星期日',
]
//循环周期
export const period = [
  {
    value: 1,
    name: '每周',
  },
  {
    value: 2,
    name: '每两周（本周开始）',
  },
  {
    value: 3,
    name: '每两周（下周开始）',
  },
]
//weeks
export const weeks = [
  {
    value: 1,
    name: '周一',
  },
  {
    value: 2,
    name: '周二',
  },
  {
    value: 3,
    name: '周三',
  },
  {
    value: 4,
    name: '周四',
  },
  {
    value: 5,
    name: '周五',
  },
  {
    value: 6,
    name: '周六',
  },
  {
    value: 7,
    name: '周日',
  },
]

export const sex = [
  {
    value: 0,
    name: '不详',
  },
  {
    value: 1,
    name: '男',
  },
  {
    value: 2,
    name: '女',
  },
]
