<template>
  <div class="team-warn-list-container">
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <!--<el-form-item v-if="isSid === 'all'" prop="sid">
            <el-select
              v-model="queryForm.sid"
              filterable
              clearable
              placeholder="选择渠道"
            >
              <el-option
                v-for="item in studioList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.id"
              filterable
              clearable
              placeholder="请选择团测计划"
              @change="bindChange"
            >
              <el-option
                v-for="item in teamTestList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>-->
          <el-form-item prop="name">
            <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入姓名"
            />
          </el-form-item>
          <el-form-item prop="account">
            <el-input
              v-model.trim="queryForm.account"
              clearable
              placeholder="请输入账号"
            />
          </el-form-item>
          <el-form-item prop="origin">
            <el-cascader
              ref="orCas"
              v-model="queryForm.origin"
              clearable
              :options="treeData"
              placeholder="组织结构"
              :props="defaultProps"
              @change="handleChange"
              @visible-change="cascaderClicked"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      :data="list"
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" label="姓名" prop="name" width="100">
        <template #default="{ row }">
          <el-button
            type="text"
            @click="handleEdit(row, '/userManagement/member/archives')"
          >
            {{ row.name }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="账号" prop="account" />
      <el-table-column align="center" label="组织结构" prop="origin" />
      <el-table-column align="center" label="预警级别" prop="warn_level" />
      <el-table-column align="center" label="结案状态" prop="settle_type" />
      <el-table-column align="center" label="评估记录" width="100">
        <template #default="{ row }">
          <el-button
            v-if="row.is_evaluate"
            type="text"
            @click="handleEdit(row, '/mental/assess')"
          >
            查看
          </el-button>
          <el-button
            v-else
            type="text"
            @click="handleEdit(row, 'evaluate', 'add')"
          >
            添加
          </el-button>
        </template>
      </el-table-column>
      <el-table-column v-if="false" align="center" label="干预记录" width="100">
        <template #default="{ row }">
          <el-button
            :disabled="!row.is_interve"
            type="text"
            @click="handleEdit(row, '/mental/interveList')"
          >
            查看
          </el-button>
          <el-button type="text" @click="handleEdit(row, 'interve', 'add')">
            添加
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getTeamTest, getTeamWarningList } from '@/api/teamList'
  import Edit from './components/TeamWarnListEdit'
  import { getStudioList } from '@/api/platformChannelManagement'
  import { getCurStudioOrgin } from '@/api/userManagementOrganization'
  import { mapGetters } from 'vuex'

  export default {
    name: 'WarnList',
    components: { Edit },
    props: {
      id: {
        type: String,
        default: '',
      },
      pid: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        list: [],
        treeData: [], //组织结构
        teamTestList: [], //团测计划列表
        studioList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          name: '',
          account: '',
          sid: '',
          origin: '',
          id: this.id,
          pid: this.pid,
        },
        button: {},
        defaultProps: {
          children: 'children',
          label: 'name',
          value: 'id',
          checkStrictly: true,
        },
        addType: '',
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    created() {
      //this.getTeamTestList()
      this.getStudio()
      this.fetchData()
    },
    methods: {
      //获取团测计划
      async getTeamTestList() {
        const { data } = await getTeamTest()
        this.teamTestList = data
      },
      bindChange(val) {
        this.queryForm.id = val
        this.fetchData()
      },
      cascaderClicked($event) {
        if ($event) {
          this.getOriginList(this.queryForm.sid)
        }
      },
      handleChange(value) {
        let id = this.$refs.orCas.getCheckedNodes(true)
        this.queryForm.origin = id.length ? id[0].value : ''
      },
      async getStudio() {
        const { data } = await getStudioList()
        this.studioList = data
      },
      async getOriginList(sid) {
        //获取组织结构
        const { data } = await getCurStudioOrgin(
          this.isSid === 'all' ? { sid: sid } : ''
        )
        this.treeData = data
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleEdit(row, url, type) {
        if (type === 'add') {
          this.$refs['edit'].showEdit(row, url)
        } else {
          let obj = {}
          if (url === '/userManagement/member/archives') {
            obj = { id: row.uid }
          } else {
            obj = { account: row.account }
          }
          this.$router.push({
            path: url,
            query: obj,
          })
        }
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getTeamWarningList(
          this.queryForm
        )
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>
