var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vab-nav" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 15 } },
        [
          _c("el-col", { attrs: { lg: 12, md: 12, sm: 12, xl: 12, xs: 4 } }, [
            _c(
              "div",
              { staticClass: "left-panel" },
              [
                _c("vab-fold"),
                _vm.layout === "comprehensive"
                  ? _c(
                      "el-tabs",
                      {
                        attrs: { "tab-position": "top" },
                        on: { "tab-click": _vm.handleTabClick },
                        model: {
                          value: _vm.extra.first,
                          callback: function ($$v) {
                            _vm.$set(_vm.extra, "first", $$v)
                          },
                          expression: "extra.first",
                        },
                      },
                      _vm._l(_vm.handleRoutes, function (item) {
                        return _c("el-tab-pane", {
                          key: item.name,
                          attrs: { name: item.name },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    item.meta.icon
                                      ? _c("vab-icon", {
                                          staticStyle: { "min-width": "16px" },
                                          attrs: {
                                            icon: item.meta.icon,
                                            "is-custom-svg":
                                              item.meta.isCustomSvg,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.translateTitle(item.meta.title)
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      }),
                      1
                    )
                  : _c("vab-breadcrumb", { staticClass: "hidden-xs-only" }),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { lg: 12, md: 12, sm: 12, xl: 12, xs: 20 } }, [
            _c(
              "div",
              { staticClass: "right-panel" },
              [
                !_vm.isNJ
                  ? [
                      _c(
                        "div",
                        { staticClass: "item", on: { click: _vm.gotoNotice } },
                        [
                          _c(
                            "el-badge",
                            {
                              staticClass: "item",
                              attrs: {
                                hidden: _vm.notice !== 0,
                                value: _vm.notice,
                              },
                            },
                            [
                              _c("vab-icon", {
                                staticClass: "i_c",
                                attrs: {
                                  icon: "notification-line",
                                  title: "消息通知",
                                },
                              }),
                              _vm._v(" 消息通知 "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.hiddenSupport == 0
                        ? _c(
                            "div",
                            { staticClass: "item", on: { click: _vm.help } },
                            [
                              _c("vab-icon", {
                                staticClass: "i_c",
                                attrs: {
                                  icon: "question-line",
                                  title: "帮助中心",
                                },
                              }),
                              _vm._v(" 帮助中心 "),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _c("vab-avatar"),
              ],
              2
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }