import request from '@/utils/request'

export function getInfo() {
  return request({
    url: '/company-info/info',
    method: 'get',
  })
}

export function saveInfo(data) {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/company-info/save',
    method: 'post',
    data,
  })
}
