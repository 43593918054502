var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "echarts",
    style: "height:" + _vm.height + "px",
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }