<template>
  <div class="notice-box">
    <div class="notice">
      <vab-icon icon="notification-2-line" />
      <div v-if="notice && notice.length > 0" class="notice-list">
        <el-carousel
          :autoplay="true"
          direction="vertical"
          height="40px"
          indicator-position="none"
        >
          <el-carousel-item v-for="item in notice" :key="item.id">
            <span style="cursor: pointer" @click="gotoNotice">
              {{ item.title }}
            </span>
            <a v-if="item.type == 2" :href="item.content" target="_blank">
              点击下载
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>
      <span v-else>暂无最新公告</span>
    </div>
    <div v-if="studio && userinfo.major === 1" class="users-num">
      <el-popover
        class="popEx"
        content="当前渠道有效期内，每有一名用户登录(重复登录算一人)剩余人数减1，当剩余人数为0时，系统将不允许用户登录。请及时联系客服，避免因为人数不足而影响业务，感谢您的配合。"
        placement="bottom-start"
        title="提示"
        trigger="hover"
        width="350"
      >
        <div slot="reference">
          有效期：
          <span>{{ studio.effect_time }}</span>
          <el-divider direction="vertical" />
          剩余人数：
          <span v-if="studio.is_limit == '20'">无限制</span>
          <span v-else>{{ studio.remain_num }}</span>
          <vab-icon icon="question-line" />
        </div>
      </el-popover>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :show-close="false"
      :visible.sync="dialogFormVisible"
      width="750px"
      @close="close"
    >
      <div slot="title" class="dia-title">
        <span class="el-icon-warning"></span>
        新功能升级提醒
      </div>
      <div v-html="newtip && newtip.content"></div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="close">我已知晓</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'NoticeBar',
    components: {},
    props: {
      studio: {
        type: Object,
        default: () => {},
      },
      notice: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        fullYear: new Date().getFullYear(),
        dialogFormVisible: false,
        newtip: null,
      }
    },
    computed: {
      ...mapGetters({
        userinfo: 'user/userinfo',
      }),
    },
    watch: {
      notice: {
        handler(newData) {
          if (newData) {
            let tip = newData.filter((o) => o.type == 5)
            if (tip.length) {
              let readt = window.localStorage.getItem('readTip')
              if (tip[0].id != readt) {
                this.dialogFormVisible = true
              }
              this.newtip = tip[0]
            }
          }
        },
        immediate: true,
        deep: true,
      },
    },
    created() {},
    mounted() {},
    methods: {
      gotoNotice() {
        this.$router.push({
          path: '/noticeList',
        })
      },
      close() {
        window.localStorage.setItem('readTip', this.newtip.id)
        this.dialogFormVisible = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .notice-box {
    display: flex;
    justify-content: space-between;
    background: rgba(0, 174, 104, 0.08);
    border: 1px solid rgba(0, 174, 104, 0.81);
    border-radius: 2px;
    line-height: 40px;
    padding: 0 20px;
    .notice {
      display: flex;
      flex: 1;
      color: #999;
      font-size: 14px;
      span {
        margin-left: 5px;
      }
      .notice-list {
        color: #999;
        margin-left: 5px;
        flex: 1;
      }
    }
    .users-num {
      color: #999;
    }
  }
  .dia-title {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    .el-icon-warning {
      font-size: 26px;
      vertical-align: middle;
    }
  }
  .dialog-footer {
    text-align: center;
  }
</style>
