<template>
  <div class="info">
    <vab-query-form>
      <vab-query-form-left-panel :span="8">
        <el-button
          v-for="(item, index) in tList"
          :key="index"
          round
          :type="cur === index ? 'primary' : 'default'"
          @click="changeTime(item.value, index)"
        >
          {{ item.name }}
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="16">
        <el-form
          ref="queryForms"
          :inline="true"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item>
            <el-select
              v-model="queryForm.pid"
              clearable
              filterable
              placeholder="选择量表"
            >
              <el-option
                v-for="item in evalList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="queryForm.time"
              end-placeholder="结束日期"
              range-separator="-"
              start-placeholder="开始日期"
              type="daterange"
              value-format="yyyy-MM-dd"
              @change="selectDate"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="getData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <div class="info-content">
      <vab-chart
        v-if="initData"
        ref="charts2"
        :height="500"
        :options="chart"
        style="margin: 50px 0"
        theme="vab-echarts-theme"
      />
      <no-data v-else :txt="'暂无记录'" />
    </div>
  </div>
</template>

<script>
  import NoData from '@/components/noData/index'
  import VabChart from '@/extra/VabChart/index.vue'
  import { getUserCh } from '@/api/userManagementOrganization'
  import { getEvalListBySid } from '@/api/mmptCategory'
  import { getRecentMonth, thirteenBitTimestamp } from '@/utils'
  export default {
    name: 'UserChart',
    components: { VabChart, NoData },
    data() {
      return {
        initData: null,
        evalList: [],
        tList: [],
        cur: 2,
        queryForm: {
          uid: '',
          pid: '',
          time: '',
        },
        chart: null,
      }
    },
    created() {
      this.queryForm.uid = this.$route.query.id
      this.getEvalList()
      this.getFastTime()
    },
    methods: {
      changeTime(val, i) {
        this.cur = i
        this.queryForm.time = val
        this.getData()
      },
      getFastTime() {
        this.tList = [
          {
            name: '近一个月',
            value: getRecentMonth(1),
          },
          {
            name: '近半年',
            value: getRecentMonth(6),
          },
          {
            name: '近一年',
            value: getRecentMonth(12),
          },
        ]
        this.queryForm.time = this.tList[2].value
      },
      async getEvalList() {
        const { data } = await getEvalListBySid()
        this.evalList = data
      },
      selectDate(val) {
        this.queryForm.time = val
        if (val) {
          this.cur = ''
        }
      },
      getData() {
        getUserCh(this.queryForm).then((res) => {
          let d = res.data
          if (d) {
            this.initData = d.line
            this.initData.series.map((item) => {
              item.type = 'line'
              return item
            })
            if (this.initData) this.getLine(this.initData)
          } else {
            this.initData = null
          }
        })
      },
      getLine(data) {
        this.chart = {
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            data: data.legend,
          },
          xAxis: {
            type: 'category',
            data: data.xAxis,
          },
          yAxis: {
            type: 'value',
          },
          series: data.series,
        }
        return this.chart
      },
    },
  }
</script>

<style lang="scss" scoped>
  .info {
    margin-top: 20px;
    .info-check {
      padding: 0 10px;
      line-height: 40px;
      margin-bottom: 20px;
    }
    .info-content {
      margin-bottom: 20px;
      .item {
        width: 50%;
        line-height: 40px;
        padding-left: 20px;
      }
    }
  }
</style>
