<template>
  <div v-if="initData" class="eval-container">
    <el-card class="tab">
      <span class="name">
        <label class="el-icon-s-order"></label>
        个人报告
      </span>
      <el-button
        :class="curTab === 0 ? '' : 'cur'"
        type="primary"
        @click="changeTab(0)"
      >
        默认样式
      </el-button>
      <el-button
        :class="curTab === 1 ? '' : 'cur'"
        type="primary"
        @click="changeTab(1)"
      >
        体检样式
      </el-button>
      <el-button
        v-if="cType !== 'pay'"
        :class="curTab === 2 ? '' : 'cur'"
        type="primary"
        @click="changeTab(2)"
      >
        医院简版
      </el-button>
      <el-tooltip
        content="系统内置三种个人报告样式，可自由切换不同的样式效果。"
        placement="top"
      >
        <span class="tip">
          <label class="el-icon-info"></label>
          说明
        </span>
      </el-tooltip>
    </el-card>
    <div
      ref="print"
      class="content"
      :class="{ invalid: !initData.is_true, customstyle: curTab }"
    >
      <div v-if="!initData.is_true" class="invalid-report">无效报告</div>
      <div class="print no-print">
        <el-tooltip placement="top">
          <div slot="content">
            可直接点击打印按钮，需要导出则选择【另存为PDF】，
            <br />
            需要【打印】则选择已连接的打印机即可直接打印报告。
          </div>
          <el-button icon="el-icon-printer" type="primary" @click="print">
            导出PDF/打印
          </el-button>
        </el-tooltip>
      </div>
      <template v-if="curTab === 2">
        <div class="hospital">
          <div class="title">
            <div class="name">{{ initData.report_set.title }}</div>
            <div class="numb">编号：{{ initData.report_set.code }}</div>
          </div>
          <div class="paper-name">{{ initData.eval.paper }}测评报告</div>
          <div class="block">
            <div class="block-name">基本信息</div>
            <div v-if="initData.basic" class="baseinfo">
              <div
                v-for="(item, index) in initData.basic"
                :key="index"
                class="base-line"
              >
                <label class="lbl">{{ item.name }}：</label>
                <span class="txt">{{ item.value }}</span>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="block-name">测试结果</div>
            <el-table border :data="initData.factor" stripe>
              <el-table-column align="center" label="因子项目" prop="fac" />
              <el-table-column align="center" label="题目数" prop="nums" />
              <el-table-column align="center" label="原始分" prop="score" />
              <el-table-column align="center" label="转换分" prop="average" />
              <el-table-column align="center" label="程度" prop="warn">
                <template #default="{ row }">
                  {{ row.warn ? row.warn : '-' }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="block">
            <div class="block-name" style="display: flex">
              指导建议
              <div class="no-print">
                <el-button style="padding: 0" type="text" @click="handleEdit">
                  [{{ editRes ? '保存' : '编辑' }}]
                </el-button>
                <el-button
                  v-if="editRes"
                  style="padding: 0; color: #ff4d4f"
                  type="text"
                  @click="clearRes"
                >
                  [一键清除]
                </el-button>
              </div>
            </div>
            <editor v-if="editRes" v-model="editContext" :is-clear="false" />
            <div v-else v-html="editContext"></div>
            <div
              v-if="false"
              class="eval-res"
              contenteditable="true"
              placeholder="请输入指导建议"
            >
              <div
                v-for="(item, index) in initData.res"
                :key="index"
                class="eval-blocks"
              >
                <div class="name">
                  <span v-if="item.name !== '分析'">
                    {{ item.name + (item.score ? '(' + item.score + ')' : '') }}
                  </span>
                  <div v-html="item.descr"></div>
                </div>
                <div class="contxt">
                  <div v-if="item.res" class="res">
                    <div
                      v-for="(r, j) in item.result"
                      :key="j"
                      v-html="r"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom">
            <div class="time-sign">
              <div class="time">
                日期：
                <el-date-picker
                  v-model="editTime"
                  style="border: 0; width: 100px"
                  type="date"
                  value-format="yyyy-MM-dd"
                  @change="bindDate"
                />
              </div>
              <div class="sign">签字：</div>
            </div>
            <div>（本报告仅供临床参考）</div>
          </div>
        </div>
      </template>
      <template v-else>
        <div v-if="curTab === 1" class="card-cust">
          <div class="r-title">{{ initData.eval.title }}</div>
          <div class="r-paper">{{ initData.eval.paper }}</div>
          <div class="r-time">时间：{{ initData.eval.test_at }}</div>
          <div class="r-time">测试人：{{ initData.name }}</div>
          <div class="r-img">
            <el-image :src="require('@/assets/report/fm.svg')" />
          </div>
          <div class="r-slogan">{{ initData.eval.slogan }}</div>
        </div>
        <div v-else class="el-card2">
          <div class="title">
            {{ initData.name + '的' + initData.eval.paper }}测评报告
          </div>
          <div class="test-time">
            <span>测评时间：{{ initData.eval.test_at }}</span>
            <span>用时：{{ initData.eval.use_at }}</span>
          </div>
        </div>
        <div class="el-card2">
          <div class="card-name base">个人信息</div>
          <div v-if="initData.basic" class="baseinfo">
            <div
              v-for="(item, index) in initData.basic"
              :key="index"
              class="base-line"
            >
              <label class="lbl">{{ item.name }}：</label>
              <span class="txt">{{ item.value }}</span>
            </div>
          </div>
        </div>
        <div class="el-card2">
          <div class="card-name intro">量表介绍</div>
          <div v-if="initData.eval" class="baseinfo">
            <div class="base-line">
              <span class="lbl" style="width: 100%; font-size: 15px">
                {{ initData.eval.paper }}
              </span>
            </div>
            <div class="base-line" style="width: 100%; margin-right: 0">
              <div class="txt intro" v-html="initData.eval.intro">
                {{ initData.eval.intro }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="initData.chart.type !== 'pan'" class="el-card3">
          <div class="card-name score">因子得分</div>
          <el-table border :data="initData.factor">
            <el-table-column label="名称" prop="fac" />
            <el-table-column
              align="center"
              label="编码"
              prop="coded"
              width="80"
            />
            <el-table-column
              align="center"
              label="包含题目"
              prop="nums"
              width="80"
            />
            <el-table-column
              align="center"
              label="原始分"
              prop="score"
              width="80"
            />
            <el-table-column
              align="center"
              label="转换分"
              prop="average"
              width="80"
            />
            <el-table-column align="center" label="程度" prop="warn">
              <template #default="{ row }">
                {{ row.warn ? row.warn : '-' }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="常模" prop="mod" />
          </el-table>
        </div>
        <div class="el-card2">
          <div
            class="card-name"
            :class="initData.chart.type === 'pan' ? 'result' : 'chart'"
          >
            {{ initData.chart.type === 'pan' ? '测评结果' : '因子图表' }}
          </div>
          <vab-chart
            ref="charts"
            :height="cHeight"
            :init-options="initOptions"
            :options="chartVal"
            theme="vab-echarts-theme"
          />
          <!--<img class="canvas-img" :src="canvasImg" />-->
        </div>
        <div class="el-card3">
          <div v-if="initData.chart.type !== 'pan'" class="card-name result">
            测评结果
          </div>
          <div class="eval-res">
            <div
              v-for="(item, index) in initData.res"
              :key="index"
              class="eval-block"
            >
              <div class="name">
                <span>
                  {{ item.name + (item.score ? '(' + item.score + ')' : '') }}
                </span>
              </div>
              <div
                v-if="curTab === 1 && item.range && item.range.length"
                class="grade-box"
              >
                <div class="grade">
                  <div v-for="(g, gi) in item.range" :key="gi" class="g-item">
                    <div
                      class="line"
                      :class="
                        (g === item.warn_name ? 'cur' : '') +
                        (' li' + (item.range.length <= 3 ? 2 * gi : gi))
                      "
                    >
                      <div v-if="g === item.warn_name" class="txt">{{ g }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="contxt">
                <div v-if="item.descr" class="descr" v-html="item.descr"></div>
                <div v-if="item.res" class="res">
                  <div v-for="(r, j) in item.result" :key="j" v-html="r"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import { getMmptRecord, editResult } from '@/api/mmptCategory'
  import VabChart from '@/extra/VabChart'
  import getPageTitle from '@/utils/pageTitle'
  import { getResult } from '@/api/psyeval'
  import editor from '@/components/editor/index.vue'
  export default {
    name: 'MmptRecord2',
    components: {
      editor,
      VabChart,
    },
    data() {
      return {
        id: '',
        cType: '',
        curTab: 0,
        initData: null,
        initOptions: {
          renderer: 'svg',
        },
        chartVal: {},
        canvasImg: '',
        cHeight: 400,
        date: '',
        editRes: false,
        oldContext: '',
        editContext: '',
        editTime: '',
      }
    },
    created() {
      this.$nextTick(() => {
        let p = this.$route.query
        this.cType = p.type
        if (p.type === 'pay') {
          this.getRecord({ oid: p.id })
        } else {
          if (p && p.params) {
            this.id = JSON.parse(decodeURIComponent(this.$route.query.params))
            this.getNotes(this.id)
          } else {
            this.id = this.$route.query
            this.getNotes(this.id)
          }
        }
      })
    },
    methods: {
      bindDate(val) {
        editResult({ time: val, id: this.id.id })
      },
      clearRes() {
        this.editContext = ''
      },
      handleEdit() {
        let id =
          this.$route.query.id ||
          JSON.parse(decodeURIComponent(this.$route.query.params)).id
        if (this.editRes) {
          if (this.oldContext !== this.editContext) {
            editResult({ remark: this.editContext, id: id }).then((res) => {
              if (res.code === 200) {
                this.oldContext = this.editContext
              }
            })
          }
          this.editRes = false
        } else {
          this.editRes = true
        }
      },
      changeTab(i) {
        this.curTab = i
      },
      handleMeta(name, meta) {
        document.title = getPageTitle(meta.title, true)
      },
      getRecord(oid) {
        getResult(oid)
          .then((res) => {
            let d = res.data
            let chart = d.chart
            this.initData = d
            this.editTime = (d.report && d.report.time) || d.eval.test_at
            this.initData.res = d.res.map((item) => {
              let r = item.res.split('<br>')
              return Object.assign({}, item, {
                result: r,
              })
            })
            this.curTab = d.report_set.type
            this.chartVal = this.getChart(chart)
            if (!(d.report && d.report.remark)) {
              let res = `<div class="eval-res" style="line-height: 1.8">`
              for (let i = 0; i < d.res.length; i++) {
                let item = d.res[i]
                res += `<div class="eval-blocks" style="margin-bottom: 20px"><div class="name">`
                if (item.name !== '分析') {
                  res += `<span>${
                    item.name + (item.score ? '(' + item.score + ')' : '')
                  }</span>`
                }
                res += `<div>${item.descr}</div></div>`
                res += `<div class="contxt">${
                  item.res.substr(0, 4) === '<br>'
                    ? item.res.substr(4)
                    : item.res
                }</div>`
                res += `</div>`
              }
              res += `</div>`
              this.editContext = res
            } else {
              this.editContext = d.report.remark
            }
            this.oldContext = this.editContext
          })
          .then(() => {
            this.$nextTick(() => {
              this.canvasImg = this.$refs.charts.getDataURL()
              this.handleMeta('MmptRecord2', {
                title:
                  this.initData.name +
                  '的' +
                  this.initData.eval.paper +
                  '测评报告',
              })
            })
          })
      },
      getNotes(id) {
        getMmptRecord(id)
          .then((res) => {
            let d = res.data
            let chart = d.chart
            this.initData = d
            this.editTime = (d.report && d.report.time) || d.eval.test_at
            this.initData.res = d.res.map((item) => {
              let r = item.res.split('<br>')
              return Object.assign({}, item, {
                result: r,
              })
            })
            this.curTab = d.report_set.type
            this.chartVal = this.getChart(chart)
            if (!(d.report && d.report.remark)) {
              let res = `<div class="eval-res" style="line-height: 1.8">`
              for (let i = 0; i < d.res.length; i++) {
                let item = d.res[i]
                res += `<div class="eval-blocks" style="margin-bottom: 20px"><div class="name">`
                if (item.name !== '分析') {
                  res += `<span>${
                    item.name + (item.score ? '(' + item.score + ')' : '')
                  }</span>`
                }
                res += `<div>${item.descr}</div></div>`
                res += `<div class="contxt">${
                  item.res.substr(0, 4) === '<br>'
                    ? item.res.substr(4)
                    : item.res
                }</div>`
                res += `</div>`
              }
              res += `</div>`
              this.editContext = res
            } else {
              this.editContext = d.report.remark
            }
            this.oldContext = this.editContext
          })
          .then(() => {
            this.$nextTick(() => {
              this.canvasImg = this.$refs.charts.getDataURL()
              this.handleMeta('MmptRecord2', {
                title:
                  this.initData.name +
                  '的' +
                  this.initData.eval.paper +
                  '测评报告',
              })
            })
          })
      },
      getChart(data) {
        let chart,
          d = data.table
        if (data.type === 'radar') {
          this.cHeight = 500
          chart = {
            tooltip: {
              trigger: 'axis',
            },
            legend: {
              show: true,
              x: 'center',
              y: '85%',
              data: d.legend,
            },
            color: [
              '#26cdb7',
              '#2fc25b',
              '#facc14',
              '#f04864',
              '#8543e0',
              '#aaaa00',
              '#00ffff',
              '#aa0000',
            ],
            radar: {
              indicator: d.radar,
              radius: 95,
              center: ['50%', '45%'],
              name: {
                textStyle: {
                  fontSize: 11,
                },
                formatter: function (value) {
                  let list = value.split('')
                  let result = ''
                  for (let i = 1; i <= list.length; i++) {
                    if (!(i % 2) && list[i] != undefined) {
                      result += list[i - 1] + '\n'
                    } else {
                      result += list[i - 1]
                    }
                  }
                  return result
                },
              },
              startAngle: 105,
            },
            series: [
              {
                type: 'pie',
                radius: ['55%', '65%'],
                center: ['50%', '45%'],
                labelLine: { length: 10 },
                data: d.ring.reverse(),
                animation: false,
                startAngle: 97.5,
                color: [
                  '#aa0000',
                  '#00ffff',
                  '#aaaa00',
                  '#8543e0',
                  '#f04864',
                  '#facc14',
                  '#2fc25b',
                  '#26cdb7',
                ],
              },
              {
                type: 'radar',
                areaStyle: {},
                data: d.series,
              },
            ],
          }
        } else if (data.type === 'pan') {
          /*chart = {
            series: [
              {
                type: 'gauge',
                min: 0,
                max: 100,
                splitNumber: 10,
                radius: '98%',
                endAngle: -135,
                axisLine: {
                  lineStyle: {
                    width: '100%',
                    color: [
                      [
                        1,
                        {
                          type: 'radial',
                          x: 0.5,
                          y: 0.5,
                          x2: 1,
                          y2: 1,
                          colorStops: [
                            {
                              offset: 0.5,
                              color: 'rgba(105, 132, 255, 1)', // 0% 处的颜色
                            },
                            {
                              offset: 1,
                              color: 'rgba(107, 134, 255, 0.2)', // 100% 处的颜色
                            },
                          ],
                          globalCoord: false,
                        },
                      ],
                    ],
                  },
                },
                splitLine: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  show: false,
                },
                pointer: {
                  show: false,
                },
                title: {
                  show: false,
                },
                anchor: {
                  show: false,
                },
              },
              {
                type: 'gauge',
                radius: '60%',
                endAngle: -135,
                axisLine: {
                  lineStyle: {
                    width: '100%',
                    color: [[1, '#fff']],
                  },
                },
                splitLine: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  show: false,
                },
                pointer: {
                  show: false,
                },
                title: {
                  show: false,
                },
                anchor: {
                  show: false,
                },
              },
              {
                type: 'gauge',
                min: 0,
                max: 100,
                splitNumber: 10,
                radius: '82%',
                endAngle: -135,
                axisLine: {
                  lineStyle: {
                    width: 62,
                    color: [
                      [0.75, '#6984FF'],
                      [1, '#7A9CFF'],
                    ],
                  },
                },
                splitLine: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  show: false,
                },
                pointer: {
                  show: false,
                },
                title: {
                  show: false,
                },
                anchor: {
                  show: false,
                },
              },
              {
                type: 'gauge',
                min: 0,
                max: 100,
                splitNumber: 10,
                radius: '77%',
                progress: {
                  show: true,
                  width: 20,
                },
                itemStyle: {
                  color: '#57DCFF',
                },
                axisLine: {
                  distance: 20,
                  length: 10,
                  lineStyle: {
                    color: [[1, '#f9fbff']],
                    width: 20,
                  },
                },
                splitLine: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  distance: -55,
                  color: '#fff',
                  fontSize: 15,
                },
                anchor: {
                  show: false,
                },
                pointer: {
                  show: false,
                },
                detail: {
                  offsetCenter: [0, 0],
                  fontSize: 70,
                  color: '#6B86FF',
                },
                title: {
                  // 设置仪表盘中间显示文字样式
                  offsetCenter: [0, '30%'],
                  textStyle: {
                    fontSize: 12,
                    color: '#3E4E96',
                  },
                },
                data: [
                  {
                    value: d.value,
                    name: '得分',
                  },
                ],
              },
              {
                type: 'gauge',
                min: 0,
                max: 100,
                radius: '82%',
                splitNumber: 10,
                axisLine: {
                  lineStyle: {
                    color: [[1, '#57DCFF']],
                    width: 3,
                  },
                },
                splitLine: {
                  distance: -8,
                  length: 6,
                  lineStyle: {
                    color: '#57DCFF',
                  },
                },
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  show: false,
                },
                pointer: {
                  show: false,
                },
                title: {
                  show: false,
                },
                anchor: {
                  show: false,
                },
              },
              {
                type: 'gauge',
                min: 0,
                max: 100,
                radius: '55%',
                splitNumber: 21,
                startAngle: 270,
                endAngle: -90,
                axisLine: {
                  lineStyle: {
                    color: [[1, '#194280']],
                    width: 3,
                  },
                },
                splitLine: {
                  distance: 0,
                  length: 8,
                  lineStyle: {
                    color: '#18EFE2',
                  },
                },
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  show: false,
                },
                pointer: {
                  show: false,
                },
                title: {
                  show: false,
                },
                anchor: {
                  show: false,
                },
              },
            ],
          }*/
          this.cHeight = 280
          chart = {
            series: [
              {
                type: 'gauge',
                startAngle: 180,
                endAngle: 0,
                min: 0,
                max: Math.ceil(d.value / 100) * 100,
                splitNumber: 10,
                radius: '150%',
                center: ['50%', '80%'],
                itemStyle: {
                  color: '#26cdb7',
                  shadowColor: 'rgba(0,138,255,0.45)',
                  shadowBlur: 5,
                  shadowOffsetX: 2,
                  shadowOffsetY: 2,
                },
                progress: {
                  show: true,
                  roundCap: true,
                  width: 10,
                },
                pointer: {
                  icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
                  length: '30%',
                  width: 10,
                  offsetCenter: [0, '-30%'],
                },
                axisLine: {
                  roundCap: true,
                  lineStyle: {
                    width: 10,
                  },
                },
                axisTick: {
                  splitNumber: 1,
                  lineStyle: {
                    width: 1,
                    color: '#ccc',
                  },
                },
                splitLine: {
                  length: 5,
                  lineStyle: {
                    width: 2,
                    color: '#ccc',
                  },
                },
                axisLabel: {
                  distance: 20,
                  color: '#ddd',
                  fontSize: 14,
                },
                title: {
                  show: false,
                },
                detail: {
                  width: '80%',
                  lineHeight: 40,
                  height: 40,
                  offsetCenter: [0, '0%'],
                  formatter: function (value) {
                    return '{value|' + value.toFixed(0) + '}{unit|分}'
                  },
                  rich: {
                    value: {
                      fontSize: 40,
                      fontWeight: 'bolder',
                      color: '#777',
                    },
                    unit: {
                      fontSize: 20,
                      color: '#999',
                      padding: [0, 0, -10, 10],
                    },
                  },
                },
                data: [
                  {
                    value: d.value,
                  },
                ],
              },
            ],
          }
        } else {
          /*this.cHeight = 200 + d.yAxis.length * 60*/
          d.datasets.forEach((ds, s) => {
            this.$set(ds, 'label', {
              show: true,
            })
            //this.$set(ds, 'showBackground', true)
            this.$set(ds, 'type', 'line')
          })
          chart = {
            title: {
              text: '',
              subtext: '',
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
              },
            },
            legend: {
              data: d.legend,
              remote: '30deg',
            },
            color: ['#4b66f3', '#f3cd5b', '#f35243', '#8cf580'],
            grid: {
              left: '3%',
              right: '8%',
              bottom: '3%',
              containLabel: true,
            },
            xAxis: {
              type: 'category',
              data: d.yAxis,
              splitLine: {
                show: false,
              },
              axisLabel: {
                rotate: d.yAxis.length > 8 ? 60 : 0,
              },
            },
            yAxis: {
              type: 'value',
            },
            series: d.datasets,
            animation: false,
          }
        }
        return chart
      },
      print() {
        if (this.editRes) {
          this.$baseMessage('请先保存指导建议', 'warning')
          return false
        }
        this.$print(this.$refs.print)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .eval-container {
    padding: 20px 0;
    .canvas-img {
      display: none;
    }
    .tab {
      width: 800px;
      margin: 0 auto 20px;
      color: rgb(111, 132, 131);
      .el-button--primary {
        font-size: 18px;
        font-weight: bold;
        border: none;
      }
      .name {
        font-size: 18px;
        font-weight: bold;
        margin-right: 10px;
        label {
          font-size: 30px;
          vertical-align: middle;
        }
      }
      .tip {
        margin-left: 15px;
      }
      .cur {
        background: rgba(38, 205, 183, 0.32);
      }
    }
  }
  .content {
    width: 800px;
    margin: auto;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    background: #fff;
    min-height: 100vh;
    .hospital {
      padding: 0 40px;
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.65);
        margin-top: 20px;
        padding: 20px 0;
        .name {
          font-weight: bold;
        }
      }
      .paper-name {
        font-size: 21px;
        text-align: center;
        padding: 30px 0;
      }
      .block {
        margin-bottom: 30px;
        .block-name {
          font-weight: bold;
          border-left: 6px solid #26cdb7;
          padding-left: 10px;
          margin-bottom: 20px;
        }
        .baseinfo {
          display: flex;
          flex-wrap: wrap;
          .base-line {
            width: 33%;
            padding-right: 2%;
            margin-bottom: 10px;
            .lbl {
            }
            .txt {
            }
          }
        }
      }
      .bottom {
        text-align: right;
        margin-top: 60px;
        padding-bottom: 30px;
        .time-sign {
          display: flex;
          align-items: center;
          border-bottom: 1px solid rgba(0, 0, 0, 0.65);
          margin-bottom: 10px;
          text-align: left;
          justify-content: flex-end;
          padding-bottom: 2px;
          .sign {
            width: 120px;
            margin-left: 20px;
          }
        }
      }
    }

    &.invalid {
      filter: grayscale(100%);
    }
    .invalid-report {
      position: absolute;
      top: 30px;
      left: -50px;
      z-index: 10;
      transform: rotate(-43deg);
      background: #eee;
      line-height: 50px;
      font-size: 20px;
      width: 220px;
      text-align: center;
    }
    .canvas-img {
      width: 96%;
    }
    .echarts {
      width: 90% !important;
      margin: auto;
    }
    .print {
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 10;
    }
    .el-card3,
    .el-card2 {
      position: relative;
      padding: 0 40px 20px 40px;
      background: #fff;
      .title {
        font-size: 24px;
        text-align: center;
        line-height: 60px;
        padding: 10px;
        font-weight: bold;
        margin-top: 30px;
      }
      .test-time {
        text-align: center;
        padding: 10px;
        > span {
          padding: 0 10px;
        }
      }
      .card-name {
        position: relative;
        font-size: 18px;
        padding-left: 20px;
        line-height: 50px;
        font-weight: bold;
        &:before {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 6px;
          height: 20px;
          border-radius: 10px;
          background: #26cdb7 !important;
        }
      }
      .baseinfo {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 20px 25px;
        .base-line {
          width: 50%;
          line-height: 1.6;
          .lbl {
            display: inline-block;
            width: 80px;
            font-weight: bold;
            &.intro {
              width: 100%;
            }
          }
          .txt {
            &.intro {
              padding: 10px 0;
              text-align: justify;
              font-weight: 100;
              > p {
                margin: 0 !important;
              }
            }
          }
        }
      }
      .eval-res {
        position: relative;
        .eval-block {
          line-height: 1.5;
          margin-bottom: 30px;
          .name {
            > span {
              display: inline-block;
              min-width: 150px;
              text-align: center;
              line-height: 40px;
              padding: 0 20px;
              border-radius: 10px 10px 0 0;
              background-color: #e0f3f1 !important;
              margin-left: 5px;
              font-size: 18px;
            }
          }
          .contxt {
            border: 1px dashed #bbb;
            border-radius: 5px;
            text-align: justify;
            padding: 25px;
          }
          .descr {
            margin-bottom: 15px;
          }
          .res {
            font-weight: 100;
            > div {
              margin-top: 8px;
            }
          }
        }
      }
    }
    &.customstyle {
      .card-cust {
        border: 2px solid rgba(38, 205, 183, 0.83);
        border-radius: 6px;
        text-align: center;
        margin: 40px;
        padding: 20px;
        .r-title {
          padding-top: 100px;
          font-size: 45px;
          font-weight: bold;
          line-height: 65px;
          color: rgb(111, 132, 131);
        }
        .r-paper {
          color: rgba(111, 132, 131, 0.9);
          font-size: 30px;
          padding: 40px 0 50px;
        }
        .el-image {
          width: 480px;
          height: 486px;
          margin: 78px 0 60px;
        }
        .r-time {
          margin-top: 5px;
          padding: 0 35%;
          text-align: left;
        }
        .r-slogan {
          color: rgba(0, 0, 0, 0.34);
          margin-bottom: 20px;
        }
      }
      .el-card2,
      .el-card3 {
        .card-name {
          padding-left: 36px;
          &:before {
            content: '';
            position: absolute;
            width: 36px;
            height: 36px;
          }
          &.base {
            &:before {
              background: url('~@/assets/report/base.svg') !important;
            }
          }
          &.intro {
            &:before {
              background: url('~@/assets/report/intro.svg') !important;
            }
          }
          &.score {
            &:before {
              background: url('~@/assets/report/score.svg') !important;
            }
          }
          &.result {
            &:before {
              background: url('~@/assets/report/res.svg') !important;
            }
          }
          &.chart {
            &:before {
              background: url('~@/assets/report/chart.svg') !important;
            }
          }
        }
        .baseinfo,
        .eval-res .eval-block {
          background: #f8fafb;
          border: none;
          border-radius: 4px;
        }
        .eval-res .eval-block {
          .name {
            padding-top: 15px;
            > span {
              position: relative;
              background: none !important;
              font-weight: bold;
              color: #6f8483;
              text-align: left;
              padding-left: 30px;
              margin-left: 20px;
              &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                margin: auto;
                width: 20px;
                height: 20px;
                background: url('~@/assets/report/ding.svg') no-repeat;
                background-size: 100%;
              }
            }
          }
          .contxt {
            border: none;
            padding-top: 10px;
          }
        }
      }
      .grade-box {
        padding: 15px 20px 30px;
        .grade {
          display: flex;
          .g-item {
            width: 100px;
            &:not(:first-child) {
              .line {
                border-left: 1px solid #fff;
              }
            }
            &:last-child {
              .line {
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px;
              }
            }
            .line {
              position: relative;
              height: 10px;
              text-align: center;
              &.li0 {
                background: #2cc642;
                color: #2cc642;
                border-top-left-radius: 2px;
                border-bottom-left-radius: 2px;
              }
              &.li1 {
                background: #26cdb7;
                color: #26cdb7;
              }
              &.li2 {
                background: #f3e869;
                color: #f3e869;
              }
              &.li3 {
                background: #ffc107;
                color: #ffc107;
              }
              &.li4 {
                background: #fd521d;
                color: #fd521d;
              }
              &.li5 {
                background: #c61812;
                color: #c61812;
              }
              &.li6 {
                background: #730c08;
                color: #730c08;
              }
              &.cur:after {
                content: '▲';
                position: absolute;
                left: 0;
                right: 0;
                width: 20px;
                height: 20px;
                bottom: -16px;
                margin: auto;
                font-size: 16px;
              }
              .txt {
                position: absolute;
                top: 26px;
                left: 0;
                right: 0;
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }

  @media print {
    @page {
      size: A4;
      margin: 10mm 0mm;
    }
    .content {
      background: #fff !important;
    }
    .hospital .title,
    .content.customstyle .card-cust {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }
    .hospital {
      * {
        color: #000 !important;
      }
    }
    .el-card2,
    .eval-block {
      break-inside: avoid;
    }
    table,
    figure {
      page-break-inside: avoid;
    }
  }
</style>

<style lang="scss">
  .el-input__inner {
    padding: 0 !important;
    border: 0 !important;
    font-size: 14px !important;
  }
  .el-input__prefix {
    display: none;
  }
  .eval-res {
    .descr b {
      display: inline-block;
      margin-bottom: 8px;
    }
    .res b {
      font-weight: bold;
    }
  }
</style>
