var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.initData
    ? _c("div", { staticClass: "eval-container" }, [
        _c("div", { ref: "print", staticClass: "content" }, [
          _c("div", { staticClass: "el-card" }, [
            _c(
              "div",
              { staticClass: "print no-print", on: { click: _vm.print } },
              [
                _c("vab-icon", { attrs: { icon: "printer-line" } }),
                _vm._v(" 导出PDF/打印 "),
              ],
              1
            ),
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.initData.paper) + "预警报告"),
            ]),
            _c("div", { staticClass: "test-time" }, [
              _c("span", [_vm._v("测评时间：" + _vm._s(_vm.initData.test_at))]),
              _c("span", [_vm._v("用时：" + _vm._s(_vm.initData.use_at))]),
            ]),
            _c("div", { staticClass: "card-name" }, [_vm._v("基本信息")]),
            _vm.initData.basic
              ? _c(
                  "div",
                  { staticClass: "baseinfo" },
                  _vm._l(_vm.initData.basic, function (item, index) {
                    return _c("div", { key: index, staticClass: "base-line" }, [
                      _c("label", { staticClass: "lbl" }, [
                        _vm._v(_vm._s(item.name) + "："),
                      ]),
                      _c("span", { staticClass: "txt" }, [
                        _vm._v(_vm._s(item.value)),
                      ]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "el-card" }, [
            _c("div", { staticClass: "card-name" }, [_vm._v("量表介绍")]),
            _vm.initData
              ? _c("div", { staticClass: "baseinfo" }, [
                  _c("div", { staticClass: "base-line" }, [
                    _c(
                      "span",
                      {
                        staticClass: "lbl",
                        staticStyle: { width: "100%", "font-size": "15px" },
                      },
                      [_vm._v(" " + _vm._s(_vm.initData.paper) + " ")]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "base-line",
                      staticStyle: { width: "100%", "margin-right": "0" },
                    },
                    [
                      _c("div", { staticClass: "txt intro" }, [
                        _vm._v(_vm._s(_vm.initData.intro)),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              staticClass: "el-card",
              staticStyle: { "padding-bottom": "40px" },
            },
            [
              _c("div", { staticClass: "card-name" }, [_vm._v("预警因子")]),
              _c(
                "el-table",
                { attrs: { border: "", data: _vm.warnTable.list } },
                _vm._l(_vm.warnTable.table, function (item, i_t) {
                  return _c("el-table-column", {
                    key: i_t,
                    attrs: {
                      align: item.value !== "fac" ? "center" : "",
                      label: item.name,
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("span", [_vm._v(_vm._s(row[item.value]))]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }