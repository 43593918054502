<template>
  <div class="login-container">
    <el-row>
      <el-col
        :lg="{ span: 14, offset: 5 }"
        :md="{ span: 20, offset: 2 }"
        :sm="{ span: 20, offset: 2 }"
        :xl="{ span: 12, offset: 6 }"
        :xs="{ span: 20, offset: 2 }"
      >
        <div class="login-box">
          <div class="left hidden-xs-only">
            <div class="login-title">{{ loginName }}</div>
            <div class="title-tips">{{ loginSlogan }}</div>
            <img src="@/assets/login_images/login_img.png" />
          </div>
          <div class="right">
            <el-form
              ref="form"
              class="login-form"
              label-position="left"
              :model="form"
              :rules="rules"
            >
              <div class="login-title1">Hi, 管理员登录</div>
              <el-form-item prop="username">
                <el-input
                  v-model.trim="form.username"
                  v-focus
                  :placeholder="translateTitle('请输入用户名')"
                  tabindex="1"
                  type="text"
                >
                  <template #prefix><vab-icon icon="user-line" /></template>
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  :key="passwordType"
                  ref="password"
                  v-model.trim="form.password"
                  :placeholder="translateTitle('请输入密码')"
                  tabindex="2"
                  :type="passwordType"
                >
                  <template #prefix>
                    <vab-icon icon="lock-line" />
                  </template>
                  <template v-if="passwordType === 'password'" #suffix>
                    <vab-icon
                      class="show-password"
                      icon="eye-off-line"
                      @click="handlePassword"
                    />
                  </template>
                  <template v-else #suffix>
                    <vab-icon
                      class="show-password"
                      icon="eye-line"
                      @click="handlePassword"
                    />
                  </template>
                </el-input>
              </el-form-item>
              <el-button
                class="login-btn"
                :loading="loading"
                type="primary"
                @click="handleLogin"
                @keyup.enter="keyDown(e)"
              >
                {{ translateTitle('登录') }}
              </el-button>
            </el-form>
          </div>
        </div>
        <div v-if="hiddenSupport == 0" class="jszc">
          <div class="beian">
            <img src="@/assets/beian.png" style="width: 20px" />
            湘公网安备43019002002402号
          </div>
          <img src="@/assets/flogo.png" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { translateTitle } from '@/utils/i18n'
  import { isPassword } from '@/utils/validate'
  import { getCompanyInfo } from '@/api/router'

  export default {
    name: 'Login',
    directives: {
      focus: {
        inserted(el) {
          el.querySelector('input').focus()
        },
      },
    },
    beforeRouteLeave(to, from, next) {
      clearInterval(this.timer)
      next()
    },
    data() {
      const validateUsername = (rule, value, callback) => {
        if ('' === value)
          callback(new Error(this.translateTitle('用户名不能为空')))
        else callback()
      }
      const validatePassword = (rule, value, callback) => {
        if (!isPassword(value) === value)
          callback(new Error(this.translateTitle('密码不能少于6位')))
        else callback()
      }
      return {
        nodeEnv: process.env.NODE_ENV,
        form: {
          username: '',
          password: '',
          directory: '',
        },
        rules: {
          username: [
            {
              required: true,
              trigger: 'blur',
              validator: validateUsername,
            },
          ],
          password: [
            {
              required: true,
              trigger: 'blur',
              validator: validatePassword,
            },
          ],
        },
        loading: false,
        passwordType: 'password',
        redirect: undefined,
        timer: 0,
      }
    },
    computed: {
      ...mapGetters({
        title: 'settings/title',
        logo: 'settings/logo',
        loginName: 'settings/loginName',
        loginSlogan: 'settings/loginSlogan',
        hiddenSupport: 'settings/hiddenSupport',
      }),
    },
    watch: {
      $route: {
        handler(route) {
          this.redirect = (route.query && route.query.redirect) || '/'
        },
        immediate: true,
      },
    },
    mounted() {
      window.addEventListener('keydown', this.keyDown)
    },
    async created() {
      let directory = window.document.location.href.match(/\/\?(\S*)\/#/)
      directory = directory ? directory[1] : ''
      this.form.directory = directory
      const { data } = await getCompanyInfo({ directory: directory })
      document.title = data.page_title
    },
    destroyed() {
      window.removeEventListener('keydown', this.keyDown, false)
    },
    methods: {
      ...mapActions({
        login: 'user/login',
      }),
      keyDown(e) {
        if (e.keyCode == 13 || e.keyCode == 108) {
          this.handleLogin()
        }
      },
      translateTitle,
      handlePassword() {
        this.passwordType === 'password'
          ? (this.passwordType = '')
          : (this.passwordType = 'password')
        this.$nextTick(() => {
          this.$refs.password.focus()
        })
      },
      handleRoute() {
        return this.redirect === '/404' || this.redirect === '/403'
          ? '/'
          : this.redirect
      },
      handleLogin() {
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            try {
              this.loading = true
              await this.login(this.form)
              await this.$router.push(this.handleRoute())
            } finally {
              this.loading = false
            }
          } else {
            return false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .login-container {
    height: 100vh;
    overflow: hidden;
    background: url('~@/assets/login_images/background.png') center center fixed
      no-repeat;
    background-size: cover;
  }
  .beian {
    font-size: 12px;
    margin-right: 20px;
    line-height: 24px;
    img {
      vertical-align: middle;
    }
  }
  .jszc {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 30px;
    font-size: 16px;
    color: #666;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 150px;
    }
  }
  .login-box {
    display: flex;
    justify-content: space-between;
    margin: calc((100vh - 500px) / 2) 0;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 0 49px 15px rgba(0, 0, 0, 0.03);
    border: 5px solid rgba(255, 255, 255, 0.8);
    overflow: hidden;
    .left {
      width: 45%;
      margin-right: 3vw;
      padding: 60px 40px 0 40px;
      color: #fff;
      background: radial-gradient(
        47% 47% at 50% 53%,
        rgb(38, 205, 183) 34%,
        rgba(38, 205, 183, 0.74) 100%
      );
      .login-title {
        margin-bottom: 40px;
        font-size: 36px;
        font-weight: bold;
      }
      img {
        width: 100%;
        max-width: 400px;
        margin-top: 20px;
        object-fit: contain;
      }
    }
    .right {
      flex: 1;
      font-weight: bold;
      .login-title1 {
        font-size: 26px;
        margin-bottom: 40px;
        line-height: 1.2;
      }
    }
  }
  .login-form {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    background-size: 100% 100%;
    padding: 60px 15%;

    .title-tips {
      font-size: 18px;
    }

    .login-btn {
      display: inherit;
      width: 100%;
      height: 50px;
      margin-top: 60px;
      border: 0;
      font-size: 20px;
      background: linear-gradient(
        90deg,
        rgba(38, 205, 183, 0.95),
        rgba(38, 205, 183, 0.55) 100%
      );
      box-shadow: 0 0 24px 9px rgba(38, 205, 183, 0.08);

      &:hover {
        opacity: 0.9;
      }

      .forget-passwordword {
        width: 100%;
        margin-top: 40px;
        text-align: left;

        .forget-password {
          width: 129px;
          height: 19px;
          font-size: 20px;
          font-weight: 400;
          color: rgba(38, 205, 183, 1);
        }
      }
    }

    .tips {
      margin-bottom: 10px;
      font-size: $base-font-size-default;
      color: $base-color-white;

      span {
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }

    .title-container {
      position: relative;

      .title {
        margin: 0 auto 40px auto;
        font-size: 34px;
        font-weight: bold;
        color: $base-color-blue;
        text-align: center;
      }
    }

    i {
      position: absolute;
      top: 8px;
      left: 5px;
      z-index: $base-z-index;
      font-size: 20px;
      color: #aaa;
      cursor: pointer;
      user-select: none;
    }

    .show-password {
      position: absolute;
      right: 25px;
      left: -35px;
      font-size: 16px;
      color: #d7dee3;
      cursor: pointer;
      user-select: none;
    }

    ::v-deep {
      .el-form-item {
        padding-right: 0;
        margin: 20px 0;
        color: #454545;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 2px;

        &__content {
          min-height: $base-input-height;
          line-height: $base-input-height;
        }

        &__error {
          position: absolute;
          top: 100%;
          left: 18px;
          font-size: $base-font-size-small;
          line-height: 18px;
          color: $base-color-red;
        }
      }

      .el-input {
        box-sizing: border-box;

        input {
          height: 48px;
          padding-left: 35px;
          font-size: $base-font-size-default;
          line-height: 58px;
        }
      }
    }
  }
</style>
