var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-card",
        {
          staticStyle: { "min-height": "600px" },
          attrs: { shadow: "never" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("span", { staticClass: "f18" }, [_vm._v("数据分布")]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.work
            ? _c(
                "div",
                { staticClass: "ul" },
                [
                  _vm.visuals
                    ? _c(
                        "router-link",
                        {
                          staticClass: "li bg6",
                          attrs: {
                            tag: "a",
                            target: "_blank",
                            to: "/viewData",
                          },
                        },
                        [
                          _c("el-image", {
                            staticClass: "icon",
                            attrs: {
                              src: require("@/assets/index_images/icon-data.png"),
                            },
                          }),
                          _c("div", { staticClass: "info" }, [
                            _c("div", { staticClass: "f16" }, [
                              _vm._v("前往数据中心"),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }