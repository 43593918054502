<template>
  <div class="gauge-container"></div>
</template>

<script>
  export default {
    name: 'MarketStatistics',
    components: {},
    data() {
      return {}
    },
    created() {},
    mounted() {},
    methods: {},
  }
</script>
