<template>
  <div class="analyse-container">
    <div v-if="list.length" ref="print" class="content">
      <div class="ques-title">
        <div>{{ querys.title }}</div>
        <el-button class="no-print" type="primary" @click="print('pdf')">
          导出PDF
        </el-button>
      </div>
      <div class="list">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="item anchor"
          :class="item.type === 'text' ? 'no-print' : ''"
        >
          <div class="top">
            <div class="title">{{ item.title }}</div>
            <span>{{ item.typeName }}</span>
          </div>
          <div class="middle">
            <template v-if="item.type === 'text'">
              <div style="margin-bottom: 30px">填空题不参与分析</div>
            </template>
            <template v-if="item.type === 'radio' || item.type === 'checkbox'">
              <el-table
                border
                :data="item.options"
                :header-cell-style="{ textAlign: 'center' }"
              >
                <el-table-column label="选项" prop="label" />
                <el-table-column label="选择人次" prop="stat.num">
                  <template #default="{ row }">
                    <span>{{ row.stat.num + '人' }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="占比" prop="stat.rate">
                  <template #default="{ row }">
                    <span>{{ row.stat.rate }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </template>
            <template
              v-if="
                item.type === 'matrix_radio' || item.type === 'matrix_checkbox'
              "
            >
              <el-table border :data="item.row">
                <el-table-column label="题目\选项">
                  <template slot-scope="scope">
                    {{ item.row[scope.$index] }}
                  </template>
                </el-table-column>
                <el-table-column
                  v-for="(col, c) in item.options"
                  :key="c"
                  :label="col.label"
                >
                  <template slot-scope="scope">
                    <span>
                      {{
                        col.stat[scope.$index].num +
                        '(' +
                        col.stat[scope.$index].rate +
                        ')'
                      }}
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </template>
            <div v-if="item.type !== 'text'" class="img">
              <vab-chart
                :ref="`charts_${index}`"
                class="no-print"
                :options="chartList[index].chartVal"
                theme="vab-echarts-theme"
              />
              <img class="canvas-img" :src="chartList[index].charImg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getSurveyAnalyse } from '@/api/teamList'
  import VabChart from '@/extra/VabChart'
  import getPageTitle from '@/utils/pageTitle'
  export default {
    name: 'SurveyAnalyse',
    components: { VabChart },
    data() {
      return {
        querys: null,
        list: [],
        chartList: [], //分析图表
        loading: false,
      }
    },
    created() {
      this.querys = this.$route.query
      this.$nextTick(async () => {
        await this.getNotes()
      })
    },
    methods: {
      handleMeta(name, meta) {
        document.title = getPageTitle(meta.title, true)
      },
      getNotes() {
        this.loading = this.$baseLoading(null, '加载中，请稍候...')
        getSurveyAnalyse({
          id: this.querys.id,
        })
          .then((res) => {
            let d = res.data.filter((f) => f.type !== 'page')
            this.list = d

            this.chartList = d.map((item, index) => {
              if (item.type !== 'page' && item.type !== 'text') {
                let c,
                  txt = [],
                  val = []
                if (item.type === 'radio') {
                  c = item.options.map((opt) => {
                    return {
                      value: opt.stat.num,
                      name: opt.label,
                    }
                  })
                } else if (item.type === 'checkbox') {
                  item.options.map((opt) => {
                    txt.push(opt.label)
                    val.push(opt.stat.num)
                  })
                  let data = [
                    {
                      data: val,
                      type: 'bar',
                      barMaxWidth: '20%',
                      label: {
                        show: true,
                        position: 'top',
                      },
                    },
                  ]
                  c = { txt, data }
                } else {
                  item.row.map((r) => {
                    txt.push(r)
                  })
                  let data = item.options.map((o) => {
                    let ser = item.row.map((r, j) => {
                      return o.stat[j].num
                    })
                    return {
                      name: o.label,
                      data: ser,
                      type: 'bar',
                      barMaxWidth: '20%',
                      label: {
                        show: true,
                        position: 'top',
                      },
                    }
                  })
                  c = { txt, data }
                }
                this.$set(item, 'cid', 'charts_' + index)
                this.$set(
                  item,
                  'chartVal',
                  item.type === 'radio'
                    ? this.getPieChart(c)
                    : this.getChart(c, item.type)
                )
                this.$set(item, 'charImg', '')
              }
              return item
            })
            this.handleMeta('SurveyAnalyse', {
              title: this.querys.title + '问卷分析',
            })
            this.loading.close()
          })
          .then(() => {
            this.$nextTick(() => {
              this.chartList.forEach((item) => {
                if (item.chartVal) {
                  this.$set(
                    item,
                    'charImg',
                    this.$refs[item.cid][0].getDataURL()
                  )
                }
              })
            })
          })
          .catch((err) => {
            this.loading.close()
          })
      },
      getPieChart(data) {
        let total = 0
        const target = new Map()
        data.forEach((item) => {
          // 计算value的和，用来算百分比
          total += item.value
          // 组成 map {A:1212} ,用来下面获取 value
          target.set(item.name, item.value)
        })
        //饼图
        let option = {
          color: [
            '#5470c6',
            '#91cc75',
            '#fac858',
            '#ee6666',
            '#73c0de',
            '#3ba272',
            '#fc8452',
            '#9a60b4',
            '#ea7ccc',
            '#26cdb7',
            '#36cbcb',
            '#4ecb73',
            '#fbd437',
            '#f2637b',
            '#975fe5',
          ],
          tooltip: {},
          legend: {
            orient: 'vertical',
            left: '50%',
            y: 'center',
            formatter: function (name) {
              let lists = []
              let inum = total === 0 ? 0 : (target.get(name) / total) * 100
              //保留inum小数点后２位
              let vnum = inum === 0 ? 0 : parseFloat(inum).toFixed(2)
              lists.push(name + '－' + vnum + '%')
              return lists
            },
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              center: ['30%', '50%'],
              animation: false,
              itemStyle: {
                borderColor: '#fff',
                borderWidth: 1,
              },
              label: {
                show: false,
              },
              /*label: {
                formatter: '{name|{b}}\n{num|占比: {d}%}',
                minMargin: 5,
                edgeDistance: 10,
                lineHeight: 15,
                rich: {
                  num: {
                    fontSize: 10,
                    color: '#999',
                  },
                },
              },
              labelLine: {
                length: 15,
                length2: 0,
                maxSurfaceAngle: 80,
              },*/
              data: data,
            },
          ],
        }
        return option
      },
      getChart(data) {
        //柱状图
        let chart = {
          color: [
            '#26cdb7',
            '#fbd437',
            '#36cbcb',
            '#f2637b',
            '#4ecb73',
            '#975fe5',
          ],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            left: 'center',
            bottom: 'bottom',
            textStyle: {
              fontSize: 10,
              fontWeight: 'normal',
            },
          },
          grid: {
            left: '15%',
            right: '15%',
            bottom: '10%',
            top: 30,
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            data: data.txt,
            axisLabel: { interval: 0, rotate: 30 },
          },
          yAxis: {
            type: 'value',
          },
          series: data.data,
          animation: false,
        }
        return chart
      },
      async print(type) {
        if (type == 'word') {
          this.wordUrl && window.open(this.wordUrl)
        } else if (type == 'pdf') {
          await this.$print(this.$refs.print)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .analyse-container {
    .canvas-img {
      display: none;
    }
    .ques-title {
      text-align: right;
      padding: 20px 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .content {
    position: relative;
    width: 1000px;
    margin: auto;
    .canvas-img {
      width: 98%;
    }
    .list {
      .item {
        margin-bottom: 20px;
        background: #fff;
        padding: 15px 40px;
        border-radius: 5px;
        .top {
          display: flex;
          justify-content: space-between;
          padding: 15px 0;
          .title {
            width: 80%;
            font-weight: bold;
            line-height: 1.5;
          }
          > span {
            font-size: 12px;
            color: #999;
          }
        }
        .middle {
          .table-header {
            text-align: center;
            font-weight: bold;
          }
          .img {
            padding: 50px 0;
          }
        }
      }
    }
  }
  @media print {
    @page {
      size: auto;
      margin: 0mm;
    }
    .ques-title {
      height: 72px;
      line-height: 72px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
    }
    .anchor {
      page-break-after: always;
    }
  }
</style>
