<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title === 'first' ? '新增一级组织' : '新增下级'"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="名称" prop="name">
        <template v-if="title === 'first'">
          <el-input
            v-model="form.name"
            placeholder="请输入一级组织名称"
            type="textarea"
          />
          <div style="color: #999">
            如需同时新增多个一级组织，请用中文逗号隔开，例：中南大学，湖南大学，深圳大学
          </div>
        </template>
        <template v-else>
          <el-input
            v-model="form.name"
            placeholder="请输入下级名称"
            type="textarea"
          />
          <div style="color: #999">
            如需同时新增多个下级，请用中文逗号隔开，例：1班，2班，3班
          </div>
        </template>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { addSubOrigin } from '@/api/userManagementOrganization'

  export default {
    name: 'OrganizationAdd',
    data() {
      return {
        form: {
          name: '',
        },
        rules: {
          name: [
            { required: true, trigger: 'blur', message: '请输入下级名称' },
          ],
        },
        title: '',
        dialogFormVisible: false,
        clickRow: null,
      }
    },
    created() {},
    methods: {
      showEdit(row, type) {
        this.title = type
        if (type === 'first') {
          //this.form.type = 'add'
        } else {
          this.clickRow = row
          this.form.id = row.id
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await addSubOrigin(this.form)
            this.$baseMessage(msg, 'success')
            this.$emit('fetch-data')
            this.title !== 'first' && this.$emit('click-row', this.clickRow)
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>
