<template>
  <div id="center" class="d-flex">
    <div class="item w30">
      <dv-border-box-12>
        <Echart id="centerChart1" :height="200" :options="pie1" />
      </dv-border-box-12>
    </div>
    <div class="item w30">
      <dv-border-box-12>
        <Echart id="centerChart2" :height="200" :options="pie2" />
      </dv-border-box-12>
    </div>
    <div class="item w30">
      <dv-border-box-12>
        <Echart id="centerChart3" :height="200" :options="pie3" />
      </dv-border-box-12>
    </div>
    <div class="item flex-1">
      <dv-border-box-12>
        <dv-scroll-board :config="config" style="width: 100%; height: 200px" />
      </dv-border-box-12>
    </div>
  </div>
</template>

<script>
  import Echart from '@/extra/VabChart/index.vue'
  export default {
    components: {
      Echart,
    },
    data() {
      return {
        pie1: {},
        pie2: {},
        pie3: {},
        config: {
          rowNum: 5,
          headerBGC: '#',
          oddRowBGC: '#0b0d1f',
          evenRowBGC: '#070e41',
          columnWidth: [130, 130],
          align: ['center', 'center', 'center', 'center', 'center', 'center'],
          header: [
            '学校名称',
            '任务名',
            '任务类型',
            '应测人数',
            '实测人数',
            '开始时间',
          ],
          data: [
            [
              '大地学校高中部',
              '怀仁大地小学',
              '怀仁市金沙滩镇寄宿制小学',
              23,
              23,
              '2024/2/2',
            ],
            [
              '怀仁九小',
              '怀仁九小西校区',
              '怀仁市第九中学',
              23,
              23,
              '2024/2/2',
            ],
            ['怀仁巨子小学', '怀仁六小', '怀仁三小', 23, 23, '2024/2/2'],
            [
              '怀仁七中',
              '怀仁市第十小学校',
              '怀仁十一中初中部',
              23,
              23,
              '2024/2/2',
            ],
            [
              '怀仁市第十小学校南校区',
              '怀仁市第十中学附属小学',
              '',
              23,
              23,
              '2024/2/2',
            ],
            [
              '怀仁市第十一中学校高中部',
              '怀仁实验小学',
              '怀仁市海北头学校',
              23,
              23,
            ],
          ],
        },
      }
    },
    mounted() {},
    created() {
      this.pie1 = this.getPie({
        title: '小学生预警情况',
        legend: ['正常', '关注', '异常'],
        color: ['#04a206', '#32c5e9', '#e81414'],
        data: [
          { value: 1548, name: '正常' },
          { value: 775, name: '关注' },
          { value: 679, name: '异常' },
        ],
      })
      this.pie2 = this.getPie({
        title: '初中生预警情况',
        legend: ['正常', '轻度', '中度', '中重度'],
        color: ['#04a206', '#32c5e9', '#dc8514', '#e81414'],
        data: [
          { value: 1548, name: '正常' },
          { value: 775, name: '轻度' },
          { value: 679, name: '中度' },
          { value: 23, name: '中重度' },
        ],
      })
      this.pie3 = this.getPie({
        title: '高中生预警情况',
        legend: ['正常', '轻度', '中度', '中重度'],
        color: ['#04a206', '#32c5e9', '#dc8514', '#e81414'],
        data: [
          { value: 1548, name: '正常' },
          { value: 775, name: '轻度' },
          { value: 679, name: '中度' },
          { value: 213, name: '中重度' },
        ],
      })
    },
    methods: {
      getPie(data) {
        let option = {
          title: {
            text: data.title,
            textStyle: {
              fontSize: 12,
              color: '#eee',
            },
            left: 'right',
          },
          legend: {
            data: data.length,
            textStyle: {
              color: '#eee',
            },
            bottom: '10%',
            right: '5%',
            orient: 'vertical',
          },
          color: data.color,
          tooltip: {
            trigger: 'item',
            formatter: '{b} : {c}人 ({d}%)',
          },
          calculable: true,
          series: [
            {
              label: {
                formatter: '{name|{c}, \n{d}%}',
                position: 'inner',
                rich: {
                  name: {
                    color: '#fff',
                  },
                },
              },
              radius: '55%',
              type: 'pie',
              center: ['40%', '50%'],
              data: data.data,
            },
          ],
        }
        return option
      },
    },
  }
</script>

<style lang="scss">
  #center {
    &.d-flex {
      justify-content: space-between;
      .item > div {
        padding: 0.2rem;
      }
      .w30 {
        width: 20%;
      }
    }
  }
</style>
