<template>
  <div class="list-container">
    <vab-query-form>
      <vab-query-form-right-panel v-if="button.search" :span="24">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-select
              v-model="queryForm.sid"
              filterable
              placeholder="请选择工作室"
            >
              <el-option
                v-for="item in studioList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入用户昵称"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.phone"
              clearable
              placeholder="请输入电话号码"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table v-loading="listLoading" :data="list">
      <el-table-column align="center" label="ID" prop="id" />
      <el-table-column
        align="center"
        label="工作室"
        prop="nickname"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="咨询师"
        prop="name"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          {{ row.name + '(' + row.phone + ')' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="头像"
        prop="psy_avatar"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-image lazy :src="row.psy_avatar" />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="等级/擅长领域"
        prop="areas"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          {{ row.psy_level + '/' + row.type_name }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="二维码"
        prop="psy_ewm"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-image lazy :src="row.psy_ewm" />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="咨询费用"
        prop="cost_range"
        show-overflow-tooltip
      />
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { getStudioList } from '@/api/platformChannelManagement'
  import { getConsultList } from '@/api/userManagementOrganization'

  export default {
    name: 'List',
    components: {},
    data() {
      return {
        list: [],
        studioList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          sid: '',
          name: '',
          phone: '',
        },
        button: {},
      }
    },
    created() {
      this.getStudio()
      this.fetchData()
    },
    mounted() {},
    methods: {
      async getStudio() {
        const { data } = await getStudioList()
        this.studioList = data
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getConsultList(
          this.queryForm
        )
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
    },
  }
</script>
