<template>
  <div id="rightChart1">
    <vab-chart
      id="line3"
      ref="line3"
      :height="150"
      :options="line"
      width="100%"
    />
  </div>
</template>

<script>
  import VabChart from '@/extra/VabChart/index.vue'
  export default {
    components: {
      VabChart,
    },
    data() {
      return {
        cdata: {
          indicatorData: [
            { name: '学习焦虑', max: 300 },
            { name: '对人焦虑', max: 250 },
            { name: '孤独倾向', max: 300 },
            { name: '自责倾向', max: 5 },
            { name: '过敏倾向', max: 200 },
            { name: '身体状况', max: 100 },
            { name: '恐怖倾向', max: 100 },
            { name: '冲动倾向', max: 100 },
          ],
          dataBJ: [
            {
              value: [94, 69, 114, 2.08, 73, 39, 22, 39, 22],
              name: 'Data A',
              areaStyle: {
                color: 'rgba(255, 228, 52, 0.6)',
              },
            },
            {
              value: [42, 27, 43, 1, 53, 22, 25, 39, 22],
              name: 'Data B',
              areaStyle: {
                color: 'rgba(97,80,250,0.6)',
              },
            },
            {
              value: [31, 12, 30, 0.5, 32, 16, 24, 39, 22],
              name: 'Data C',
              areaStyle: {
                color: 'rgba(156,252,180,0.6)',
              },
            },
            {
              value: [154, 117, 157, 3.05, 92, 58, 26, 39, 22],
              name: 'Data D',
              areaStyle: {
                color: 'rgba(248,168,148,0.6)',
              },
            },
          ],
        },
        line: {},
        timer: null,
      }
    },
    mounted() {
      this.$nextTick(() => {
        let option = this.line
        setInterval(() => {
          if (option.dataZoom[0].endValue == option.xAxis.data.length) {
            option.dataZoom[0].endValue = 15
            option.dataZoom[0].startValue = 0
          } else {
            option.dataZoom[0].endValue = option.dataZoom[0].endValue + 1
            option.dataZoom[0].startValue = option.dataZoom[0].startValue + 1
          }
        }, 2000)
      })
    },
    created() {
      this.getLine()
      /*this.$nextTick(() => {
        let app = {
          currentIndex: -1,
        }
        let that = this
        if (this.lineChartInterval) {
          window.clearInterval(that.lineChartInterval)
        }
        let dataLen = this.line.series[0].data.length
        let lc = this.$refs.line3.chart
        this.lineChartInterval = setInterval(function () {
          // 取消之前高亮的图形
          lc.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: app.currentIndex,
          })
          app.currentIndex = (app.currentIndex + 1) % dataLen
          //console.log(app.currentIndex);
          // 高亮当前图形
          lc.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: app.currentIndex,
          })
          // 显示 tooltip
          lc.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: app.currentIndex,
          })
        }, 2000)
      })*/
    },
    methods: {
      getLine() {
        this.line = {
          title: {
            text: '学校数据统计',
            textStyle: {
              fontSize: 12,
              color: '#eee',
            },
          },
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            data: ['注册人数', '档案数'],
            top: 0,
            textStyle: {
              color: '#999',
            },
          },
          grid: {
            top: 30,
            bottom: 20,
            right: 20,
            left: 20,
          },
          dataZoom: [
            //滑动条
            {
              xAxisIndex: 0, //这里是从X轴的0刻度开始
              show: false, //是否显示滑动条，不影响使用
              type: 'slider', // 这个 dataZoom 组件是 slider 型 dataZoom 组件
              startValue: 0, // 开始位置。
              endValue: 15, // 一次性展示几个。（如果startValue设定为0，那么页面展示数就是endValue+1，比如此例页面展示2个）
            },
          ],
          xAxis: {
            type: 'category',
            data: [
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
            ],
            axisPointer: {
              type: 'shadow',
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          },
          yAxis: {
            type: 'value',
            show: false,
            splitLine: { lineStyle: { color: '#666' } },
          },
          series: [
            {
              name: '注册人数',
              type: 'bar',
              data: [
                120, 132, 23, 545, 23, 546, 32, 674, 21, 673, 546, 32, 674, 21,
                653,
              ],
              label: {
                show: true,
                position: 'top',
                color: '#fff',
                formatter: '{c}人',
              },
            },
            {
              name: '档案数',
              type: 'bar',
              data: [
                220, 182, 65, 13, 876, 546, 32, 674, 21, 653, 23, 32, 674, 21,
                653,
              ],
              label: {
                show: true,
                position: 'top',
                color: '#fff',
                formatter: '{c}人',
              },
            },
          ],
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  #rightChart1 {
    height: 100%;
    padding: 0.3rem;
  }
</style>
