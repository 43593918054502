var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "setCrisis-container" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "预警级别", name: "tab1" } },
            [_vm.activeName === "tab1" ? _c("warn") : _vm._e()],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "访谈级别", name: "tab6" } },
            [_vm.activeName === "tab6" ? _c("assess-grade") : _vm._e()],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "干预级别", name: "tab2" } },
            [_vm.activeName === "tab2" ? _c("meddle") : _vm._e()],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "结案状态", name: "tab3" } },
            [_vm.activeName === "tab3" ? _c("status") : _vm._e()],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "预警来源", name: "tab4" } },
            [_vm.activeName === "tab4" ? _c("warn-source") : _vm._e()],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "预警问题", name: "tab5" } },
            [_vm.activeName === "tab5" ? _c("warn-quest") : _vm._e()],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "预警通知", name: "tab7" } },
            [_vm.activeName === "tab7" ? _c("warn-notice") : _vm._e()],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }