<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item v-if="isSid === 'all'" label="渠道" prop="sid">
        <el-select
          v-model="form.sid"
          filterable
          placeholder="渠道名称"
          @change="handCheckStudio"
        >
          <el-option
            v-for="item in studioList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="登录账号" prop="username">
        <el-input v-model="form.username" placeholder="请输入登录账号" />
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          v-model="form.password"
          autocomplete="new-password"
          maxlength="12"
          minlength="6"
          :placeholder="
            title === '编辑'
              ? '密码长度6~12位，不输入则使用原密码'
              : '密码长度6~12位'
          "
          type="password"
        />
      </el-form-item>
      <el-form-item label="确认密码" prop="repass">
        <el-input
          v-model="form.repass"
          placeholder="再次输入密码"
          type="password"
        />
      </el-form-item>
      <el-form-item label="电话" prop="phone">
        <el-input
          v-model="form.phone"
          autocomplete="off"
          maxlength="11"
          placeholder="手机号码"
          type="tel"
        />
      </el-form-item>
      <el-form-item label="所属角色" prop="role">
        <el-select v-model="form.role" filterable placeholder="选择角色">
          <el-option
            v-for="item in roleList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getAllRole, editRole } from '@/api/settingAuthority'
  import { getStudioList } from '@/api/platformChannelManagement'
  import { isPhone } from '@/utils/validate'

  export default {
    name: 'AdminEdit',
    data() {
      const validatePass = (rule, value, callback) => {
        if (value && value.length < 6) {
          callback(new Error('密码长度6~12位'))
        } else {
          callback()
        }
      }
      const userPassWord = (rule, value, callback) => {
        if (value !== this.form.password) {
          callback(new Error('两次输入密码不一致'))
        } else {
          callback()
        }
      }
      const validatePhone = (rule, value, callback) => {
        if (!isPhone(value)) {
          callback(new Error('手机号码格式不正确'))
        } else {
          callback()
        }
      }
      return {
        form: {
          sid: '',
          username: '',
          phone: '',
          role: '',
          password: '',
          repass: '',
          remark: '',
        },
        rules: {
          sid: [{ required: true, trigger: 'change', message: '请选择渠道' }],
          username: [
            { required: true, trigger: 'blur', message: '请输入用户名' },
          ],
          phone: [
            { required: true, trigger: 'blur', message: '请输入手机号' },
            { required: true, validator: validatePhone, trigger: 'blur' },
          ],
          role: [{ required: true, trigger: 'change', message: '请选择角色' }],
          password: [
            { required: false, trigger: 'blur', message: '请输入密码' },
            { required: false, trigger: 'blur', validator: validatePass },
          ],
          repass: [
            { required: false, trigger: 'blur', message: '请再次输入密码' },
            { required: false, validator: userPassWord, trigger: 'blur' },
          ],
        },
        title: '',
        dialogFormVisible: false,
        studioList: [], //渠道列表
        roleList: [],
        isSid: '',
      }
    },
    created() {
      this.studioData()
    },
    methods: {
      showEdit(row, isSid) {
        this.isSid = isSid
        if (!row) {
          this.title = '添加'
          this.rules.password[0].required = true
          this.rules.password[1].required = true
          this.rules.repass[0].required = true
          this.rules.repass[1].required = true
          if (isSid !== 'all') this.getRoleList()
        } else {
          this.title = '编辑'
          this.rules.password[0].required = false
          this.rules.password[1].required = false
          this.rules.repass[0].required = false
          this.rules.repass[1].required = false
          this.getRoleInfo(row.id, 'info')
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.roleList = []
        this.dialogFormVisible = false
      },
      handCheckStudio(val) {
        this.form.role = ''
        this.getRoleList(val)
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.form.type = this.form.id ? 'edit' : 'add'
            const { msg, code } = await editRole(this.form)
            if (code === 200) {
              this.$baseMessage(msg, 'success')
              this.$emit('fetch-data')
              this.close()
            } else {
              this.$baseMessage(msg, 'error')
            }
          } else {
            return false
          }
        })
      },
      async studioData() {
        const { data } = await getStudioList()
        this.studioList = data
      },
      getRoleList(sid) {
        getAllRole(this.isSid === 'all' ? { sid: sid } : '').then((res) => {
          this.roleList = res.data
        })
      },
      async getRoleInfo(id, type) {
        const { data } = await editRole({ id, type })
        this.form = data
        this.getRoleList(data.sid)
      },
    },
  }
</script>
