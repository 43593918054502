<template>
  <div class="vab-nav">
    <el-row :gutter="15">
      <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="4">
        <div class="left-panel">
          <vab-fold />
          <el-tabs
            v-if="layout === 'comprehensive'"
            v-model="extra.first"
            tab-position="top"
            @tab-click="handleTabClick"
          >
            <el-tab-pane
              v-for="item in handleRoutes"
              :key="item.name"
              :name="item.name"
            >
              <template #label>
                <vab-icon
                  v-if="item.meta.icon"
                  :icon="item.meta.icon"
                  :is-custom-svg="item.meta.isCustomSvg"
                  style="min-width: 16px"
                />
                {{ translateTitle(item.meta.title) }}
              </template>
            </el-tab-pane>
          </el-tabs>
          <vab-breadcrumb v-else class="hidden-xs-only" />
        </div>
      </el-col>
      <el-col :lg="12" :md="12" :sm="12" :xl="12" :xs="20">
        <div class="right-panel">
          <template v-if="!isNJ">
            <div class="item" @click="gotoNotice">
              <el-badge class="item" :hidden="notice !== 0" :value="notice">
                <vab-icon
                  class="i_c"
                  icon="notification-line"
                  title="消息通知"
                />
                消息通知
              </el-badge>
            </div>
            <div v-if="hiddenSupport == 0" class="item" @click="help">
              <vab-icon class="i_c" icon="question-line" title="帮助中心" />
              帮助中心
            </div>
          </template>
          <vab-avatar />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { translateTitle } from '@/utils/i18n'
  import { mapGetters } from 'vuex'
  import { openFirstMenu } from '@/config'
  import { getNoticeNum } from '@/api/idx'
  import { isNJ } from '@/utils/utils'

  export default {
    name: 'VabNav',
    props: {
      layout: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        isNJ: isNJ(),
        firstMenu: '',
        noticeNum: 0,
        userActive: false,
        counselorActive: false,
        link: null,
      }
    },
    computed: {
      ...mapGetters({
        extra: 'settings/extra',
        routes: 'routes/routes',
        notice: 'user/notice',
        hiddenSupport: 'settings/hiddenSupport',
      }),
      handleRoutes() {
        return this.routes.filter((item) => item.hidden !== true && item.meta)
      },
      handlePartialRoutes() {
        const activeMenu = this.routes.find((_) => _.name === this.extra.first)
        return activeMenu ? activeMenu.children : []
      },
    },
    watch: {
      $route: {
        handler(route) {
          const firstMenu = route.matched[0].name
          if (this.extra.first !== firstMenu) {
            this.extra.first = firstMenu
            this.handleTabClick(true)
          }
        },
        immediate: true,
      },
    },
    created() {
      // this.getNoticeNum()
    },
    methods: {
      translateTitle,
      handleTabClick(handler) {
        if (handler !== true && openFirstMenu)
          this.$router.push(this.handlePartialRoutes[0].path)
      },
      help() {
        window.open('https://docs.qq.com/doc/DTEVwa2VYdEl3d2ZK', '_blank')
      },
      gotoNotice() {
        this.$router.push({
          path: '/noticeList',
        })
      },
      async getNoticeNum() {
        const { num } = await getNoticeNum()
        this.noticeNum = Number(num)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .vab-nav {
    position: relative;
    height: $base-nav-height;
    padding-right: $base-padding;
    padding-left: $base-padding;
    overflow: hidden;
    user-select: none;
    background: $base-color-white;
    box-shadow: $base-box-shadow;

    .left-panel {
      display: flex;
      align-items: center;
      justify-items: center;
      height: $base-nav-height;

      ::v-deep {
        .vab-breadcrumb {
          margin-left: $base-margin;
        }

        .el-tabs {
          margin-left: $base-margin;

          .el-tabs__header {
            margin: 0;
          }

          .el-tabs__item {
            > div {
              display: flex;
              align-items: center;

              i {
                margin-right: 3px;
              }
            }
          }
        }

        .el-tabs__nav-wrap::after {
          display: none;
        }
      }
    }

    .right-panel {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: flex-end;
      height: $base-nav-height;
      ::v-deep {
        [class*='ri-'] {
          margin-left: $base-margin;
          color: $base-color-gray;
          cursor: pointer;
        }

        button {
          [class*='ri-'] {
            margin-left: 0;
            color: $base-color-white;
            cursor: pointer;
          }
        }
      }
      .item {
        cursor: pointer;
        color: #999;
        .i_c {
          color: #999;
        }
        &:hover {
          color: #333;
          .i_c {
            color: #333;
          }
        }
      }
    }
  }
</style>
