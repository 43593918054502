import request from '@/utils/request'

//获取组织结构列表
export function getOriginList(params) {
  return request({
    url: '/user/origin-list',
    method: 'get',
    params,
  })
}

//获取用户列表
export function getUserList(params) {
  return request({
    url: '/user/member-list',
    method: 'get',
    params,
  })
}

//高级筛选获取用户列表
export function getHighUserList(data) {
  return request({
    url: '/user/sup-member-list',
    method: 'post',
    data,
  })
}

//获取咨询师列表
export function getConsultList(params) {
  return request({
    url: '/psy/consult-list',
    method: 'get',
    params,
  })
}

//获取组织结构分类
export function getOrginType(params) {
  return request({
    url: '/router/get-origin-type',
    method: 'get',
    params,
  })
}

//获取组织结构模版
export function getOrginDoc(params) {
  return request({
    url: '/router/get-doc',
    method: 'get',
    params,
  })
}

//获取用户档案
export function memberRecord(params) {
  return request({
    url: '/user/member-record',
    method: 'get',
    params,
  })
}

//批量删除用户
export function delUserMore(data) {
  return request({
    url: '/user/real-member-delete',
    method: 'post',
    data,
  })
}

//获取当前渠道的组织结构
export function getCurStudioOrgin(params) {
  return request({
    url: '/router/get-origin-list',
    method: 'get',
    params,
  })
}

//上传组织结构
export function doUpload(data) {
  return request({
    timeout: 600000,
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/user/origin-upload',
    method: 'post',
    data,
  })
}

//导入用户
export function checkUploadUser(data) {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/user/check-upload',
    method: 'post',
    timeout: 900000,
    data,
  })
}
export function doUploadUser(data) {
  return request({
    timeout: 900000,
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/user/student-upload',
    method: 'post',
    data,
  })
}

//团测导入手机号
export function checkUploadPhone(data) {
  return request({
    timeout: 900000,
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/user/check-phone-list',
    method: 'post',
    data,
  })
}
export function doUploadPhone(data) {
  return request({
    timeout: 900000,
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/user/phone-list-upload',
    method: 'post',
    data,
  })
}

//批量更新导入用户组织结构
export function checkUploadUserUpdate(data) {
  return request({
    timeout: 900000,
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/user/check-renewal',
    method: 'post',
    data,
  })
}
export function doUploadUserUpdate(data) {
  return request({
    timeout: 900000,
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/user/student-renewal',
    method: 'post',
    data,
  })
}

//编辑组织结构
export function doEdit(data) {
  return request({
    url: '/user/origin-edit',
    method: 'post',
    data,
  })
}

//创建用户
export function addUser(data) {
  return request({
    url: '/user/member-add',
    method: 'post',
    data,
  })
}

//编辑用户
export function editUser(data) {
  return request({
    url: '/user/member-edit',
    method: 'post',
    data,
  })
}

//删除用户
export function delUser(data) {
  return request({
    url: '/user/member-delete',
    method: 'post',
    data,
  })
}

//新增下级
export function addSubOrigin(data) {
  return request({
    url: '/user/origin-add',
    method: 'post',
    data,
  })
}

export function doDelete(data) {
  return request({
    url: '/user/origin-delete',
    method: 'post',
    data,
  })
}

//获取用户心理测评曲线图数据
export function getUserCh(params) {
  return request({
    url: '/home/mental-curve',
    method: 'get',
    params,
  })
}
