<template>
  <div>
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      title="图片库"
      :visible.sync="dialogGalleryVisible"
      width="860px"
      @close="close(0)"
    >
      <el-row class="gallery-box" :gutter="15">
        <el-col class="gallery-left" :span="4">
          <ul class="type-list">
            <li
              v-for="(item, index) in imgTypeList"
              :key="index"
              :class="curType === item.id ? 'active' : ''"
              @click="changeType(item)"
            >
              <span
                class="el-icon-edit"
                @click.stop="showEditType('edit', item)"
              ></span>
              <div class="type-name">{{ item.name }}</div>
              <span class="el-icon-delete" @click.stop="saveType(item)"></span>
            </li>
          </ul>
          <el-button
            style="margin-left: 27px"
            type="text"
            @click="showEditType('add')"
          >
            添加分类
          </el-button>
        </el-col>
        <el-col class="gallery-right" :span="20">
          <div class="right-top">
            <el-button icon="el-icon-delete" type="danger" @click="delImg">
              删除
            </el-button>
            <el-upload
              ref="upload"
              accept="image/png, image/jpeg"
              :action="UploadUrl()"
              :http-request="uploadFiles"
              :on-change="fileChange"
              :on-success="handleImgSuccess"
              :show-file-list="false"
              style="float: right"
            >
              <el-button icon="el-icon-plus">上传图片</el-button>
            </el-upload>
          </div>
          <ul v-loading="listLoading" class="img-list">
            <li
              v-for="(item, index) in list"
              :key="index"
              :class="item.checked ? 'cur' : ''"
              @click="liChecked(item)"
            >
              <i v-if="item.checked" class="el-icon-check"></i>
              <el-image lazy :src="item.url" />
            </li>
            <div v-if="!total" class="no-data">
              <i class="el-icon-picture"></i>
              <div>该分类下暂无图片</div>
            </div>
          </ul>
          <el-pagination
            :current-page="queryForm.pageNo"
            layout="prev, pager, next"
            :page-size="queryForm.pageSize"
            small
            :total="total"
            @current-change="handleCurrentChange"
          />
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close(0)">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      append-to-body
      :close-on-click-modal="false"
      title="编辑图片分类"
      :visible.sync="showType"
      width="360px"
      @close="close(1)"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        style="margin: 20px 13px"
      >
        <el-form-item label="" prop="name">
          <el-input v-model="form.name" placeholder="请输入分类名称" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close(1)">取 消</el-button>
        <el-button type="primary" @click="saveType()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getImgList,
    getImgType,
    upload,
    changeImg,
    editType,
  } from '@/api/img'

  export default {
    name: 'Gallery',
    data() {
      return {
        dialogGalleryVisible: false,
        showType: false,
        list: [], //图片列表
        imgTypeList: [], //分类列表
        total: 0,
        imgs: [], //上传的图片
        queryForm: {
          pageNo: 1,
          pageSize: 8,
          type: 'all', //图片分类
        },
        curType: 0,
        listLoading: true,
        form: {
          name: '',
        },
        rules: {
          name: [
            { required: true, trigger: 'blur', message: '请输入分类名称' },
          ],
        },
      }
    },
    created() {
      this.fetchData()
      this.getImgType()
    },
    methods: {
      showEdit() {
        this.fetchData()
        this.dialogGalleryVisible = true
      },
      close(type) {
        if (type === 0) {
          this.queryForm = {
            pageNo: 1,
            pageSize: 8,
            type: 'all',
          }
          this.dialogGalleryVisible = false
        } else {
          this.$refs['form'].resetFields()
          this.showType = false
        }
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      save() {
        let imgList = this.list.filter((item) => item.checked === true)
        if (!imgList.length) {
          this.$message.error('请先选择图片')
        } else {
          if (imgList.length > 1) {
            this.$message.error('只能选择一张图片哦~')
          } else {
            this.$emit('checkedImg', imgList[0])
            this.close(0)
          }
        }
      },
      saveType(item) {
        //编辑分类
        let params = this.form
        if (item) {
          params.type = 'del'
          params.id = item.id
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            this.saveTypeMe(params)
          })
        } else {
          this.$refs['form'].validate(async (valid) => {
            if (valid) {
              this.saveTypeMe(params)
            }
          })
        }
      },
      saveTypeMe(data) {
        console.log(data)
        editType(data).then((res) => {
          if (res.code === 200) {
            this.$baseMessage(res.msg, 'success')
            this.getImgType()
            this.close(1)
          }
        })
      },
      showEditType(type, item) {
        if (type === 'edit') {
          this.form.name = item.name
          this.form.type = 'edit'
          this.form.id = item.id
        } else {
          this.form.type = 'add'
        }
        this.showType = true
      },
      async getImgType() {
        const { data } = await getImgType()
        this.imgTypeList = data
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount } = await getImgList(this.queryForm)
        this.list = data.map((item) => {
          return Object.assign({}, item, {
            checked: false,
          })
        })
        this.total = totalCount
        this.listLoading = false
      },
      liChecked(item) {
        //选择图片
        item.checked = !item.checked
      },
      changeType(item) {
        //切换分类
        this.curType = item.id
        this.queryForm.type = item.id === 0 ? 'all' : item.id
        this.list = []
        this.fetchData()
      },
      delImg() {
        //删除图片
        let imgList = this.list.filter((item) => item.checked === true)
        if (!imgList.length) {
          this.$message.error('请先选择图片')
        } else {
          let arr = []
          imgList.map((item) => {
            arr.push(item.name)
          })
          let data = {
            type: 'del',
            name: arr,
          }
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            changeImg(data).then((res) => {
              if (res.code === 200) {
                this.fetchData()
                this.$baseMessage(res.msg, 'success')
              }
            })
          })
        }
      },
      UploadUrl: function () {
        return ''
      },
      handleImgSuccess(file) {
        //this.form.img = URL.createObjectURL(file.raw)
      },
      uploadFiles(files) {
        const file = files.file
        const isLt2M = file.size / 1024 / 1024 < 1
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
          this.$message.error('上传图片只能是 JPG、PNG 格式!')
          return false
        }
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 1MB!')
          return false
        }

        let formData = new FormData()
        this.imgs = this.imgs.filter((item) => item.size / 1024 / 1024 < 1)
        formData.append('type', this.queryForm.type)
        formData.append('image_file', this.imgs[0].raw, this.imgs[0].raw.name)
        upload(formData)
          .then((res) => {
            if (res.code === 200) {
              this.$baseMessage(res.msg, 'success')
              this.fetchData()
            }
            this.imgs = []
            this.$refs.upload.clearFiles()
          })
          .catch((err) => {
            this.imgs = []
            this.$refs.upload.clearFiles()
          })
      },
      fileChange(file, fileList) {
        this.imgs = fileList
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-dialog {
      &__body {
        padding: 0 10px !important;
      }
    }
    .el-pagination {
      margin-bottom: 20px !important;
      text-align: right !important;
    }
    .el-image__inner {
      height: 124px;
    }
  }
  .gallery-box {
    ul {
      padding: 0;
      list-style: none;
      li {
        display: flex;
        align-items: center;
      }
    }
    .gallery-left {
      .type-list {
        li {
          line-height: 40px;
          border-radius: 3px;
          padding: 0 10px;
          margin: 5px 0;
          > span {
            visibility: hidden;
          }
          .type-name {
            flex: 1;
            margin: 0 5px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          &:hover {
            color: #26cdb7;
            background-color: #f0fcfa;
            &:not(:first-child) > span {
              visibility: visible;
            }
          }
          &.active {
            color: #26cdb7;
            background-color: #f0fcfa;
          }
        }
      }
    }
    .gallery-right {
      padding-right: 20px !important;
      .right-top {
        padding-left: 10px;
        margin-top: 15px;
      }
      .img-list {
        display: flex;
        flex-wrap: wrap;
        height: 340px;
        overflow-y: auto;
        .no-data {
          width: 100%;
          text-align: center;
          padding: 100px 30px;
          color: #ccc;
          i {
            font-size: 100px;
            margin-bottom: 10px;
          }
        }
        li {
          position: relative;
          width: 146px;
          height: 146px;
          cursor: pointer;
          border-radius: 6px;
          padding: 10px;
          border: 1px solid rgba(0, 0, 0, 0.05);
          margin: 10px;
          transition: All 0.2s ease-in-out;
          overflow: hidden;
          &:hover {
            border-color: #26cdb7;
          }
          &.cur {
            &:after {
              position: absolute;
              content: '';
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              background-color: rgba(0, 0, 0, 0.5);
            }
            i {
              font-size: 50px;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              text-align: center;
              z-index: 10;
              line-height: 146px;
              font-weight: bold;
              color: #fff;
            }
          }
        }
      }
    }
  }
</style>
