<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <div slot="title" style="font-size: 18px">
      {{ title }}
    </div>
    <div class="tips">
      批量更新针对组织结构的变更场景下使用，更新前，请确保组织结构及用户均已存在，更新的用户模板可通过导出功能修改最新的组织结构后上传。更新后用户组织结构变更，用户数据记录均保留。
    </div>
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item v-if="isSid === 'all'" label="渠道" prop="sid">
        <el-select v-model="form.sid" filterable placeholder="选择渠道">
          <el-option
            v-for="item in studioList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="上传文件" prop="file">
        <el-upload
          ref="upload"
          accept=".xls,.xlsx"
          :action="UploadUrl()"
          :before-upload="beforeUploadFile"
          :file-list="form.file"
          :http-request="updFile"
          :limit="1"
          :on-change="fileChange"
          :on-error="handleError"
          :on-exceed="exceedFile"
          :on-remove="handleRemove"
          :on-success="handleSuccess"
        >
          <el-button
            v-show="!hasFile"
            slot="trigger"
            size="small"
            type="primary"
          >
            选取文件
          </el-button>
          <el-button v-show="hasFile" disabled size="small" type="primary">
            选取文件
          </el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传
            <span>xls、xlsx</span>
            文件，且
            <span>不超过2M</span>
          </div>
        </el-upload>
        <div
          v-if="errTxt"
          style="color: red; line-height: initial; margin-top: 10px"
        >
          {{ errTxt }}
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button :disabled="canSave" type="primary" @click="save">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {
    checkUploadUserUpdate,
    doUploadUserUpdate,
  } from '@/api/userManagementOrganization'
  import { mapGetters } from 'vuex'

  export default {
    name: 'MemberUpdate',
    props: {
      list: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        studioList: [],
        form: {
          file: [],
          sid: '',
        },
        rules: {
          sid: [{ required: true, trigger: 'change', message: '请选择渠道' }],
          file: [
            { required: true, message: '请选择上传文件', trigger: 'blur' },
          ],
        },
        title: '',
        dialogFormVisible: false,
        hasFile: false,
        canSave: true,
        errTxt: '',
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    watch: {
      list: {
        handler(newData) {
          if (this.isSid === 'all') this.studioList = newData
        },
        immediate: true,
        deep: true,
      },
    },
    created() {},
    methods: {
      showEdit() {
        this.title = '批量更新'
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
        this.hasFile = false
        this.errTxt = ''
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const isLt2M = this.form.file.every(
              (file) => file.size / 1024 / 1024 < 2
            )
            if (!isLt2M) {
              this.$message.error('上传文件大小不能超过2M!')
            } else {
              let dataPar = this.form
              let formData = new FormData() //  用FormData存放上传文件
              if (this.isSid === 'all') formData.append('sid', dataPar.sid)
              this.form.file.forEach((file) => {
                formData.append('file', file.raw, file.raw.name)
              })

              const { msg, code } = await doUploadUserUpdate(formData)
              if (code === 200) {
                this.$baseMessage(msg, 'success')
                this.$emit('fetch-data')
                this.close()
              } else {
                this.$baseAlert(msg)
              }
            }
          } else {
            return false
          }
        })
      },
      // 文件超出个数限制时的钩子
      exceedFile(files, fileList) {
        this.$baseMessage(
          `只能选择 1 个文件，当前共选择了 ${
            files.length + fileList.length
          } 个`,
          'warning'
        )
      },
      // 文件状态改变时的钩子
      fileChange(file, fileList) {
        let existFile = fileList
          .slice(0, fileList.length - 1)
          .find((f) => f.name === file.name)
        if (existFile) {
          this.$baseMessage('当前文件已经存在!', 'error')
          fileList.pop()
          return false
        }
        if (fileList.length === 1) {
          this.hasFile = true
        }
        this.form.file = fileList
      },
      // 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
      beforeUploadFile(file) {
        let extension = file.name.substring(file.name.lastIndexOf('.') + 1)
        let size = file.size / 1024 / 1024
        if (extension !== 'xls' && extension !== 'xlsx') {
          this.$baseMessage('只能上传后缀是.xls、.xlsx的文件', 'warning')
        }
        if (size > 2) {
          this.$baseMessage('文件大小不得超过2M', 'warning')
          this.handleRemove(file, this.form.file)
          this.hasFile = false
          return false
        } else {
          this.canSave = false
        }
      },
      updFile() {
        let dataPar = this.form
        let formData = new FormData() //  用FormData存放上传文件
        if (this.isSid === 'all') formData.append('sid', dataPar.sid)
        this.form.file.forEach((file) => {
          formData.append('file', file.raw, file.raw.name)
        })

        checkUploadUserUpdate(formData)
          .then((res) => {
            if (res.code === 200) {
              this.$baseMessage(res.msg, 'success')
              this.errTxt = ''
            }
          })
          .catch((err) => {
            this.handleRemove(this.form.file[0], this.form.file)
            this.errTxt = err
            this.hasFile = false
            this.canSave = true
          })
      },
      handleRemove(file, fileList) {
        if (!fileList.length) {
          this.hasFile = false
        }
        this.form.file = []
        this.errTxt = ''
      },
      // 文件上传成功时的钩子
      handleSuccess(res, file, fileList) {
        this.$baseMessage('校验成功', 'success')
      },
      // 文件上传失败时的钩子
      handleError(err, file, fileList) {
        console.log(err)
        this.$baseMessage('文件校验失败', 'error')
      },
      UploadUrl: function () {
        // 因为action参数是必填项，我们使用二次确认进行文件上传时，直接填上传文件的url会因为没有参数导致api报404，所以这里将action设置为一个返回为空的方法就行，避免抛错
        return ''
      },
    },
  }
</script>
<style lang="scss" scoped>
  .el-upload__tip {
    display: inline;
    margin-left: 10px;
    > span {
      color: $base-color-red;
      font-weight: bold;
    }
  }
  .tips {
    font-size: 12px;
    background: #eee;
    color: rgb(230, 78, 31);
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 3px;
    line-height: 1.5;
  }
</style>
