var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.dialogFormVisible,
            width: "650px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "120px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _vm.isSid === "all"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "渠道", prop: "sid" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", placeholder: "选择渠道" },
                          on: { change: _vm.bindChange },
                          model: {
                            value: _vm.form.sid,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "sid", $$v)
                            },
                            expression: "form.sid",
                          },
                        },
                        _vm._l(_vm.studioList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入咨询师姓名" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "资质", prop: "credent_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择资质",
                      },
                      model: {
                        value: _vm.form.credent_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "credent_id", $$v)
                        },
                        expression: "form.credent_id",
                      },
                    },
                    _vm._l(_vm.credentList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "头像", prop: "avatar" } }, [
                _c(
                  "div",
                  { staticClass: "img-uploader" },
                  [
                    _c(
                      "el-upload",
                      {
                        attrs: {
                          accept: "image/png, image/jpeg",
                          action: _vm.UploadUrl(),
                          "auto-upload": false,
                          "on-change": _vm.fileChange,
                          "on-remove": _vm.handleRemove,
                          "show-file-list": false,
                        },
                      },
                      [
                        _vm.form.avatar
                          ? _c("img", {
                              staticClass: "img",
                              attrs: { src: _vm.form.avatar },
                            })
                          : _c("i", { staticClass: "el-icon-plus" }),
                      ]
                    ),
                    false
                      ? _c(
                          "div",
                          {
                            staticClass: "el-upload el-upload__text",
                            on: { click: _vm.imgDialog },
                          },
                          [
                            _vm.form.avatar
                              ? _c("img", {
                                  staticClass: "img",
                                  attrs: { src: _vm.form.avatar },
                                })
                              : _c("i", { staticClass: "el-icon-plus" }),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "el-upload__tip",
                        attrs: { slot: "tip" },
                        slot: "tip",
                      },
                      [
                        _c("div", [
                          _vm._v(" 只能上传 "),
                          _c("span", [_vm._v(".jpg、.png")]),
                          _vm._v(" 文件，且 "),
                          _c("span", [_vm._v("不超过1M")]),
                        ]),
                        _c("div", [_vm._v("(图片尺寸1:1)")]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "一级组织结构", prop: "orgid" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择一级组织结构",
                      },
                      model: {
                        value: _vm.form.orgid,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "orgid", $$v)
                        },
                        expression: "form.orgid",
                      },
                    },
                    _vm._l(_vm.schoolList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sort" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "数字越大越靠前" },
                    model: {
                      value: _vm.form.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort", $$v)
                      },
                      expression: "form.sort",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "个人简介", prop: "intro" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "800",
                      placeholder:
                        "请输入个人简介，如需换行，请用中文的分号“；”分隔",
                      rows: 3,
                      "show-word-limit": "",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.form.intro,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "intro", $$v)
                      },
                      expression: "form.intro",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "受训背景", prop: "background" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "800",
                      placeholder:
                        "请输入受训背景，如需换行，请用中文的分号“；”分隔",
                      rows: 3,
                      "show-word-limit": "",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.form.background,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "background", $$v)
                      },
                      expression: "form.background",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      false
        ? _c("vab-cropper", {
            ref: "vabCropper",
            attrs: {
              "append-to-body": "",
              imgurl:
                _vm.form.avatar ||
                require("@/assets/login_images/login_img.png"),
            },
            on: { checkedImg: _vm.getImg },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }