<template>
  <div class="meddle-grade">
    <div class="top">
      <el-button icon="el-icon-refresh" type="primary" @click="fetchData">
        刷新
      </el-button>
      <el-button type="primary" @click="handleEdit">新增</el-button>
    </div>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column align="center" label="访谈级别" prop="name" />
      <el-table-column align="center" label="标记色" prop="color">
        <template #default="{ row }">
          <div
            v-if="row.color"
            class="color"
            :style="'background-color:' + row.color"
          ></div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="备注" prop="remark" />
      <el-table-column align="center" label="设置时间" prop="created_at" />
      <el-table-column align="center" label="操作" width="120">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button
            v-if="row.status == 1"
            type="text"
            @click="handleStatus(row)"
          >
            禁用
          </el-button>
          <el-button v-else type="text" @click="handleStatus(row)">
            启用
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="500px"
      @close="close"
    >
      <el-form ref="form" label-width="80px" :model="form" :rules="rules">
        <div class="form-row">
          <el-form-item label="访谈级别" prop="name">
            <el-input v-model="form.name" placeholder="请输入访谈级别" />
          </el-form-item>
          <el-form-item label="标记色" prop="color">
            <el-input v-model="form.color" style="width: 80px" type="color" />
            <span
              v-if="form.color"
              class="tag-color"
              :style="{
                'background-color': hexToRgba(form.color, 0.1),
                color: form.color,
                border: '1px solid ' + hexToRgba(form.color, 0.3),
              }"
            >
              标记色示例
            </span>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="form.remark"
              placeholder="请输入备注"
              type="textarea"
            />
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getWarnParam, setWarnParam, setWarnParamStatus } from '@/api/crisis'
  import { hexToRgba } from '@/utils/utils'

  export default {
    name: 'AssessGrade',
    data() {
      return {
        title: '',
        list: [],
        listLoading: true,
        form: {
          name: '',
          remark: '',
          type: '',
          color: '',
        },
        rules: {
          name: [
            { required: true, message: '请输入访谈级别', trigger: 'blur' },
          ],
        },
        dialogFormVisible: false,
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      hexToRgba,
      handleEdit(row) {
        this.dialogFormVisible = true
        if (row.sort) {
          this.title = '编辑'
          this.form = JSON.parse(JSON.stringify(row))
          this.form.type = 'edit'
        } else {
          this.title = '新增'
          this.form = {
            name: '',
            remark: '',
            type: 'add',
            color: '',
          }
        }
      },
      handleDelete(row) {
        this.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg, code } = await setWarnParam({
            type: 'delete',
            style: 'assessLevel',
            id: row.id,
          })
          if (code === 200) {
            this.$baseMessage(msg, 'success')
            await this.fetchData()
          } else {
            this.$baseMessage(msg, 'error')
          }
        })
      },
      async handleStatus(row) {
        if (row.status == 1) {
          this.$baseConfirm(
            '禁用后将无法使用该配置项，是否确认',
            null,
            async () => {
              const { msg, code } = await setWarnParamStatus({
                id: row.id,
                style: 'assessLevel',
              })
              if (code === 200) {
                this.$baseMessage(msg, 'success')
                await this.fetchData()
              } else {
                this.$baseMessage(msg, 'error')
              }
            }
          )
        } else {
          const { msg, code } = await setWarnParamStatus({
            id: row.id,
            style: 'assessLevel',
          })
          if (code === 200) {
            this.$baseMessage(msg, 'success')
            await this.fetchData()
          } else {
            this.$baseMessage(msg, 'error')
          }
        }
      },
      async fetchData() {
        this.listLoading = true
        const { data } = await getWarnParam({ type: 'assess' })
        this.list = data
        this.listLoading = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.form.style = 'assessLevel'
            const { msg } = await setWarnParam(this.form)
            this.$baseMessage(msg, 'success')
            await this.fetchData()
            this.close()
          } else {
            return false
          }
        })
      },
      close() {
        this.dialogFormVisible = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .meddle-grade {
    .top {
      margin-bottom: 15px;
      text-align: right;
    }
  }
</style>
