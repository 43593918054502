var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "views-data-container" },
    [
      _c(
        "dv-full-screen-container",
        { staticClass: "bg" },
        [
          _vm.loading
            ? _c("dv-loading", [_vm._v("Loading...")])
            : _c("div", { staticClass: "host-body" }, [
                _c(
                  "div",
                  { staticClass: "d-flex jc-center" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex flex-column",
                        staticStyle: { width: "23.3%" },
                      },
                      [
                        _c("dv-decoration-10", {
                          staticStyle: { height: "0.0825rem" },
                        }),
                        _c("div", { staticClass: "dates" }, [
                          _vm._v(_vm._s(_vm.date)),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex jc-center" },
                      [
                        _c("dv-decoration-8", {
                          staticStyle: { width: "2.5rem", height: "0.625rem" },
                          attrs: { color: ["#568aea", "#000000"] },
                        }),
                        _c(
                          "div",
                          { staticClass: "title" },
                          [
                            _c("span", { staticClass: "title-text" }, [
                              _vm._v(
                                " 南阳市宛城区心理健康综合服务平台大数据中心 "
                              ),
                            ]),
                            _c("dv-decoration-6", {
                              staticClass: "title-bototm",
                              staticStyle: {
                                width: "3.125rem",
                                height: "0.2rem",
                              },
                              attrs: { color: ["#50e3c2", "#67a1e5"] },
                            }),
                          ],
                          1
                        ),
                        _c("dv-decoration-8", {
                          staticStyle: { width: "2.5rem", height: "0.625rem" },
                          attrs: {
                            color: ["#568aea", "#000000"],
                            reverse: true,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("dv-decoration-10", {
                      staticStyle: {
                        width: "23.3%",
                        height: "0.0825rem",
                        transform: "rotateY(180deg)",
                      },
                    }),
                    _c("div", { staticClass: "opration" }, [
                      _c(
                        "div",
                        { staticClass: "item", attrs: { title: "返回首页" } },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/" } },
                            [
                              _c("vab-icon", {
                                attrs: { icon: "home-4-line" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "item",
                          attrs: { title: "全屏/退出全屏" },
                        },
                        [
                          _c("vab-full-screen", {
                            staticStyle: { cursor: "pointer" },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "d-flex data-box" }, [
                  _c("div", { staticClass: "left" }, [
                    _c(
                      "div",
                      { staticClass: "left-item" },
                      [
                        _c(
                          "dv-border-box-11",
                          { attrs: { title: "建档数据" } },
                          [_c("left1", { attrs: { cdata: _vm.cdata } })],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "left-item" },
                      [
                        _c(
                          "dv-border-box-11",
                          { attrs: { title: "测评数据" } },
                          [_c("left2", { attrs: { cdata: _vm.cdata } })],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "center" }, [_c("center")], 1),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("dv-border-box-12", [_c("right1")], 1),
                      false
                        ? _c(
                            "dv-border-box-10",
                            { staticClass: "left-item" },
                            [_c("right2")],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }