var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info" },
    [
      _vm.evalList && _vm.evalList.length
        ? _c(
            "el-timeline",
            _vm._l(_vm.evalList, function (activity, index) {
              return _c(
                "el-timeline-item",
                {
                  key: index,
                  attrs: {
                    color: activity.color === "green" ? "#1cc512" : "#f62b24",
                    timestamp: activity.time,
                  },
                },
                [
                  _c("div", { staticClass: "block", class: activity.color }, [
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(activity.quest)),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "btn",
                        on: {
                          click: function ($event) {
                            return _vm.toView(activity.id)
                          },
                        },
                      },
                      [_vm._v("查看报告")]
                    ),
                  ]),
                ]
              )
            }),
            1
          )
        : _c("no-data", { attrs: { txt: "暂无记录" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }