<template>
  <div class="add-form-container">
    <div class="add-title">{{ form.id ? '编辑管理员' : '新增管理员' }}</div>
    <el-row :gutter="15">
      <el-col
        :lg="{ span: 12, offset: 6 }"
        :md="{ span: 20, offset: 2 }"
        :sm="{ span: 20, offset: 2 }"
        :xl="{ span: 12, offset: 6 }"
        :xs="24"
      >
        <el-form ref="form" label-width="80px" :model="form" :rules="rules">
          <el-form-item v-if="isSid === 'all'" label="渠道" prop="sid">
            <el-select
              v-model="form.sid"
              filterable
              placeholder="渠道名称"
              @change="handCheckStudio"
            >
              <el-option
                v-for="item in studioList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-if="dataTree.length" label="组织结构" prop="origin">
            <el-input v-model="filterTree" placeholder="输入关键字进行过滤" />
            <el-tree
              ref="ozTree"
              :data="dataTree"
              :filter-node-method="filterTreeNode"
              node-key="id"
              :props="defaultProps2"
              show-checkbox
              @check="treeChecked('ozTree')"
            />
          </el-form-item>
          <el-form-item label="所属角色" prop="role">
            <el-select v-model="form.role" filterable placeholder="选择角色">
              <el-option
                v-for="item in roleList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="登录账号" prop="username">
            <el-input v-model="form.username" placeholder="请输入登录账号" />
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              v-model="form.password"
              autocomplete="new-password"
              maxlength="12"
              minlength="6"
              :placeholder="
                form.id
                  ? '密码长度6~12位，不输入则使用原密码'
                  : '密码长度6~12位'
              "
              type="password"
            />
          </el-form-item>
          <el-form-item label="确认密码" prop="repass">
            <el-input
              v-model="form.repass"
              placeholder="再次输入密码"
              type="password"
            />
          </el-form-item>
          <el-form-item label="电话" prop="phone">
            <el-input
              v-model="form.phone"
              autocomplete="off"
              maxlength="11"
              placeholder="手机号码"
              type="tel"
            />
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="form.remark" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('form')">
              {{ form.id ? '保存修改' : '立即创建' }}
            </el-button>
            <el-button v-if="!form.id" @click="resetForm('form')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { getCurStudioOrgin } from '@/api/userManagementOrganization'
  import { handleActivePath } from '@/utils/routes'
  import { getStudioList } from '@/api/platformChannelManagement'
  import { getAllRole, editRole } from '@/api/settingAuthority'
  import { isPhone } from '@/utils/validate'
  import { isNJ } from '@/utils/utils'

  export default {
    name: 'AddAdmin',
    inject: ['reload'],
    data() {
      const validatePass = (rule, value, callback) => {
        if (value && value.length < 6) {
          callback(new Error('密码长度6~12位'))
        } else {
          callback()
        }
      }
      const userPassWord = (rule, value, callback) => {
        if (value !== this.form.password) {
          callback(new Error('两次输入密码不一致'))
        } else {
          callback()
        }
      }
      const validatePhone = (rule, value, callback) => {
        if (value && !isPhone(value)) {
          callback(new Error('手机号码格式不正确'))
        } else {
          callback()
        }
      }
      return {
        roleList: [], //角色列表
        dataTree: [], //组织结构列表
        studioList: [], //渠道列表
        labelPosition: 'right',
        form: {
          sid: '',
          username: '',
          phone: '',
          role: '',
          password: '',
          repass: '',
          remark: '',
          origin: [],
        },
        rules: {
          sid: [{ required: true, trigger: 'change', message: '请选择渠道' }],
          username: [
            { required: true, trigger: 'blur', message: '请输入用户名' },
          ],
          phone: [
            {
              required: !isNJ() ? true : false,
              trigger: 'blur',
              message: '请输入手机号',
            },
            {
              required: !isNJ() ? true : false,
              validator: validatePhone,
              trigger: 'blur',
            },
          ],
          role: [{ required: true, trigger: 'change', message: '请选择角色' }],
          password: [
            { required: false, trigger: 'blur', message: '请输入密码' },
            { required: false, trigger: 'blur', validator: validatePass },
          ],
          repass: [
            { required: false, trigger: 'blur', message: '请再次输入密码' },
            { required: false, validator: userPassWord, trigger: 'blur' },
          ],
        },
        defaultProps: {
          children: 'children',
          label: 'label',
        },
        defaultProps2: {
          children: 'children',
          label: 'name',
        },
        filterTree: '',
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    watch: {
      filterTree(val) {
        this.$refs.ozTree.filter(val)
      },
    },
    created() {
      if (this.isSid === 'all') this.studioData()
      else this.getRoleList()
      this.getPath()
    },
    methods: {
      ...mapActions({
        changeTabsMeta: 'tabs/changeTabsMeta',
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      async studioData() {
        //获取渠道
        const { data } = await getStudioList()
        this.studioList = data
      },
      getPath() {
        const route = this.$route
        this.$nextTick(() => {
          let rid = route.query.id
          if (rid) {
            //编辑
            this.rules.password[0].required = false
            this.rules.password[1].required = false
            this.rules.repass[0].required = false
            this.rules.repass[1].required = false
            this.getRoleInfo(rid, 'info')
            this.form.id = rid
          } else {
            //新增
            this.rules.password[0].required = true
            this.rules.password[1].required = true
            this.rules.repass[0].required = true
            this.rules.repass[1].required = true
            this.getOriginList()
          }
        })
      },
      async getOriginList(sid) {
        //获取组织结构
        const { data } = await getCurStudioOrgin({ sid: sid })
        this.dataTree = data
      },
      handCheckStudio(val) {
        this.form.sid = val
        this.form.orgid = ''
        this.form.role = ''
        this.getOriginList(val)
        this.getRoleList(val)
      },
      filterTreeNode(value, data, node) {
        if (!value) return true
        //return data.name.indexOf(value) !== -1
        if (data.name.indexOf(value) !== -1) return true
        // 否则要去判断它是不是选中节点的子节点
        return this.checkBelongToChooseNode(value, data, node)
      },
      // 判断传入的节点是不是选中节点的子节点
      checkBelongToChooseNode(value, data, node) {
        const level = node.level
        // 如果传入的节点本身就是一级节点就不用校验了if(level ===1) {returnfalse; }
        // 先取当前节点的父节点
        let parentData = node.parent
        // 遍历当前节点的父节点
        let index = 0
        while (index < level - 1) {
          // 如果匹配到直接返回
          if (parentData.data.name.indexOf(value) !== -1) {
            return true
          }
          // 否则的话再往上一层做匹配
          parentData = parentData.parent
          index++
        }
        // 没匹配到返回false
        return false
      },
      submitForm(formName) {
        if (this.dataTree.length) {
          this.form.origin = this.$refs.ozTree.getCheckedKeys(false)
        }

        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.form.type = this.form.id ? 'edit' : 'add'
            const { msg, code } = await editRole(this.form)
            if (code === 200) {
              this.$baseMessage(msg, 'success')
              //关闭当前tab，返回list页并刷新
              await this.delVisitedRoute(handleActivePath(this.$route, true))
              this.reload()
              this.$router.push('/authority/administrators')
            } else {
              this.$baseMessage(msg, 'error')
            }
          } else {
            // eslint-disable-next-line no-console
            console.log('error submit!!')
            return false
          }
        })
      },
      resetForm(formName) {
        this.$refs.ozTree && this.$refs.ozTree.setCheckedKeys([])
        this.$refs[formName].resetFields()
      },
      treeChecked(obj) {
        if (this.dataTree.length) {
          this.form.origin = this.$refs.ozTree.getCheckedKeys(false)
        }
      },
      getRoleList(sid) {
        getAllRole(this.isSid === 'all' ? { sid: sid } : '').then((res) => {
          this.roleList = res.data
        })
      },
      async getRoleInfo(id, type) {
        const { data } = await editRole({ id, type })
        data.password = ''
        data.repass = ''
        this.form = data
        this.getRoleList(data.sid)
        await this.getOriginList(data.sid)
        this.$refs.ozTree.setCheckedKeys(data.origin)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .add-form-container {
    .demo-form {
      margin-top: 10px;
    }
    ::v-deep {
      .el-form-item__content {
        .el-rate {
          display: inline-block;
          font-size: 0;
          line-height: 1;
          vertical-align: middle;
        }

        .el-transfer__buttons {
          padding: 10px 10px 0 10px;
        }
      }
      .el-switch__core {
        width: 50px;
      }
      .el-switch__label * {
        font-size: 12px;
      }
      .el-switch__label--left {
        position: relative;
        left: 44px;
        color: #fff;
        z-index: -1111;
      }
      .el-switch__label--right {
        position: relative;
        right: 44px;
        color: #fff;
        z-index: -1111;
      }
      .el-switch__label--right.is-active,
      .el-switch__label--left.is-active {
        z-index: 1111;
        color: #fff !important;
      }
      .el-tree {
        margin-top: 15px;
      }
    }
    .add-title {
      text-align: center;
      line-height: 50px;
      background: #f0fcfa;
      margin: -20px -20px 20px;
      font-weight: bold;
      font-size: 16px;
    }
    .form-row {
      &:not(:first-child) {
        padding-top: 20px;
        margin-bottom: 20px;
      }
      .block-title {
        font-weight: bold;
        padding-left: 10px;
        border-left: 6px solid #26cdb7;
        margin-bottom: 20px;
      }
    }
  }
</style>
