var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "paper",
      attrs: {
        "element-loading-background": "transparent",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-text": "拼命加载中",
      },
    },
    [
      _vm.dataList
        ? _c("div", { staticClass: "exram" }, [
            _c("div", { staticClass: "paper-name" }, [
              _vm._v(_vm._s(_vm.dataList.name)),
            ]),
            _c("div", { staticClass: "guide" }, [
              _vm._v(_vm._s(_vm.dataList.guide)),
            ]),
            _c("div", { staticClass: "exam-process" }, [
              _c(
                "div",
                { staticClass: "u-flex u-flex-items-center u-flex-between" },
                [
                  _c("div", [
                    _c("span", { staticClass: "exam-num" }, [
                      _vm._v(
                        " 问题" +
                          _vm._s(
                            _vm.current >= _vm.dataList.maxQues
                              ? _vm.dataList.maxQues
                              : _vm.current + 1
                          ) +
                          " "
                      ),
                    ]),
                    _vm._v(" /" + _vm._s(_vm.dataList.maxQues) + " "),
                  ]),
                  _c("div", [_vm._v("选择你的答案进入下一题")]),
                ]
              ),
              _c("div", { staticClass: "exam-bar" }, [
                _c("div", {
                  staticClass: "exam-bar-percent",
                  style:
                    "width:" + (_vm.current / _vm.dataList.maxQues) * 100 + "%",
                }),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "exram-question" },
              _vm._l(_vm.dataList.quesList, function (list, li) {
                return _c(
                  "div",
                  { key: li, staticClass: "list" },
                  [
                    _vm.current === li
                      ? [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(_vm._s(list.quesName)),
                          ]),
                          list.selectType === "fillblank"
                            ? _c(
                                "div",
                                { staticClass: "ipt" },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入...",
                                      type: "digit",
                                      "v-model":
                                        _vm.answerArr[li] &&
                                        _vm.answerArr[li].qAnwser,
                                      value:
                                        _vm.answerArr[li] &&
                                        _vm.answerArr[li].qAnwser,
                                    },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.bindFocus($event, li)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : list.selectType === "image"
                            ? [
                                _c(
                                  "div",
                                  { staticStyle: { "text-align": "center" } },
                                  [
                                    _c("el-image", {
                                      staticStyle: { width: "300px" },
                                      attrs: {
                                        mode: "widthFix",
                                        src: list.quesUrl,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "imgQues" },
                                  _vm._l(
                                    list.quesOptionList,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "quesOption",
                                          class: [
                                            item.fldOptionIndex ===
                                            (_vm.answerArr[li] &&
                                              _vm.answerArr[li].qAnwser)
                                              ? "selected"
                                              : "",
                                            list.quesOptionList.length > 6
                                              ? "w4"
                                              : "w3",
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.goToPage(
                                                "next",
                                                list,
                                                item,
                                                li
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.fldOptionText)),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            : list.selectType === "date"
                            ? _c(
                                "div",
                                { staticClass: "ipt" },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      placeholder: "请选择日期",
                                      type: "date",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.bindFocus($event, li)
                                      },
                                      input: _vm.bindDate,
                                    },
                                    model: {
                                      value: _vm.answerArr[li].qAnwser,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.answerArr[li],
                                          "qAnwser",
                                          $$v
                                        )
                                      },
                                      expression: "answerArr[li].qAnwser",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "ques",
                                  class:
                                    list.quesOptionList &&
                                    list.quesOptionList.length > 5
                                      ? "u-flex u-flex-between two"
                                      : "",
                                },
                                _vm._l(list.quesOptionList, function (item, q) {
                                  return _c(
                                    "div",
                                    {
                                      key: q,
                                      staticClass: "item",
                                      class:
                                        item.fldOptionIndex ===
                                        (_vm.answerArr[li] &&
                                          _vm.answerArr[li].qAnwser)
                                          ? "selected"
                                          : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToPage(
                                            "next",
                                            list,
                                            item,
                                            li
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.fldOptionText) + " "
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                          _c(
                            "div",
                            { staticClass: "btn" },
                            [
                              _vm.current !== 0
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { width: "150px" },
                                      attrs: { size: "large", type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToPage("prev")
                                        },
                                      },
                                    },
                                    [_vm._v(" 上一题 ")]
                                  )
                                : _vm._e(),
                              list.selectType === "fillblank" ||
                              list.selectType === "date"
                                ? [
                                    _vm.current <= _vm.dataList.maxQues
                                      ? _c(
                                          "el-button",
                                          {
                                            staticStyle: { width: "150px" },
                                            attrs: {
                                              size: "large",
                                              type: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.goToPage(
                                                  "next",
                                                  list,
                                                  null,
                                                  li
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 下一题 ")]
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
              0
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }