var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-submenu",
    {
      ref: "subMenu",
      attrs: { index: _vm.itemOrMenu.path, "popper-append-to-body": false },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [
              _vm.itemOrMenu.meta && _vm.itemOrMenu.meta.icon
                ? _c("vab-icon", {
                    staticStyle: { width: "20px", height: "20px" },
                    attrs: {
                      icon:
                        _vm.itemOrMenu.meta.title === "更多功能"
                          ? "menu-more"
                          : _vm.itemOrMenu.meta.icon,
                      "is-custom-svg":
                        _vm.itemOrMenu.meta.isCustomSvg ||
                        _vm.itemOrMenu.meta.title === "更多功能",
                      title: _vm.translateTitle(_vm.itemOrMenu.meta.title),
                    },
                  })
                : _vm._e(),
              _c(
                "span",
                {
                  attrs: {
                    title: _vm.translateTitle(_vm.itemOrMenu.meta.title),
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.translateTitle(_vm.itemOrMenu.meta.title)) +
                      " "
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }