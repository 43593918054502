<template>
  <div class="setCrisis-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="预警级别" name="tab1">
        <warn v-if="activeName === 'tab1'" />
      </el-tab-pane>
      <el-tab-pane label="访谈级别" name="tab6">
        <assess-grade v-if="activeName === 'tab6'" />
      </el-tab-pane>
      <el-tab-pane label="干预级别" name="tab2">
        <meddle v-if="activeName === 'tab2'" />
      </el-tab-pane>
      <el-tab-pane label="结案状态" name="tab3">
        <status v-if="activeName === 'tab3'" />
      </el-tab-pane>
      <el-tab-pane label="预警来源" name="tab4">
        <warn-source v-if="activeName === 'tab4'" />
      </el-tab-pane>
      <el-tab-pane label="预警问题" name="tab5">
        <warn-quest v-if="activeName === 'tab5'" />
      </el-tab-pane>
      <el-tab-pane label="预警通知" name="tab7">
        <warn-notice v-if="activeName === 'tab7'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import Meddle from '../components/meddleGrade'
  import Warn from '../components/warnGrade'
  import Status from '../components/closeStatus'
  import WarnSource from '../components/warnSource'
  import WarnQuest from '../components/warnQuest'
  import AssessGrade from '../components/assessGrade'
  import WarnNotice from '../components/warnNotice'
  export default {
    name: 'SetCrisis',
    components: {
      Meddle,
      Warn,
      Status,
      WarnSource,
      WarnQuest,
      AssessGrade,
      WarnNotice,
    },
    data() {
      return {
        activeName: 'tab1',
      }
    },
    created() {},
    mounted() {},
    methods: {
      handleClick(tab, event) {
        this.activeName = tab.name
      },
    },
  }
</script>
<style lang="scss">
  .w220 {
    width: 215px;
  }
  .color {
    display: inline-block;
    width: 40px;
    height: 24px;
    border-radius: 2px;
    box-shadow: 1px 2px 3px 0 rgba(157, 157, 157, 0.2);
  }
  .tag-color {
    display: inline-block;
    color: #fff;
    padding: 0 10px;
    border-radius: 3px;
    vertical-align: top;
    margin-left: 10px;
  }
</style>
