var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vab-app-main" }, [
    _c(
      "div",
      { staticClass: "box-shadow" },
      [
        _c(
          "section",
          [
            _c(
              "transition",
              { attrs: { mode: "out-in", name: "fade-transform" } },
              [_vm.routerView ? _c("vab-keep-alive") : _vm._e()],
              1
            ),
          ],
          1
        ),
        _c("vab-footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }