<template>
  <div id="centerRight2">
    <div class="item-title">
      健康状况
      <el-select v-model="evalId" class="select" placeholder="请选择">
        <el-option
          v-for="item in selData"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <vab-chart :height="400" :options="options" />
  </div>
</template>

<script>
  import VabChart from '@/extra/VabChart'
  export default {
    components: {
      VabChart,
    },
    data() {
      return {
        selData: [
          {
            value: '1',
            label: '中小学心理健康测评（mht）',
          },
          {
            value: '2',
            label: 'scl-90症状自测量表',
          },
        ],
        evalId: '1',
        options: {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999',
              },
            },
          },
          color: ['#56A3F1', '#FF917C', '#FFE434'],
          legend: {
            data: ['男', '女', '平均'],
            textStyle: {
              color: '#fff',
            },
          },
          xAxis: [
            {
              type: 'category',
              data: [
                '学习焦虑',
                '对人焦虑',
                '孤独倾向',
                '自责倾向',
                '过敏倾向',
                '身体状况',
                '恐怖倾向',
                '冲动倾向',
              ],
              axisPointer: {
                type: 'shadow',
              },
              axisLabel: { interval: 0, rotate: 30 },
            },
          ],
          yAxis: [
            {
              type: 'value',
              name: '人数',
            },
            {
              type: 'value',
              name: '使用率',
            },
          ],
          series: [
            {
              name: '男',
              type: 'bar',
              data: [20, 49, 70, 232, 56, 76, 59, 90],
              barWidth: 10,
            },
            {
              name: '女',
              type: 'bar',
              data: [26, 59, 90, 264, 287, 707, 213, 231],
              barWidth: 10,
            },
            {
              name: '平均',
              type: 'line',
              yAxisIndex: 1,
              data: [20, 22, 33, 45, 63, 12, 32, 32],
            },
          ],
        },
      }
    },
    mounted() {},
    methods: {},
  }
</script>

<style lang="scss">
  #centerRight2 {
    padding: 0.3rem;
    .select {
      float: right;
      .el-input__inner {
        background-color: transparent;
        text-align: right;
        border: 0;
        color: #fff;
        font-size: 0.2rem;
      }
    }
  }
</style>
