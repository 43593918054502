<template>
  <div class="views-data-container">
    <dv-full-screen-container class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <div class="d-flex flex-column" style="width: 23.3%">
            <dv-decoration-10 style="height: 0.0825rem" />
            <div class="dates">{{ date }}</div>
          </div>
          <div class="d-flex jc-center">
            <dv-decoration-8
              :color="['#568aea', '#000000']"
              style="width: 2.5rem; height: 0.625rem"
            />
            <div class="title">
              <span class="title-text">
                南阳市宛城区心理健康综合服务平台大数据中心
              </span>
              <dv-decoration-6
                class="title-bototm"
                :color="['#50e3c2', '#67a1e5']"
                style="width: 3.125rem; height: 0.2rem"
              />
            </div>
            <dv-decoration-8
              :color="['#568aea', '#000000']"
              :reverse="true"
              style="width: 2.5rem; height: 0.625rem"
            />
          </div>
          <dv-decoration-10
            style="width: 23.3%; height: 0.0825rem; transform: rotateY(180deg)"
          />
          <div class="opration">
            <div class="item" title="返回首页">
              <router-link to="/"><vab-icon icon="home-4-line" /></router-link>
            </div>
            <div class="item" title="全屏/退出全屏">
              <vab-full-screen style="cursor: pointer" />
            </div>
          </div>
        </div>
        <div class="d-flex data-box">
          <div class="left">
            <div class="left-item">
              <dv-border-box-11 title="建档数据">
                <left1 :cdata="cdata" />
              </dv-border-box-11>
            </div>
            <div class="left-item">
              <dv-border-box-11 title="测评数据">
                <left2 :cdata="cdata" />
              </dv-border-box-11>
            </div>
          </div>
          <div class="center">
            <center />
          </div>
          <div class="right">
            <dv-border-box-12>
              <right1 />
            </dv-border-box-12>
            <dv-border-box-10 v-if="false" class="left-item">
              <right2 />
            </dv-border-box-10>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
  // 适配flex
  import '@/utils/flexible.js'
  import Left1 from './components/left1'
  import Left2 from './components/left2'
  import Right1 from './right1'
  import Right2 from './components/right2'
  import Center from './center'
  import screenfull from 'screenfull'
  import VabFullScreen from '@/vab/components/VabFullScreen/index.vue'
  export default {
    name: 'ViewData',
    components: {
      VabFullScreen,
      Left1,
      Left2,
      Right1,
      Right2,
      Center,
    },
    data() {
      return {
        loading: true,
        cdata: {
          xData: ['已测人数', '未测人数'],
          seriesData: [
            { value: 8, name: '未测人数' },
            { value: 88, name: '已测人数' },
          ],
        },
        week: [
          '星期天',
          '星期一',
          '星期二',
          '星期三',
          '星期四',
          '星期五',
          '星期六',
        ],
        timerID: null,
        date: '',
        time: '',
      }
    },
    mounted() {
      /*if (this.timerID) {
        this.timerID = null
        this.timerID = setInterval(this.updateTime, 1000)
      } else {
        this.timerID = setInterval(this.updateTime, 1000)
      }*/
    },
    created() {
      this.cancelLoading()
      this.updateTime()
    },
    destroyed() {
      this.timerID = null
    },
    methods: {
      click() {
        if (!screenfull.isEnabled) {
          this.$baseMessage(
            '开启全屏失败',
            'error',
            false,
            'vab-hey-message-error'
          )
          return false
        }
        screenfull.toggle()
        this.$emit('refresh')
      },
      cancelLoading() {
        setTimeout(() => {
          this.loading = false
        }, 500)
      },
      updateTime() {
        let cd = new Date()
        this.time =
          this.zeroPadding(cd.getHours(), 2) +
          ':' +
          this.zeroPadding(cd.getMinutes(), 2) +
          ':' +
          this.zeroPadding(cd.getSeconds(), 2)
        this.date =
          this.zeroPadding(cd.getFullYear(), 4) +
          '-' +
          this.zeroPadding(cd.getMonth() + 1, 2) +
          '-' +
          this.zeroPadding(cd.getDate(), 2) +
          ' ' +
          this.week[cd.getDay()]
      },

      zeroPadding(num, digit) {
        let zero = ''
        for (let i = 0; i < digit; i++) {
          zero += '0'
        }
        return (zero + num).slice(-digit)
      },
    },
  }
</script>

<style lang="scss">
  @import 'scss/style';
  .views-data-container {
    color: #fff;
    background-color: #000000;
    .dates {
      text-align: center;
      padding: 5px;
    }
    .bg {
      background-image: url('./scss/pageBg.png');
      background-size: cover;
      background-position: center center;
    }
    .opration {
      position: fixed;
      display: flex;
      top: 20px;
      right: 20px;
      z-index: 1000;
      .item {
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 100%;
        background: #131c5b;
        margin: 0 10px;
        box-shadow: 0 0 15px 0 rgb(124 154 227 / 45%);
        i {
          color: #fff;
          font-size: 20px !important;
        }
      }
    }
    .dv-scroll-board .header .header-item,
    .dv-scroll-board .rows .row-item {
      font-size: 12px;
    }

    .host-body {
      height: 100%;
      padding: 0.2rem;
      box-sizing: border-box;
      overflow-y: scroll;
      .title {
        position: relative;
        width: 10.25rem;
        height: 0.8rem;
        text-align: center;
        background-size: cover;
        background-repeat: no-repeat;

        .title-text {
          font-size: 0.45rem;
          position: absolute;
          bottom: 0.2rem;
          top: 0;
          line-height: 1.5;
          left: 0;
          right: 0;
          text-align: center;
          font-weight: bold;
          /* 这里可以随意加样式 */
          /*background-color: #fff;*/
          background: linear-gradient(
            to right,
            #4270fa 30%,
            #88a3f3 45%,
            #5d95e8 80% 95%
          );

          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          &:after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            bottom: 0;
            height: 24px;
            margin: auto;
            width: 60px;
            box-shadow: 0 0 rgba(174, 182, 248, 0.31);
            background-image: linear-gradient(
              -135deg,
              transparent 25%,
              transparent 40%,
              rgba(255, 255, 255, 0.3) 40%,
              rgba(174, 182, 248, 0.31) 60%,
              transparent 60%,
              transparent
            );
            background-size: 80px 30px;
            background-repeat: no-repeat;
            animation: scratchy 5s linear infinite;
          }
        }

        @keyframes scratchy {
          0% {
            left: 10%;
          }
          30% {
            left: 90%;
          }
          40% {
            left: 40%;
          }
          100% {
            left: 90%;
          }
        }

        .title-bototm {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%);
        }
      }

      .data-box {
        display: flex;
        flex-direction: column;
        .item-title {
          font-size: 0.25rem;
          line-height: 2;
          margin-bottom: 0.1rem;
        }
        .left,
        .right {
          display: flex;
          flex-direction: row;
          .left-item {
            flex: 1 !important;
          }
        }
        .center {
          flex: 1;
        }
      }
    }
  }
</style>
