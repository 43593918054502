var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: { "font-size": "18px" },
          attrs: { slot: "title" },
          slot: "title",
        },
        [_vm._v(" " + _vm._s(_vm.title) + " ")]
      ),
      _c("div", { staticClass: "tips" }, [
        _vm._v(
          " 批量更新针对组织结构的变更场景下使用，更新前，请确保组织结构及用户均已存在，更新的用户模板可通过导出功能修改最新的组织结构后上传。更新后用户组织结构变更，用户数据记录均保留。 "
        ),
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "80px", model: _vm.form, rules: _vm.rules },
        },
        [
          _vm.isSid === "all"
            ? _c(
                "el-form-item",
                { attrs: { label: "渠道", prop: "sid" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "选择渠道" },
                      model: {
                        value: _vm.form.sid,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "sid", $$v)
                        },
                        expression: "form.sid",
                      },
                    },
                    _vm._l(_vm.studioList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "上传文件", prop: "file" } },
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  attrs: {
                    accept: ".xls,.xlsx",
                    action: _vm.UploadUrl(),
                    "before-upload": _vm.beforeUploadFile,
                    "file-list": _vm.form.file,
                    "http-request": _vm.updFile,
                    limit: 1,
                    "on-change": _vm.fileChange,
                    "on-error": _vm.handleError,
                    "on-exceed": _vm.exceedFile,
                    "on-remove": _vm.handleRemove,
                    "on-success": _vm.handleSuccess,
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.hasFile,
                          expression: "!hasFile",
                        },
                      ],
                      attrs: {
                        slot: "trigger",
                        size: "small",
                        type: "primary",
                      },
                      slot: "trigger",
                    },
                    [_vm._v(" 选取文件 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.hasFile,
                          expression: "hasFile",
                        },
                      ],
                      attrs: { disabled: "", size: "small", type: "primary" },
                    },
                    [_vm._v(" 选取文件 ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [
                      _vm._v(" 只能上传 "),
                      _c("span", [_vm._v("xls、xlsx")]),
                      _vm._v(" 文件，且 "),
                      _c("span", [_vm._v("不超过2M")]),
                    ]
                  ),
                ],
                1
              ),
              _vm.errTxt
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        color: "red",
                        "line-height": "initial",
                        "margin-top": "10px",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.errTxt) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.canSave, type: "primary" },
              on: { click: _vm.save },
            },
            [_vm._v(" 确 定 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }