<template>
  <div class="personal-center-container">
    <el-card class="boxs" shadow="never">
      <div slot="header" class="header">公司信息</div>
      <div class="info">
        <el-form ref="form" label-width="120px" :model="form" :rules="rules">
          <el-form-item label="Logo" prop="avatar">
            <div class="img-uploader">
              <el-upload
                accept="image/png, image/jpeg"
                :action="UploadUrl()"
                :auto-upload="false"
                :on-change="fileChange"
                :on-remove="handleRemove"
                :show-file-list="false"
              >
                <img v-if="form.logo" class="img" :src="form.logo" />
                <i v-else class="el-icon-plus"></i>
              </el-upload>
              <div
                v-if="false"
                class="el-upload el-upload__text"
                @click="imgDialog"
              >
                <img v-if="form.logo" class="img" :src="form.logo" />
                <i v-else class="el-icon-plus"></i>
              </div>
              <div slot="tip" class="el-upload__tip">
                <div>
                  只能上传
                  <span>.jpg、.png</span>
                  文件，且
                  <span>不超过1M</span>
                </div>
                <div>(图片尺寸200*40)</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="公司名称" prop="name">
            <el-input v-model="form.name" placeholder="请输入公司名称" />
          </el-form-item>
          <el-form-item label="网页标题" prop="page_title">
            <el-input v-model="form.page_title" placeholder="请输入网页标题" />
          </el-form-item>
          <el-form-item label="登录页名称" prop="login_name">
            <el-input
              v-model="form.login_name"
              placeholder="请输入登录页名称"
            />
          </el-form-item>
          <el-form-item label="登录页Slogan" prop="login_slogan">
            <el-input
              v-model="form.login_slogan"
              placeholder="请输入登录页Slogan"
            />
          </el-form-item>
          <el-form-item label="">
            <el-button class="btn" size="medium" type="primary" @click="save">
              保存
            </el-button>
            <el-button
              v-if="loginUrl"
              size="medium"
              type="primary"
              @click="copy"
            >
              复制链接
            </el-button>
          </el-form-item>
          <el-form-item label="">
            <span>
              不知道怎么修改？
              <a
                href="https://docs.qq.com/doc/DTFFoWmNWeFV6Sm1R"
                target="_blank"
              >
                点击查看
              </a>
              教程
            </span>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <vab-cropper
      v-if="false"
      ref="vabCropper"
      append-to-body
      :imgurl="form.avatar || require('@/assets/login_images/login_img.png')"
      @checkedImg="getImg"
    />
  </div>
</template>

<script>
  import { getInfo, saveInfo } from '@/api/companyInfo'
  import { mapGetters } from 'vuex'
  import VabCropper from '@/extra/VabCropper'
  import { isNumber } from '@/utils/validate'
  import clip from '@/utils/clipboard'

  export default {
    name: 'CompanyInfo',
    components: { VabCropper },
    data() {
      const validateNum = (rule, value, callback) => {
        if (value !== '' && !isNumber(value)) {
          callback(new Error('请输入正整数'))
        } else callback()
      }
      return {
        studioList: [],
        schoolList: [], //学校列表
        credentList: [], //资质列表
        form: {
          logo: '',
          name: '',
          page_title: '',
          login_name: '',
          login_slogan: '',
        },
        rules: {},
        title: '',
        dialogFormVisible: false,
        hideUpload: false,
        hasImg: '',
        loginUrl: '',
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    created() {
      this.getCompanyInfo()
    },
    methods: {
      copy() {
        clip(this.loginUrl, event)
      },
      async getCompanyInfo() {
        const { data } = await getInfo()
        if (data) {
          this.form.logo = data.logo
          this.form.name = data.name
          this.form.page_title = data.page_title
          this.form.login_name = data.login_name
          this.form.login_slogan = data.login_slogan
          if (data.directory) {
            this.loginUrl =
              location.protocol +
              '//' +
              window.location.host +
              window.location.pathname +
              '?' +
              data.directory +
              '/'
          }
        }
      },
      imgDialog() {
        this.$refs['vabCropper'].dialogVisible = true
      },
      getImg(val) {
        this.form.logo = val
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.hasImg = ''
        this.hideUpload = false
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let p = this.form
            let formData = new FormData()
            formData.append('name', p.name)
            formData.append('page_title', p.page_title)
            formData.append('login_name', p.login_name)
            formData.append('login_slogan', p.login_slogan)
            if (this.hasImg && this.hasImg.raw)
              formData.append(
                'image_file',
                this.hasImg.raw,
                this.hasImg.raw.name
              )
            const { data, msg } = await saveInfo(formData)
            if (data.directory) {
              this.loginUrl =
                location.protocol +
                '//' +
                window.location.host +
                window.location.pathname +
                '?' +
                data.directory +
                '/'
            }
            this.$baseMessage(msg, 'success')
          } else {
            return false
          }
        })
      },
      UploadUrl: function () {
        return ''
      },
      fileChange(file, fileList) {
        if (fileList.length >= 1) {
          this.handleRemove(fileList)
        }
        const isLt2M = file.size / 1024 / 1024 < 1
        if (file.raw.type !== 'image/jpeg' && file.raw.type !== 'image/png') {
          this.$message.error('上传图片只能是 JPG、PNG 格式!')
          return false
        } else if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 1MB!')
          fileList.map((item, index) => {
            if (item === file) {
              fileList.splice(index, 1)
            }
          })
        } else {
          this.form.logo = URL.createObjectURL(file.raw)
          this.hasImg = file
          this.hideUpload = fileList.length > 0
        }
      },
      handleRemove(fileList) {
        this.hideUpload = fileList.length > 0
      },
    },
  }
</script>
<style lang="scss" scoped>
  .personal-center-container {
    .el-card__header {
      padding-left: 0 !important;
      padding-top: 0 !important;
    }
    .el-input {
      width: 320px !important;
    }
    .boxs {
      border: 0 !important;
      .btn {
        margin: 20px 0;
      }
      .header {
        position: relative;
        font-size: 16px;
        font-weight: bold;
        padding: 0 20px;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 6px;
          background: #26cdb7;
          border-radius: 3px 0 0 3px;
        }
      }
    }
    .info {
      .item {
        label {
          width: 100px;
          color: #999;
          font-weight: bold;
        }
        display: flex;
        line-height: 40px;
        font-size: 16px;
      }
    }
  }
  .el-select {
    width: 100%;
  }
  .img-uploader {
    display: flex;
    ::v-deep.el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 3px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    ::v-deep.el-upload--picture-card,
    ::v-deep.el-upload-list--picture-card .el-upload-list__item {
      width: 100px;
      height: 100px;
    }
    ::v-deep.el-list-enter-active,
    ::v-deep.el-list-leave-active {
      transition: none;
    }

    ::v-deep.el-list-enter,
    ::v-deep.el-list-leave-active {
      opacity: 0;
    }
    .hide ::v-deep.el-upload--picture-card {
      display: none;
    }
    .el-upload__tip {
      flex: 1;
      margin-left: 10px;
      color: #999;
      line-height: normal;
      span {
        color: #e64e1f;
      }
    }
    ::v-deep.el-upload:hover {
      border-color: #92e6db;
    }
    .img,
    .el-icon-plus {
      width: 320px;
      height: 64px;
      display: block;
      line-height: 64px;
    }
  }
</style>
