var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "index-container" }, [
    _vm.work
      ? _c("div", { staticClass: "ul" }, [
          _c(
            "div",
            { staticClass: "li bg1" },
            [
              _c("el-image", {
                staticClass: "icon",
                attrs: {
                  src: require("@/assets/index_images/icon-school.png"),
                },
              }),
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "f16" }, [_vm._v("全部机构")]),
                _c("div", [
                  _c("span", { staticClass: "f30" }, [
                    _vm._v(_vm._s(_vm.work.institution)),
                  ]),
                  _vm._v(" 个 "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "li bg2" },
            [
              _c("el-image", {
                staticClass: "icon",
                attrs: {
                  src: require("@/assets/index_images/icon-teacher.png"),
                },
              }),
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "f16" }, [_vm._v("总人数")]),
                _c("div", [
                  _c("span", { staticClass: "f30" }, [
                    _vm._v(_vm._s(_vm.work.pep)),
                  ]),
                  _vm._v(" 人 "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "li bg3" },
            [
              _c("el-image", {
                staticClass: "icon",
                attrs: {
                  src: require("@/assets/index_images/icon-student.png"),
                },
              }),
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "f16" }, [_vm._v("测评次数")]),
                _c("div", [
                  _c("span", { staticClass: "f30" }, [
                    _vm._v(_vm._s(_vm.work.sum)),
                  ]),
                  _vm._v(" 次 "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "li bg4" },
            [
              _c("el-image", {
                staticClass: "icon",
                attrs: {
                  src: require("@/assets/index_images/icon-warning.png"),
                },
              }),
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "f16" }, [_vm._v("预警人数")]),
                _c("div", [
                  _c("span", { staticClass: "f30" }, [
                    _vm._v(_vm._s(_vm.work.warn)),
                  ]),
                  _vm._v(" 人 "),
                ]),
              ]),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }