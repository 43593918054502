import request from '@/utils/request'
//家长列表
export function getParentList(data) {
  return request({
    url: '/tencent/parent-list',
    method: 'post',
    data,
  })
}
//删除
export function delParent(data) {
  return request({
    url: '/tencent/parent-del',
    method: 'post',
    data,
  })
}
//解绑
export function unbind(data) {
  return request({
    url: '/tencent/relieve',
    method: 'post',
    data,
  })
}
//获取公众号自定义菜单
export function getOfficialMenu(data) {
  return request({
    url: '/tencent/menu',
    method: 'post',
    data,
  })
}
//公众号模板消息 list:获取列表  add:添加  del:删除（template_id）
export function getTeamplate(data) {
  return request({
    url: '/tencent/message-template',
    method: 'post',
    data,
  })
}
//群发消息
export function sendMessages(data) {
  return request({
    url: '/tencent/send-message',
    method: 'post',
    data,
  })
}
//公众号设置
export function setGZH(data) {
  return request({
    url: '/setting/tencent',
    method: 'post',
    data,
  })
}
