var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "生成报告",
        visible: _vm.dialogFormVisible,
        width: "480px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          !_vm.loading
            ? _c("el-tree", {
                ref: "ozTree",
                attrs: {
                  data: _vm.dataTree,
                  "node-key": "id",
                  props: _vm.defaultProps,
                  "show-checkbox": "",
                },
                on: { check: _vm.treeChecked },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ node }) {
                        return _c("span", {}, [
                          _c("span", [_vm._v(_vm._s(node.label))]),
                          _c("span", { staticClass: "orange" }, [
                            _vm._v(_vm._s(node.data.pep || 0) + " 人"),
                          ]),
                        ])
                      },
                    },
                  ],
                  null,
                  false,
                  1454607621
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }