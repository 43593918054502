var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "centerRight2" } },
    [
      _c(
        "div",
        { staticClass: "item-title" },
        [
          _vm._v(" 健康状况 "),
          _c(
            "el-select",
            {
              staticClass: "select",
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.evalId,
                callback: function ($$v) {
                  _vm.evalId = $$v
                },
                expression: "evalId",
              },
            },
            _vm._l(_vm.selData, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c("vab-chart", { attrs: { height: 400, options: _vm.options } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }