<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="600px"
    @close="close"
  >
    <el-form ref="form" label-width="90px" :model="form" :rules="rules">
      <el-form-item
        v-if="isSid === 'all' && title !== '编辑'"
        label="渠道"
        prop="sid"
      >
        <el-select
          v-model="form.sid"
          filterable
          placeholder="请选择渠道"
          @change="bindChange"
        >
          <el-option
            v-for="item in studioList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item v-if="title !== '编辑'" label="咨询用户" prop="uid">
        <el-button type="primary" @click="addPerson">选择</el-button>
        <div v-if="form.uid.length" class="userlist">
          <div v-for="(user, index) in form.uid" :key="index" class="username">
            {{ user.name }}
            <span class="el-icon-error" @click="delUserId(user.uid)"></span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="咨询师" prop="counsellor_id">
        <el-select
          v-model="form.counsellor_id"
          filterable
          placeholder="请选择咨询师"
        >
          <el-option
            v-for="item in teacherList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="咨询日期" prop="date">
        <el-date-picker
          v-model="form.date"
          :picker-options="pickerOptions"
          placeholder="请选择日期"
          type="date"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item label="咨询时段" prop="time">
        <el-select
          v-model.trim="form.time"
          clearable
          filterable
          placeholder="请选择时段"
        >
          <el-option
            v-for="(item, index) in timeList"
            :key="index"
            :label="item[0] + '~' + item[1]"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="咨询问题" prop="question_id">
        <el-select
          v-model="form.question_id"
          filterable
          placeholder="请选择咨询问题"
        >
          <el-option
            v-for="item in questList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="主诉" prop="user_complain">
        <el-input
          v-model="form.user_complain"
          maxlength="800"
          :rows="3"
          show-word-limit
          type="textarea"
        />
      </el-form-item>
      <el-form-item label="咨询师分析" prop="counsellor_explain">
        <el-input
          v-model="form.counsellor_explain"
          maxlength="800"
          :rows="3"
          show-word-limit
          type="textarea"
        />
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="form.remark"
          maxlength="200"
          show-word-limit
          type="textarea"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
    <add-stu ref="addstu" @fetch-data="getInfo" />
  </el-dialog>
</template>

<script>
  import {
    ConRecordAdd,
    ConRecordEdit,
    getConRecordDetail,
    getPsyList,
    getPsyQuestList,
  } from '@/api/appointment'
  import { mapGetters } from 'vuex'
  import AddStu from '@/views/appointment/components/addStu'

  export default {
    name: 'ConRecordEdit',
    components: { AddStu },
    props: {
      studio: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        studioList: [],
        teacherList: [], //咨询师列表
        questList: [], //咨询问题
        form: {
          uid: '',
          date: '',
          time: '',
          counsellor_id: '',
          question_id: '',
          user_complain: '',
          counsellor_explain: '',
          remark: '',
        },
        rules: {
          uid: [{ required: true, trigger: 'blur', message: '请选择咨询用户' }],
          date: [{ required: true, trigger: 'change', message: '请选择日期' }],
          time: [{ required: true, trigger: 'change', message: '请选择时间' }],
          counsellor_id: [
            { required: true, trigger: 'change', message: '请选择咨询师' },
          ],
          question_id: [
            { required: true, trigger: 'change', message: '请选择咨询问题' },
          ],
          user_complain: [
            { required: true, trigger: 'blur', message: '请输入主诉' },
          ],
          counsellor_explain: [
            { required: true, trigger: 'blur', message: '请输入咨询师分析' },
          ],
        },
        timeList: [
          ['08:00', '09:00'],
          ['09:00', '10:00'],
          ['10:00', '11:00'],
          ['11:00', '12:00'],
          ['12:00', '13:00'],
          ['13:00', '14:00'],
          ['14:00', '15:00'],
          ['15:00', '16:00'],
          ['16:00', '17:00'],
          ['17:00', '18:00'],
          ['18:00', '19:00'],
          ['19:00', '20:00'],
          ['20:00', '21:00'],
          ['21:00', '22:00'],
        ],
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now()
          },
        },
        title: '',
        dialogFormVisible: false,
        treeIds: [],
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    watch: {
      studio: {
        handler(newData) {
          this.studioList = newData
        },
        immediate: true,
        deep: true,
      },
    },
    created() {},
    methods: {
      getTeacher(sid) {
        getPsyList(this.isSid === 'all' ? { sid: sid } : '').then((res) => {
          this.teacherList = res.data
        })
      },
      getPsyQuestion(sid) {
        getPsyQuestList(this.isSid === 'all' ? { sid: sid } : '').then(
          (res) => {
            this.questList = res.data
          }
        )
      },
      addPerson() {
        if (this.isSid === 'all' && !this.form.sid) {
          this.$baseMessage('请选择渠道', 'error')
          return false
        }
        let data = {
          ids: this.treeIds,
          list: this.form.uid,
        }
        this.$refs['addstu'].showEdit(this.form.sid, data)
      },
      getInfo(val) {
        this.form.uid = []
        this.treeIds = []
        this.form.uid = val.list
        this.treeIds = val.ids
      },
      delUserId(id) {
        this.form.uid = this.form.uid.filter((item) => item.uid !== id)
      },
      bindChange(val) {
        this.form.counsellor_id = ''
        this.form.question_id = ''
        this.getTeacher(val)
        this.getPsyQuestion(val)
      },
      showEdit(row) {
        if (!row) {
          this.title = '添加'
          this.getTeacher()
          this.getPsyQuestion()
        } else {
          this.title = '编辑'
          getConRecordDetail({ id: row.id }).then((res) => {
            this.form = res.data
            let d = res.data.time
            this.form.time = d[0] + '~' + d[1]
            this.getTeacher(row.sid)
            this.getPsyQuestion(row.sid)
          })
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.title === '编辑') {
              let f = this.form
              let t = typeof f.time
              let p = {
                id: f.id,
                uid: f.uid,
                date: f.date,
                time: t === 'string' ? f.time.split('~') : f.time,
                counsellor_id: f.counsellor_id,
                question_id: f.question_id,
                user_complain: f.user_complain,
                counsellor_explain: f.counsellor_explain,
                remark: f.remark,
              }
              ConRecordEdit(p).then((res) => {
                this.$baseMessage(res.msg, 'success')
                this.$emit('fetch-data')
              })
            } else {
              ConRecordAdd(this.form).then((res) => {
                this.$baseMessage(res.msg, 'success')
                this.$emit('fetch-data')
              })
            }
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style lang="scss">
  .userlist {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    .username {
      background: #f9f9f9;
      border-radius: 4px;
      border: 1px solid #ddd;
      padding: 0 8px;
      margin-right: 10px;
      line-height: 24px;
      margin-bottom: 10px;
      .el-icon-error {
        color: #ddd;
        &:hover {
          color: #999;
        }
      }
    }
  }
</style>
