import request from '@/utils/request'
export function getList(params) {
  return request({
    url: '/intervene/meddle-list',
    method: 'get',
    params,
  })
}
//干预记录
export function getMentalList(data) {
  return request({
    url: '/mental/interve-list',
    method: 'post',
    data,
  })
}
//评估记录
export function getEvaList(data) {
  return request({
    url: '/mental/evaluate-list',
    method: 'post',
    data,
  })
}
//预警概况
export function getWarnState(data) {
  return request({
    url: '/mental/warn-state',
    method: 'post',
    data,
  })
}
//预警列表
export function getMentalWarList(data) {
  return request({
    timeout: 180000,
    url: '/mental/warn-list',
    method: 'post',
    data,
  })
}
//结案状态
export function getSettleList(data) {
  return request({
    url: '/mental/settle-list',
    method: 'post',
    data,
  })
}
//获取预警人员名单
export function getWarnPerson(data) {
  return request({
    url: '/mental/warn-person',
    method: 'post',
    data,
  })
}
export function getWarnList(params) {
  return request({
    url: '/intervene/warn-list',
    method: 'get',
    params,
  })
}
//获取预警参数
export function getWarnParam(params) {
  return request({
    url: '/mental/get-meddle-type',
    method: 'get',
    params,
  })
}
//设置预警参数等级
export function setWarnParam(data) {
  return request({
    url: '/mental/meddle-change',
    method: 'post',
    data,
  })
}
//设置预警状态
export function setWarnParamStatus(data) {
  return request({
    url: '/mental/set-mental-status',
    method: 'post',
    data,
  })
}
//删除
export function setStatus(data) {
  return request({
    url: '/intervene/meddle-status',
    method: 'post',
    data,
  })
}
//添加干预
export function meddleAdd(data) {
  return request({
    url: '/intervene/meddle-add',
    method: 'post',
    data,
  })
}
//干预相关参数列表
export function getParams(params) {
  return request({
    url: '/intervene/get-meddle-type',
    method: 'get',
    params,
  })
}
//获取干预对象
export function getUser(data) {
  return request({
    url: '/router/get-origin-users',
    method: 'post',
    data,
  })
}
//上传预警、干预文
export function uploadWarn(data) {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/mental/file-change',
    method: 'post',
    data,
  })
}

//结案
export function caseFinish(data) {
  return request({
    url: '/mental/case-finish',
    method: 'post',
    data,
  })
}
//预警通知
export function warnNotify(data) {
  return request({
    url: '/setting/warn-notify',
    method: 'post',
    data,
  })
}
//批量处理
export function setAllWarn(data) {
  return request({
    url: '/mental/eval-all-edit',
    method: 'post',
    data,
  })
}
