var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "answer-container" }, [
    _c(
      "div",
      { staticClass: "box" },
      [
        _c(
          "el-card",
          { staticClass: "tips" },
          [
            _vm._v(" 提示：仅显示 "),
            _c("span", { staticClass: "c_red" }, [
              _vm._v(_vm._s(_vm.list.name)),
            ]),
            _vm._v(" 对 "),
            _c("span", { staticClass: "c_red" }, [
              _vm._v(_vm._s(_vm.list.paper)),
            ]),
            _vm._v(" 的最后一次答题记录。 "),
            _vm.$route.query.type !== "pay"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.downloadAnswer },
                  },
                  [_vm._v(" 导出Excel ")]
                )
              : _vm._e(),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.print } },
              [_vm._v("导出Pdf")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { ref: "print", staticClass: "answer-list" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                attrs: { border: "", data: _vm.list.ques },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "题目", prop: "title" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _vm.isImage(row.title)
                            ? [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(row.title.split("、")[0]) + "、"
                                  ),
                                ]),
                                _c("el-image", {
                                  attrs: {
                                    "preview-src-list": [
                                      row.title.split("、")[1],
                                    ],
                                    src: row.title.split("、")[1],
                                  },
                                }),
                              ]
                            : _c("span", [_vm._v(_vm._s(row.title))]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "选项", prop: "select" },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "答案",
                    prop: "answer",
                    width: "180",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "得分",
                    prop: "score",
                    width: "80",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }