var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.dialogFormVisible,
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-width": "100px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "测评名称", prop: "title" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { max: "16", placeholder: "请输入测评名称" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "测评对象", prop: "userArr" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addPerson },
                    },
                    [_vm._v("选择")]
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        color: "#999",
                        "padding-left": "20px",
                      },
                    },
                    [_vm._v(" 没有想选择的用户？ ")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "text" }, on: { click: _vm.handleAdd } },
                    [_vm._v("新增")]
                  ),
                  _vm.form.userArr.length
                    ? _c(
                        "div",
                        { staticClass: "userlist" },
                        _vm._l(_vm.form.userArr, function (user, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "username" },
                            [
                              _vm._v(" " + _vm._s(user.name) + " "),
                              _c("span", {
                                staticClass: "el-icon-error",
                                on: {
                                  click: function ($event) {
                                    return _vm.delUserId(user.uid)
                                  },
                                },
                              }),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "测评类型", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("全部")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("自评")]),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("他评")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "测评量表", prop: "evalArr" } },
                [
                  _c("el-transfer", {
                    attrs: {
                      data: _vm.evalList,
                      "filter-method": _vm.filterMethod,
                      "filter-placeholder": "搜索量表",
                      filterable: "",
                      props: { key: "id", label: "name" },
                      titles: ["量表库", "已选量表"],
                    },
                    model: {
                      value: _vm.form.evalArr,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "evalArr", $$v)
                      },
                      expression: "form.evalArr",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开放报告", prop: "is_open" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-text": "是",
                      "active-value": "1",
                      "inactive-text": "否",
                      "inactive-value": "0",
                    },
                    model: {
                      value: _vm.form.is_open,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "is_open", $$v)
                      },
                      expression: "form.is_open",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "middle" }, on: { click: _vm.close } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "middle", type: "primary" },
                  on: { click: _vm.save },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("add-person", { ref: "addperson", on: { "fetch-data": _vm.getInfo } }),
      _c("add-user", { ref: "add", on: { "fetch-data": _vm.getUser } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }