var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "personal-center-container" },
    [
      _c("el-card", { staticClass: "boxs", attrs: { shadow: "never" } }, [
        _c(
          "div",
          { staticClass: "header", attrs: { slot: "header" }, slot: "header" },
          [_vm._v("公司信息")]
        ),
        _c(
          "div",
          { staticClass: "info" },
          [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  "label-width": "120px",
                  model: _vm.form,
                  rules: _vm.rules,
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Logo", prop: "avatar" } },
                  [
                    _c(
                      "div",
                      { staticClass: "img-uploader" },
                      [
                        _c(
                          "el-upload",
                          {
                            attrs: {
                              accept: "image/png, image/jpeg",
                              action: _vm.UploadUrl(),
                              "auto-upload": false,
                              "on-change": _vm.fileChange,
                              "on-remove": _vm.handleRemove,
                              "show-file-list": false,
                            },
                          },
                          [
                            _vm.form.logo
                              ? _c("img", {
                                  staticClass: "img",
                                  attrs: { src: _vm.form.logo },
                                })
                              : _c("i", { staticClass: "el-icon-plus" }),
                          ]
                        ),
                        false
                          ? _c(
                              "div",
                              {
                                staticClass: "el-upload el-upload__text",
                                on: { click: _vm.imgDialog },
                              },
                              [
                                _vm.form.logo
                                  ? _c("img", {
                                      staticClass: "img",
                                      attrs: { src: _vm.form.logo },
                                    })
                                  : _c("i", { staticClass: "el-icon-plus" }),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "el-upload__tip",
                            attrs: { slot: "tip" },
                            slot: "tip",
                          },
                          [
                            _c("div", [
                              _vm._v(" 只能上传 "),
                              _c("span", [_vm._v(".jpg、.png")]),
                              _vm._v(" 文件，且 "),
                              _c("span", [_vm._v("不超过1M")]),
                            ]),
                            _c("div", [_vm._v("(图片尺寸200*40)")]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "公司名称", prop: "name" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入公司名称" },
                      model: {
                        value: _vm.form.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "网页标题", prop: "page_title" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入网页标题" },
                      model: {
                        value: _vm.form.page_title,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "page_title", $$v)
                        },
                        expression: "form.page_title",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "登录页名称", prop: "login_name" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入登录页名称" },
                      model: {
                        value: _vm.form.login_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "login_name", $$v)
                        },
                        expression: "form.login_name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "登录页Slogan", prop: "login_slogan" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入登录页Slogan" },
                      model: {
                        value: _vm.form.login_slogan,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "login_slogan", $$v)
                        },
                        expression: "form.login_slogan",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { size: "medium", type: "primary" },
                        on: { click: _vm.save },
                      },
                      [_vm._v(" 保存 ")]
                    ),
                    _vm.loginUrl
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "medium", type: "primary" },
                            on: { click: _vm.copy },
                          },
                          [_vm._v(" 复制链接 ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c("el-form-item", { attrs: { label: "" } }, [
                  _c("span", [
                    _vm._v(" 不知道怎么修改？ "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://docs.qq.com/doc/DTFFoWmNWeFV6Sm1R",
                          target: "_blank",
                        },
                      },
                      [_vm._v(" 点击查看 ")]
                    ),
                    _vm._v(" 教程 "),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      false
        ? _c("vab-cropper", {
            ref: "vabCropper",
            attrs: {
              "append-to-body": "",
              imgurl:
                _vm.form.avatar ||
                require("@/assets/login_images/login_img.png"),
            },
            on: { checkedImg: _vm.getImg },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }