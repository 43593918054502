var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-scrollbar",
    {
      staticClass: "vab-side-bar",
      class: {
        "is-collapse": _vm.collapse,
        "side-bar-common": _vm.layout === "common",
      },
    },
    [
      _vm.layout === "vertical" || _vm.layout === "comprehensive"
        ? _c("vab-logo")
        : _vm._e(),
      _c(
        "el-menu",
        {
          attrs: {
            "active-text-color": _vm.variables["menu-color-active"],
            "background-color": _vm.variables["menu-background"],
            collapse: _vm.collapse,
            "collapse-transition": false,
            "default-active": _vm.activeMenu,
            "default-openeds": _vm.defaultOpeneds,
            "menu-trigger": "click",
            mode: "vertical",
            "text-color": _vm.variables["menu-color"],
            "unique-opened": _vm.uniqueOpened,
          },
        },
        [
          _vm._l(_vm.handleRoutes, function (route) {
            return [
              !route.hidden
                ? _c("vab-menu", { key: route.path, attrs: { item: route } })
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _c("div", { staticClass: "version" }, [
        _vm.hiddenSupport == 0
          ? _c("div", { staticClass: "help", on: { click: _vm.help } }, [
              _c("i", { staticClass: "el-icon-question" }),
              _vm._v(" 帮助中心 "),
            ])
          : _vm._e(),
        _c("div", { staticClass: "num" }, [
          _vm._v("版本：" + _vm._s(_vm.version)),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }