import { title2, titleReverse, titleSeparator } from '@/config'
import i18n from '@/i18n'
import store from '../store/index'

/**
 * @description 设置标题
 * @param pageTitle
 * @returns {string}
 */
export default function getPageTitle(pageTitle, hiddenTitle2) {
  let title2 = store.getters['settings/pageTitle']
  if (i18n.te(`vabI18n.${pageTitle}`))
    pageTitle = i18n.t(`vabI18n.${pageTitle}`)
  let newTitles = []
  if (pageTitle) newTitles.push(pageTitle)
  if (title2) newTitles.push(!hiddenTitle2 ? title2 : '')
  if (titleReverse) newTitles = newTitles.reverse()
  return newTitles.join(!hiddenTitle2 ? titleSeparator : '')
}
