<template>
  <div class="warn-notice">
    <el-alert
      :closable="false"
      title="说明：该功能主要通过短信的形式将相关的预警数据通知到已绑定手机号的管理员，管理员可登录管理后台查看具体的数据并合理的进行心理服务的后续工作"
      type="warning"
    />
    <el-form
      ref="form"
      label-position="right"
      label-width="120px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="通知管理员" prop="admin_id">
        <template slot="label">
          通知管理员
          <el-tooltip
            content="当前仅展示已绑定手机号的管理员"
            effect="dark"
            placement="right-start"
          >
            <i class="el-icon-info" style="cursor: pointer"></i>
          </el-tooltip>
        </template>
        <el-checkbox-group v-model="form.admin_id">
          <el-checkbox
            v-for="(admin, index) in adminList"
            :key="index"
            :label="admin.id"
          >
            {{ admin.username }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="通知频率" prop="date">
        <el-radio-group v-model="form.date">
          <el-radio
            v-for="(date, index) in dateList"
            :key="index"
            :label="date.date"
          >
            {{ date.name }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="通知规则" prop="warn">
        <el-radio-group v-model="form.warn">
          <el-radio
            v-for="(warn, index) in warnList"
            :key="index"
            :label="warn.sort"
          >
            {{ warn.name }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import { warnNotify } from '@/api/crisis'

  export default {
    name: 'WarnNotice',
    data() {
      return {
        adminList: [], //管理员
        warnList: [], //预警
        dateList: [], //频率
        form: {
          admin_id: [],
          date: '',
          warn: '',
        },
        rules: {
          admin_id: [
            {
              type: 'array',
              required: true,
              message: '请至少选择一个管理员',
              trigger: 'change',
            },
          ],
          date: [
            { required: true, message: '请选择通知频率', trigger: 'change' },
          ],
          warn: [
            { required: true, message: '请选择通知规则', trigger: 'change' },
          ],
        },
      }
    },
    created() {
      this.getData()
    },
    methods: {
      getData() {
        warnNotify({ type: 'info' }).then((res) => {
          let d = res.data
          this.adminList = d.admin
          this.dateList = d.date_list
          this.warnList = d.level
          this.warnList.unshift({
            sort: '0',
            name: '全部',
          })
          this.form = {
            admin_id: d.admin_id || [],
            date: d.date || '',
            warn: d.warn || '',
          }
        })
      },
      submitForm() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.form.type = 'edit'
            const { data } = await warnNotify(this.form)
            if (data.code === 200) {
              this.$baseMessage(data.msg, 'success')
            } else {
              this.$baseMessage(data.msg, 'error')
            }
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .warn-notice {
  }
</style>
