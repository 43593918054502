<template>
  <div class="user-list-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="8">
        <el-button
          v-if="button.add"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit"
        >
          添加
        </el-button>
        <el-button
          v-else
          disabled
          icon="el-icon-plus"
          title="暂无权限，如有需要，请联系管理员"
          type="primary"
        >
          添加
        </el-button>
        <el-button
          v-if="button.delete"
          icon="el-icon-delete"
          type="danger"
          @click="deleteUsers"
        >
          删除
        </el-button>
        <el-button
          v-else
          disabled
          icon="el-icon-delete"
          title="暂无权限，如有需要，请联系管理员"
          type="danger"
        >
          删除
        </el-button>
        <el-button
          v-if="button.add"
          icon="el-icon-upload2"
          title="导入用户"
          type="primary"
          @click="handleAdd('add')"
        >
          导入
        </el-button>
        <el-button
          v-else
          disabled
          icon="el-icon-upload2"
          title="暂无权限，如有需要，请联系管理员"
          type="text"
        >
          导入
        </el-button>
        <el-button
          v-if="button.add"
          icon="el-icon-download"
          title="导出用户"
          @click="handleExport"
        >
          导出
        </el-button>
        <el-button
          v-else
          disabled
          icon="el-icon-download"
          title="暂无权限，如有需要，请联系管理员"
        >
          导出
        </el-button>
        <el-button
          v-if="button.edit"
          icon="el-icon-edit-outline"
          title="批量更新用户组织结构"
          @click="handleAdd('update')"
        >
          更新
        </el-button>
        <el-button
          v-else
          disabled
          icon="el-icon-edit-outline"
          title="暂无权限，如有需要，请联系管理员"
          type="text"
        >
          更新
        </el-button>
        <div style="margin-bottom: 10px">
          为避免导入用户失败，请先
          <el-button title="下载Excel模版" type="text" @click="downLoadDoc">
            下载模版
          </el-button>
        </div>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel v-if="button.search" :span="16">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item v-if="isSid === 'all'">
            <el-select
              v-model="queryForm.sid"
              clearable
              filterable
              placeholder="请选择渠道"
              @change="bindChange"
            >
              <el-option
                v-for="item in studioList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-cascader
              ref="orCas"
              clearable
              :options="treeData"
              placeholder="组织结构"
              :props="defaultProps"
              @change="handleChange"
              @visible-change="cascaderClicked"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入姓名"
            />
          </el-form-item>
          <el-form-item>
            <el-select v-model="queryForm.sex" clearable placeholder="选择性别">
              <el-option label="全部" value="0" />
              <el-option label="男" value="1" />
              <el-option label="女" value="2" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.account"
              clearable
              placeholder="请输入账号"
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.phone"
              clearable
              placeholder="请输入联系方式"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.loginStatus"
              clearable
              placeholder="登录状态"
            >
              <el-option label="全部" value="0" />
              <el-option label="已登录" value="1" />
              <el-option label="未登录" value="2" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="queryForm.loginTime"
              end-placeholder="登录结束日期"
              start-placeholder="登录开始日期"
              type="daterange"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @selection-change="selectChange"
    >
      <el-table-column align="center" type="selection" />
      <el-table-column align="center" label="序号" type="index" width="50" />
      <el-table-column label="姓名" min-width="120" prop="name">
        <template #default="{ row }">
          <div class="user">
            <img v-if="row.sex === '男'" src="@/assets/male.png" />
            <img v-else-if="row.sex === '女'" src="@/assets/female.png" />
            <img v-else src="@/assets/unknow.png" />
            <span>{{ row.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="账号" prop="account" />
      <el-table-column align="center" label="密码" prop="password" />
      <el-table-column align="center" label="联系方式" prop="phone" />
      <el-table-column label="组织结构" min-width="200" prop="origin" />
      <el-table-column align="center" label="上次登录" prop="login_at" />
      <el-table-column align="center" fixed="right" label="操作" width="150">
        <template #default="{ row }">
          <el-button type="text" @click="toArchive(row)">档案</el-button>
          <el-button v-if="button.edit" type="text" @click="handleEdit(row)">
            编辑
          </el-button>
          <el-button
            v-else
            disabled
            title="暂无权限，如有需要，请联系管理员"
            type="text"
          >
            编辑
          </el-button>
          <el-button
            v-if="button.delete"
            class="del"
            type="text"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
          <el-button
            v-else
            disabled
            title="暂无权限，如有需要，请联系管理员"
            type="text"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <add ref="add" :list="studioList" @fetch-data="fetchData" />
    <edit ref="edit" :list="studioList" @fetch-data="fetchData" />
    <update ref="update" :list="studioList" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import {
    getUserList,
    delUser,
    getOrginDoc,
    delUserMore,
    getCurStudioOrgin,
  } from '@/api/userManagementOrganization'
  import { getStudioList } from '@/api/platformChannelManagement'
  import Add from './components/MemberAdd'
  import Edit from './components/MemberEdit'
  import Update from './components/MemberUpdate'
  import { mapGetters } from 'vuex'
  export default {
    name: 'UserList',
    components: { Add, Edit, Update },
    data() {
      return {
        list: [],
        studioList: [],
        treeData: [], //获取组织结构
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        drawer: false,
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          sid: '',
          name: '',
          phone: '',
          orgid: '',
          sex: '',
          account: '',
          loginTime: [],
          loginStatus: '',
        },
        button: {},
        docUrl: '',
        defaultProps: {
          children: 'children',
          label: 'name',
          value: 'id',
          checkStrictly: true,
        },
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    created() {
      this.getDoc()
      this.fetchData()
      if (this.isSid === 'all') this.getStudio()
      // else this.getOriginList()
    },
    mounted() {},
    methods: {
      deleteUsers() {
        if (this.selectRows.length) {
          let uid = this.selectRows.map((item) => item.uid)
          this.$baseConfirm(
            '你确定要删除当前选择的所有项吗',
            null,
            async () => {
              const { msg, code } = await delUserMore({ uid: uid })
              if (code === 200) {
                this.$baseMessage(msg, 'success')
                await this.fetchData()
              } else {
                this.$baseMessage(msg, 'error')
              }
            }
          )
        } else {
          this.$baseMessage('请选择要删除的行！', 'warning')
        }
      },
      selectChange(val) {
        this.selectRows = val
      },
      cascaderClicked($event) {
        if ($event) {
          this.getOriginList(this.queryForm.sid)
        }
      },
      async getStudio() {
        const { data } = await getStudioList()
        this.studioList = data
      },
      async getOriginList(sid) {
        //获取组织结构
        const { data } = await getCurStudioOrgin(
          this.isSid === 'all' ? { sid: sid } : ''
        )
        this.treeData = data
      },
      bindChange(val) {
        this.queryForm.orgid = ''
        if (val) {
          this.getOriginList(val)
        }
      },
      handleAdd(type) {
        this.$refs[type].showEdit()
      },
      async handleExport() {
        this.listLoading = true
        let queryForm = this.queryForm
        queryForm.pageNo = 1
        queryForm.excel = 'csv'
        const { data, header, filename } = await getUserList(this.queryForm)
        import('@/utils/excel').then((excel) => {
          excel.export_json_to_excel({
            header,
            data,
            filename: filename,
            autoWidth: true,
            bookType: 'xlsx',
          })
          this.listLoading = false
        })
      },
      formatJson(filterVal, jsonData) {
        return jsonData.map((v) =>
          filterVal.map((j) => {
            return v[j]
          })
        )
      },
      handleEdit(row) {
        if (row.uid) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit(null, this.isSid)
        }
      },
      handleChange(value) {
        let id = this.$refs.orCas.getCheckedNodes(true)
        this.queryForm.orgid = id.length ? id[0].value : ''
      },
      handleDelete(row) {
        this.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg, code } = await delUser({ uid: row.uid })
          if (code === 200) {
            this.$baseMessage(msg, 'success')
            await this.fetchData()
          } else {
            this.$baseMessage(msg, 'error')
          }
        })
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        this.$delete(this.queryForm, 'excel')
        const { data, totalCount, button } = await getUserList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
      getDoc() {
        getOrginDoc({ type: 'user' }).then((res) => {
          if (res.code === 200) {
            this.docUrl = res.data
          }
        })
      },
      downLoadDoc() {
        window.location.href = this.docUrl
      },
      toArchive(row) {
        this.$router.push({
          query: { id: row.uid },
          path: '/userManagement/member/archives',
        })
      },
    },
  }
</script>
<style lang="scss"></style>
