var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "warn-notice" },
    [
      _c("el-alert", {
        attrs: {
          closable: false,
          title:
            "说明：该功能主要通过短信的形式将相关的预警数据通知到已绑定手机号的管理员，管理员可登录管理后台查看具体的数据并合理的进行心理服务的后续工作",
          type: "warning",
        },
      }),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "right",
            "label-width": "120px",
            model: _vm.form,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "通知管理员", prop: "admin_id" } },
            [
              _c(
                "template",
                { slot: "label" },
                [
                  _vm._v(" 通知管理员 "),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content: "当前仅展示已绑定手机号的管理员",
                        effect: "dark",
                        placement: "right-start",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-info",
                        staticStyle: { cursor: "pointer" },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.form.admin_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "admin_id", $$v)
                    },
                    expression: "form.admin_id",
                  },
                },
                _vm._l(_vm.adminList, function (admin, index) {
                  return _c(
                    "el-checkbox",
                    { key: index, attrs: { label: admin.id } },
                    [_vm._v(" " + _vm._s(admin.username) + " ")]
                  )
                }),
                1
              ),
            ],
            2
          ),
          _c(
            "el-form-item",
            { attrs: { label: "通知频率", prop: "date" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.date,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "date", $$v)
                    },
                    expression: "form.date",
                  },
                },
                _vm._l(_vm.dateList, function (date, index) {
                  return _c(
                    "el-radio",
                    { key: index, attrs: { label: date.date } },
                    [_vm._v(" " + _vm._s(date.name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "通知规则", prop: "warn" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.warn,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "warn", $$v)
                    },
                    expression: "form.warn",
                  },
                },
                _vm._l(_vm.warnList, function (warn, index) {
                  return _c(
                    "el-radio",
                    { key: index, attrs: { label: warn.sort } },
                    [_vm._v(" " + _vm._s(warn.name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }