<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item v-if="isSid === 'all'" label="渠道" prop="sid">
        <el-select
          v-model="form.sid"
          filterable
          placeholder="选择渠道"
          @change="bindChange"
        >
          <el-option
            v-for="item in studioList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input
          v-model="form.name"
          autocomplete="off"
          placeholder="请输入姓名"
        />
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-radio-group v-model="form.sex">
          <el-radio :label="1">男</el-radio>
          <el-radio :label="2">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="账号" prop="account">
        <el-input
          v-model="form.account"
          :disabled="optionType === 'edit'"
          maxlength="20"
          minlength="1"
          placeholder="可使用字母/数字/数字与字母组合，长度1~20位"
        />
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          v-model="form.password"
          autocomplete="new-password"
          maxlength="12"
          minlength="6"
          :placeholder="
            optionType === 'edit'
              ? '密码长度6~12位，不输入则使用原密码'
              : '密码长度6~12位'
          "
          type="password"
        />
      </el-form-item>
      <el-form-item label="组织结构" prop="orgid">
        <el-cascader
          ref="orCas"
          v-model="form.orgid"
          :options="treeData"
          :props="defaultProps"
          style="width: 100%"
          @change="handleChange"
          @visible-change="cascaderClicked"
        />
      </el-form-item>
      <el-form-item label="联系方式" prop="phone">
        <el-input
          v-model="form.phone"
          maxlength="11"
          placeholder="手机号码"
          type="tel"
        />
      </el-form-item>
      <el-form-item label="第三方账号" prop="third_uid">
        <el-input
          v-model="form.third_uid"
          maxlength="18"
          minlength="1"
          placeholder="可使用字母/数字/数字与字母组合，长度1~18位"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {
    getCurStudioOrgin,
    addUser,
    editUser,
  } from '@/api/userManagementOrganization'
  import { isPassword, isPhone } from '@/utils/validate'

  export default {
    name: 'OrganizationEdit',
    props: {
      list: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      const new_password = (rule, value, callback) => {
        if (value !== '' && !isPassword(value)) {
          callback(new Error('密码不能少于6位'))
        } else {
          callback()
        }
      }
      const validatePhone = (rule, value, callback) => {
        if (value.length && !isPhone(value)) {
          callback(new Error('手机号码格式不正确'))
        } else {
          callback()
        }
      }
      const validateName = (rule, value, callback) => {
        let reg = /^[0-9a-zA-Z]{1,20}$/
        if (!reg.test(value)) {
          callback(new Error('账号为字母/数字/数字与字母组合，长度1~20位'))
        } else {
          callback()
        }
      }
      return {
        studioList: [],
        treeData: [], //获取组织结构
        defaultProps: {
          children: 'children',
          label: 'name',
          value: 'id',
        },
        form: {
          account: '',
          sex: '',
          name: '',
          phone: '',
          password: '',
          orgid: '',
          is_school: '',
          third_uid: '',
        },
        rules: {
          sid: [{ required: true, trigger: 'change', message: '请选择渠道' }],
          name: [{ required: true, trigger: 'blur', message: '请输入姓名' }],
          /*sex: [{ required: true, trigger: 'change', message: '请选择性别' }],
          is_school: [
            { required: true, trigger: 'change', message: '请选择状态' },
          ],*/
          account: [
            { required: true, trigger: 'blur', validator: validateName },
          ],
          password: [
            { required: true, trigger: 'blur', message: '请输入密码' },
            { required: true, trigger: 'blur', validator: new_password },
          ],
          orgid: [
            { required: true, trigger: 'change', message: '请选择组织结构' },
          ],
          phone: [
            { required: false, trigger: 'blur', validator: validatePhone },
          ],
        },
        title: '',
        dialogFormVisible: false,
        optionType: '',
        isSid: '',
      }
    },
    watch: {
      list: {
        handler(newData) {
          this.studioList = newData
        },
        immediate: true,
        deep: true,
      },
    },
    created() {},
    methods: {
      cascaderClicked($event) {
        if ($event) {
          this.getOriginList()
        }
      },
      async getOriginList(sid) {
        //获取组织结构
        const { data } = await getCurStudioOrgin({ sid: sid })
        this.treeData = data
      },
      bindChange(val) {
        this.form.sid = val
        this.form.orgid = ''
        this.getOriginList(val)
      },
      showEdit(row, isSid) {
        this.treeData = []
        this.isSid = isSid
        if (!row) {
          this.title = '添加'
          this.optionType = 'add'
          this.rules.password[0].required = true
          this.rules.password[1].required = true
          if (isSid !== 'all') this.getOriginList()
        } else {
          this.title = '编辑'
          this.optionType = 'edit'
          this.rules.password[0].required = false
          this.rules.password[1].required = false
          editUser({ type: 1, uid: row.uid }).then((res) => {
            this.form = res.data
            this.getOriginList(res.data.sid)
          })
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.form.type = this.optionType === 'edit' ? 0 : ''
            const { msg, user } =
              this.optionType === 'edit'
                ? await editUser(this.form)
                : await addUser(this.form)
            this.$baseMessage(msg, 'success')
            this.close()
            this.$emit('fetch-data', user)
          } else {
            return false
          }
        })
      },
      handleChange(value) {
        let id = this.$refs.orCas.getCheckedNodes(true)
        console.log(value, id[0].value)
      },
    },
  }
</script>
