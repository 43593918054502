<template>
  <div class="interve-list-container">
    <vab-query-form>
      <vab-query-form-left-panel v-if="false" :span="4">
        <el-button
          :disabled="!button.add"
          icon="el-icon-plus"
          type="primary"
          @click="handleEdit('', '/mental/addMental')"
        >
          添加
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="24">
        <el-form
          ref="qf"
          :inline="true"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item v-if="isSid === 'all'" prop="sid">
            <el-select
              v-model="queryForm.sid"
              clearable
              filterable
              placeholder="选择渠道"
            >
              <el-option
                v-for="item in studioList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item prop="origin">
            <el-cascader
              ref="orCas"
              v-model="queryForm.origin"
              clearable
              :options="treeData"
              placeholder="组织结构"
              :props="defaultProps"
              @change="handleChange"
              @visible-change="cascaderClicked"
            />
          </el-form-item>
          <el-form-item prop="name">
            <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入姓名"
            />
          </el-form-item>
          <el-form-item prop="account">
            <el-input
              v-model.trim="queryForm.account"
              clearable
              placeholder="请输入账号"
            />
          </el-form-item>
          <el-form-item prop="pname">
            <el-input
              v-model.trim="queryForm.pname"
              clearable
              placeholder="请输入负责人"
            />
          </el-form-item>
          <el-form-item prop="interve">
            <el-select
              v-model="queryForm.interve"
              clearable
              filterable
              placeholder="干预等级"
            >
              <el-option
                v-for="item in interveList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item prop="assess">
            <el-select
              v-model="queryForm.assess"
              clearable
              filterable
              placeholder="评估等级"
            >
              <el-option
                v-for="item in assessList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item prop="status">
            <el-select
              v-model="queryForm.status"
              clearable
              filterable
              placeholder="状态"
            >
              <el-option
                v-for="item in statusList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
            <el-button icon="el-icon-refresh" type="primary" @click="refresh">
              刷新
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      v-loading="listLoading"
      border
      :data="list"
      @sort-change="changeSort"
    >
      <el-table-column label="姓名" min-width="120" prop="name">
        <template #default="{ row }">
          <div class="user">
            <img v-if="row.sex === '男'" src="@/assets/male.png" />
            <img v-else-if="row.sex === '女'" src="@/assets/female.png" />
            <img v-else src="@/assets/unknow.png" />
            <span>{{ row.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="组织结构" prop="origin" />
      <el-table-column
        align="center"
        label="干预时间"
        prop="mental_time"
        sortable
      />
      <el-table-column
        align="center"
        label="评估级别"
        prop="mental_assess_level_name"
      >
        <template #default="{ row }">
          <span
            v-if="row.mental_assess_level_color"
            class="b_c"
            :style="{
              'background-color': hexToRgba(row.mental_assess_level_color, 0.1),
              color: row.mental_assess_level_color,
              border:
                '1px solid ' + hexToRgba(row.mental_assess_level_color, 0.3),
            }"
          >
            {{ row.mental_assess_level_name }}
          </span>
          <span v-else>{{ row.mental_assess_level_name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="干预级别"
        prop="mental_interve_level_name"
      >
        <template #default="{ row }">
          <span
            v-if="row.mental_interve_level_color"
            class="b_c"
            :style="{
              'background-color': hexToRgba(
                row.mental_interve_level_color,
                0.1
              ),
              color: row.mental_interve_level_color,
              border:
                '1px solid ' + hexToRgba(row.mental_interve_level_color, 0.3),
            }"
          >
            {{ row.mental_interve_level_name }}
          </span>
          <span v-else>{{ row.mental_interve_level_name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="干预内容"
        prop="mental_remark"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="状态" prop="status">
        <template #default="{ row }">
          <el-tag
            :type="
              row.end_status == 3
                ? 'primary'
                : row.end_status == 1
                ? 'warning'
                : 'danger'
            "
          >
            {{ row.end_status_name }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="120">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row, '/mental/addMental')">
            处理
          </el-button>
          <el-button
            :disabled="row.end_status == 3"
            type="text"
            @click="finished(row)"
          >
            结案
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <add-mental ref="add-mental" @fetch-data="fetchData" />
    <finish-case ref="finish" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { getMentalList, getWarnParam } from '@/api/crisis'
  import { getStudioList } from '@/api/platformChannelManagement'
  import { mapGetters } from 'vuex'
  import { getCurStudioOrgin } from '@/api/userManagementOrganization'
  import AddMental from './components/AddMental'
  import FinishCase from './components/finishCase'
  import { hexToRgba } from '@/utils/utils'

  export default {
    name: 'InterveList',
    components: { AddMental, FinishCase },
    data() {
      return {
        list: [],
        studioList: [],
        assessList: [],
        interveList: [],
        statusList: [
          {
            id: 2,
            name: '干预中',
          },
          {
            id: 3,
            name: '已结案',
          },
        ],
        treeData: [], //组织结构
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          pageNo: 1,
          pageSize: 10,
          name: '',
          sid: '',
          origin: '',
          pname: '',
          account: '',
          assess: '',
          interve: '',
          status: '',
        },
        button: {},
        defaultProps: {
          children: 'children',
          label: 'name',
          value: 'id',
          checkStrictly: true,
        },
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    created() {
      const route = this.$route
      this.queryForm.account = route.query.account || ''
      this.getStudio()
      this.levelType('interve')
      this.levelType('assess')
      this.fetchData()
    },
    methods: {
      hexToRgba,
      levelType(type) {
        getWarnParam({ type: type }).then((res) => {
          this[type + 'List'] = res.data
        })
      },
      finished(row) {
        this.$refs['finish'].showEdit(row.id, 'interve')
      },
      changeSort(val) {
        //排序
        let order = val.order === 'ascending' ? 'asc' : 'desc'
        let prop = val.prop
        this.queryForm.pageNo = 1
        this.queryForm.orderby = prop + ' ' + order
        this.fetchData()
      },
      cascaderClicked($event) {
        if ($event) {
          this.getOriginList(this.queryForm.sid)
        }
      },
      handleChange(value) {
        let id = this.$refs.orCas.getCheckedNodes(true)
        this.queryForm.origin = id.length ? id[0].value : ''
      },
      async getOriginList(sid) {
        //获取组织结构
        const { data } = await getCurStudioOrgin(
          this.isSid === 'all' ? { sid: sid } : ''
        )
        this.treeData = data
      },
      async getStudio() {
        const { data } = await getStudioList()
        this.studioList = data
      },
      selectDate(val) {
        this.queryForm.time = val
      },
      handleEdit(row, url, type) {
        if (row.id) {
          if (type === 'record') {
            let routeData = this.routeTo({ id: row.id }, url)
            window.open(routeData.href, '_blank')
          } else {
            /*this.$router.push({
              path: url,
              query: { id: row.id, style: 'interve' },
            })*/
            this.$refs['add-mental'].showDrawer(row, 'interve')
          }
        } else {
          this.$refs['add-mental'].showDrawer(0, 'interve')
          /*this.$router.push({
            path: url,
            query: { style: 'interve' },
          })*/
        }
      },
      routeTo(id, url) {
        let routeData = this.$router.resolve({
          path: url,
          query: id,
        })
        return routeData
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNo = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.pageNo = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data, totalCount, button } = await getMentalList(this.queryForm)
        this.list = data
        this.total = totalCount
        this.button = button
        this.listLoading = false
      },
      refresh() {
        this.$refs['qf'].resetFields()
        this.queryForm.account = ''
        this.queryData()
      },
    },
  }
</script>
<style lang="scss">
  .interve-list-container {
    .b_c {
      display: inline-block;
      padding: 0 7px;
      border-radius: 3px;
      font-size: 12px;
    }
  }
</style>
