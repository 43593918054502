<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item v-if="isSid === 'all'" label="渠道" prop="sid">
        <el-select v-model="form.sid" filterable placeholder="选择渠道">
          <el-option
            v-for="item in studioList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="结构类别" prop="type">
        <el-select v-model="form.type">
          <el-option label="学校学生" value="1" />
        </el-select>
      </el-form-item> -->
      <el-form-item label="上传文件" prop="file">
        <el-upload
          ref="upload"
          accept=".xls,.xlsx"
          :action="UploadUrl()"
          :auto-upload="false"
          :before-upload="beforeUploadFile"
          :file-list="form.file"
          :limit="1"
          :on-change="fileChange"
          :on-error="handleError"
          :on-exceed="exceedFile"
          :on-remove="handleRemove"
          :on-success="handleSuccess"
        >
          <el-button
            v-show="!hasFile"
            slot="trigger"
            size="small"
            type="primary"
          >
            选取文件
          </el-button>
          <el-button v-show="hasFile" disabled size="small" type="primary">
            选取文件
          </el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传
            <span>xls、xlsx</span>
            文件，且
            <span>不超过2M</span>
          </div>
        </el-upload>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getOrginType, doUpload } from '@/api/userManagementOrganization'
  import { mapGetters } from 'vuex'

  export default {
    name: 'OrganizationImport',
    props: {
      list: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        studioList: [],
        orginList: [],
        form: {
          file: [],
          sid: '',
          // type: '1',
        },
        rules: {
          sid: [{ required: true, trigger: 'change', message: '请选择渠道' }],
          // type: [
          //   {
          //     required: true,
          //     message: '请选择组织结构类别',
          //     trigger: 'change',
          //   },
          // ],
          file: [
            { required: true, message: '请选择上传文件', trigger: 'blur' },
          ],
        },
        title: '',
        dialogFormVisible: false,
        hasFile: false,
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    watch: {
      list: {
        handler(newData) {
          if (this.isSid === 'all') this.studioList = newData
        },
        immediate: true,
        deep: true,
      },
    },
    created() {},
    methods: {
      showEdit(row) {
        if (!row) {
          this.title = '添加'
        } else {
          this.title = '编辑'
          this.form = Object.assign({}, this.form, row)
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
        this.hasFile = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const isLt2M = this.form.file.every(
              (file) => file.size / 1024 / 1024 < 2
            )
            if (!isLt2M) {
              this.$message.error('上传文件大小不能超过2M!')
            } else {
              let dataPar = this.form
              let formData = new FormData() //  用FormData存放上传文件
              formData.append('sid', dataPar.sid)
              formData.append('type', dataPar.type)
              this.form.file.forEach((file) => {
                formData.append('file', file.raw, file.raw.name)
              })

              const { msg } = await doUpload(formData)
              this.$baseMessage(msg, 'success')
              this.$emit('fetch-data')
              this.close()
            }
          } else {
            return false
          }
        })
      },
      // 文件超出个数限制时的钩子
      exceedFile(files, fileList) {
        this.$baseMessage(
          `只能选择 1 个文件，当前共选择了 ${
            files.length + fileList.length
          } 个`,
          'warning'
        )
      },
      // 文件状态改变时的钩子
      fileChange(file, fileList) {
        let existFile = fileList
          .slice(0, fileList.length - 1)
          .find((f) => f.name === file.name)
        if (existFile) {
          this.$baseMessage('当前文件已经存在!', 'error')
          fileList.pop()
          return false
        }
        if (fileList.length === 1) {
          this.hasFile = true
        }
        this.form.file = fileList
      },
      // 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
      beforeUploadFile(file) {
        console.log('before upload')
        console.log(file)
        let extension = file.name.substring(file.name.lastIndexOf('.') + 1)
        let size = file.size / 1024 / 1024
        if (extension !== 'xls' && extension !== 'xlsx') {
          this.$baseMessage('只能上传后缀是.xls、.xlsx的文件', 'warning')
        }
        if (size > 2) {
          this.$baseMessage('文件大小不得超过2M', 'warning')
        }
      },
      handleRemove(file, fileList) {
        if (!fileList.length) {
          this.hasFile = false
        }
        this.form.file = []
      },
      // 文件上传成功时的钩子
      handleSuccess(res, file, fileList) {
        this.$baseMessage('上传成功', 'success')
      },
      // 文件上传失败时的钩子
      handleError(err, file, fileList) {
        this.$baseMessage('文件上传失败', 'error')
      },
      UploadUrl: function () {
        // 因为action参数是必填项，我们使用二次确认进行文件上传时，直接填上传文件的url会因为没有参数导致api报404，所以这里将action设置为一个返回为空的方法就行，避免抛错
        return ''
      },
    },
  }
</script>
<style lang="scss" scoped>
  .el-upload__tip {
    display: inline;
    margin-left: 10px;
    > span {
      color: $base-color-red;
      font-weight: bold;
    }
  }
</style>
