import request from '@/utils/request'
/*=======================量表管理=======================*/
//列表
export function getEvalList(data) {
  return request({
    url: '/paper/index',
    method: 'post',
    data,
  })
}
//编辑
export function evalEdit(data) {
  return request({
    url: '/paper/change',
    method: 'post',
    data,
  })
}
//上架、下架
export function setEvalStatus(data) {
  return request({
    url: '/paper/del',
    method: 'post',
    data,
  })
}
//获取量表题目
export function getEvalSubject(params) {
  return request({
    url: '/paper/info',
    method: 'get',
    params,
  })
}
//上传量表题目
export function uploadEval(data) {
  return request({
    url: '/paper/sync-upload',
    method: 'post',
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    data,
  })
}
//上传量表题目文件验证
export function checkEval(data) {
  return request({
    url: '/paper/sync-check',
    method: 'post',
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    data,
  })
}
//编辑量表题目
export function editSubject(id, data) {
  return request({
    url: '/paper/edit?id=' + id,
    method: 'post',
    data,
  })
}

/*=======================自由测评=======================*/
//列表
export function getFreeList(data) {
  return request({
    url: '/papereval/free-list',
    method: 'post',
    data,
  })
}
//上下架
export function setStatus(params) {
  return request({
    url: '/papereval/change-status',
    method: 'get',
    params,
  })
}
//列表增删改查
export function freeListEdit(data) {
  return request({
    url: '/papereval/free-list-change',
    method: 'post',
    data,
  })
}
//分类
export function getFreeCate(data) {
  return request({
    url: '/papereval/free-cate',
    method: 'post',
    data,
  })
}
//分类增删改查
export function freeCateEdit(data) {
  return request({
    url: '/papereval/free-cate-change',
    method: 'post',
    data,
  })
}
//自由测评记录
export function getFreeRecord(data) {
  return request({
    url: '/papereval/free-test-record',
    method: 'post',
    data,
  })
}

/*=======================匿名测评=======================*/
//列表
export function getAnonymList(data) {
  return request({
    url: '/papereval/anonym-list',
    method: 'post',
    data,
  })
}
//列表增删改查
export function anonymListEdit(data) {
  return request({
    url: '/papereval/anonym-list-change',
    method: 'post',
    data,
  })
}
//匿名测评记录
export function getAnonymRecord(data) {
  return request({
    url: '/papereval/anonym-test-record',
    method: 'post',
    data,
  })
}

//获取测评答案（通用）
export function getAnwser(data) {
  return request({
    url: '/papereval/ques',
    method: 'post',
    data,
  })
}
//获取测评报告（通用）
export function getResult(data) {
  return request({
    url: '/papereval/test-result',
    method: 'post',
    data,
  })
}

/*=======================自定义预警=======================*/
//获取预警方案列表
export function getWarnPlan(params) {
  return request({
    url: '/warning/plan-lists',
    method: 'get',
    params,
  })
}
//操作预警方案
export function doOpration(data) {
  return request({
    url: '/warning/plan-change',
    method: 'post',
    data,
  })
}

//编辑预警方案
export function doPlanEdit(data) {
  return request({
    url: '/warning/plan-detail',
    method: 'post',
    data,
  })
}

//预警逻辑列表
export function getRuleList(data) {
  return request({
    url: '/warning/plan-rule',
    method: 'post',
    data,
  })
}

//操作预警逻辑、预警项列表
export function doRuleEdit(data) {
  return request({
    url: '/warning/plan-rule-detail',
    method: 'post',
    data,
  })
}

//预警项需要获取的字段内容
export function getWarnType(params) {
  return request({
    url: '/warning/plan-warning-type',
    method: 'get',
    params,
  })
}

//预警项的信息获取 编辑 新增
export function doRuleItemEdit(data) {
  return request({
    url: '/warning/plan-warning-item',
    method: 'post',
    data,
  })
}

/*=======================因子分=======================*/
//获取因子分列表
export function getScoreList(params) {
  return request({
    url: '/paper/index-score-rule',
    method: 'get',
    params,
  })
}
//编辑因子分
export function eidtScore(id, data) {
  return request({
    url: '/paper/edit-score-rule?id=' + id,
    method: 'post',
    data,
  })
}
//获取因子分规则详情
export function getScoreDetail(params) {
  return request({
    url: '/paper/info-score-rule',
    method: 'get',
    params,
  })
}
//获取因子分列表
export function delScore(data) {
  return request({
    url: '/paper/del-score-rule',
    method: 'post',
    data,
  })
}
//获取因子分得分分级列表
export function getLvList(params) {
  return request({
    url: '/paper/index-lv-rule',
    method: 'get',
    params,
  })
}
//编辑因子分得分分级
export function eidtLv(id, data) {
  return request({
    url: '/paper/edit-lv-rule?id=' + id,
    method: 'post',
    data,
  })
}
//删除因子分得分分级
export function delLv(data) {
  return request({
    url: '/paper/del-lv-rule',
    method: 'post',
    data,
  })
}
