var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.initData
    ? _c(
        "div",
        { staticClass: "eval-container" },
        [
          _c(
            "div",
            { ref: "print", staticClass: "content" },
            [
              _vm.initData.tips
                ? _c(
                    "div",
                    { staticClass: "recreatetips no-print" },
                    [
                      _c(
                        "el-alert",
                        {
                          attrs: {
                            effect: "dark",
                            title: _vm.initData.tips,
                            type: "success",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn",
                              attrs: { type: "text" },
                              on: { click: _vm.showBox },
                            },
                            [_vm._v(" 生成报告 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "nav-right no-print" },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.jump(0)
                        },
                      },
                    },
                    [_vm._v("封面")]
                  ),
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.jump(1)
                        },
                      },
                    },
                    [_vm._v("目录")]
                  ),
                  _vm._l(_vm.mulu, function (menu, mIndex) {
                    return _c(
                      "a",
                      {
                        key: mIndex,
                        attrs: { href: "javascript:void(0)", title: menu.name },
                        on: {
                          click: function ($event) {
                            return _vm.jump(menu.page + 1)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(menu.name) + " ")]
                    )
                  }),
                  _c(
                    "a",
                    {
                      staticClass: "btn-a",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.print("word")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-printer" }),
                      _vm._v(" 导出word "),
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn-a",
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.print("pdf")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-printer" }),
                      _vm._v(" 导出PDF "),
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn-a",
                      attrs: { href: "javascript:void(0)" },
                      on: { click: _vm.showBox },
                    },
                    [
                      _c("i", { staticClass: "el-icon-refresh" }),
                      _vm._v(" 重新生成 "),
                    ]
                  ),
                ],
                2
              ),
              _c("el-card", { staticClass: "anchor first" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "font-size": "45px",
                      "font-weight": "bold",
                      padding: "200px 0 40px",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.basic.title) + " ")]
                ),
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.basic.subhead)),
                ]),
                _c(
                  "div",
                  { staticClass: "bg" },
                  [
                    _c("div", { staticClass: "time" }, [
                      _c("div", [
                        _vm._v("普查时间：" + _vm._s(_vm.basic.times)),
                      ]),
                      _vm.basic.update_times
                        ? _c("div", [
                            _vm._v(
                              " 报告生成时间：" +
                                _vm._s(_vm.basic.update_times) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    false
                      ? _c("el-image", {
                          attrs: { src: require("@/assets/report.svg") },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("el-card", { staticClass: "anchor" }, [
                _c("div", { staticClass: "card-header" }, [
                  _c("span", [_vm._v("目录")]),
                ]),
                _c(
                  "div",
                  { staticClass: "catalogue" },
                  _vm._l(_vm.mulu, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "li" },
                      [
                        _c("div", { staticClass: "pra-item" }, [
                          _c("div", { staticClass: "left" }, [
                            _vm._v(" " + _vm._s("0" + (index + 1) + "/") + " "),
                            _c("span", { staticClass: "p-title" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                          ]),
                          _c("div", { staticClass: "line" }),
                          _c("div", { staticClass: "right" }, [
                            _vm._v(_vm._s(item.page)),
                          ]),
                        ]),
                        item.children
                          ? _vm._l(item.children, function (sub, j) {
                              return _c(
                                "div",
                                { key: j, staticClass: "sub-item" },
                                [
                                  _c("div", { staticClass: "left" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(index + 1 + "." + (j + 1)) +
                                        _vm._s(sub.name) +
                                        " "
                                    ),
                                  ]),
                                  _c("div", { staticClass: "line" }),
                                  _c("div", { staticClass: "right" }, [
                                    _vm._v(_vm._s(sub.page)),
                                  ]),
                                ]
                              )
                            })
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
              _c("el-card", { staticClass: "anchor" }, [
                _c("div", { staticClass: "card-page" }, [
                  _c("div", { staticClass: "l" }, [
                    _vm._v(_vm._s(_vm.basic.subhead)),
                  ]),
                  _c("div", { staticClass: "c" }),
                  _c("div", { staticClass: "r" }, [
                    _vm._v("第" + _vm._s(_vm.initData.first.page) + "页"),
                  ]),
                ]),
                _c("div", { staticClass: "card-header" }, [
                  _c("span", [_vm._v("报告前言")]),
                ]),
                _c("div", { staticClass: "qy" }, [
                  _c("div", { staticClass: "qy-item" }, [
                    _c("div", { staticClass: "qy-title" }, [
                      _vm._v("一、测评意义"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "contxt",
                        domProps: {
                          innerHTML: _vm._s(_vm.initData.first.purport),
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.initData.first.purport) + " ")]
                    ),
                  ]),
                  _c("div", { staticClass: "qy-item" }, [
                    _c("div", { staticClass: "qy-title" }, [
                      _vm._v("二、报告说明"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "contxt",
                        domProps: {
                          innerHTML: _vm._s(_vm.initData.first.explain),
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.initData.first.explain) + " ")]
                    ),
                  ]),
                ]),
              ]),
              _c("el-card", { staticClass: "anchor" }, [
                _c("div", { staticClass: "card-page" }, [
                  _c("div", { staticClass: "l" }, [
                    _vm._v(_vm._s(_vm.basic.subhead)),
                  ]),
                  _c("div", { staticClass: "c" }),
                  _c("div", { staticClass: "r" }, [
                    _vm._v("第" + _vm._s(_vm.initData.student.page) + "页"),
                  ]),
                ]),
                _c("div", { staticClass: "card-header" }, [
                  _c("span", [_vm._v("完成概况")]),
                ]),
                _c(
                  "div",
                  { staticClass: "card-box" },
                  [
                    _c("div", { staticClass: "team-info" }, [
                      _c("div", { staticClass: "team-info-top" }, [
                        _c("span", [_vm._v("完成统计")]),
                      ]),
                      _c("div", { staticClass: "team-info-div" }, [
                        _vm._v(
                          " " + _vm._s(_vm.pepInfo.descr) + " 应测评人数为 "
                        ),
                        _c("span", [_vm._v(_vm._s(_vm.pepInfo.all))]),
                        _vm._v(" 人，完成所有量表的人数为 "),
                        _c("span", [_vm._v(_vm._s(_vm.pepInfo.comp))]),
                        _vm._v(" 人，完成率为 "),
                        _c("span", [_vm._v(_vm._s(_vm.pepInfo.comp_rate))]),
                        _vm._v(" ，参与团测人数为 "),
                        _c("span", [_vm._v(_vm._s(_vm.pepInfo.add))]),
                        _vm._v(" 人，参与率为 "),
                        _c("span", [_vm._v(_vm._s(_vm.pepInfo.add_rate))]),
                      ]),
                      _c("div", { staticClass: "tips" }, [
                        _vm._v(
                          " 注：参与团测包含只完成部分量表用户和完成所有量表用户 "
                        ),
                      ]),
                    ]),
                    _c("vab-chart", {
                      ref: "pepChart",
                      staticClass: "no-print",
                      attrs: {
                        options: _vm.initData.pepChart,
                        theme: "vab-echarts-theme",
                      },
                    }),
                    _c("img", {
                      staticClass: "canvas-img",
                      attrs: { src: _vm.gkImg.pepChart },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "card-box" },
                  [
                    _c("div", { staticClass: "team-info" }, [
                      _c("div", { staticClass: "team-info-top" }, [
                        _c("span", [_vm._v("预警统计")]),
                      ]),
                      _c("div", { staticClass: "team-info-div" }, [
                        _vm._v(" 团测报告共分析样本 "),
                        _c("span", [_vm._v(_vm._s(_vm.pepInfo.join))]),
                        _vm._v(" 人，其中 "),
                        _c("span", [_vm._v(_vm._s(_vm.evalInfo.warn))]),
                        _vm._v(" 触发预警，占比 "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.evalInfo.warn_join_rate)),
                        ]),
                        _vm._v(" ；其中 "),
                        _c("span", [_vm._v(_vm._s(_vm.evalInfo.max))]),
                        _c("span", [_vm._v(_vm._s(_vm.evalInfo.max_pep))]),
                        _vm._v(" 预警， "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.evalInfo.max_pep_descr)),
                        ]),
                        _vm._v(
                          " ， " + _vm._s(_vm.evalInfo.max_pep_descr2) + " "
                        ),
                        _c("span", [_vm._v(_vm._s(_vm.evalInfo.max_pep_rate))]),
                        _vm._v(" 。 "),
                      ]),
                    ]),
                    _c("vab-chart", {
                      ref: "evalChart",
                      staticClass: "no-print",
                      attrs: {
                        options: _vm.initData.evalChart,
                        theme: "vab-echarts-theme",
                      },
                    }),
                    _c("img", {
                      staticClass: "canvas-img",
                      attrs: { src: _vm.gkImg.evalChart },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._l(_vm.tables, function (item, tIndex) {
                return _c(
                  "el-card",
                  { key: tIndex, staticClass: "anchor" },
                  [
                    _c("div", { staticClass: "card-page" }, [
                      _c("div", { staticClass: "l" }, [
                        _vm._v(_vm._s(_vm.basic.subhead)),
                      ]),
                      _c("div", { staticClass: "c" }),
                      _c("div", { staticClass: "r" }, [
                        _vm._v("第" + _vm._s(item.page) + "页"),
                      ]),
                    ]),
                    _c("div", { staticClass: "card-header" }, [
                      _c("span", [_vm._v("建档数据")]),
                    ]),
                    _c(
                      "el-table",
                      { attrs: { border: "", data: item.list } },
                      [
                        _c("el-table-column", {
                          attrs: { "min-width": "300", prop: "name" },
                        }),
                        _c("el-table-column", {
                          attrs: { align: "center", label: "男", prop: "man" },
                        }),
                        _c("el-table-column", {
                          attrs: { align: "center", label: "女", prop: "wom" },
                        }),
                        _c("el-table-column", {
                          attrs: { align: "center", label: "不详", prop: "no" },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "合计（人）",
                            prop: "all",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _vm._l(_vm.evalTable, function (table, index) {
                return [
                  _c("el-card", { key: index + ".0", staticClass: "anchor" }, [
                    _c("div", { staticClass: "card-page" }, [
                      _c("div", { staticClass: "l" }, [
                        _vm._v(_vm._s(_vm.basic.subhead)),
                      ]),
                      _c("div", { staticClass: "c" }),
                      _c("div", { staticClass: "r" }, [
                        _vm._v("第" + _vm._s(table.detail.page) + "页"),
                      ]),
                    ]),
                    _c("div", { staticClass: "card-header" }, [
                      _c("span", [_vm._v(_vm._s(table.title))]),
                    ]),
                    _c("div", { staticClass: "card-box" }, [
                      _c("div", { staticClass: "team-info" }, [
                        _c("div", { staticClass: "team-info-top" }, [
                          _c("span", [_vm._v("问卷概况")]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "team-info-div",
                            domProps: {
                              innerHTML: _vm._s(table.detail.survey),
                            },
                          },
                          [_vm._v(" " + _vm._s(table.detail.survey) + " ")]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "card-box" }, [
                      _c("div", { staticClass: "team-info" }, [
                        _c("div", { staticClass: "team-info-top" }, [
                          _c("span", [_vm._v("调查目的")]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "team-info-div",
                            domProps: {
                              innerHTML: _vm._s(table.detail.purpose),
                            },
                          },
                          [_vm._v(" " + _vm._s(table.detail.purpose) + " ")]
                        ),
                      ]),
                    ]),
                  ]),
                  _c("el-card", { key: index + ".1", staticClass: "anchor" }, [
                    _c("div", { staticClass: "card-page" }, [
                      _c("div", { staticClass: "l" }, [
                        _vm._v(_vm._s(_vm.basic.subhead)),
                      ]),
                      _c("div", { staticClass: "c" }),
                      _c("div", { staticClass: "r" }, [
                        _vm._v("第" + _vm._s(table.eval.page) + "页"),
                      ]),
                    ]),
                    _c("div", { staticClass: "card-header" }, [
                      _c("span", [_vm._v(_vm._s(table.title))]),
                    ]),
                    _c("div", { staticClass: "card-box" }, [
                      _c(
                        "div",
                        { staticClass: "team-info" },
                        [
                          _c("div", { staticClass: "team-info-title" }, [
                            _c("span", [_vm._v("调查统计")]),
                          ]),
                          _c("div", { staticClass: "team-info-top" }, [
                            _c("span", [_vm._v("数据说明")]),
                          ]),
                          _c("div", { staticClass: "team-info-div" }, [
                            _c("span", [_vm._v(_vm._s(table.eval.name))]),
                            _c("span", [_vm._v(_vm._s(_vm.pepInfo.descr))]),
                            _vm._v(" 计划测评 "),
                            _c("span", [_vm._v(_vm._s(_vm.evalInfo.all))]),
                            _vm._v(" ； "),
                            _c("span", [_vm._v(_vm._s(table.eval.test))]),
                            _vm._v(" 人完成测试，完成率 "),
                            _c("span", [_vm._v(_vm._s(table.eval.test_rate))]),
                            _vm._v(" ; 共有 "),
                            _c("span", [_vm._v(_vm._s(table.eval.warn))]),
                            _vm._v(" 人预警，占完成测试人数的 "),
                            _c("span", [_vm._v(_vm._s(table.eval.rate))]),
                            _vm._v(" 。 "),
                          ]),
                          _c("vab-chart", {
                            ref: "tjCharts_" + index,
                            refInFor: true,
                            staticClass: "no-print",
                            attrs: {
                              height:
                                _vm.tjChartList[index].chartVal.series.length >
                                2
                                  ? 800
                                  : 400,
                              options: _vm.tjChartList[index].chartVal,
                              theme: "vab-echarts-theme",
                            },
                          }),
                          _c("img", {
                            staticClass: "canvas-img",
                            attrs: { src: _vm.tjChartsImg[index] },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  table.factor.type === "table"
                    ? _c(
                        "el-card",
                        { key: index + ".2", staticClass: "anchor" },
                        [
                          _c("div", { staticClass: "card-page" }, [
                            _c("div", { staticClass: "l" }, [
                              _vm._v(_vm._s(_vm.basic.subhead)),
                            ]),
                            _c("div", { staticClass: "c" }),
                            _c("div", { staticClass: "r" }, [
                              _vm._v("第" + _vm._s(table.factor.page) + "页"),
                            ]),
                          ]),
                          _c("div", { staticClass: "card-header" }, [
                            _c("span", [_vm._v(_vm._s(table.title))]),
                          ]),
                          _c("div", { staticClass: "card-box" }, [
                            _c(
                              "div",
                              { staticClass: "team-info" },
                              [
                                _c("div", { staticClass: "team-info-title" }, [
                                  _vm._v("因子分布"),
                                ]),
                                table.factor.list.length
                                  ? _c(
                                      "el-table",
                                      {
                                        attrs: {
                                          border: "",
                                          data: table.factor.list,
                                          size: "mini",
                                        },
                                      },
                                      _vm._l(
                                        table.factor.table,
                                        function (item, i_t) {
                                          return _c("el-table-column", {
                                            key: i_t,
                                            attrs: {
                                              label: item.name,
                                              "min-width": "100",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ row }) {
                                                    return [
                                                      _c("span", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            row[item.value]
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  : _c("no-data", {
                                      attrs: { txt: "暂无数据" },
                                    }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "card-box" }, [
                            _c("div", { staticClass: "team-info" }, [
                              _c("div", { staticClass: "team-info-top" }, [
                                _c("span", [_vm._v("数据说明")]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "team-info-div",
                                  domProps: {
                                    innerHTML: _vm._s(table.factor.descr),
                                  },
                                },
                                [_vm._v(" " + _vm._s(table.factor.descr) + " ")]
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._l(table.factor.list, function (yz, z) {
                        return _c(
                          "el-card",
                          { key: index + ".2" + z, staticClass: "anchor" },
                          [
                            _c("div", { staticClass: "card-page" }, [
                              _c("div", { staticClass: "l" }, [
                                _vm._v(_vm._s(_vm.basic.subhead)),
                              ]),
                              _c("div", { staticClass: "c" }),
                              _c("div", { staticClass: "r" }, [
                                _vm._v("第" + _vm._s(yz.page) + "页"),
                              ]),
                            ]),
                            _c("div", { staticClass: "card-header" }, [
                              _c("span", [_vm._v(_vm._s(table.title))]),
                            ]),
                            _c("div", { staticClass: "team-info" }, [
                              _c("div", { staticClass: "team-info-title" }, [
                                _vm._v(_vm._s("因子分布 - " + yz.title)),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "card-box" },
                              [
                                _c("vab-chart", {
                                  ref: _vm.yzChartsList[index][z].cid,
                                  refInFor: true,
                                  staticClass: "no-print",
                                  attrs: {
                                    options:
                                      _vm.yzChartsList[index][z].chartVal,
                                    theme: "vab-echarts-theme",
                                  },
                                }),
                                _c("img", {
                                  staticClass: "canvas-img",
                                  attrs: {
                                    src: _vm.yzChartsList[index][z].charImg,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "card-box" }, [
                              _c("div", { staticClass: "team-info" }, [
                                _c("div", { staticClass: "team-info-top" }, [
                                  _c("span", [_vm._v("注解")]),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "team-info-div",
                                    domProps: { innerHTML: _vm._s(yz.care) },
                                  },
                                  [_vm._v(" " + _vm._s(yz.care) + " ")]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "card-box" }, [
                              _c("div", { staticClass: "team-info" }, [
                                _c("div", { staticClass: "team-info-top" }, [
                                  _c("span", [_vm._v("数据说明")]),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "team-info-div",
                                    domProps: { innerHTML: _vm._s(yz.explain) },
                                  },
                                  [_vm._v(" " + _vm._s(yz.explain) + " ")]
                                ),
                              ]),
                            ]),
                          ]
                        )
                      }),
                  _c("el-card", { key: index + ".3", staticClass: "anchor" }, [
                    _c("div", { staticClass: "card-page" }, [
                      _c("div", { staticClass: "l" }, [
                        _vm._v(_vm._s(_vm.basic.subhead)),
                      ]),
                      _c("div", { staticClass: "c" }),
                      _c("div", { staticClass: "r" }, [
                        _vm._v("第" + _vm._s(table.warn.page) + "页"),
                      ]),
                    ]),
                    _c("div", { staticClass: "card-header" }, [
                      _c("span", [_vm._v(_vm._s(table.title))]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "card-box" },
                      [
                        _c("div", { staticClass: "team-info" }, [
                          _c("div", { staticClass: "team-info-title" }, [
                            _vm._v("预警统计"),
                          ]),
                        ]),
                        table.warn.factor.length
                          ? [
                              _c(
                                "el-table",
                                {
                                  attrs: {
                                    border: "",
                                    data: table.warn.factor,
                                    size: "mini",
                                  },
                                },
                                [
                                  _vm.isNJ
                                    ? [
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: "预警等级",
                                            prop: "name",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: "预警内容",
                                            prop: "descr",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: "样本数",
                                            prop: "nums",
                                            width: "100",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            align: "center",
                                            label: "占比",
                                            prop: "rate",
                                            width: "100",
                                          },
                                        }),
                                      ]
                                    : [
                                        table.warn.plan
                                          ? [
                                              _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  label: "预警等级",
                                                  prop: "descr",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  label: "样本数",
                                                  prop: "nums",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  label: "占比",
                                                  prop: "rate",
                                                },
                                              }),
                                            ]
                                          : [
                                              _c("el-table-column", {
                                                attrs: {
                                                  label: "",
                                                  prop: "fac",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  label: "预警范围(分)",
                                                  prop: "min",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({ row }) {
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                row.min +
                                                                  "~" +
                                                                  row.max
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  label: "样本总数",
                                                  prop: "example",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  label: "占比",
                                                  prop: "rate",
                                                },
                                              }),
                                            ],
                                      ],
                                ],
                                2
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "chart-box",
                                  staticStyle: { "margin-top": "60px" },
                                },
                                [
                                  _c("vab-chart", {
                                    ref: _vm.yjChartsList[index].cid,
                                    refInFor: true,
                                    staticClass: "no-print",
                                    attrs: {
                                      height: _vm.yjChartsList[index].cHeight,
                                      options: _vm.yjChartsList[index].chartVal,
                                      theme: "vab-echarts-theme",
                                    },
                                  }),
                                  _c("img", {
                                    staticClass: "canvas-img",
                                    attrs: {
                                      src: _vm.yjChartsList[index].charImg,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _c("no-data", { attrs: { txt: "暂无数据" } }),
                      ],
                      2
                    ),
                    _c("div", { staticClass: "card-box" }, [
                      _c("div", { staticClass: "team-info" }, [
                        _c("div", { staticClass: "team-info-top" }, [
                          _c("span", [_vm._v("预警说明")]),
                        ]),
                        _c("div", { staticClass: "team-info-div" }, [
                          _vm._v(
                            " " + _vm._s(table.warn.descr || "暂无数据") + " "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  table.chart.length
                    ? _vm._l(table.chart, function (chart, j) {
                        return _c(
                          "el-card",
                          { key: index + ".4" + j, staticClass: "anchor" },
                          [
                            _c("div", { staticClass: "card-page" }, [
                              _c("div", { staticClass: "l" }, [
                                _vm._v(_vm._s(_vm.basic.subhead)),
                              ]),
                              _c("div", { staticClass: "c" }),
                              _c("div", { staticClass: "r" }, [
                                _vm._v("第" + _vm._s(chart.page) + "页"),
                              ]),
                            ]),
                            _c("div", { staticClass: "card-header" }, [
                              _c("span", [_vm._v(_vm._s(table.title))]),
                            ]),
                            _c("div", { staticClass: "card-box" }, [
                              _c("div", { staticClass: "team-info" }, [
                                _c("div", { staticClass: "team-info-title" }, [
                                  _vm._v("维度分析"),
                                ]),
                                _c("div", { staticClass: "team-info-top" }, [
                                  _c("span", [_vm._v(_vm._s(chart.fac))]),
                                ]),
                                _c("div", { staticClass: "team-info-div" }, [
                                  _vm._v(_vm._s(chart.descr)),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "chart-box" },
                                [
                                  _c("vab-chart", {
                                    ref: chart.cid,
                                    refInFor: true,
                                    staticClass: "no-print",
                                    attrs: {
                                      height: chart.cHeight,
                                      options: chart.chartVal,
                                      theme: "vab-echarts-theme",
                                    },
                                  }),
                                  _c("img", {
                                    staticClass: "canvas-img",
                                    attrs: { src: chart.charImg },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        )
                      })
                    : _vm._e(),
                ]
              }),
              _vm.initData.other.list.length
                ? _c("el-card", { staticClass: "anchor" }, [
                    _c("div", { staticClass: "card-page" }, [
                      _c("div", { staticClass: "l" }, [
                        _vm._v(_vm._s(_vm.basic.subhead)),
                      ]),
                      _c("div", { staticClass: "c" }),
                      _c("div", { staticClass: "r" }, [
                        _vm._v("第" + _vm._s(_vm.initData.other.page) + "页"),
                      ]),
                    ]),
                    _c("div", { staticClass: "card-header" }, [
                      _c("span", [_vm._v("附录")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "fl-list" },
                      _vm._l(_vm.initData.other.list, function (fl, f) {
                        return _c(
                          "div",
                          { key: f, staticClass: "fl-item" },
                          [
                            _c("span", [_vm._v("附录" + _vm._s(f + 1) + "：")]),
                            _c("el-button", { attrs: { type: "text" } }, [
                              _vm._v(" " + _vm._s(fl) + " "),
                            ]),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
          _c("origin", { ref: "origin", on: { refresh: _vm.refresh } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }