/**
 * @description router全局配置，如有必要可分文件抽离，其中asyncRoutes只有在intelligence模式下才会用到，pro版只支持remixIcon图标，具体配置请查看vip群文档
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/vab/layouts'
/* 多级路由不需要缓存时可放开注释直接引入 用法component:VabEmptyLayout*/
/*import VabEmptyLayout from '@/vab/layouts/VabEmptyLayout'*/
import { publicPath, routerMode } from '@/config'

Vue.use(VueRouter)
export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login'),
    hidden: true,
  },
  {
    path: '/register',
    component: () => import('@/views/register'),
    hidden: true,
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/403'),
    hidden: true,
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    hidden: true,
  },
  {
    path: '/other/answerNotes',
    name: 'AnswerNotes',
    component: () => import('@/views/other/answerNotes'),
    hidden: true,
  },
  {
    path: '/other/surveyAnswerNotes',
    name: 'SurveyAnswerNotes',
    component: () => import('@/views/other/surveyAnswerNotes'),
    hidden: true,
  },
  {
    path: '/other/teamEvalRecord',
    name: 'TeamEvalRecord',
    component: () => import('@/views/other/teamEvalRecord'),
    hidden: true,
  },
  {
    path: '/other/teamRecord',
    name: 'TeamRecord',
    component: () => import('@/views/other/teamRecord'),
    hidden: true,
  },
  {
    path: '/other/singleWarnRecord',
    name: 'SingleWarnRecord',
    component: () => import('@/views/other/singleWarnRecord'),
    hidden: true,
  },
  {
    path: '/other/mmptRecord',
    name: 'MmptRecord2',
    component: () => import('@/views/other/mmptRecord'),
    hidden: true,
  },
  {
    path: '/other/surveyAnalyse',
    name: 'SurveyAnalyse',
    component: () => import('@/views/other/surveyAnalyse'),
    hidden: true,
  },
  {
    path: '/viewData',
    name: 'ViewData',
    component: () => import('@/views/viewData'),
    hidden: false,
  },
  {
    path: '/other/commAnswer',
    name: 'CommAnswer',
    component: () => import('@/views/other/commAnswer'),
    hidden: true,
  },
  {
    path: '/other/commRecord',
    name: 'CommRecord',
    component: () => import('@/views/other/commRecord'),
    hidden: true,
  },
  {
    path: '/other/paper',
    name: 'Paper',
    component: () => import('@/views/other/paper'),
    hidden: true,
  },
]

export const asyncRoutes = [
  {
    path: '/',
    name: 'Root',
    component: Layout,
    redirect: '/index',
    hidden: false,
    meta: {
      title: '首页',
      icon: 'home-2-line',
      dynamicNewTab: false,
    },
    children: [
      {
        path: 'index',
        name: 'Index',
        hidden: false,
        component: () => import('@/views/index'),
        meta: {
          title: '首页',
          icon: 'home-2-line',
          dynamicNewTab: false,
        },
      },
      {
        path: 'workbench',
        name: 'Workbench',
        hidden: false,
        component: () => import('@/views/index/workbench'),
        meta: {
          title: '工作台',
          icon: 'tv-2-line',
          dynamicNewTab: false,
        },
      },
      {
        path: 'toDo',
        name: 'toDo',
        hidden: true,
        component: () => import('@/views/index/toDo'),
        meta: {
          title: '待办事项',
          icon: 'todo-line',
          dynamicNewTab: false,
        },
      },
      {
        path: 'noticeList',
        name: 'NoticeList',
        hidden: false,
        component: () => import('@/views/index/noticeList'),
        meta: {
          title: '公告列表',
          icon: 'todo-line',
          dynamicNewTab: false,
        },
      },
    ],
  },
  {
    path: '/userManagement',
    name: 'UserManagement',
    component: Layout,
    redirect: '/userManagement/member/userList',
    hidden: false,
    meta: {
      title: '用户',
      icon: 'user-settings-line',
      dynamicNewTab: false,
    },
    children: [
      {
        path: 'member',
        name: 'Member',
        hidden: false,
        component: () => import('@/views/userManagement/member'),
        redirect: '/userManagement/member/userList',
        meta: {
          title: '用户中心',
          icon: 'user-line',
          dynamicNewTab: false,
        },
        children: [
          {
            path: 'userList',
            name: 'UserList',
            hidden: false,
            component: () => import('@/views/userManagement/member/userList'),
            meta: {
              title: '用户列表',
              dynamicNewTab: false,
            },
          },
          {
            path: 'archives',
            name: 'Archives',
            hidden: false,
            component: () => import('@/views/userManagement/member/archives'),
            meta: {
              title: '档案管理',
              dynamicNewTab: false,
            },
          },
        ],
      },
      {
        path: 'consultant',
        name: 'Consultant',
        hidden: false,
        component: () => import('@/views/userManagement/consultant'),
        redirect: '/userManagement/consultant/userList',
        meta: {
          title: '咨询师',
          icon: 'user-2-line',
          dynamicNewTab: false,
        },
        children: [
          {
            path: 'list',
            name: 'List',
            hidden: false,
            component: () => import('@/views/userManagement/consultant/list'),
            meta: {
              title: '列表',
              dynamicNewTab: false,
            },
          },
          {
            path: 'examine',
            name: 'Examine',
            hidden: false,
            component: () =>
              import('@/views/userManagement/consultant/examine'),
            meta: {
              title: '入驻审核',
              dynamicNewTab: false,
            },
          },
        ],
      },
      {
        path: 'organization',
        name: 'Organization',
        hidden: false,
        component: () => import('@/views/userManagement/organization'),
        meta: {
          title: '组织结构',
          icon: 'organization-chart',
          dynamicNewTab: false,
        },
      },
    ],
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Layout,
    redirect: '/orders/consult',
    hidden: false,
    meta: {
      title: '订单',
      icon: 'file-paper-2-line',
      dynamicNewTab: false,
    },
    children: [
      {
        path: 'consult',
        name: 'OrdersConsult',
        hidden: false,
        component: () => import('@/views/orders/consult'),
        meta: {
          title: '咨询',
          icon: 'question-answer-line',
          dynamicNewTab: false,
        },
      },
      {
        path: 'evaluation',
        name: 'OrdersEvaluation',
        hidden: false,
        component: () => import('@/views/orders/evaluation'),
        meta: {
          title: '测评',
          icon: 'edit-2-line',
          dynamicNewTab: false,
        },
      },
      {
        path: 'course',
        name: 'OrdersCourse',
        hidden: false,
        component: () => import('@/views/orders/course'),
        meta: {
          title: '课程',
          icon: 'book-open-line',
          dynamicNewTab: false,
        },
      },
      {
        path: 'room',
        name: 'OrdersRoom',
        hidden: false,
        component: () => import('@/views/orders/room'),
        meta: {
          title: '咨询室',
          icon: 'door-open-line',
          dynamicNewTab: false,
        },
      },
    ],
  },
  {
    path: '/mmpt',
    name: 'Mmpt',
    component: Layout,
    redirect: '/mmpt/category',
    hidden: false,
    meta: {
      title: '测评',
      icon: 'edit-2-line',
      dynamicNewTab: false,
    },
    children: [
      {
        path: 'category',
        name: 'MmptCategory',
        hidden: false,
        component: () => import('@/views/mmpt/category'),
        meta: {
          title: '分类',
          icon: 'list-unordered',
          dynamicNewTab: false,
        },
      },
      {
        path: 'eval',
        name: 'MmptEval',
        hidden: false,
        component: () => import('@/views/mmpt/eval'),
        meta: {
          title: '量表',
          icon: 'briefcase-2-line',
          dynamicNewTab: false,
        },
      },
      {
        path: 'record',
        name: 'MmptRecord',
        hidden: false,
        component: () => import('@/views/mmpt/record'),
        meta: {
          title: '记录',
          icon: 'file-list-2-line',
          dynamicNewTab: false,
        },
      },
    ],
  },
  {
    path: '/team',
    name: 'Teams',
    component: Layout,
    redirect: '/team/list/teamList',
    hidden: false,
    meta: {
      title: '团测',
      icon: 'team-line',
      dynamicNewTab: false,
    },
    children: [
      {
        path: 'list',
        name: 'Lists',
        hidden: false,
        component: () => import('@/views/team/list'),
        redirect: '/team/list/teamList',
        meta: {
          title: '列表',
          icon: 'file-list-3-line',
          dynamicNewTab: false,
        },
        children: [
          {
            path: 'teamList',
            name: 'TeamList',
            hidden: false,
            component: () => import('@/views/team/list/teamList'),
            meta: {
              title: '列表',
              icon: 'file-list-3-line',
              dynamicNewTab: false,
            },
          },
          {
            path: 'addTeamTest',
            name: 'AddTeamTest',
            hidden: true,
            component: () => import('@/views/team/list/addTeamTest'),
            meta: {
              title: '团测计划',
              icon: 'file-list-3-line',
              dynamicNewTab: true,
            },
          },
          {
            path: 'schel',
            name: 'TeamSchel',
            hidden: true,
            component: () => import('@/views/team/list/schel'),
            meta: {
              title: '完成情况',
              icon: 'file-list-3-line',
              dynamicNewTab: true,
            },
          },
        ],
      },
      {
        path: 'single',
        name: 'TeamSingle',
        hidden: false,
        component: () => import('@/views/team/single'),
        meta: {
          title: '个体预警',
          icon: 'error-warning-line',
          dynamicNewTab: false,
        },
      },
      {
        path: 'group',
        name: 'TeamGroup',
        hidden: false,
        component: () => import('@/views/team/group'),
        meta: {
          title: '团体预警',
          icon: 'error-warning-line',
          dynamicNewTab: false,
        },
      },
      {
        path: 'report',
        name: 'TeamReport',
        hidden: false,
        component: () => import('@/views/team/report'),
        meta: {
          title: '团体报告',
          icon: 'clipboard-line',
          dynamicNewTab: false,
        },
      },
    ],
  },
  {
    path: '/market',
    name: 'Market',
    component: Layout,
    redirect: '/market/member/memberSetting',
    hidden: false,
    meta: {
      title: '营销',
      icon: 'stack-line',
      dynamicNewTab: false,
    },
    children: [
      {
        path: 'member',
        name: 'MarketMember',
        hidden: false,
        component: () => import('@/views/market/member'),
        redirect: '/market/member/memberSetting',
        meta: {
          title: '会员中心',
          icon: 'user-line',
          dynamicNewTab: false,
        },
        children: [
          {
            path: 'memberSetting',
            name: 'MemberSetting',
            hidden: false,
            component: () => import('@/views/market/member/memberSetting'),
            meta: {
              title: '会员设置',
              dynamicNewTab: false,
            },
          },
          {
            path: 'rechargeSetting',
            name: 'RechargeSetting',
            hidden: false,
            component: () => import('@/views/market/member/rechargeSetting'),
            meta: {
              title: '充值设置',
              dynamicNewTab: false,
            },
          },
          {
            path: 'rechargeRecord',
            name: 'RechargeRecord',
            hidden: false,
            component: () => import('@/views/market/member/rechargeRecord'),
            meta: {
              title: '充值记录',
              dynamicNewTab: false,
            },
          },
        ],
      },
      {
        path: 'activity',
        name: 'Activity',
        hidden: false,
        component: () => import('@/views/market/activity'),
        redirect: '/market/activity/activityList',
        meta: {
          title: '活动',
          icon: 'heart-pulse-line',
          dynamicNewTab: false,
        },
        children: [
          {
            path: 'activityList',
            name: 'MarketActivity',
            hidden: false,
            component: () => import('@/views/market/activity/activityList'),
            meta: {
              title: '列表',
              dynamicNewTab: false,
            },
          },
          {
            path: 'couponList',
            name: 'MarketCoupon',
            hidden: false,
            component: () => import('@/views/market/activity/couponList'),
            meta: {
              title: '优惠券',
              dynamicNewTab: false,
            },
          },
          {
            path: 'statistics',
            name: 'MarketStatistics',
            hidden: false,
            component: () => import('@/views/market/activity/statistics'),
            meta: {
              title: '数据统计',
              dynamicNewTab: false,
            },
          },
        ],
      },
      {
        path: 'distribution',
        name: 'Distribution',
        hidden: false,
        component: () => import('@/views/market/distribution'),
        redirect: '/market/distribution/distributionSetting',
        meta: {
          title: '分销',
          icon: 'stack-line',
          dynamicNewTab: false,
        },
        children: [
          {
            path: 'distributionSetting',
            name: 'DistributionSetting',
            hidden: false,
            component: () =>
              import('@/views/market/distribution/distributionSetting'),
            meta: {
              title: '审核',
              dynamicNewTab: false,
            },
          },
          {
            path: 'list',
            name: 'DistributionList',
            hidden: false,
            component: () => import('@/views/market/distribution/list'),
            meta: {
              title: '列表',
              dynamicNewTab: false,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/application',
    name: 'Application',
    component: Layout,
    redirect: '/application/EAP',
    hidden: false,
    meta: {
      title: '应用',
      icon: 'apps-line',
      dynamicNewTab: false,
    },
    children: [
      {
        path: 'EAP',
        name: 'ApplicationEAP',
        hidden: false,
        component: () => import('@/views/application/EAP'),
        redirect: '/application/EAP/eapList',
        meta: {
          title: 'EAP',
          icon: 'contacts-line',
          dynamicNewTab: false,
        },
        children: [
          {
            path: 'eapList',
            name: 'EapList',
            hidden: false,
            component: () => import('@/views/application/EAP/eapList'),
            meta: {
              title: '列表',
              icon: 'file-list-3-line',
              dynamicNewTab: false,
            },
          },
          {
            path: 'eapUser',
            name: 'EapUser',
            hidden: false,
            component: () => import('@/views/application/EAP/eapUser'),
            meta: {
              title: 'EAP用户',
              dynamicNewTab: false,
            },
          },
          {
            path: 'eapStatistics',
            name: 'EapStatistics',
            hidden: false,
            component: () => import('@/views/application/EAP/eapStatistics'),
            meta: {
              title: '服务统计',
              dynamicNewTab: false,
            },
          },
        ],
      },
      {
        path: 'QA',
        name: 'ApplicationQa',
        hidden: false,
        component: () => import('@/views/application/QA'),
        redirect: '/application/QA/ques',
        meta: {
          title: '问答',
          icon: 'draft-line',
          dynamicNewTab: false,
        },
        children: [
          {
            path: 'ques',
            name: 'QaQues',
            hidden: false,
            component: () => import('@/views/application/QA/ques'),
            meta: {
              title: '问题列表',
              dynamicNewTab: false,
            },
          },
          {
            path: 'answer',
            name: 'QaAnswer',
            hidden: false,
            component: () => import('@/views/application/QA/answer'),
            meta: {
              title: '回答列表',
              dynamicNewTab: false,
            },
          },
        ],
      },
      {
        path: 'course',
        name: 'ApplicationCourse',
        hidden: false,
        component: () => import('@/views/application/course'),
        redirect: '/application/course/cate',
        meta: {
          title: '课程',
          icon: 'book-2-line',
          dynamicNewTab: false,
        },
        children: [
          {
            path: 'cate',
            name: 'CourseCate',
            hidden: false,
            component: () => import('@/views/application/course/cate'),
            meta: {
              title: '分类',
              dynamicNewTab: false,
            },
          },
          {
            path: 'list',
            name: 'CourseList',
            hidden: false,
            component: () => import('@/views/application/course/list'),
            meta: {
              title: '列表',
              dynamicNewTab: false,
            },
          },
        ],
      },
      {
        path: 'room',
        name: 'ApplicationRoom',
        hidden: false,
        component: () => import('@/views/application/room'),
        meta: {
          title: '咨询室',
          icon: 'building-line',
          dynamicNewTab: false,
        },
      },
      {
        path: 'article',
        name: 'ApplicationArticle',
        hidden: false,
        component: () => import('@/views/application/article'),
        redirect: '/application/article/cate',
        meta: {
          title: '文章',
          icon: 'article-line',
          dynamicNewTab: false,
        },
        children: [
          {
            path: 'cate',
            name: 'ArticleCate',
            hidden: false,
            component: () => import('@/views/application/article/cate'),
            meta: {
              title: '分类',
              dynamicNewTab: false,
            },
          },
          {
            path: 'list',
            name: 'ArticleList',
            hidden: false,
            component: () => import('@/views/application/article/list'),
            meta: {
              title: '列表',
              dynamicNewTab: false,
            },
          },
          {
            path: 'addArticle',
            name: 'AddArticle',
            hidden: true,
            component: () => import('@/views/application/article/addArticle'),
            meta: {
              title: '编辑文章',
              dynamicNewTab: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: 'finance',
    name: 'Finance',
    component: Layout,
    redirect: '/finance/dataCenter',
    hidden: false,
    meta: {
      title: '财务',
      icon: 'bank-line',
      dynamicNewTab: false,
    },
    children: [
      {
        path: 'dataCenter',
        name: 'FinanceDataCenter',
        hidden: false,
        component: () => import('@/views/finance/dataCenter'),
        meta: {
          title: '数据中心',
          icon: 'bar-chart-2-line',
          dynamicNewTab: false,
        },
      },
      {
        path: 'fxCashout',
        name: 'FxCashout',
        hidden: false,
        component: () => import('@/views/finance/fxCashout'),
        meta: {
          title: '分销商提现',
          icon: 'money-cny-circle-line',
          dynamicNewTab: false,
        },
      },
      {
        path: 'zxCashout',
        name: 'ZxCashout',
        hidden: false,
        component: () => import('@/views/finance/zxCashout'),
        meta: {
          title: '咨询师提现',
          icon: 'money-cny-circle-line',
          dynamicNewTab: false,
        },
      },
    ],
  },
  {
    path: 'setting',
    name: 'Setting',
    component: Layout,
    redirect: '/setting/baseSetting',
    hidden: false,
    meta: {
      title: '设置',
      icon: 'settings-3-line',
      dynamicNewTab: false,
    },
    children: [
      {
        path: 'personalCenter',
        name: 'PersonalCenter',
        hidden: false,
        component: () => import('@/views/setting/personalCenter'),
        meta: {
          title: '个人中心',
          icon: 'user-fill',
          dynamicNewTab: false,
        },
      },
      {
        path: 'companyInfo',
        name: 'CompanyInfo',
        hidden: false,
        component: () => import('@/views/setting/companyInfo'),
        meta: {
          title: '公司信息设置',
          icon: 'user-fill',
          dynamicNewTab: false,
        },
      },
      {
        path: 'baseSetting',
        name: 'BaseSetting',
        hidden: false,
        component: () => import('@/views/setting/baseSetting'),
        meta: {
          title: '系统设置',
          icon: 'settings-6-line',
          dynamicNewTab: false,
        },
      },
      {
        path: 'authority',
        name: 'Authority',
        hidden: false,
        component: () => import('@/views/setting/authority'),
        redirect: '/setting/authority/roles',
        meta: {
          title: '权限',
          icon: 'admin-line',
          dynamicNewTab: false,
        },
        children: [
          {
            path: 'roles',
            name: 'AuthorityRoles',
            hidden: false,
            component: () => import('@/views/setting/authority/roles'),
            meta: {
              title: '角色',
              dynamicNewTab: false,
            },
          },
          {
            path: 'administrators',
            name: 'AuthorityAdmin',
            hidden: false,
            component: () => import('@/views/setting/authority/administrators'),
            meta: {
              title: '管理员',
              dynamicNewTab: false,
            },
          },
          {
            path: 'addAdmin',
            name: 'AddAdmin',
            hidden: true,
            component: () => import('@/views/setting/authority/addAdmin'),
            meta: {
              title: '编辑管理员',
              dynamicNewTab: false,
            },
          },
          {
            path: 'addRole',
            name: 'AddRole',
            hidden: true,
            component: () => import('@/views/setting/authority/addRole'),
            meta: {
              title: '编辑角色',
              dynamicNewTab: false,
            },
          },
        ],
      },
      {
        path: 'log',
        name: 'Log',
        hidden: false,
        component: () => import('@/views/setting/log'),
        meta: {
          title: '操作日志',
          icon: 'file-list-3-line',
          dynamicNewTab: false,
          noKeepAlive: true,
        },
      },
    ],
  },
  {
    path: 'mental',
    name: 'Mental',
    component: Layout,
    redirect: '/mental/index',
    meta: {
      title: '预警',
      icon: 'alarm-warning-line',
    },
    children: [
      {
        path: 'warnView',
        name: 'WarnView',
        hidden: false,
        component: () => import('@/views/mental/warnView'),
        meta: {
          title: '预警概览',
          icon: 'pie-chart-line',
          dynamicNewTab: false,
        },
      },
      {
        path: 'warning',
        name: 'WarningList',
        hidden: false,
        component: () => import('@/views/mental/personList'),
        meta: {
          title: '预警名单',
          icon: 'file-list-3-line',
          dynamicNewTab: false,
        },
      },
      {
        path: 'assess',
        name: 'Assess',
        hidden: false,
        component: () => import('@/views/mental/assess'),
        meta: {
          title: '评估记录',
          icon: 'file-list-3-line',
          dynamicNewTab: false,
        },
      },
      {
        path: 'list',
        name: 'CrisisList',
        hidden: false,
        component: () => import('@/views/mental/interveList'),
        meta: {
          title: '干预记录',
          icon: 'file-list-3-line',
          dynamicNewTab: false,
        },
      },
      {
        path: 'setCrisis',
        name: 'SetCrisis',
        hidden: false,
        component: () => import('@/views/mental/setCrisis'),
        meta: {
          title: '预警设置',
          icon: 'settings-3-line',
          dynamicNewTab: false,
        },
      },
      {
        path: 'addCrisis',
        name: 'AddCrisis',
        hidden: true,
        component: () => import('@/views/mental/addMental'),
        meta: {
          title: '危机干预',
          icon: 'file-list-3-line',
          dynamicNewTab: false,
        },
      },
    ],
  },
  {
    path: 'appointment',
    name: 'Appointment',
    component: Layout,
    redirect: '/appointment/counselor',
    meta: {
      title: '预约',
      icon: 'user-5-line',
    },
    children: [
      {
        path: 'counselor',
        name: 'Counselor',
        hidden: false,
        component: () => import('@/views/appointment/counselor'),
        meta: {
          title: '咨询师',
          icon: 'user-heart-line',
          dynamicNewTab: false,
        },
      },
      {
        path: 'times',
        name: 'Times',
        hidden: false,
        component: () => import('@/views/appointment/times'),
        meta: {
          title: '预约时段',
          icon: 'time-line',
          dynamicNewTab: false,
        },
      },
      {
        path: 'apoRecord',
        name: 'ApoRecord',
        hidden: false,
        component: () => import('@/views/appointment/apoRecord'),
        meta: {
          title: '预约记录',
          icon: 'file-list-3-line',
          dynamicNewTab: false,
        },
      },
      {
        path: 'conRecord',
        name: 'ConRecord',
        hidden: false,
        component: () => import('@/views/appointment/conRecord'),
        meta: {
          title: '咨询记录',
          icon: 'file-list-3-line',
          dynamicNewTab: false,
        },
      },
    ],
  },
]

const router = createRouter()

export function resetRouter(routes = constantRoutes) {
  router.matcher = createRouter(routes).matcher
}

function createRouter(routes = constantRoutes) {
  return new VueRouter({
    base: publicPath,
    mode: routerMode,
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: routes,
  })
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

export default router
