var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "notice-box" },
    [
      _c(
        "div",
        { staticClass: "notice" },
        [
          _c("vab-icon", { attrs: { icon: "notification-2-line" } }),
          _vm.notice && _vm.notice.length > 0
            ? _c(
                "div",
                { staticClass: "notice-list" },
                [
                  _c(
                    "el-carousel",
                    {
                      attrs: {
                        autoplay: true,
                        direction: "vertical",
                        height: "40px",
                        "indicator-position": "none",
                      },
                    },
                    _vm._l(_vm.notice, function (item) {
                      return _c("el-carousel-item", { key: item.id }, [
                        _c(
                          "span",
                          {
                            staticStyle: { cursor: "pointer" },
                            on: { click: _vm.gotoNotice },
                          },
                          [_vm._v(" " + _vm._s(item.title) + " ")]
                        ),
                        item.type == 2
                          ? _c(
                              "a",
                              {
                                attrs: { href: item.content, target: "_blank" },
                              },
                              [_vm._v(" 点击下载 ")]
                            )
                          : _vm._e(),
                      ])
                    }),
                    1
                  ),
                ],
                1
              )
            : _c("span", [_vm._v("暂无最新公告")]),
        ],
        1
      ),
      _vm.studio && _vm.userinfo.major === 1
        ? _c(
            "div",
            { staticClass: "users-num" },
            [
              _c(
                "el-popover",
                {
                  staticClass: "popEx",
                  attrs: {
                    content:
                      "当前渠道有效期内，每有一名用户登录(重复登录算一人)剩余人数减1，当剩余人数为0时，系统将不允许用户登录。请及时联系客服，避免因为人数不足而影响业务，感谢您的配合。",
                    placement: "bottom-start",
                    title: "提示",
                    trigger: "hover",
                    width: "350",
                  },
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "reference" }, slot: "reference" },
                    [
                      _vm._v(" 有效期： "),
                      _c("span", [_vm._v(_vm._s(_vm.studio.effect_time))]),
                      _c("el-divider", { attrs: { direction: "vertical" } }),
                      _vm._v(" 剩余人数： "),
                      _vm.studio.is_limit == "20"
                        ? _c("span", [_vm._v("无限制")])
                        : _c("span", [_vm._v(_vm._s(_vm.studio.remain_num))]),
                      _c("vab-icon", { attrs: { icon: "question-line" } }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "show-close": false,
            visible: _vm.dialogFormVisible,
            width: "750px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dia-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("span", { staticClass: "el-icon-warning" }),
              _vm._v(" 新功能升级提醒 "),
            ]
          ),
          _c("div", {
            domProps: { innerHTML: _vm._s(_vm.newtip && _vm.newtip.content) },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.close } },
                [_vm._v("我已知晓")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }