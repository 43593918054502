<template>
  <div class="index-container">
    <notice-bar v-if="!isNJ" :notice="notice" :studio="studio" />
    <!-- <el-card shadow="never">
      <div v-if="studio" class="studio-box">
        <el-image class="img" :src="studio.logo" />
        <div class="studio">
          <p class="title">
            {{ studio.name }}
          </p>
          <p class="desc">{{ studio.descr }}</p>
        </div>
        <div class="fast-to">
          <router-link
            class="item"
            to="/userManagement/member/userList"
            tag="a"
          >
            <img
              class="icon"
              :src="require('@/assets/index_images/svg-user.svg')"
            />
            用户管理
          </router-link>
          <router-link class="item" to="/mmpt/eval" tag="a">
            <img
              class="icon"
              :src="require('@/assets/index_images/svg-test.svg')"
            />
            分发测试
          </router-link>
          <router-link class="item" to="/team/list/teamList" tag="a">
            <img
              class="icon"
              :src="require('@/assets/index_images/svg-plan.svg')"
            />
            普查计划
          </router-link>
          <router-link class="item" to="/crisis/list" tag="a">
            <img
              class="icon"
              :src="require('@/assets/index_images/svg-danger.svg')"
            />
            危机干预
          </router-link>
        </div>
      </div>
    </el-card> -->
    <div class="info">
      <div class="left">
        <span>{{ username }}，您好！</span>
        欢迎登录{{ title }}
      </div>
      <div class="right">
        <img src="@/assets/index_images/idx02.png" />
      </div>
    </div>
    <work v-if="userinfo.major === 1" />
    <el-row :gutter="15">
      <el-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24">
        <el-card shadow="hover">
          <template #header>
            <span class="f18">用户</span>
          </template>
          <vab-chart
            ref="chartsuser"
            :init-options="initOptions"
            :options="state"
            style="margin: 100px 0"
            theme="vab-echarts-theme"
          />
        </el-card>
      </el-col>
      <el-col :lg="16" :md="24" :sm="24" :xl="16" :xs="24">
        <el-card shadow="hover">
          <template #header>
            <span class="f18">团测计划</span>
          </template>
          <el-table
            v-loading="listLoading"
            :data="plan"
            size="medium"
            @row-click="clickRowHandle"
          >
            <el-table-column
              label="标题"
              prop="title"
              show-overflow-tooltip
              width="200"
            />
            <el-table-column
              align="center"
              label="进度"
              min-width="300"
              prop="rate"
              show-overflow-tooltip
            >
              <template #default="{ row }">
                <el-progress
                  :percentage="row.rate * 1"
                  :stroke-width="16"
                  :text-inside="true"
                />
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="已完成"
              prop="comp"
              show-overflow-tooltip
              width="100"
            >
              <template #default="{ row }">
                {{ row.comp + '人' }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="未完成"
              prop="uncomp"
              show-overflow-tooltip
              width="100"
            >
              <template #default="{ row }">
                {{ row.uncomp + '人' }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="截止日期"
              prop="end_at"
              show-overflow-tooltip
            />
          </el-table>
        </el-card>
        <el-card shadow="hover">
          <template #header>
            <span class="f18">访问</span>
          </template>
          <vab-chart
            ref="charts"
            :height="300"
            :init-options="initOptions"
            :options="view"
            theme="vab-echarts-theme"
          />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { getStuIndex } from '@/api/idx'
  import noticeBar from '@/views/index/components/noticeBar'
  import VabChart from '@/extra/VabChart/index'
  import { isNJ } from '@/utils/utils'
  import { mapGetters } from 'vuex'
  import Work from '@/views/index/components/work'
  export default {
    name: 'Index',
    components: {
      Work,
      noticeBar,
      VabChart,
    },
    data() {
      return {
        isNJ: isNJ(),
        listLoading: true,
        initOptions: {
          renderer: 'svg',
        },
        notice: null,
        studio: null, //渠道信息
        plan: [], //团测计划
        quick: null, //快捷入口
        state: null, //用户
        view: null, //访问
        visuals: false, //是否展示可视化按钮
      }
    },
    computed: {
      ...mapGetters({
        title: 'settings/title',
        username: 'user/username',
        userinfo: 'user/userinfo',
      }),
    },
    created() {
      this.getData()
    },
    methods: {
      async getData() {
        this.listLoading = true
        const { notice, plan, quick, state, view, studio, visual } =
          await getStuIndex()
        this.plan = plan
        this.quick = quick
        this.state = this.getPieChart(state)
        this.view = this.getChart(view)
        this.studio = studio
        this.notice = notice
        this.visuals = visual
        this.listLoading = false
      },
      navTo(url) {
        this.$router.push(url)
      },
      clickRowHandle(row, column, event) {
        this.$router.push(row.path)
      },
      getChart(data) {
        let chart = {
          title: {
            text: '',
            subtext: '',
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: data.legend,
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          yAxis: {
            type: 'value',
            boundaryGap: [0, 0.01],
          },
          xAxis: data.xAxis,
          series: data.series,
        }
        return chart
      },
      getPieChart(data) {
        let chart = {
          title: data.title,
          /*toolbox: {
            feature: {
              saveAsImage: {
                type: 'png',
                pixelRatio: 1.5,
              },
            },
          },*/
          tooltip: data.tooltip,
          legend: data.legend,
          series: data.series,
        }
        if (data.title.text === '用户') {
          chart.color = ['#e66', '#91cc75']
        }
        return chart
      },
    },
  }
</script>

<style lang="scss" scoped>
  .index-container {
    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: rgb(246, 249, 255);
      border: 2px solid rgba(238, 240, 245, 0.5);
      box-shadow: 0 0 35px 7px rgba(38, 205, 183, 0.08);
      border-radius: 7px;
      padding: 15px 40px;
      margin: 20px 0 30px;
      .left {
        span {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
    ::v-deep {
      .el-progress {
        .el-progress-bar__inner {
          animation: move 1s;
        }
      }

      @keyframes move {
        0% {
          width: 0%;
        }
      }
    }
    .f18 {
      font-size: 18px;
      font-weight: bold;
    }
    .studio-box {
      display: flex;
      align-items: center;
      .fast-to {
        display: flex;
        align-items: center;
        border-left: 1px solid #eee;
        padding-left: 50px;
        .item {
          border-radius: 10px;
          padding: 10px 15px;
          margin: 0 20px;
          color: #777;
          background: #fff;
          box-shadow: 0 0 30px 15px rgba(232, 244, 255, 0.4);
          .icon {
            width: 40px;
            height: 40px;
            vertical-align: middle;
            margin-right: 10px;
          }
        }
      }
      .img {
        width: 90px;
        height: 90px;
        margin-right: 20px;
      }
      .studio {
        flex: 1;
        .title {
          font-size: 20px;
          font-weight: bold;
        }
        .desc {
          font-size: 12px;
          margin-top: 8px;
        }
      }
    }
  }
</style>
