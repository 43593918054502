var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "swiper",
    {
      ref: "mySwiper",
      staticClass: "swiper-container",
      attrs: { options: _vm.swiper },
    },
    [
      _vm._l(_vm.list, function (item, index) {
        return _c("swiper-slide", { key: index, staticClass: "swiper-slide" }, [
          _c(
            "div",
            {
              staticClass: "content",
              class: item.is_check ? "select" : "",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.itemClick(item)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "title" },
                [
                  _c("span", { staticClass: "t" }, [_vm._v(_vm._s(item.name))]),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        content: item.descr,
                        placement: "top-start",
                        trigger: "hover",
                        width: "300",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            cursor: "pointer",
                            "font-size": "12px",
                          },
                          attrs: { slot: "reference" },
                          slot: "reference",
                        },
                        [
                          _c("i", { staticClass: "el-icon-question" }),
                          _vm._v(" 说明 "),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "detail" }, [
                _c("div", [
                  _c("label", { staticClass: "lbl" }, [_vm._v("【适用】：")]),
                  _c("span", [_vm._v(_vm._s(item.range))]),
                ]),
                _c(
                  "div",
                  [
                    _c("label", { staticClass: "lbl" }, [_vm._v("【量表】：")]),
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.evals_name.length > 20
                              ? item.evals_name.substr(0, 11) + "..."
                              : item.evals_name
                          ) +
                          " "
                      ),
                    ]),
                    item.evals_name.length > 20
                      ? _c(
                          "el-tooltip",
                          { attrs: { effect: "dark", placement: "bottom" } },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "max-width": "300px",
                                  "line-height": "1.6",
                                },
                                attrs: { slot: "content" },
                                slot: "content",
                              },
                              _vm._l(
                                item.evals_name.split("、"),
                                function (subitem, j) {
                                  return _c("div", { key: j }, [
                                    _vm._v(
                                      " " + _vm._s(j + 1 + "、" + subitem) + " "
                                    ),
                                  ])
                                }
                              ),
                              0
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { padding: "0" },
                                attrs: { type: "text" },
                              },
                              [_vm._v("查看>>")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c("div", [
                  _c("label", { staticClass: "lbl" }, [
                    _vm._v("【开放报告】："),
                  ]),
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-text": "开",
                          "active-value": "10",
                          "inactive-text": "关",
                          "inactive-value": "20",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.switchChange(item)
                          },
                        },
                        model: {
                          value: item.is_open,
                          callback: function ($$v) {
                            _vm.$set(item, "is_open", $$v)
                          },
                          expression: "item.is_open",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", [
                  _c("label", { staticClass: "lbl" }, [
                    _vm._v("【重复测试】："),
                  ]),
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-text": "开",
                          "active-value": "10",
                          "inactive-text": "关",
                          "inactive-value": "20",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.switchChange(item)
                          },
                        },
                        model: {
                          value: item.is_repeat,
                          callback: function ($$v) {
                            _vm.$set(item, "is_repeat", $$v)
                          },
                          expression: "item.is_repeat",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ])
      }),
      _c(
        "div",
        {
          staticClass: "swiper-button-prev",
          attrs: { slot: "button-prev" },
          slot: "button-prev",
        },
        [_c("i", { staticClass: "el-icon-arrow-left" })]
      ),
      _c(
        "div",
        {
          staticClass: "swiper-button-next",
          attrs: { slot: "button-next" },
          slot: "button-next",
        },
        [_c("i", { staticClass: "el-icon-arrow-right" })]
      ),
      _c("div", {
        staticClass: "swiper-pagination",
        attrs: { slot: "pagination" },
        slot: "pagination",
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }