<template>
  <div class="answer-container">
    <div class="box">
      <el-card class="tips">
        提示：仅显示
        <span class="c_red">{{ list.name }}</span>
        对
        <span class="c_red">{{ list.paper }}</span>
        的最后一次答题记录。
        <el-button
          v-if="$route.query.type !== 'pay'"
          type="primary"
          @click="downloadAnswer"
        >
          导出Excel
        </el-button>
        <el-button type="primary" @click="print">导出Pdf</el-button>
      </el-card>
      <div ref="print" class="answer-list">
        <el-table v-loading="loading" border :data="list.ques">
          <el-table-column label="题目" prop="title">
            <template #default="{ row }">
              <template v-if="isImage(row.title)">
                <span>{{ row.title.split('、')[0] }}、</span>
                <el-image
                  :preview-src-list="[row.title.split('、')[1]]"
                  :src="row.title.split('、')[1]"
                />
              </template>
              <span v-else>{{ row.title }}</span>
            </template>
          </el-table-column>
          <el-table-column label="选项" prop="select" />
          <el-table-column
            align="center"
            label="答案"
            prop="answer"
            width="180"
          />
          <el-table-column
            align="center"
            label="得分"
            prop="score"
            width="80"
          />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
  import getPageTitle from '@/utils/pageTitle'
  import { baseURL } from '@/config'
  import { getAnswerNote } from '@/api/teamList'
  import { getTestAnswer } from '@/api/mmptCategory'
  import { isImage } from '@/utils/utils'
  import { getRecord } from '@/api/psyeval'
  export default {
    name: 'AnswerNotes',
    data() {
      return {
        list: [],
        loading: false,
      }
    },
    created() {
      this.getNotes(this.$route.query.id)
    },
    methods: {
      isImage,
      handleMeta(name, meta) {
        document.title = getPageTitle(meta.title, true)
      },
      downloadAnswer() {
        let uri = ''
        if (this.$route.query.type === 'single') {
          uri = '/psyevallist/test-answer?id='
        } else {
          uri = '/teamtest/export-ques?id='
        }
        window.location.href = baseURL + uri + this.$route.query.id
      },
      async getNotes(id) {
        this.loading = true
        let type = this.$route.query.type
        let data = null
        if (type === 'single') {
          data = await getTestAnswer({ id })
        } else if (type === 'team') {
          data = await getAnswerNote({ id })
        } else if (type === 'pay') {
          data = await getRecord({ oid: id })
        }
        this.list = data.data
        this.handleMeta('AnswerNotes', {
          title: data.data.name + data.data.paper + '答题记录',
        })
        this.loading = false
      },
      print() {
        this.$print(this.$refs.print)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .answer-container {
    .box {
      width: 840px;
      background: #f5f5f5;
      padding: 20px;
      margin: auto;
      .el-card {
        &.is-always-shadow {
          box-shadow: 0 2px 12px 0 rgba(187, 187, 187, 0.1);
        }
        .c_red {
          color: #e64e1f;
        }
      }
      .answer-list {
        background: #fff;
        padding: 20px;
      }
    }
  }
  @media print {
    @page {
      size: auto;
      margin: 5mm;
    }
  }
</style>
