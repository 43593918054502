var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add-form-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 15 } },
        [
          _c(
            "el-col",
            {
              attrs: {
                lg: { span: 12, offset: 4 },
                md: { span: 20, offset: 2 },
                sm: { span: 20, offset: 2 },
                xl: { span: 12, offset: 4 },
                xs: 24,
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "demo-form",
                  attrs: {
                    "label-position": _vm.labelPosition,
                    "label-width": "130px",
                    model: _vm.form,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "姓名", prop: "name" } },
                        [
                          _vm.form.id
                            ? _c("el-input", {
                                staticClass: "w220",
                                attrs: { disabled: "", value: _vm.form.name },
                              })
                            : _c("el-input", {
                                staticClass: "w220",
                                attrs: {
                                  placeholder: "请选择用户",
                                  readonly: "",
                                  value: _vm.name,
                                },
                                on: { focus: _vm.addPerson },
                              }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.lblPname, prop: "pname" } },
                        [
                          _c("el-input", {
                            staticClass: "w220",
                            attrs: { placeholder: "请输入" + _vm.lblPname },
                            model: {
                              value: _vm.form.pname,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "pname", $$v)
                              },
                              expression: "form.pname",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.lblType + "内容",
                            prop: "mental_remark",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入" + _vm.lblType + "内容",
                              type: "textarea",
                            },
                            model: {
                              value: _vm.form.mental_remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "mental_remark", $$v)
                              },
                              expression: "form.mental_remark",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "预警级别",
                            prop: "mental_warn_level",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.mental_warn_level,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "mental_warn_level", $$v)
                                },
                                expression: "form.mental_warn_level",
                              },
                            },
                            _vm._l(_vm.warningList, function (item) {
                              return _c(
                                "el-radio",
                                { key: item.id, attrs: { label: item.sort } },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "干预级别",
                            prop: "mental_interve_level",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.mental_interve_level,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "mental_interve_level",
                                    $$v
                                  )
                                },
                                expression: "form.mental_interve_level",
                              },
                            },
                            _vm._l(_vm.interveList, function (item) {
                              return _c(
                                "el-radio",
                                { key: item.id, attrs: { label: item.sort } },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否结案", prop: "settle_type" } },
                        [
                          _c("el-switch", {
                            attrs: { disabled: _vm.disabledSwitch },
                            model: {
                              value: _vm.switchs,
                              callback: function ($$v) {
                                _vm.switchs = $$v
                              },
                              expression: "switchs",
                            },
                          }),
                          _vm.switchs
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { disabled: _vm.disabledSwitch },
                                      model: {
                                        value: _vm.form.settle_type,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "settle_type", $$v)
                                        },
                                        expression: "form.settle_type",
                                      },
                                    },
                                    _vm._l(_vm.settleList, function (item) {
                                      return _c(
                                        "el-radio",
                                        {
                                          key: item.id,
                                          attrs: { label: item.id },
                                        },
                                        [_vm._v(" " + _vm._s(item.name) + " ")]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "上传文件", prop: "mental_file" } },
                        [
                          _vm.hasFile
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-right": "15px" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.form.mental_file_name) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: { click: _vm.downloadFile },
                                    },
                                    [_vm._v("下载")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { color: "red" },
                                      attrs: { type: "text" },
                                      on: { click: _vm.deleteFile },
                                    },
                                    [_vm._v(" 删除 ")]
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "el-upload",
                                {
                                  ref: "upload",
                                  attrs: {
                                    accept: ".xls,.xlsx,.doc,.docx,.ppt,.zip",
                                    action: _vm.UploadUrl(),
                                    "before-upload": _vm.beforeUploadFile,
                                    "file-list": _vm.file,
                                    "http-request": _vm.updFile,
                                    limit: 1,
                                    "on-change": _vm.fileChange,
                                    "on-error": _vm.handleError,
                                    "on-exceed": _vm.exceedFile,
                                    "on-remove": _vm.handleRemove,
                                    "on-success": _vm.handleSuccess,
                                    "show-file-list": false,
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.hasFile,
                                          expression: "!hasFile",
                                        },
                                      ],
                                      attrs: {
                                        slot: "trigger",
                                        size: "small",
                                        type: "primary",
                                      },
                                      slot: "trigger",
                                    },
                                    [_vm._v(" 选取文件 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.hasFile,
                                          expression: "hasFile",
                                        },
                                      ],
                                      attrs: {
                                        disabled: "",
                                        size: "small",
                                        type: "primary",
                                      },
                                    },
                                    [_vm._v(" 选取文件 ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-upload__tip",
                                      attrs: { slot: "tip" },
                                      slot: "tip",
                                    },
                                    [
                                      _vm._v(" 只能上传 "),
                                      _c("span", [
                                        _vm._v("excel,word,ppt,zip"),
                                      ]),
                                      _vm._v(" 文件，且 "),
                                      _c("span", [_vm._v("不超过2M")]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                          _vm.errTxt
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "red",
                                    "line-height": "initial",
                                    "margin-top": "10px",
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.errTxt) + " ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.lblType + "时间",
                            prop: "mental_time",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "picker-options": _vm.pickerOptions,
                              placeholder: "请选择日期",
                              type: "date",
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.form.mental_time,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "mental_time", $$v)
                              },
                              expression: "form.mental_time",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("form")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.form.id ? "保存修改" : "立即创建") +
                              " "
                          ),
                        ]
                      ),
                      !_vm.form.id
                        ? _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.resetForm("form")
                                },
                              },
                            },
                            [_vm._v(" 重置 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("edit", { ref: "edit", on: { "fetch-data": _vm.getInfo } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }