<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="400px"
    @close="close"
  >
    <el-form ref="form" label-width="50px" :model="form" :rules="rules">
      <el-form-item label="原因" prop="reason">
        <el-input
          v-model="form.reason"
          maxlength="30"
          placeholder="请输入取消原因"
          show-word-limit
          type="textarea"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { ApoCancel } from '@/api/appointment'

  export default {
    name: 'ApoCancelEdit',
    data() {
      const validateReason = (rule, value, callback) => {
        let patrn = /^[\u4e00-\u9fa5a-zA-Z0-9,.，。\s]+$/
        if (!patrn.test(value)) {
          callback(new Error('请勿输入特殊字符'))
        } else callback()
      }
      return {
        form: {
          id: '',
          reason: '',
        },
        rules: {
          reason: [
            { required: true, trigger: 'blur', message: '请输入取消原因' },
            { required: true, trigger: 'blur', validator: validateReason },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      showEdit(row) {
        this.form.id = row.id
        this.title = '取消预约'
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await ApoCancel(this.form)
            this.$baseMessage(msg, 'success')
            this.$emit('fetch-data')
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style lang="scss"></style>
