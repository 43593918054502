var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex", attrs: { id: "centerLeft1" } },
    [
      _c(
        "dv-border-box-7",
        { staticClass: "top-left flex-1", attrs: { color: ["#121b5a"] } },
        [
          _c(
            "div",
            {
              staticClass: "d-flex jc-around ai-center",
              staticStyle: { color: "#e2a33c" },
            },
            [
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "info-name" }, [_vm._v("学校总数")]),
                _c("div", [
                  _c("span", { staticClass: "num24" }, [_vm._v("2234")]),
                  _vm._v(" 所 "),
                ]),
              ]),
              _c("div", { staticClass: "info" }, [
                _c("div", [
                  _vm._v(" 小学： "),
                  _c("span", [_vm._v("32")]),
                  _vm._v(" 所 "),
                ]),
                _c("div", [
                  _vm._v(" 初中： "),
                  _c("span", [_vm._v("32")]),
                  _vm._v(" 所 "),
                ]),
                _c("div", [
                  _vm._v(" 高中： "),
                  _c("span", [_vm._v("32")]),
                  _vm._v(" 所 "),
                ]),
              ]),
            ]
          ),
          _c("Echart", {
            ref: "top1pie",
            attrs: { id: "top1pie", height: 240, options: _vm.options },
          }),
        ],
        1
      ),
      _c(
        "dv-border-box-7",
        { staticClass: "top-left flex-1", attrs: { color: ["#121b5a"] } },
        [
          _c(
            "div",
            {
              staticClass: "d-flex jc-around ai-center",
              staticStyle: { color: "#8f4ee7" },
            },
            [
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "info-name" }, [_vm._v("学生总数")]),
                _c("div", [
                  _c("span", { staticClass: "num24" }, [_vm._v("2234")]),
                  _vm._v(" 人 "),
                ]),
              ]),
              _c("div", { staticClass: "info" }, [
                _c("div", [
                  _vm._v(" 小学： "),
                  _c("span", [_vm._v("32")]),
                  _vm._v(" 人 "),
                ]),
                _c("div", [
                  _vm._v(" 初中： "),
                  _c("span", [_vm._v("32")]),
                  _vm._v(" 人 "),
                ]),
                _c("div", [
                  _vm._v(" 高中： "),
                  _c("span", [_vm._v("32")]),
                  _vm._v(" 人 "),
                ]),
              ]),
            ]
          ),
          _c("Echart", {
            attrs: { id: "test", height: 240, options: _vm.bar },
          }),
        ],
        1
      ),
      _c(
        "dv-border-box-7",
        { staticClass: "top-left flex-1", attrs: { color: ["#121b5a"] } },
        [
          _c(
            "div",
            {
              staticClass: "d-flex jc-around ai-center",
              staticStyle: { color: "#2c8bdf" },
            },
            [
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "info-name" }, [
                  _vm._v("心理辅导老师数"),
                ]),
                _c("div", [
                  _c("span", { staticClass: "num24" }, [_vm._v("2234")]),
                  _vm._v(" 人 "),
                ]),
              ]),
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "info-name" }, [_vm._v("导师配比")]),
                _c("div", [
                  _c("span", { staticClass: "num24" }, [_vm._v("2234:1")]),
                ]),
              ]),
            ]
          ),
          _c("Echart", {
            attrs: { id: "bar2", height: 240, options: _vm.bar2 },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }