<template>
  <div class="index-container">
    <!-- <el-card shadow="never">
      <div v-if="studio" class="studio-box">
        <el-image class="img" :src="studio.logo" />
        <div class="studio">
          <p class="title">
            {{ studio.name }}
          </p>
          <p class="desc">{{ studio.descr }}</p>
        </div>
        <div v-if="false" class="notice">
          公告：请各单位完成{{ fullYear }}年普查计划
          <router-link to="/team/list/teamList" tag="a">前往发布</router-link>
        </div>
      </div>
    </el-card> -->
    <el-card shadow="never" style="min-height: 600px">
      <template #header>
        <span class="f18">数据分布</span>
      </template>
      <div v-if="work" class="ul">
        <!--<div class="li bg1">
          <el-image
            class="icon"
            :src="require('@/assets/index_images/icon-school.png')"
          />
          <div class="info">
            <div class="f16">学校总数</div>
            <div class="f30">{{ work.school }}</div>
          </div>
        </div>
        <div class="li bg2">
          <el-image
            class="icon"
            :src="require('@/assets/index_images/icon-teacher.png')"
          />
          <div class="info">
            <div class="f16">咨询师(个)</div>
            <div class="f30">{{ work.psy }}</div>
          </div>
        </div>
        <div class="li bg3">
          <el-image
            class="icon"
            :src="require('@/assets/index_images/icon-student.png')"
          />
          <div class="info">
            <div class="f16">学生人数</div>
            <div class="f30">{{ work.pep }}</div>
          </div>
        </div>
        <div class="li bg4">
          <el-image
            class="icon"
            :src="require('@/assets/index_images/icon-danger.png')"
          />
          <div class="info">
            <div class="f16">预警人数</div>
            <div class="f30">{{ work.warn }}</div>
          </div>
        </div>
        <div class="li bg5">
          <el-image
            class="icon"
            :src="require('@/assets/index_images/icon-warning.png')"
          />
          <div class="info">
            <div class="f16">干预人数</div>
            <div class="f30">{{ work.interve }}</div>
          </div>
        </div>-->
        <router-link
          v-if="visuals"
          class="li bg6"
          tag="a"
          target="_blank"
          to="/viewData"
        >
          <el-image
            class="icon"
            :src="require('@/assets/index_images/icon-data.png')"
          />
          <div class="info">
            <div class="f16">前往数据中心</div>
          </div>
        </router-link>
      </div>
    </el-card>
  </div>
</template>

<script>
  import { getStuIndex, getWorkStage } from '@/api/idx'
  export default {
    name: 'Workbench',
    data() {
      return {
        studio: null, //渠道信息
        visuals: false, //是否展示可视化按钮
        fullYear: new Date().getFullYear(),
        work: null,
      }
    },
    created() {
      this.getData()
      this.getWork()
    },
    methods: {
      async getData() {
        const { studio, visual } = await getStuIndex()
        this.studio = studio
        this.visuals = visual
      },
      getWork() {
        getWorkStage().then((res) => {
          this.work = res.data
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .index-container {
    ::v-deep {
      .el-progress {
        .el-progress-bar__inner {
          animation: move 1s;
        }
      }

      @keyframes move {
        0% {
          width: 0%;
        }
      }
    }
    .f18 {
      font-size: 18px;
      font-weight: bold;
    }
    .studio-box {
      display: flex;
      align-items: center;
      .fast-to {
        display: flex;
        align-items: center;
        border-left: 1px solid #eee;
        padding-left: 50px;
        .item {
          border-radius: 10px;
          padding: 10px 15px;
          margin: 0 20px;
          color: #777;
          background: #fff;
          box-shadow: 0 0 30px 15px rgba(232, 244, 255, 0.4);
          .icon {
            width: 40px;
            height: 40px;
            vertical-align: middle;
            margin-right: 10px;
          }
        }
      }
      .img {
        width: 90px;
        height: 90px;
        margin-right: 20px;
      }
      .studio {
        flex: 1;
        .title {
          font-size: 20px;
          font-weight: bold;
        }
        .desc {
          font-size: 12px;
          margin-top: 8px;
        }
      }
    }
    .ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0;
      list-style: none;
      margin: 0;
      .li {
        width: 30%;
        min-width: 320px;
        display: flex;
        align-items: center;
        color: #fff;
        border-radius: 10px;
        padding: 40px 60px;
        margin: 15px;
        cursor: pointer;
        box-shadow: 0 0 13px #ddd;
        &.bg1 {
          background-image: linear-gradient(to bottom, #ff6563, #ff9265);
        }
        &.bg2 {
          background-image: linear-gradient(to bottom, #578de4, #39cadd);
        }
        &.bg3 {
          background-image: linear-gradient(to bottom, #f09614, #feb559);
        }
        &.bg4 {
          background-image: linear-gradient(to bottom, #578de4, #84edaf);
        }
        &.bg5 {
          background-image: linear-gradient(to bottom, #2a4aff, #3867fd);
        }
        &.bg6 {
          background-image: linear-gradient(to bottom, #2f8bdd, #05cbfb);
        }
        .icon {
          width: 80px;
          margin-right: 20px;
        }
        .info {
          flex: 1;
          width: 0;
        }
        .f16 {
          font-size: 18px;
        }
        .f30 {
          font-size: 34px;
          margin-top: 6px;
          text-shadow: 0 0 4px rgba(169, 88, 88, 0.6);
        }
      }
    }
  }
</style>
