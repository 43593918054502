var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "450px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c("div", { staticClass: "join-team" }, [
        _c(
          "div",
          { staticClass: "img" },
          [_c("el-image", { attrs: { src: _vm.params.code } })],
          1
        ),
        _c(
          "div",
          { staticClass: "btn" },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.saveImg } },
              [_vm._v("下载二维码")]
            ),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.copy } },
              [_vm._v("复制链接")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "tips" }, [
        _vm._v("参与测评：1.手机扫码参与测评；2.复制链接进入测评"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }