var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.itemOrMenu && _vm.itemOrMenu.meta && _vm.itemOrMenu.meta.title
    ? _c(
        "el-menu-item",
        {
          attrs: { index: _vm.itemOrMenu.path },
          on: { click: _vm.handleLink },
        },
        [
          _vm.itemOrMenu.meta && _vm.itemOrMenu.meta.icon
            ? _c("vab-icon", {
                attrs: {
                  icon: _vm.itemOrMenu.meta.icon,
                  "is-custom-svg": _vm.itemOrMenu.meta.isCustomSvg,
                  title: _vm.translateTitle(_vm.itemOrMenu.meta.title),
                },
              })
            : _vm._e(),
          _c(
            "span",
            { attrs: { title: _vm.translateTitle(_vm.itemOrMenu.meta.title) } },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.translateTitle(_vm.itemOrMenu.meta.title)) +
                  " "
              ),
            ]
          ),
          _vm.itemOrMenu.meta && _vm.itemOrMenu.meta.badge
            ? _c("el-tag", { attrs: { effect: "dark", type: "danger" } }, [
                _vm._v(" " + _vm._s(_vm.itemOrMenu.meta.badge) + " "),
              ])
            : _vm._e(),
          _vm.itemOrMenu.meta && _vm.itemOrMenu.meta.dot
            ? _c("span", { staticClass: "vab-dot vab-dot-error" }, [_c("span")])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }