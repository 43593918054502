var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-scrollbar",
    {
      staticClass: "column-bar-container",
      class: {
        "is-collapse": _vm.collapse,
        ["column-bar-container-" + _vm.theme.columnStyle]: true,
      },
    },
    [
      _c("vab-logo"),
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "left" },
          on: { "tab-click": _vm.handleTabClick },
          model: {
            value: _vm.extra.first,
            callback: function ($$v) {
              _vm.$set(_vm.extra, "first", $$v)
            },
            expression: "extra.first",
          },
        },
        _vm._l(_vm.handleRoutes, function (item) {
          return _c(
            "el-tab-pane",
            { key: item.name, attrs: { name: item.name } },
            [
              _c("template", { slot: "label" }, [
                _c(
                  "div",
                  {
                    staticClass: "column-grid",
                    class: {
                      ["column-grid-" + _vm.theme.columnStyle]: true,
                    },
                    attrs: { title: _vm.translateTitle(item.meta.title) },
                  },
                  [
                    _c(
                      "div",
                      [
                        item.meta.icon
                          ? _c("vab-icon", {
                              attrs: {
                                icon: item.meta.icon,
                                "is-custom-svg": item.meta.isCustomSvg,
                              },
                            })
                          : _vm._e(),
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.translateTitle(item.meta.title)) +
                              " "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            2
          )
        }),
        1
      ),
      _c(
        "el-menu",
        {
          attrs: {
            "background-color": _vm.variables["column-second-menu-background"],
            "default-active": _vm.activeMenu,
            "default-openeds": _vm.defaultOpeneds,
            mode: "vertical",
            "unique-opened": _vm.uniqueOpened,
          },
        },
        [
          _c("el-divider", [
            _vm._v(
              " " + _vm._s(_vm.translateTitle(_vm.handleGroupTitle)) + " "
            ),
          ]),
          _vm._l(_vm.handlePartialRoutes, function (route) {
            return [
              !route.hidden
                ? _c("vab-menu", { key: route.path, attrs: { item: route } })
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }