var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "organization-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-left-panel",
            { attrs: { span: _vm.isSid === "all" ? 8 : 24 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.button.add,
                    icon: "el-icon-plus",
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addOrg("", "first")
                    },
                  },
                },
                [_vm._v(" 添加 ")]
              ),
              _vm.button.add
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-document-add",
                        title: "导入组织结构",
                        type: "primary",
                      },
                      on: { click: _vm.handleEdit },
                    },
                    [_vm._v(" 导入 ")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: "",
                        icon: "el-icon-document-add",
                        title: "暂无权限，如有需要，请联系管理员",
                        type: "primary",
                      },
                    },
                    [_vm._v(" 导入 ")]
                  ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _vm._v(" 为避免导入组织结构失败，请先 "),
                  _c(
                    "el-button",
                    {
                      attrs: { title: "下载Excel模版", type: "text" },
                      on: { click: _vm.downLoadDoc },
                    },
                    [_vm._v(" 下载模版 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isSid === "all"
            ? _c(
                "vab-query-form-right-panel",
                { attrs: { span: 16 } },
                [
                  _vm.isSid === "all"
                    ? _c(
                        "el-form",
                        {
                          attrs: { inline: true, model: _vm.queryForm },
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "渠道名称",
                                  },
                                  model: {
                                    value: _vm.queryForm.sid,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryForm, "sid", $$v)
                                    },
                                    expression: "queryForm.sid",
                                  },
                                },
                                _vm._l(_vm.studioList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-search",
                                    type: "primary",
                                  },
                                  on: { click: _vm.queryData },
                                },
                                [_vm._v(" 查询 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "refTable",
          attrs: {
            border: "",
            data: _vm.list,
            "row-key": "id",
            "tree-props": { children: "children" },
          },
          on: {
            "row-click": _vm.clickRow,
            "selection-change": _vm.setSelectRows,
          },
        },
        [
          false
            ? _c("el-table-column", {
                attrs: { align: "center", type: "selection" },
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: "名称", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(" " + _vm._s(row.name) + " "),
                    row.pid == 0
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: `ID：${row.id}`,
                              effect: "dark",
                              placement: "top-start",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-info",
                              staticStyle: { color: "#ccc" },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "创建时间", prop: "created_at" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm.button.edit
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.addOrg(row)
                              },
                            },
                          },
                          [_vm._v(" 新增下级 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: "",
                              title: "暂无权限，如有需要，请联系管理员",
                              type: "text",
                            },
                          },
                          [_vm._v(" 新增下级 ")]
                        ),
                    _vm.button.edit
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleEdit(row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: "",
                              title: "暂无权限，如有需要，请联系管理员",
                              type: "text",
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                    _vm.button.delete
                      ? _c(
                          "el-button",
                          {
                            staticClass: "del",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleDelete(row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: "",
                              title: "暂无权限，如有需要，请联系管理员",
                              type: "text",
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("or-import", {
        ref: "orImport",
        attrs: { list: _vm.studioList },
        on: { "fetch-data": _vm.fetchData },
      }),
      _c("add", {
        ref: "add",
        on: { "click-row": _vm.clickRow, "fetch-data": _vm.fetchData },
      }),
      _c("edit", { ref: "edit", on: { "fetch-data": _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }