import request from '@/utils/request'

export async function login(data) {
  return request({
    url: '/passport/login',
    method: 'post',
    data,
  })
}

export function getUserInfo() {
  return request({
    url: '/router/user-info',
    method: 'get',
  })
}

export function logout() {
  return request({
    url: '/passport/logout',
    method: 'get',
  })
}

export function register(data) {
  return request({
    url: '/register',
    method: 'post',
    data,
  })
}

//个人中心获取用户信息
export function getUserInfo2() {
  return request({
    url: '/home/user-info',
    method: 'get',
  })
}

//修改密码
export function editPassword(data) {
  return request({
    url: '/home/repass',
    method: 'post',
    data,
  })
}

//系统设置
export function editSys(data) {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/setting/sys',
    method: 'post',
    data,
  })
}

//日志
export function getLog(params) {
  return request({
    url: '/setting/operate-record',
    method: 'get',
    params,
  })
}

//获取用户端、咨询师端二维码等
export function getNetUrl(params) {
  return request({
    url: '/router/get-link',
    method: 'get',
    params,
  })
}

//支付配置setting/report
export function paySettings(data) {
  return request({
    url: '/setting/pay',
    method: 'post',
    data,
  })
}

//报告配置
export function reportSettings(data) {
  return request({
    url: '/setting/report',
    method: 'post',
    data,
  })
}
