import { baseURL } from '@/config'
export function isNJ() {
  //判断是否是南京教育局
  if (
    baseURL === 'http://njeduadapi.haotupsy.com' ||
    baseURL === 'http://xladapi.ksedu.cn'
  ) {
    return true
  }
}
/*色值转换*/
export function hexToRgba(hex, opacity = 1) {
  // 色值转换
  if (
    hex &&
    hex != '' &&
    hex.startsWith('#') &&
    (hex.length == 4 || hex.length == 7)
  ) {
    if (hex.length == 4) {
      let [s, r, g, b] = hex
      hex = `${s}${r}${r}${g}${g}${b}${b}`
    }
    let reg = /[0-9a-fA-F]{2}/g
    let match = hex.match(reg)
    match = match.map((item) => {
      return parseInt(`0x${item}`)
    })
    return `rgba(${match.toString()},${opacity})`
  }
  return 'What You Want ?'
}
export function getTime() {
  let d = new Date()
  let y = d.getFullYear()
  let m = d.getMonth() + 1
  let time
  if (m > 1 && m < 9) {
    time = [y + '-02-01', y + '-08-31']
  } else {
    if (m === 1) time = [y - 1 + '-09-01', y + '-01-31']
    else time = [y + '-09-01', y + 1 + '-01-31']
  }
  return time
}
//判断是否为图片
export function isImage(url) {
  // 获取文件扩展名
  const extension = url.substring(url.lastIndexOf('.') + 1).toLowerCase()
  // 支持的图片扩展名列表
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
  // 判断当前文件扩展名是否为图片扩展名
  if (imageExtensions.includes(extension)) {
    return true
  }
  return false
}
