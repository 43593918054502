<template>
  <el-dialog
    append-to-body
    :close-on-click-modal="false"
    title="添加用户"
    :visible.sync="dialogFormVisible"
    width="900px"
    @close="close"
  >
    <div class="box">
      <div class="left">
        <el-tree
          ref="ozTree"
          :accordion="true"
          :check-on-click-node="true"
          :check-strictly="true"
          :data="originList"
          highlight-current
          node-key="id"
          :props="defaultProps"
          style="height: 100%; overflow-y: auto"
          @check="checkChange"
          @node-click="handleNodeClick"
        />
      </div>
      <div class="right">
        <div class="search-box">
          <el-input
            v-model="searchContent"
            class="search"
            placeholder="输入姓名或账号搜索"
            size="middle"
            @keyup.enter.native="filterData"
          />
          <el-button
            class="btn"
            size="middle"
            type="primary"
            @click="filterData"
          >
            搜索
          </el-button>
        </div>
        <div class="table-list">
          <el-table
            ref="userTable"
            :data="userList"
            max-height="320"
            @select="selectRow"
            @selection-change="setSelectRows"
          >
            <el-table-column align="center" type="selection" />
            <el-table-column
              align="center"
              label="姓名"
              prop="name"
              show-overflow-tooltip
            />
            <el-table-column align="right" label="账号" prop="account" />
          </el-table>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button size="middle" @click="close">取 消</el-button>
      <el-button size="middle" type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getUser } from '@/api/crisis'
  import { getCurStudioOrgin } from '@/api/userManagementOrganization'

  export default {
    name: 'AddStu',
    data() {
      return {
        originList: [], //获取组织结构
        userList: [],
        userArr: [], //选择的成员
        dialogFormVisible: false,
        defaultProps: {
          children: 'children',
          label: 'name',
        },
        editCheckId: '',
        sid: '',
        searchContent: '',
        checkedIds: [], //选中的树形结构ID
        checkedList: [],
      }
    },
    created() {},
    methods: {
      filterData() {
        let input = this.searchContent && this.searchContent.toLowerCase()
        let items = this.userList
        if (input) {
          this.userList = items.filter(function (item) {
            return Object.keys(item).some(function (key1) {
              return String(item[key1]).toLowerCase().match(input)
            })
          })
          this.selectDefaultUser(this.userList, this.userArr)
        } else {
          getUser({ id: this.editCheckId }).then((res) => {
            this.userList = res.data
            this.selectDefaultUser(this.userList, this.userArr)
          })
        }
      },
      showEdit(sid, data) {
        this.getOriginList(sid)
        this.dialogFormVisible = true
        this.userArr = data.list || []
        this.editCheckId = data.ids[0]
      },
      close() {
        this.editCheckId = ''
        this.searchContent = ''
        this.userList = []
        this.$refs.ozTree.setCheckedKeys([])
        this.dialogFormVisible = false
      },
      async getOriginList(sid) {
        const { data } = sid
          ? await getCurStudioOrgin({ sid: sid })
          : await getCurStudioOrgin()
        this.originList = data
      },
      handCheckStudio(val) {
        this.getOriginList(val)
      },
      handleNodeClick(item, node, self) {
        //自己定义的editCheckId，防止单选出现混乱
        this.editCheckId = item.id
        this.$refs.ozTree.setCheckedKeys([item.id])
      },
      checkChange(item, node, self) {
        if (node === true) {
          this.editCheckId = item.id
          this.$refs.ozTree.setCheckedKeys([item.id])
        } else {
          if (this.editCheckId === item.id) {
            this.$refs.ozTree.setCheckedKeys([item.id])
          }
        }
        if (!item.children) {
          this.checkedIds = this.checkedIds.concat(item.id)
          this.checkedIds = [...new Set(this.checkedIds)]

          getUser({ id: item.id }).then((res) => {
            this.userList = res.data
            this.selectDefaultUser(this.userList, this.userArr)
          })
        }
      },
      selectDefaultUser(arr1, arr2) {
        this.$nextTick(() => {
          arr1.forEach((row) => {
            arr2.forEach((item) => {
              if (row.uid === item.uid) {
                this.$refs.userTable.toggleRowSelection(row, true)
              }
            })
          })
        })
      },
      selectRow(val, row) {
        let oldList = this.userArr
        if (row.checked) {
          for (let i = 0; i < oldList.length; i++) {
            if (oldList[i].uid === row.uid) {
              oldList[i].checked = false
            }
          }
          this.userArr = oldList.filter((o) => o.checked === true)
        } else {
          row.checked = true
          this.userArr = oldList.concat(row)
        }
      },
      setSelectRows(val) {
        let oldList = this.userArr,
          rows = this.userList
        let li = val.map((v) => {
          return Object.assign(v, {
            checked: true,
          })
        })
        if (!val.length) {
          for (let i = 0; i < oldList.length; i++) {
            for (let j = 0; j < rows.length; j++) {
              if (oldList[i].uid === rows[j].uid) {
                oldList[i].checked = false
              }
            }
          }
          this.userArr = oldList.filter((o) => o.checked === true)
        } else {
          let list = this.userArr.concat(li)
          const res = new Map()
          this.userArr = list.filter(
            (a) => !res.has(a.uid) && res.set(a.uid, 1)
          )
        }
      },
      save() {
        let data = {
          ids: this.checkedIds,
          list: this.userArr,
        }
        if (this.userArr.length) {
          this.$emit('fetch-data', data)
          this.dialogFormVisible = false
          this.searchContent = ''
        } else {
          this.$baseMessage('请选择成员')
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .box {
    display: flex;
    flex-direction: row;
    height: 400px;
    > div {
      &.left {
        width: 260px;
        border: 1px solid #eee;
        padding: 15px;
        .trees {
          height: 100%;
          overflow-y: scroll;
        }
      }
      &.right {
        width: 520px;
        display: flex;
        flex-direction: column;
        .search-box {
          display: flex;
          margin-bottom: 10px;
          .search {
            margin-right: 10px;
          }
          .btn {
            width: 120px;
          }
        }
        .table-list {
          flex: 1;
          border: 1px solid #eee;
          padding: 15px;
          border-radius: 8px;
        }
      }
      margin: 0 10px;
      border-radius: 8px;
    }
  }
  .dialog-footer {
    padding: 0 10px !important;
    .el-button {
      width: 100px;
      margin-left: 15px;
    }
  }
</style>
