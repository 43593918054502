import request from '@/utils/request'
//文章资讯列表
export function getList(params) {
  return request({
    url: '/article/info-list',
    method: 'get',
    params,
  })
}

//文章资讯分类
export function getArticleCateList(params) {
  return request({
    url: '/article/info-cate-list',
    method: 'get',
    params,
  })
}

export function doEdit(data) {
  return request({
    url: '/application/EAP/doEdit',
    method: 'post',
    data,
  })
}

export function doDelete(data) {
  return request({
    url: '/application/EAP/doDelete',
    method: 'post',
    data,
  })
}
