var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "team-list-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-left-panel",
            { attrs: { span: 4 } },
            [
              _vm.button.add
                ? _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-plus", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleEdit("", "/team/list/addTeamTest")
                        },
                      },
                    },
                    [_vm._v(" 添加 ")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: "",
                        icon: "el-icon-plus",
                        title: "暂无权限，如有需要，请联系管理员",
                        type: "primary",
                      },
                    },
                    [_vm._v(" 添加 ")]
                  ),
            ],
            1
          ),
          _c(
            "vab-query-form-right-panel",
            { attrs: { span: 20 } },
            [
              _c(
                "el-form",
                {
                  attrs: { inline: true, model: _vm.queryForm },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _vm.isSid === "all"
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                placeholder: "选择渠道",
                              },
                              model: {
                                value: _vm.queryForm.sid,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "sid", $$v)
                                },
                                expression: "queryForm.sid",
                              },
                            },
                            _vm._l(_vm.studioList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入标题" },
                        model: {
                          value: _vm.queryForm.title,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryForm,
                              "title",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryForm.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "团测状态",
                          },
                          model: {
                            value: _vm.queryForm.time_status,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "time_status", $$v)
                            },
                            expression: "queryForm.time_status",
                          },
                        },
                        _vm._l(_vm.statusList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-date-picker", {
                        attrs: {
                          align: "center",
                          "end-placeholder": "结束日期",
                          "picker-options": _vm.pickerOptions,
                          "range-separator": "-",
                          "start-placeholder": "开始日期",
                          type: "daterange",
                          "unlink-panels": "",
                          "value-format": "yyyy-MM-dd",
                        },
                        on: { change: _vm.selectDate },
                        model: {
                          value: _vm.queryForm.time,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "time", $$v)
                          },
                          expression: "queryForm.time",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-search", type: "primary" },
                          on: { click: _vm.queryData },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { border: "", data: _vm.list },
          on: { "selection-change": _vm.setSelectRows },
        },
        [
          _vm.isSid === "all"
            ? _c("el-table-column", {
                attrs: { label: "渠道", prop: "studio" },
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: "计划名称", prop: "title" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "tag" },
                      [
                        row.is_mian * 1 === 1
                          ? _c("el-tooltip", { attrs: { content: "免登录" } }, [
                              _c("span", [_vm._v("【免】")]),
                            ])
                          : _vm._e(),
                        _vm._v(" " + _vm._s(row.title) + " "),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "封面", prop: "cover" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("el-image", { attrs: { lazy: "", src: row.cover } }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "量表数", prop: "eval", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_vm._v(" " + _vm._s("包含" + row.eval + "个") + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "进度", prop: "rate" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("el-progress", {
                      attrs: {
                        percentage: row.rate * 1 > 100 ? 100 : row.rate * 1,
                        "stroke-width": 16,
                        "text-inside": true,
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "分发团测",
              prop: "",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.joinT(row)
                          },
                        },
                      },
                      [_vm._v("链接和二维码")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "测评时间", prop: "start_at" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("div", [_vm._v(_vm._s("开始时间：" + row.start_at))]),
                    _c("div", [_vm._v(_vm._s("结束时间：" + row.end_at))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "添加者", prop: "name", width: "120" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作时间",
              prop: "created_at",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态", prop: "time_status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type:
                            row.time_status === "已结束"
                              ? "success"
                              : row.time_status === "进行中"
                              ? "warning"
                              : "info",
                        },
                      },
                      [_vm._v(" " + _vm._s(row.time_status) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row, "/team/list/schel")
                          },
                        },
                      },
                      [_vm._v(" 完成情况 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { title: "刷新预警方案", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.refreshPlan(row)
                          },
                        },
                      },
                      [_vm._v(" 刷新 ")]
                    ),
                    _vm.button.edit
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(
                                  row,
                                  "/team/list/addTeamTest"
                                )
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: "",
                              title: "暂无权限，如有需要，请联系管理员",
                              type: "text",
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                    row.btn_del
                      ? _c(
                          "el-button",
                          {
                            staticClass: "del",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.doOptions(
                                  row,
                                  "确定删除该团测计划吗？",
                                  "delete"
                                )
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: "",
                              title: "暂无权限，如有需要，请联系管理员",
                              type: "text",
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("join-team", { ref: "join" }),
      _c(
        "el-dialog",
        {
          attrs: {
            center: "",
            "close-on-click-modal": false,
            "show-close": false,
            title: "温馨提示",
            visible: _vm.centerDialogVisible,
            width: "350px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.centerDialogVisible = $event
            },
          },
        },
        [
          _c("p", [_vm._v("推送团测计划前请检查参与测试人数是否有误，")]),
          _c("p", [_vm._v("如有误，请删除当前团测计划后重新创建")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.centerDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.centerDialogVisible = false
                      _vm.handleEdit(_vm.selectedRow, "/team/list/schel")
                    },
                  },
                },
                [_vm._v(" 检 查 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            center: "",
            "close-on-click-modal": false,
            "show-close": false,
            title: "温馨提示",
            visible: _vm.refreshVisible,
            width: "350px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.refreshVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.refreshLoading,
                  expression: "refreshLoading",
                },
              ],
              attrs: {
                "element-loading-spinner": "el-icon-loading",
                "element-loading-text": "拼命刷新中...",
              },
            },
            [
              !_vm.refreshLoading
                ? _c(
                    "div",
                    {
                      staticStyle: { "text-align": "center", color: "#36cdb7" },
                    },
                    [
                      _c("i", { staticClass: "el-icon-success" }),
                      _vm._v(" 该团测预警方案已全部刷新完毕！ "),
                    ]
                  )
                : _c("div", {
                    staticStyle: { height: "80px", "padding-bottom": "50px" },
                  }),
            ]
          ),
          !_vm.refreshLoading
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.refreshVisible = false
                        },
                      },
                    },
                    [_vm._v(" 关闭 ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }