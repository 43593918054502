<template>
  <el-dialog
    :close-on-click-modal="false"
    title="群发通知"
    :visible.sync="dialogFormVisible"
    width="600px"
    @close="close"
  >
    <el-form
      ref="form"
      class="demo-form"
      :label-position="labelPosition"
      label-width="90px"
      :model="form"
      :rules="rules"
    >
      <div class="form-row">
        <el-form-item label="接收用户" prop="name">
          <template #label>
            接收用户
            <el-tooltip
              class="item"
              content="接收用户为输入框中绑定学生的家长"
              effect="dark"
              placement="top-start"
            >
              <i class="el-icon-warning" style="color: #bbb"></i>
            </el-tooltip>
          </template>
          <el-input disabled rows="4" type="textarea" :value="form.name" />
        </el-form-item>
        <el-form-item label="选择模板" prop="template_id">
          <el-select
            v-model="form.template_id"
            clearable
            filterable
            placeholder="请选择消息模板"
            style="width: 100%"
          >
            <el-option
              v-for="item in tempList"
              :key="item.template_id"
              :label="item.title"
              :value="item.template_id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="跳转链接"
          prop="url"
          :rules="{
            type: 'url',
            message: '请输入正确的跳转链接',
            trigger: ['blur', 'change'],
          }"
        >
          <el-input v-model="form.url" placeholder="请输入跳转链接" />
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submitForm('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { setAllWarn } from '@/api/crisis'
  import { getTeamplate, sendMessages } from '@/api/tencent'

  export default {
    name: 'SendMessage',
    data() {
      return {
        tempList: [],
        id: '',
        labelPosition: 'right',
        form: {
          template_id: '',
          id: [],
          url: '',
        },
        rules: {
          template_id: [
            {
              required: true,
              message: '请选择消息模板',
              trigger: 'change',
            },
          ],
        },
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() + 24 * 60 * 60 * 1000
          },
        },
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      showEdit(row) {
        let users = row.map((item) => item.name)
        this.form.name = users.join('、')
        this.form.id = row.map((item) => item.id)

        getTeamplate({
          type: 'list',
        }).then((res) => {
          this.tempList = res.data.template_list
        })
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      submitForm(formName) {
        //提交
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            let p = this.form
            let obj = {
              id: p.id,
              template_id: p.template_id,
            }
            const { msg, code } = await sendMessages(obj)
            if (code === 200) {
              this.$baseMessage(msg, 'success')
              this.$emit('fetch-data')
              this.close()
            } else {
              this.$baseMessage(msg, 'error')
            }
          } else {
            // eslint-disable-next-line no-console
            console.log('error submit!!')
            return false
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .form-row {
    .w220 {
      width: 220px;
    }
  }
</style>
