<template>
  <div class="add-form-container">
    <div class="add-title">{{ form.id ? '编辑角色' : '新增角色' }}</div>
    <el-row :gutter="15">
      <el-col
        :lg="{ span: 12, offset: 6 }"
        :md="{ span: 20, offset: 2 }"
        :sm="{ span: 20, offset: 2 }"
        :xl="{ span: 12, offset: 6 }"
        :xs="24"
      >
        <el-form
          ref="form"
          class="demo-form"
          :label-position="labelPosition"
          label-width="130px"
          :model="form"
          :rules="rules"
        >
          <!-- <el-form-item
            v-if="isSid === 'all' && !form.id"
            label="渠道"
            prop="sid"
          >
            <el-select
              v-model="form.sid"
              filterable
              placeholder="请选择渠道"
              @change="bindChange"
            >
              <el-option
                v-for="item in studioList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item> -->
          <el-form-item label="名称" prop="name">
            <el-input v-model="form.name" placeholder="角色名称" />
          </el-form-item>
          <el-form-item ref="rightTree" label="权限" prop="rights">
            <el-tree
              ref="qxTree"
              :data="rightList"
              :default-checked-keys="form.rights"
              :default-expanded-keys="['1']"
              node-key="id"
              :props="defaultProps"
              show-checkbox
              @check="treeChecked('qxTree')"
            />
          </el-form-item>
          <el-form-item v-if="false" label="组织结构" prop="origin">
            <el-input v-model="filterTree" placeholder="输入关键字进行过滤" />
            <el-tree
              ref="ozTree"
              :data="dataTree"
              :default-checked-keys="form.origin"
              :filter-node-method="filterTreeNode"
              node-key="id"
              :props="defaultProps2"
              show-checkbox
              @check="treeChecked('ozTree')"
            />
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input v-model="form.sort" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('form')">
              {{ form.id ? '保存修改' : '立即创建' }}
            </el-button>
            <el-button v-if="!form.id" @click="resetForm('form')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { getMenuList, addRole } from '@/api/settingAuthority'
  import { getCurStudioOrgin } from '@/api/userManagementOrganization'
  import { handleActivePath } from '@/utils/routes'
  import { getStudioList } from '@/api/platformChannelManagement'
  import { isNumber } from '@/utils/validate'

  export default {
    name: 'AddRole',
    inject: ['reload'],
    data() {
      const validateNumber = (rule, value, callback) => {
        if (value.length && !isNumber(value)) {
          callback(new Error('请输入正整数'))
        } else {
          callback()
        }
      }
      return {
        rightList: [], //权限列表
        dataTree: [], //组织结构列表
        studioList: [], //渠道列表
        labelPosition: 'right',
        form: {
          name: '',
          sort: '',
          rights: ['14', '88'],
          origin: [],
        },
        rules: {
          // sid: [{ required: true, message: '请选择渠道' }],
          name: [{ required: true, message: '请输入角色名称' }],
          rights: [{ required: true, message: '请选择权限' }],
          sort: [{ required: false, validator: validateNumber }],
        },
        defaultProps: {
          children: 'children',
          label: 'label',
        },
        defaultProps2: {
          children: 'children',
          label: 'name',
        },
        filterTree: '',
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    watch: {
      filterTree(val) {
        this.$refs.ozTree.filter(val)
      },
    },
    created() {
      if (this.isSid === 'all') this.studioData()
      this.getPath()
      this.fetchData()
    },
    methods: {
      ...mapActions({
        changeTabsMeta: 'tabs/changeTabsMeta',
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      init() {
        this.form = {
          name: '',
          sort: '',
          rights: [],
          origin: [],
        }
        this.resetForm('form')
      },
      async studioData() {
        //获取渠道
        const { data } = await getStudioList()
        this.studioList = data
      },
      getPath() {
        const route = this.$route
        this.$nextTick(() => {
          let rid = route.query.id
          //this.init()
          if (rid) {
            this.getData(rid, 'info')
            this.form.id = rid
          }
        })
      },
      async getOriginList(sid) {
        //获取组织结构
        const { data } = await getCurStudioOrgin({ sid: sid })
        this.dataTree = data
      },
      bindChange(val) {
        this.form.sid = val
        this.form.orgid = ''
        this.getOriginList(val)
      },
      filterTreeNode(value, data) {
        if (!value) return true
        return data.label.indexOf(value) !== -1
      },
      submitForm(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.form.type = this.form.id ? 'edit' : 'add'
            const { msg, code } = await addRole(this.form)
            if (code === 200) {
              this.$baseMessage(msg, 'success')
              //关闭当前tab，返回list页并刷新
              await this.delVisitedRoute(handleActivePath(this.$route, true))
              this.reload()
              this.$router.push('/authority/roles')
            } else {
              this.$baseMessage(msg, 'error')
            }
          } else {
            // eslint-disable-next-line no-console
            console.log('error submit!!')
            return false
          }
        })
      },
      resetForm(formName) {
        this.$refs.qxTree.setCheckedKeys(['14'])
        //this.$refs.ozTree.setCheckedKeys([])
        this.$refs[formName].resetFields()
      },
      treeChecked(obj) {
        if (obj === 'qxTree') {
          this.form.rights = this.$refs.qxTree.getCheckedKeys(true)
          if (this.form.rights.length) {
            this.$refs.rightTree.clearValidate()
          }
        } else {
          if (this.dataTree.length) {
            this.form.origin = this.$refs.ozTree.getCheckedKeys(true)
          }
        }
      },
      async fetchData() {
        //获取权限菜单
        const { data } = await getMenuList()
        this.rightList = data
      },
      async getData(id, type) {
        //获取角色信息
        const { data } = await addRole({ id, type })
        this.form = data
        await this.getOriginList(data.sid)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .add-form-container {
    .demo-form {
      margin-top: 10px;
    }
    ::v-deep {
      .el-form-item__content {
        .el-rate {
          display: inline-block;
          font-size: 0;
          line-height: 1;
          vertical-align: middle;
        }

        .el-transfer__buttons {
          padding: 10px 10px 0 10px;
        }
      }
      .el-switch__core {
        width: 50px;
      }
      .el-switch__label * {
        font-size: 12px;
      }
      .el-switch__label--left {
        position: relative;
        left: 44px;
        color: #fff;
        z-index: -1111;
      }
      .el-switch__label--right {
        position: relative;
        right: 44px;
        color: #fff;
        z-index: -1111;
      }
      .el-switch__label--right.is-active,
      .el-switch__label--left.is-active {
        z-index: 1111;
        color: #fff !important;
      }
      .el-tree {
        margin-top: 15px;
      }
    }
    .add-title {
      text-align: center;
      line-height: 50px;
      background: #f0fcfa;
      margin: -20px -20px 20px;
      font-weight: bold;
      font-size: 16px;
    }
    .form-row {
      &:not(:first-child) {
        padding-top: 20px;
        margin-bottom: 20px;
      }
      .block-title {
        font-weight: bold;
        padding-left: 10px;
        border-left: 6px solid #26cdb7;
        margin-bottom: 20px;
      }
    }
  }
</style>
