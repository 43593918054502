var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add-form-container" },
    [
      _c("div", { staticClass: "add-title" }, [
        _vm._v(_vm._s(_vm.form.id ? "编辑管理员" : "新增管理员")),
      ]),
      _c(
        "el-row",
        { attrs: { gutter: 15 } },
        [
          _c(
            "el-col",
            {
              attrs: {
                lg: { span: 12, offset: 6 },
                md: { span: 20, offset: 2 },
                sm: { span: 20, offset: 2 },
                xl: { span: 12, offset: 6 },
                xs: 24,
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    "label-width": "80px",
                    model: _vm.form,
                    rules: _vm.rules,
                  },
                },
                [
                  _vm.isSid === "all"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "渠道", prop: "sid" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                placeholder: "渠道名称",
                              },
                              on: { change: _vm.handCheckStudio },
                              model: {
                                value: _vm.form.sid,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "sid", $$v)
                                },
                                expression: "form.sid",
                              },
                            },
                            _vm._l(_vm.studioList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dataTree.length
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "组织结构", prop: "origin" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "输入关键字进行过滤" },
                            model: {
                              value: _vm.filterTree,
                              callback: function ($$v) {
                                _vm.filterTree = $$v
                              },
                              expression: "filterTree",
                            },
                          }),
                          _c("el-tree", {
                            ref: "ozTree",
                            attrs: {
                              data: _vm.dataTree,
                              "filter-node-method": _vm.filterTreeNode,
                              "node-key": "id",
                              props: _vm.defaultProps2,
                              "show-checkbox": "",
                            },
                            on: {
                              check: function ($event) {
                                return _vm.treeChecked("ozTree")
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属角色", prop: "role" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", placeholder: "选择角色" },
                          model: {
                            value: _vm.form.role,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "role", $$v)
                            },
                            expression: "form.role",
                          },
                        },
                        _vm._l(_vm.roleList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "登录账号", prop: "username" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入登录账号" },
                        model: {
                          value: _vm.form.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "username", $$v)
                          },
                          expression: "form.username",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "密码", prop: "password" } },
                    [
                      _c("el-input", {
                        attrs: {
                          autocomplete: "new-password",
                          maxlength: "12",
                          minlength: "6",
                          placeholder: _vm.form.id
                            ? "密码长度6~12位，不输入则使用原密码"
                            : "密码长度6~12位",
                          type: "password",
                        },
                        model: {
                          value: _vm.form.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "确认密码", prop: "repass" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "再次输入密码",
                          type: "password",
                        },
                        model: {
                          value: _vm.form.repass,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "repass", $$v)
                          },
                          expression: "form.repass",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "电话", prop: "phone" } },
                    [
                      _c("el-input", {
                        attrs: {
                          autocomplete: "off",
                          maxlength: "11",
                          placeholder: "手机号码",
                          type: "tel",
                        },
                        model: {
                          value: _vm.form.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "remark" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("form")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.form.id ? "保存修改" : "立即创建") +
                              " "
                          ),
                        ]
                      ),
                      !_vm.form.id
                        ? _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.resetForm("form")
                                },
                              },
                            },
                            [_vm._v(" 重置 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }