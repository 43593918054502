<template>
  <div class="eval-container">
    <div class="wxts">
      提示：请依次选择
      <span>测评计划</span>
      和
      <span>测评量表</span>
      ，点击
      <el-button icon="el-icon-search" size="mini" type="primary">
        查询
      </el-button>
      按钮查看团体预警
    </div>
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item>
            <el-select
              v-model="queryForm.id"
              clearable
              filterable
              placeholder="请选择团测计划"
              @change="bindChange"
            >
              <el-option
                v-for="item in teamTestList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="queryForm.pid"
              clearable
              filterable
              placeholder="请选择量表"
            >
              <el-option
                v-for="item in evalList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="getNotes">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <div v-if="basic" ref="print" class="content">
      <el-card>
        <div class="print no-print" @click="print">
          <vab-icon icon="printer-line" />
          导出PDF/打印
        </div>
        <div class="title">{{ basic.name }} - 预警报告</div>
        <div class="card-name">{{ basic.name }}团体预警数据分析</div>
        <div class="baseinfo">
          <div class="base-line">
            <label class="lbl">总样本数</label>
            <span class="txt">{{ basic.all }}</span>
          </div>
          <div class="base-line">
            <label class="lbl">预警样本数</label>
            <span class="txt">{{ basic.warn }}</span>
          </div>
          <div class="base-line">
            <label class="lbl">占比</label>
            <span class="txt">{{ basic.rate }}</span>
          </div>
        </div>
      </el-card>
      <el-card>
        <div class="card-name">预警情况统计</div>
        <el-table border :data="warn">
          <el-table-column align="center" label="预警等级" prop="name" />
          <el-table-column align="center" label="预警内容" prop="descr" />
          <el-table-column
            align="center"
            label="样本数"
            prop="nums"
            width="100"
          />
          <el-table-column
            align="center"
            label="占比"
            prop="rate"
            width="100"
          />
        </el-table>
      </el-card>
      <el-card v-if="false">
        <div class="card-name">因子图表</div>
        <div class="chart-box">
          <vab-chart
            ref="charts"
            class="no-print"
            :height="chartVal.yAxis.data.length * 40 + 100"
            :init-options="initOptions"
            :options="chartVal"
            theme="vab-echarts-theme"
          />
          <img class="canvas-img" :src="canvasImg" />
        </div>
      </el-card>
      <el-card v-if="!isNJ">
        <div class="card-name">风险因子(仅供参考)</div>
        <el-table border :data="factor">
          <el-table-column label="" prop="fac" />
          <el-table-column align="center" label="风险范围" prop="min">
            <template #default="{ row }">
              {{ row.min + '~' + row.max }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="人数" prop="example" />
          <el-table-column align="center" label="占比" prop="rate" />
        </el-table>
      </el-card>
      <el-card>
        <div class="card-name">量表预警名单</div>
        <warn-list :id="queryForm.id" :pid="queryForm.pid" />
      </el-card>
      <el-card v-if="false">
        <div class="card-name">预警因子(维度数据)</div>
        <div
          v-for="(chart, index) in chartsList"
          :key="index"
          class="chart-box"
        >
          <!--<div class="chart-title">{{ chart.title }}</div>-->
          <vab-chart
            :ref="chart.cid"
            class="no-print"
            :height="chart.cHeight"
            :init-options="initOptions"
            :options="chart.chartVal"
            theme="vab-echarts-theme"
          />
          <img class="canvas-img" :src="chart.cImg" />
        </div>
      </el-card>
    </div>
    <no-data v-if="noData.show" :txt="noData.txt" />
  </div>
</template>

<script>
  import {
    getTeamTest,
    getTeamPaper,
    getTeamWarningDetail,
  } from '@/api/teamList'
  import VabChart from '@/extra/VabChart'
  import NoData from '@/components/noData/index'
  import getPageTitle from '@/utils/pageTitle'
  import WarnList from '../warnList'
  import { isNJ } from '@/utils/utils'
  export default {
    name: 'TeamGroup',
    components: {
      VabChart,
      NoData,
      WarnList,
    },
    data() {
      return {
        isNJ: isNJ(),
        teamTestList: [], //团测计划列表
        evalList: [], //获取所有量表
        factor: null,
        basic: null,
        warn: null, //预警情况统计
        chartsList: [], //维度图表
        initOptions: {
          renderer: 'svg',
        },
        chartVal: {},
        canvasImg: '',
        queryForm: {
          id: '',
          pid: '',
        },
        noData: {
          txt: '',
          show: false,
        },
      }
    },
    created() {
      this.getTeamTestList()
      let param = this.$route.query
      if (param.id && param.pid) {
        this.queryForm = {
          id: param.id,
          pid: param.pid,
        }
        this.getEvalList(param.id)
        this.getNotes()
      }
    },
    methods: {
      handleMeta(name, meta) {
        document.title = getPageTitle(meta.title, true)
      },
      bindChange(val) {
        this.queryForm.id = val
        this.queryForm.pid = ''
        this.getEvalList(val)
      },
      //获取量表
      async getEvalList(id) {
        const { data } = await getTeamPaper({ id })
        this.evalList = data
      },
      //获取团测计划
      async getTeamTestList() {
        const { data } = await getTeamTest()
        this.teamTestList = data
      },
      getNotes() {
        let param = this.queryForm
        if (!param.id || !param.pid) return false
        this.factor = null
        this.basic = null
        this.charts = []
        this.noData.show = false
        getTeamWarningDetail({
          id: param.id,
          pid: param.pid,
        })
          .then((res) => {
            let d = res
            let chart = d.evalZhu
            this.basic = d.basic
            this.factor = d.factor
            this.warn = d.warn
            /*this.chartVal = this.getChart(chart)
            this.chartsList = d.evalBing.map((item, index) => {
              let ya = (item.evals && item.evals.yAxis) || []
              return {
                cid: 'charts_' + index,
                title: item.fac,
                chartVal: this.getChart(item.evals, item.fac),
                cHeight: ya.length * 40 + 100,
                cImg: '',
              }
            })*/
          })
          .then(() => {
            this.$nextTick(() => {
              /*this.canvasImg = this.$refs.charts.getDataURL()
              this.chartsList.forEach((item) => {
                this.$set(item, 'cImg', this.$refs[item.cid][0].getDataURL())
              })*/
              this.handleMeta('TeamGroup', {
                title: this.basic.name + '团体预警',
              })
            })
          })
          .catch((err) => {
            this.noData = {
              txt: err,
              show: true,
            }
          })
      },
      getChart(data, title) {
        let chart = {
          title: {
            text: title || '',
            subtext: '',
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            data: data.legend,
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 1],
          },
          yAxis: {
            type: 'category',
            data: data.yAxis,
          },
          series: data.datasets,
        }
        return chart
      },
      print() {
        this.$print(this.$refs.print)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .eval-container {
    overflow-x: scroll;
    padding: 20px 0;
    .wxts {
      margin-bottom: 20px;
      background: #f9f9f9;
      padding: 10px 15px;
      border-radius: 4px;
      color: #555;
      span {
        color: #e64e1f;
        font-weight: bold;
      }
    }
    .canvas-img {
      display: none;
    }
  }
  .content {
    width: 1200px;
    margin: auto;
    padding: 10px;
    border-radius: 4px;
    border: 4px solid #e4e7ed;
    .canvas-img {
      width: 98%;
    }
    .el-card {
      position: relative;
      margin-bottom: 0;
      border: 0;
      padding: 20px 40px;
      &.is-always-shadow {
        box-shadow: none;
      }
      .print {
        position: absolute;
        right: 15px;
        top: 15px;
        border-radius: 3px;
        background: #b3b3b3;
        padding: 5px 10px;
        font-size: 14px;
        color: #fff;
        cursor: pointer;
      }
      .title {
        font-size: 32px;
        text-align: center;
        line-height: 60px;
        padding: 10px;
      }
      .card-name {
        position: relative;
        font-size: 20px;
        padding-left: 20px;
        line-height: 50px;
        font-weight: bold;
        margin-bottom: 20px;
        &:before {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 6px;
          height: 30px;
          border-radius: 10px;
          background: #26cdb7;
        }
      }
      .baseinfo {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .base-line {
          width: 300px;
          margin: 15px 20px 15px 0;
          line-height: 38px;
          border-radius: 3px;
          border: 1px solid #e6e6e6;
          .lbl {
            display: inline-block;
            width: 110px;
            text-align: center;
            background: #f5f7fa;
            font-weight: bold;
            border-right: 1px solid #e6e6e6;
            &.intro {
              width: 100%;
              border-right: 0;
              border-bottom: 1px solid #eee;
            }
          }
          .txt {
            padding: 0 10px;
            font-size: 16px;
            &.intro {
              font-weight: 100;
              line-height: 26px;
              padding: 20px;
              text-align: justify;
            }
          }
        }
      }
      .chart-box {
        border: 1px solid #f5f5f5;
        border-radius: 4px;
        padding: 25px 10%;
        margin-top: 30px;
        .chart-title {
          text-align: center;
          font-size: 16px;
          line-height: 40px;
          font-weight: bold;
        }
      }
    }
  }
  @media print {
    @page {
      size: auto;
      margin: 0mm;
    }
    .content {
      border: 0;
    }
  }
</style>
