var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title === "first" ? "新增一级组织" : "新增下级",
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "80px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "名称", prop: "name" } },
            [
              _vm.title === "first"
                ? [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入一级组织名称",
                        type: "textarea",
                      },
                      model: {
                        value: _vm.form.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name",
                      },
                    }),
                    _c("div", { staticStyle: { color: "#999" } }, [
                      _vm._v(
                        " 如需同时新增多个一级组织，请用中文逗号隔开，例：中南大学，湖南大学，深圳大学 "
                      ),
                    ]),
                  ]
                : [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入下级名称",
                        type: "textarea",
                      },
                      model: {
                        value: _vm.form.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name",
                      },
                    }),
                    _c("div", { staticStyle: { color: "#999" } }, [
                      _vm._v(
                        " 如需同时新增多个下级，请用中文逗号隔开，例：1班，2班，3班 "
                      ),
                    ]),
                  ],
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }