var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      !_vm.isNJ
        ? _c("notice-bar", {
            attrs: { notice: _vm.notice, studio: _vm.studio },
          })
        : _vm._e(),
      _c("div", { staticClass: "info" }, [
        _c("div", { staticClass: "left" }, [
          _c("span", [_vm._v(_vm._s(_vm.username) + "，您好！")]),
          _vm._v(" 欢迎登录" + _vm._s(_vm.title) + " "),
        ]),
        _vm._m(0),
      ]),
      _vm.userinfo.major === 1 ? _c("work") : _vm._e(),
      _c(
        "el-row",
        { attrs: { gutter: 15 } },
        [
          _c(
            "el-col",
            { attrs: { lg: 8, md: 24, sm: 24, xl: 8, xs: 24 } },
            [
              _c(
                "el-card",
                {
                  attrs: { shadow: "hover" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c("span", { staticClass: "f18" }, [_vm._v("用户")]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("vab-chart", {
                    ref: "chartsuser",
                    staticStyle: { margin: "100px 0" },
                    attrs: {
                      "init-options": _vm.initOptions,
                      options: _vm.state,
                      theme: "vab-echarts-theme",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 16, md: 24, sm: 24, xl: 16, xs: 24 } },
            [
              _c(
                "el-card",
                {
                  attrs: { shadow: "hover" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c("span", { staticClass: "f18" }, [
                            _vm._v("团测计划"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      attrs: { data: _vm.plan, size: "medium" },
                      on: { "row-click": _vm.clickRowHandle },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "标题",
                          prop: "title",
                          "show-overflow-tooltip": "",
                          width: "200",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "进度",
                          "min-width": "300",
                          prop: "rate",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("el-progress", {
                                  attrs: {
                                    percentage: row.rate * 1,
                                    "stroke-width": 16,
                                    "text-inside": true,
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "已完成",
                          prop: "comp",
                          "show-overflow-tooltip": "",
                          width: "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _vm._v(" " + _vm._s(row.comp + "人") + " "),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "未完成",
                          prop: "uncomp",
                          "show-overflow-tooltip": "",
                          width: "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _vm._v(" " + _vm._s(row.uncomp + "人") + " "),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "截止日期",
                          prop: "end_at",
                          "show-overflow-tooltip": "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                {
                  attrs: { shadow: "hover" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c("span", { staticClass: "f18" }, [_vm._v("访问")]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("vab-chart", {
                    ref: "charts",
                    attrs: {
                      height: 300,
                      "init-options": _vm.initOptions,
                      options: _vm.view,
                      theme: "vab-echarts-theme",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "right" }, [
      _c("img", { attrs: { src: require("@/assets/index_images/idx02.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }