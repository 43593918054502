<template>
  <el-dialog
    :close-on-click-modal="false"
    title="选择人员"
    :visible.sync="dialogFormVisible"
    width="900px"
    @close="close"
  >
    <div class="box">
      <div class="left">
        <el-tree
          ref="ozTree"
          :accordion="true"
          :check-on-click-node="true"
          :check-strictly="true"
          :data="originList"
          default-expand-all
          highlight-current
          node-key="id"
          :props="defaultProps"
          @check="checkChange"
          @node-click="handleNodeClick"
        />
      </div>
      <div class="right">
        <div class="search-box">
          <el-input
            v-model="searchContent"
            class="search"
            placeholder="输入姓名或账号搜索"
            size="middle"
            @keyup.enter.native="filterData"
          />
          <el-button
            class="btn"
            size="middle"
            type="primary"
            @click="filterData"
          >
            搜索
          </el-button>
        </div>
        <div class="table-list">
          <el-table
            ref="userTable"
            :data="userList"
            max-height="300"
            @select="selectRow"
            @select-all="selectAll"
          >
            <el-table-column align="center" type="selection" />
            <el-table-column
              align="center"
              label="姓名"
              prop="name"
              show-overflow-tooltip
            />
            <el-table-column align="right" label="账号" prop="account" />
          </el-table>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getUser } from '@/api/crisis'
  import { getCurStudioOrgin } from '@/api/userManagementOrganization'

  export default {
    name: 'AddWarnPerson',
    data() {
      return {
        originList: [], //获取组织结构
        userList: [],
        userArr: null, //选择的成员
        dialogFormVisible: false,
        defaultProps: {
          children: 'children',
          label: 'name',
        },
        editCheckId: '',
        searchContent: '',
        checkedIds: [], //选中的树形结构ID
        checkedList: [],
      }
    },
    //搜索数据
    computed: {},
    created() {},
    methods: {
      filterData() {
        let input = this.searchContent && this.searchContent.toLowerCase()
        let items = this.userList
        if (input) {
          this.userList = items.filter(function (item) {
            return Object.keys(item).some(function (key1) {
              return String(item[key1]).toLowerCase().match(input)
            })
          })
          this.selectDefaultUser(this.userList, this.userArr)
        } else {
          getUser({ id: this.editCheckId }).then((res) => {
            this.userList = res.data
            this.selectDefaultUser(this.userList, this.userArr)
          })
        }
      },
      showEdit() {
        this.getOriginList()
        this.dialogFormVisible = true
      },
      close() {
        /*this.editCheckId = ''
        this.searchContent = ''
        this.userList = []*/
        this.$refs.ozTree.setCheckedKeys([])
        this.dialogFormVisible = false
      },
      async getOriginList() {
        const { data } = await getCurStudioOrgin()
        this.originList = data
      },
      handleNodeClick(item, node, self) {
        //自己定义的editCheckId，防止单选出现混乱
        this.editCheckId = item.id
        this.$refs.ozTree.setCheckedKeys([item.id])
      },
      checkChange(item, node, self) {
        if (node === true) {
          this.editCheckId = item.id
          this.$refs.ozTree.setCheckedKeys([item.id])
        } else {
          if (this.editCheckId === item.id) {
            this.$refs.ozTree.setCheckedKeys([item.id])
          }
        }
        if (!item.children) {
          this.checkedIds = this.checkedIds.concat(item.id)
          this.checkedIds = [...new Set(this.checkedIds)]

          getUser({ id: item.id }).then((res) => {
            this.userList = res.data
            this.selectDefaultUser(this.userList, this.userArr)
          })
        }
      },
      selectDefaultUser(arr1, arr2) {
        this.$nextTick(() => {
          arr1.forEach((row) => {
            if (arr2 && row.uid === arr2.uid) {
              this.$refs.userTable.toggleRowSelection(row, true)
            }
          })
        })
      },
      selectAll() {
        this.$refs.userTable.clearSelection()
      },
      selectRow(val, row) {
        this.userArr = row
        this.$refs.userTable.clearSelection()
        if (val.length === 0) {
          this.userArr = null
          return false
        }
        this.$refs.userTable.toggleRowSelection(row, true)
      },
      save() {
        let userLen = this.userArr
        if (userLen) {
          this.$emit('fetch-data', this.userArr)
          this.close()
        } else {
          this.$baseMessage('请选择干预人员')
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .box {
    display: flex;
    flex-direction: row;
    height: 410px;
    > div {
      &.left {
        width: 260px;
        overflow-y: scroll;
      }
      &.right {
        flex: 1;
        display: flex;
        flex-direction: column;
        .search-box {
          display: flex;
          margin-bottom: 10px;
          .search {
            margin-right: 10px;
          }
          .btn {
            width: 120px;
          }
        }
        .table-list {
          flex: 1;
          border: 1px solid #eee;
          padding: 15px;
          border-radius: 8px;
          box-sizing: border-box;

          .el-table__header .el-checkbox {
            display: none;
          }
        }
      }
      margin: 0 10px;
      border-radius: 8px;
      border: 1px solid #eee;
      padding: 15px;
    }
  }
</style>
