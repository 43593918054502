var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.initData
    ? _c(
        "div",
        { staticClass: "eval-container" },
        [
          _c(
            "el-card",
            { staticClass: "tab" },
            [
              _c("span", { staticClass: "name" }, [
                _c("label", { staticClass: "el-icon-s-order" }),
                _vm._v(" 个人报告 "),
              ]),
              _c(
                "el-button",
                {
                  class: _vm.curTab === 0 ? "" : "cur",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.changeTab(0)
                    },
                  },
                },
                [_vm._v(" 默认样式 ")]
              ),
              _c(
                "el-button",
                {
                  class: _vm.curTab === 1 ? "" : "cur",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.changeTab(1)
                    },
                  },
                },
                [_vm._v(" 体检样式 ")]
              ),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content:
                      "系统内置两种个人报告样式，可自由切换不同的样式效果。",
                    placement: "top",
                  },
                },
                [
                  _c("span", { staticClass: "tip" }, [
                    _c("label", { staticClass: "el-icon-info" }),
                    _vm._v(" 说明 "),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              ref: "print",
              staticClass: "content",
              class: {
                invalid: !_vm.initData.is_true,
                customstyle: _vm.curTab,
              },
            },
            [
              !_vm.initData.is_true
                ? _c("div", { staticClass: "invalid-report" }, [
                    _vm._v("无效报告"),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "print no-print" },
                [
                  _c(
                    "el-tooltip",
                    { attrs: { placement: "top" } },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v(
                            " 可直接点击打印按钮，需要导出则选择【另存为PDF】， "
                          ),
                          _c("br"),
                          _vm._v(
                            " 需要【打印】则选择已连接的打印机即可直接打印报告。 "
                          ),
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-printer", type: "primary" },
                          on: { click: _vm.print },
                        },
                        [_vm._v(" 导出PDF/打印 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.curTab === 1
                ? _c("div", { staticClass: "card-cust" }, [
                    _c("div", { staticClass: "r-title" }, [
                      _vm._v(_vm._s(_vm.initData.eval.title)),
                    ]),
                    _c("div", { staticClass: "r-paper" }, [
                      _vm._v(_vm._s(_vm.initData.eval.paper)),
                    ]),
                    _c("div", { staticClass: "r-time" }, [
                      _vm._v("时间：" + _vm._s(_vm.initData.eval.test_at)),
                    ]),
                    _c("div", { staticClass: "r-time" }, [
                      _vm._v("测试人：" + _vm._s(_vm.initData.name)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "r-img" },
                      [
                        _c("el-image", {
                          attrs: { src: require("@/assets/report/fm.svg") },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "r-slogan" }, [
                      _vm._v(_vm._s(_vm.initData.eval.slogan)),
                    ]),
                  ])
                : _c("div", { staticClass: "el-card" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.initData.name + "的" + _vm.initData.eval.paper
                          ) +
                          "测评报告 "
                      ),
                    ]),
                    _c("div", { staticClass: "test-time" }, [
                      _c("span", [
                        _vm._v(
                          "测评时间：" + _vm._s(_vm.initData.eval.test_at)
                        ),
                      ]),
                      _c("span", [
                        _vm._v("用时：" + _vm._s(_vm.initData.eval.use_at)),
                      ]),
                    ]),
                  ]),
              _c("div", { staticClass: "el-card" }, [
                _c("div", { staticClass: "card-name base" }, [
                  _vm._v("个人信息"),
                ]),
                _vm.initData.basic
                  ? _c(
                      "div",
                      { staticClass: "baseinfo" },
                      _vm._l(_vm.initData.basic, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "base-line" },
                          [
                            _c("label", { staticClass: "lbl" }, [
                              _vm._v(_vm._s(item.name) + "："),
                            ]),
                            _c("span", { staticClass: "txt" }, [
                              _vm._v(_vm._s(item.value)),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
              _vm.initData.result
                ? [
                    _c("div", { staticClass: "el-card" }, [
                      _c("div", { staticClass: "card-name intro" }, [
                        _vm._v("量表介绍"),
                      ]),
                      _vm.initData.eval
                        ? _c("div", { staticClass: "baseinfo" }, [
                            _c("div", { staticClass: "base-line" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "lbl",
                                  staticStyle: {
                                    width: "100%",
                                    "font-size": "15px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.initData.eval.paper) + " "
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "base-line",
                                staticStyle: {
                                  width: "100%",
                                  "margin-right": "0",
                                },
                              },
                              [
                                _c("div", { staticClass: "txt intro" }, [
                                  _vm._v(_vm._s(_vm.initData.eval.intro)),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm.initData.chart.type !== "pan"
                      ? _c(
                          "div",
                          { staticClass: "el-card" },
                          [
                            _c("div", { staticClass: "card-name score" }, [
                              _vm._v("因子得分"),
                            ]),
                            _c(
                              "el-table",
                              {
                                attrs: {
                                  border: "",
                                  data: _vm.initData.factor,
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: { label: "名称", prop: "fac" },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "编码",
                                    prop: "coded",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "包含题目",
                                    prop: "nums",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "原始分",
                                    prop: "score",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "转换分",
                                    prop: "average",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "程度",
                                    prop: "warn",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ row }) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  row.warn ? row.warn : "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2734425043
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "常模",
                                    prop: "mod",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "el-card" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "card-name",
                            class:
                              _vm.initData.chart.type === "pan"
                                ? "result"
                                : "score",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.initData.chart.type === "pan"
                                    ? "测评结果"
                                    : "因子图表"
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c("vab-chart", {
                          ref: "charts",
                          staticClass: "no-print",
                          attrs: {
                            height: _vm.cHeight,
                            "init-options": _vm.initOptions,
                            options: _vm.chartVal,
                            theme: "vab-echarts-theme",
                          },
                        }),
                        _c("img", {
                          staticClass: "canvas-img",
                          attrs: { src: _vm.canvasImg },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "el-card" }, [
                      _vm.initData.chart.type !== "pan"
                        ? _c("div", { staticClass: "card-name result" }, [
                            _vm._v(" 结果分析 "),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "eval-res" },
                        _vm._l(_vm.initData.res, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "eval-block" },
                            [
                              _c("div", { staticClass: "name" }, [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.name +
                                          (item.score
                                            ? "(" + item.score + ")"
                                            : "")
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _vm.curTab === 1 &&
                              item.range &&
                              item.range.length
                                ? _c("div", { staticClass: "grade-box" }, [
                                    _c(
                                      "div",
                                      { staticClass: "grade" },
                                      _vm._l(item.range, function (g, gi) {
                                        return _c(
                                          "div",
                                          { key: gi, staticClass: "g-item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "line",
                                                class:
                                                  (g === item.warn_name
                                                    ? "cur"
                                                    : "") +
                                                  (" li" +
                                                    (item.range.length <= 3
                                                      ? 2 * gi
                                                      : gi)),
                                              },
                                              [
                                                g === item.warn_name
                                                  ? _c(
                                                      "div",
                                                      { staticClass: "txt" },
                                                      [_vm._v(_vm._s(g))]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "contxt" }, [
                                item.descr
                                  ? _c("div", {
                                      staticClass: "descr",
                                      domProps: {
                                        innerHTML: _vm._s(item.descr),
                                      },
                                    })
                                  : _vm._e(),
                                item.res
                                  ? _c(
                                      "div",
                                      { staticClass: "res" },
                                      _vm._l(item.result, function (r, j) {
                                        return _c("div", {
                                          key: j,
                                          domProps: { innerHTML: _vm._s(r) },
                                        })
                                      }),
                                      0
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }