<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="600px"
    @close="close"
  >
    <div>
      <el-form ref="form" class="demo-form" label-width="130px" :model="form">
        <div class="form-row">
          <el-form-item label="组织结构">
            <el-tree
              ref="ozTree"
              v-loading="loading"
              :data="dataTree"
              :default-checked-keys="form.treeOz"
              element-loading-text="拼命加载中"
              node-key="id"
              :props="defaultProps"
              show-checkbox
              @check="treeChecked"
            >
              <span slot-scope="{ node }">
                <span>{{ node.label }}</span>
                <span class="orange">{{ node.data.pep || 0 }} 人</span>
              </span>
            </el-tree>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="primary" @click="submitForm('form')">
            同步数据
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
  import {
    getEvalOriginList,
    getDetail,
    setEvals,
    doEdit,
    syncDataCheck,
    syncDataSave,
  } from '@/api/teamList'

  export default {
    name: 'SyncData',
    data() {
      return {
        loading: false,
        params: {},
        title: '同步数据',
        dialogFormVisible: false,
        dataTree: [],
        form: {},
        defaultProps: {
          children: 'children',
          label: 'name',
        },
      }
    },
    created() {},
    methods: {
      showEdit(id) {
        this.getDetailById(id)
        this.dialogFormVisible = true
      },
      close() {
        this.dialogFormVisible = false
      },
      treeChecked() {
        this.form.treeOz = this.$refs.ozTree.getCheckedKeys(true)
      },
      async getDetailById(id) {
        const { data } = await getDetail({ id })
        this.form = data
        this.surveyList = data.survey_list
        this.form.id = id
        let p = {
          origin: data.origin_id,
          id: id,
        }
        this.getOrigin(p)
      },
      getOrigin(datas) {
        this.loading = true
        getEvalOriginList(datas)
          .then((res) => {
            this.dataTree = res.data
          })
          .then(() => {
            this.loading = false
          })
      },
      submitForm(formName) {
        //提交
        this.$refs[formName].validate(async (valid, error) => {
          if (valid) {
            let obj = this.form
            let p = {
              id: obj.id,
              treeOz: obj.treeOz || [],
            }
            if (p.treeOz.length === 0) {
              this.$baseMessage('请选择组织结构', 'error')
              return false
            }
            const { data } = await syncDataCheck(p)
            let msg = ''
            let bstyle = 'color: red;padding: 0 4px'
            msg += '新增<b style="' + bstyle + '">' + data.add + '</b>人，'
            msg += '修改<b style="' + bstyle + '">' + data.edit + '</b>人，'
            msg += '删除<b style="' + bstyle + '">' + data.delete + '</b>人，'
            msg += '是否继续?'
            let txt =
              '同步数据功能仅限特殊情况下使用，请勿随意操作！同步数据会更新团测计划内的个人报告、团体报告等关键历史记录为最新数据，无法溯源。'
            if (data.is_addman)
              txt =
                '当前操作会对异动（删除）的用户测评数据重置（不可复原），请核对数据且谨慎操作！'
            this.$confirm(
              '<div>' +
                msg +
                '</div>' +
                '<div style="color: red;margin-top: 10px">' +
                txt +
                '</div>',
              '温馨提示',
              {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                dangerouslyUseHTMLString: true,
              }
            ).then(async () => {
              const { msg } = await syncDataSave(p)
              this.$message({
                type: 'success',
                message: msg,
              })
              //this.dialogFormVisible = true
              this.$emit('fetch-data')
              this.close()
            })
          } else {
            // eslint-disable-next-line no-console
            for (let key in error) {
              this.$message.error(error[key][0].message)
              break
            }
            return false
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .join-team {
    display: flex;
    .img {
      width: 140px;
      height: 140px;
      margin-right: 30px;
    }
    .btn {
      flex: 1;
      text-align: right;
      .el-button {
        width: 120px;
        margin: 15px 0 !important;
      }
    }
  }
  .tips {
    margin-top: 10px;
    color: #999;
    line-height: 40px;
  }
  .orange {
    color: #e6a155;
    margin-left: 5px;
    font-weight: bold;
  }
</style>
