var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-list-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-left-panel",
            { attrs: { span: 8 } },
            [
              _vm.button.add
                ? _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-plus", type: "primary" },
                      on: { click: _vm.handleEdit },
                    },
                    [_vm._v(" 添加 ")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: "",
                        icon: "el-icon-plus",
                        title: "暂无权限，如有需要，请联系管理员",
                        type: "primary",
                      },
                    },
                    [_vm._v(" 添加 ")]
                  ),
              _vm.button.delete
                ? _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-delete", type: "danger" },
                      on: { click: _vm.deleteUsers },
                    },
                    [_vm._v(" 删除 ")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: "",
                        icon: "el-icon-delete",
                        title: "暂无权限，如有需要，请联系管理员",
                        type: "danger",
                      },
                    },
                    [_vm._v(" 删除 ")]
                  ),
              _vm.button.add
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-upload2",
                        title: "导入用户",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleAdd("add")
                        },
                      },
                    },
                    [_vm._v(" 导入 ")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: "",
                        icon: "el-icon-upload2",
                        title: "暂无权限，如有需要，请联系管理员",
                        type: "text",
                      },
                    },
                    [_vm._v(" 导入 ")]
                  ),
              _vm.button.add
                ? _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-download", title: "导出用户" },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v(" 导出 ")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: "",
                        icon: "el-icon-download",
                        title: "暂无权限，如有需要，请联系管理员",
                      },
                    },
                    [_vm._v(" 导出 ")]
                  ),
              _vm.button.edit
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-edit-outline",
                        title: "批量更新用户组织结构",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleAdd("update")
                        },
                      },
                    },
                    [_vm._v(" 更新 ")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: "",
                        icon: "el-icon-edit-outline",
                        title: "暂无权限，如有需要，请联系管理员",
                        type: "text",
                      },
                    },
                    [_vm._v(" 更新 ")]
                  ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _vm._v(" 为避免导入用户失败，请先 "),
                  _c(
                    "el-button",
                    {
                      attrs: { title: "下载Excel模版", type: "text" },
                      on: { click: _vm.downLoadDoc },
                    },
                    [_vm._v(" 下载模版 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.button.search
            ? _c(
                "vab-query-form-right-panel",
                { attrs: { span: 16 } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: { inline: true, model: _vm.queryForm },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _vm.isSid === "all"
                        ? _c(
                            "el-form-item",
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "请选择渠道",
                                  },
                                  on: { change: _vm.bindChange },
                                  model: {
                                    value: _vm.queryForm.sid,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryForm, "sid", $$v)
                                    },
                                    expression: "queryForm.sid",
                                  },
                                },
                                _vm._l(_vm.studioList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        [
                          _c("el-cascader", {
                            ref: "orCas",
                            attrs: {
                              clearable: "",
                              options: _vm.treeData,
                              placeholder: "组织结构",
                              props: _vm.defaultProps,
                            },
                            on: {
                              change: _vm.handleChange,
                              "visible-change": _vm.cascaderClicked,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入姓名" },
                            model: {
                              value: _vm.queryForm.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "queryForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "选择性别" },
                              model: {
                                value: _vm.queryForm.sex,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "sex", $$v)
                                },
                                expression: "queryForm.sex",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: "0" },
                              }),
                              _c("el-option", {
                                attrs: { label: "男", value: "1" },
                              }),
                              _c("el-option", {
                                attrs: { label: "女", value: "2" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入账号" },
                            model: {
                              value: _vm.queryForm.account,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm,
                                  "account",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "queryForm.account",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入联系方式",
                            },
                            model: {
                              value: _vm.queryForm.phone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm,
                                  "phone",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "queryForm.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "登录状态" },
                              model: {
                                value: _vm.queryForm.loginStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "loginStatus", $$v)
                                },
                                expression: "queryForm.loginStatus",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: "0" },
                              }),
                              _c("el-option", {
                                attrs: { label: "已登录", value: "1" },
                              }),
                              _c("el-option", {
                                attrs: { label: "未登录", value: "2" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "end-placeholder": "登录结束日期",
                              "start-placeholder": "登录开始日期",
                              type: "daterange",
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.queryForm.loginTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryForm, "loginTime", $$v)
                              },
                              expression: "queryForm.loginTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-search",
                                type: "primary",
                              },
                              on: { click: _vm.queryData },
                            },
                            [_vm._v(" 查询 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { border: "", data: _vm.list },
          on: { "selection-change": _vm.selectChange },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "selection" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "序号",
              type: "index",
              width: "50",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "姓名", "min-width": "120", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("div", { staticClass: "user" }, [
                      row.sex === "男"
                        ? _c("img", {
                            attrs: { src: require("@/assets/male.png") },
                          })
                        : row.sex === "女"
                        ? _c("img", {
                            attrs: { src: require("@/assets/female.png") },
                          })
                        : _c("img", {
                            attrs: { src: require("@/assets/unknow.png") },
                          }),
                      _c("span", [_vm._v(_vm._s(row.name))]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "账号", prop: "account" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "密码", prop: "password" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "联系方式", prop: "phone" },
          }),
          _c("el-table-column", {
            attrs: { label: "组织结构", "min-width": "200", prop: "origin" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "上次登录", prop: "login_at" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.toArchive(row)
                          },
                        },
                      },
                      [_vm._v("档案")]
                    ),
                    _vm.button.edit
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: "",
                              title: "暂无权限，如有需要，请联系管理员",
                              type: "text",
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                    _vm.button.delete
                      ? _c(
                          "el-button",
                          {
                            staticClass: "del",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: "",
                              title: "暂无权限，如有需要，请联系管理员",
                              type: "text",
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("add", {
        ref: "add",
        attrs: { list: _vm.studioList },
        on: { "fetch-data": _vm.fetchData },
      }),
      _c("edit", {
        ref: "edit",
        attrs: { list: _vm.studioList },
        on: { "fetch-data": _vm.fetchData },
      }),
      _c("update", {
        ref: "update",
        attrs: { list: _vm.studioList },
        on: { "fetch-data": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }