<template>
  <div class="times-container">
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item v-if="isSid === 'all'" label="渠道" prop="sid">
        <el-select
          v-model="form.sid"
          filterable
          placeholder="请选择渠道"
          @change="bindStudio"
        >
          <el-option
            v-for="item in datas.studioList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="咨询师" prop="counsellor_id">
        <el-select
          v-model="form.counsellor_id"
          filterable
          placeholder="请选择咨询师"
          @change="bindChange"
        >
          <el-option
            v-for="item in teacherList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
        <div class="add-box">
          还没有咨询师？点击
          <el-button size="medium" type="text" @click="addTea">
            新增咨询师
          </el-button>
        </div>
      </el-form-item>
      <el-form-item label="预约时间" prop="data">
        <div style="color: #999">
          <!--<el-switch v-model="value" active-text="开" inactive-text="关" />-->
          设置预约时间段，选中表示可预约
        </div>
        <div class="time-box">
          <div v-for="item in list" :key="item.date" class="t-time-box">
            <el-checkbox
              v-model="item.checkAll"
              :indeterminate="itemIndeterminate(item)"
              @change="(val) => handleCheckAllChange(val, item)"
            >
              {{ item.date }}
            </el-checkbox>
            <el-checkbox-group v-model="checkedTimes" class="time-item">
              <el-checkbox
                v-for="time in item.times"
                :key="time[3]"
                :label="time"
              >
                {{ time[0] + '~' + time[1] }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="save">提交</el-button>
      </el-form-item>
    </el-form>
    <add ref="add" :list="datas" @fetch-data="getTeacher" />
  </div>
</template>

<script>
  import {
    getCredentList,
    getPsyList,
    getTimeDetail,
    saveTime,
  } from '@/api/appointment'
  import { getStudioList } from '@/api/platformChannelManagement'
  import { mapGetters } from 'vuex'
  import Add from '../counselor/components/CounselorEdit'

  export default {
    name: 'Times',
    components: { Add },
    data() {
      return {
        value: true,
        teacherList: [],
        form: {
          counsellor_id: '',
          data: [],
        },
        rules: {
          sid: [{ required: true, trigger: 'change', message: '请选择渠道' }],
          counsellor_id: [
            { required: true, trigger: 'change', message: '请选择咨询师' },
          ],
        },
        list: [],
        checkedTags: [],
        checkedTimes: [],
        hasSet: false,
        datas: {
          studioList: [], //渠道
          credentList: [], //资质列表
        },
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    created() {
      this.getCredent()
      if (this.isSid === 'all') this.getStudio()
      else this.getTeacher()
    },
    mounted() {},
    methods: {
      async getStudio() {
        const { data } = await getStudioList()
        this.datas.studioList = data
      },
      async getCredent() {
        const { data } = await getCredentList()
        this.datas.credentList = data
      },
      addTea() {
        this.$refs['add'].showEdit()
      },
      getTeacher(sid) {
        getPsyList(this.isSid === 'all' ? { sid: sid } : '').then((res) => {
          this.teacherList = res.data
        })
      },
      getTimes(tid) {
        getTimeDetail({ counsellor_id: tid }).then((res) => {
          this.list = res.data.map((item) => {
            let chkLen = item.times.filter((o) => o[2] === '10')
            !!chkLen.length &&
              (this.checkedTimes = this.checkedTimes.concat(chkLen))
            let chked = chkLen.length === item.times.length
            return Object.assign({}, item, {
              checkAll: chked,
            })
          })

          this.hasSet = !!this.checkedTimes.length
        })
      },
      //判断是否是不确定    条件1：当前item.items里至少有一个选上   条件2：当前item.items里至少有一个没有被选上
      itemIndeterminate(item) {
        return (
          item.times.some((v) => this.checkedTimes.indexOf(v) > -1) &&
          !item.times.every((v) => this.checkedTimes.indexOf(v) > -1)
        )
      },
      handleCheckAllChange(val, item) {
        const filterArr = this.checkedTimes.filter(
          (v) => item.times.indexOf(v) === -1
        )
        this.checkedTimes = val ? filterArr.concat(item.times) : filterArr
      },
      bindStudio(val) {
        this.form.sid = val
        this.form.counsellor_id = ''
        this.checkedTimes = []
        this.list = []
        this.getTeacher(val)
      },
      bindChange(val) {
        this.form.counsellor_id = val
        this.checkedTimes = []
        this.getTimes(val)
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.form.data = this.list
              .map((v) => ({
                date: v.date,
                times: v.times.filter((v) => this.checkedTimes.indexOf(v) > -1),
              }))
              .filter((v) => v.times.length)
            if (!this.form.data.length && !this.hasSet) {
              this.$baseMessage('请选择可预约时间', 'error')
              return false
            }
            const { msg } = await saveTime(this.form)
            this.$baseMessage(msg, 'success')
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .times-container {
    .add-box {
      display: inline-block;
      color: #999;
      margin-left: 10px;
    }
    ::v-deep.el-checkbox-group {
      .el-checkbox__input {
        display: none;
      }
      .el-checkbox {
        width: 100px;
        height: 34px;
        text-align: center;
        margin: 0.1425%;
        border: 1px solid #eee;
        border-radius: 3px;
        color: #777;
        &.is-checked {
          background: #26cdb7;
          border-color: #26cdb7;
        }
      }
      .el-checkbox__label {
        padding-left: 0;
      }
      .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #fff;
      }
    }
    .time-box {
      display: flex;
      flex-wrap: wrap;
      .t-time-box {
        width: 120px;
      }
    }
  }
</style>
