<template>
  <div class="setting-container">
    <el-card v-if="form" shadow="never">
      <div slot="header" class="header">系统设置</div>
      <el-form ref="form" label-width="80px" :model="form">
        <el-form-item label="平台名称" prop="sysname">
          <el-input v-model="form.sysname" placeholder="请输入平台名称" />
        </el-form-item>
        <el-form-item label="客服电话" prop="custphone">
          <el-input
            v-model="form.custphone"
            maxlength="13"
            placeholder="请输入客服电话"
          />
        </el-form-item>
        <el-form-item label="封面" prop="banner">
          <div class="img-uploader">
            <el-upload
              accept="image/png, image/jpeg"
              :action="UploadUrl()"
              :auto-upload="false"
              :on-change="fileChange"
              :on-remove="handleRemove"
              :show-file-list="false"
            >
              <img v-if="form.banner" class="img" :src="form.banner" />
              <i v-else class="el-icon-plus"></i>
            </el-upload>
            <div slot="tip" class="el-upload__tip">
              <div>
                只能上传
                <span>.jpg、.png</span>
                文件，且
                <span>不超过1M</span>
              </div>
              <div>(图片尺寸：750*360)</div>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button size="medium" type="primary" @click="save">保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
  import { editSys } from '@/api/user'
  export default {
    name: 'BaseSetting',
    components: {},
    data() {
      return {
        form: {
          custphone: '',
          sysname: '',
          banner: '',
        },
        hasImg: '',
      }
    },
    created() {
      this.getData()
    },
    mounted() {},
    methods: {
      async getData() {
        const { data } = await editSys({ type: 'info' })
        if (data) {
          this.form = data
        } else {
          this.form = {
            custphone: '',
            sysname: '',
            banner: '',
          }
        }
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let p = this.form
            let formData = new FormData()
            formData.append('custphone', p.custphone)
            formData.append('sysname', p.sysname)
            formData.append('type', 'edit')
            if (this.hasImg && this.hasImg.raw)
              formData.append(
                'image_file',
                this.hasImg.raw,
                this.hasImg.raw.name
              )
            const { data } = await editSys(formData)
            this.$baseMessage(data.msg, data.code === 200 ? 'success' : 'error')
          } else {
            return false
          }
        })
      },
      UploadUrl: function () {
        return ''
      },
      fileChange(file, fileList) {
        if (fileList.length >= 1) {
          this.handleRemove(fileList)
        }
        const isLt2M = file.size / 1024 / 1024 < 1
        if (file.raw.type !== 'image/jpeg' && file.raw.type !== 'image/png') {
          this.$message.error('上传图片只能是 JPG、PNG 格式!')
          return false
        } else if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 1MB!')
          fileList.map((item, index) => {
            if (item === file) {
              fileList.splice(index, 1)
            }
          })
        } else {
          this.form.banner = URL.createObjectURL(file.raw)
          this.hasImg = file
        }
      },
      handleRemove(fileList) {
        console.log(fileList)
      },
    },
  }
</script>

<style lang="scss">
  .setting-container {
    .el-card {
      border: 0 !important;
    }
    .el-card__header {
      padding-left: 0 !important;
      padding-top: 0 !important;
    }
    .el-input {
      width: 220px !important;
    }
    .el-form-item--small .el-form-item__label {
      color: #999;
    }
    .header {
      position: relative;
      font-size: 16px;
      font-weight: bold;
      padding: 0 20px;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 6px;
        background: #26cdb7;
        border-radius: 3px 0 0 3px;
      }
    }
    .img-uploader {
      display: flex;
      ::v-deep.el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 3px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      /* ::v-deep.el-upload--picture-card,
      ::v-deep.el-upload-list--picture-card .el-upload-list__item {
        width: 200px;
        height: 100px;
      }*/
      ::v-deep.el-list-enter-active,
      ::v-deep.el-list-leave-active {
        transition: none;
      }

      ::v-deep.el-list-enter,
      ::v-deep.el-list-leave-active {
        opacity: 0;
      }
      .hide ::v-deep.el-upload--picture-card {
        display: none;
      }
      .el-upload__tip {
        flex: 1;
        margin-left: 10px;
        color: #999;
        line-height: normal;
        span {
          color: #e64e1f;
        }
      }
      ::v-deep.el-upload:hover {
        border-color: #92e6db;
      }
      .img,
      .el-icon-plus {
        width: 200px;
        height: 100px;
        display: block;
        line-height: 100px;
      }
    }
  }
</style>
