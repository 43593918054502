var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "logo-container",
      class: {
        ["logo-container-" + _vm.theme.layout]: true,
      },
    },
    [
      _c("router-link", { attrs: { to: "/" } }, [
        _c("span", { staticClass: "logo" }, [
          !_vm.logo
            ? _c("img", { attrs: { src: require("@/assets/logo.png") } })
            : _c("img", { attrs: { src: _vm.logo } }),
        ]),
        _c(
          "span",
          {
            staticClass: "title new",
            class: { "hidden-xs-only": _vm.theme.layout === "horizontal" },
          },
          [_vm._v(" " + _vm._s(_vm.title) + " ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }