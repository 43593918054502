<template>
  <div class="info">
    <div class="info-title">个人信息</div>
    <div v-if="info" class="info-content border">
      <div class="img">
        <img v-if="info.sex == 1" src="@/assets/male.png" />
        <img v-else-if="info.sex == 2" src="@/assets/female.png" />
        <img v-else src="@/assets/unknow.png" />
      </div>
      <div class="base">
        <div class="name">
          {{ info.name }}
          <span>测评用户</span>
        </div>
        <div class="other">
          <div>
            性别：
            <span>{{ sexs[info.sex] && sexs[info.sex].name }}</span>
          </div>
          <div>
            账号：
            <span>{{ info.account }}</span>
          </div>
          <div>
            密码：
            <span>{{ info.password }}</span>
          </div>
        </div>
        <div class="other">
          <div v-for="(item, index) in info.warn" :key="index">
            {{ item.name }}：
            <span :style="'color:' + item.color">{{ item.value }}</span>
          </div>
        </div>
      </div>
      <div class="btn">
        <el-button icon="el-icon-edit-outline" type="primary" @click="editInfo">
          编辑资料
        </el-button>
      </div>
    </div>
    <template v-if="showAI && out">
      <div class="info-title">AI心理画像</div>
      <iframe
        :src="`https://psy-business.luoke101.com/#/white/archivesDetail?sid=${out.sid}&uid=${out.uid}&phone=${out.phone}`"
        style="border: 0; width: 100%; height: 600px"
      ></iframe>
    </template>
    <div class="info-title">基础信息</div>
    <div v-if="basic.length" class="info-content">
      <div v-for="(item, e) in basic" :key="e" class="item">
        {{ item.name + '：' + item.value }}
      </div>
      <template v-if="ex.length">
        <div v-if="!showMore" class="more">
          <el-button type="text" @click="show">
            展开查看更多
            <i class="el-icon-arrow-down"></i>
          </el-button>
        </div>
        <div v-else class="info-content">
          <div v-for="(eitem, i) in ex" :key="i" class="item">
            {{ eitem.name + '：' + eitem.value }}
          </div>
        </div>
      </template>
    </div>
    <div class="info-title">心理画像</div>
    <div class="info-chart">
      <vab-chart
        v-if="chart"
        ref="charts"
        :height="500"
        :options="initData"
        style="margin-top: 50px"
        theme="vab-echarts-theme"
      />
      <no-data v-else style="margin: 50px 0" :txt="'暂无数据'" />
    </div>
    <edit ref="edit" :list="studioList" @fetch-data="getUserDate" />
  </div>
</template>

<script>
  import NoData from '@/components/noData/index'
  import VabChart from '@/extra/VabChart/index.vue'
  import Edit from '@/views/userManagement/member/components/MemberEdit.vue'
  import { getStudioList } from '@/api/platformChannelManagement'
  import { editUser, memberRecord } from '@/api/userManagementOrganization'
  import { sex } from '@/utils/selects'
  export default {
    name: 'Person',
    components: { Edit, VabChart, NoData },
    props: {
      uid: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        id: '',
        studioList: [],
        info: [],
        basic: [],
        chart: null,
        ex: [],
        initData: null,
        sexs: sex,
        showMore: false,
        showAI: false,
        out: null,
      }
    },
    watch: {
      uid: {
        handler(newData) {
          this.id = newData
          //this.getUserDate()
        },
        deep: true,
        immediate: true,
      },
    },
    created() {
      this.getUserDate()
    },
    methods: {
      show() {
        this.showMore = true
      },
      getUserDate() {
        this.getInfo()
        this.getData()
      },
      getData() {
        let uid = this.$route.query.id
        memberRecord({ uid: uid, type: 'person' }).then((res) => {
          let newData = res.data
          this.showAI = newData.open_ai
          this.chart = newData.char
          this.out = newData.out
          this.basic =
            (newData.basic &&
              newData.basic.filter((item) => item.value !== '')) ||
            []
          this.ex =
            (newData.ex && newData.ex.filter((item) => item.value !== '')) || []

          this.getRadar()
        })
      },
      getInfo() {
        let uid = this.$route.query.id
        editUser({ type: 1, uid: uid }).then((res) => {
          this.info = res.data
        })
      },
      async getStudio() {
        const { data } = await getStudioList()
        this.studioList = data
      },
      editInfo() {
        this.$refs['edit'].showEdit(this.info)
      },
      getRadar() {
        let data = this.chart
        if (!data) return
        data.series[0].areaStyle = {
          color: new VabChart.graphic.RadialGradient(0.1, 0.6, 1, [
            {
              color: 'rgba(38, 205, 183, 0.1)',
              offset: 0,
            },
            {
              color: 'rgba(38, 205, 183, 0.9)',
              offset: 1,
            },
          ]),
        }
        data.series[1].areaStyle = {
          color: new VabChart.graphic.RadialGradient(0.1, 0.6, 1, [
            {
              color: 'rgba(253, 87, 55, 0.1)',
              offset: 0,
            },
            {
              color: 'rgba(253, 87, 55, 0.9)',
              offset: 1,
            },
          ]),
        }
        this.initData = {
          color: ['#26cdb7', '#fd5737'],
          legend: {
            data: data.legend,
          },
          tooltip: {},
          radar: {
            shape: 'circle',
            indicator: data.radar.indicator,
            radius: 180,
            splitNumber: 4,
            /*splitArea: {
              areaStyle: {
                color: ['#77EADF', '#26C3BE', '#64AFE9', '#428BD4'],
                shadowColor: 'rgba(0, 0, 0, 0.2)',
                shadowBlur: 10,
              },
            },*/
          },
          series: [
            {
              type: 'radar',
              data: data.series.map((item) => {
                item.label = { show: true }
                return item
              }),
            },
          ],
        }
        return this.initData
      },
    },
  }
</script>

<style lang="scss" scoped>
  .info {
    .info-title {
      position: relative;
      font-weight: bold;
      padding: 0 15px;
      line-height: 40px;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 5px;
        border-radius: 3px;
        height: 20px;
        margin: auto;
        background: #2ecfb9;
      }
    }
    .info-content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px;
      &.border {
        border-bottom: 1px solid #e4e7ed;
        padding: 10px 20px 40px;
      }
      .item {
        width: 50%;
        line-height: 40px;
        padding-left: 20px;
      }
      .more {
        width: 100%;
        text-align: center;
      }
      .img {
        width: 70px;
        height: 70px;
        border-radius: 100%;
        border: 4px solid #2ecfb9;
        box-shadow: 0 0 24px 7px rgba(46, 207, 185, 0.3);
        img {
          width: 100%;
          height: 100%;
          border-radius: 100%;
        }
      }
      .base {
        flex: 1;
        margin-left: 15px;
        .name {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 20px;
          span {
            font-weight: normal;
            font-size: 9px;
            color: rgb(46, 207, 185);
            border-radius: 2px;
            background: linear-gradient(
              90deg,
              rgba(46, 207, 185, 0.47),
              rgba(46, 207, 185, 0.13) 100%
            );
            padding: 3px 4px;
          }
        }
        .other {
          display: flex;
          margin-top: 15px;
          > div {
            margin-right: 20px;
            > span {
              color: rgba(1, 1, 1, 0.5);
            }
          }
        }
      }
      .btn {
        width: 100px;
        text-align: right;
        .el-button--primary {
          border: none;
          box-shadow: 0 0 20px 7px rgba(46, 207, 185, 0.13);
          background: linear-gradient(
            141.69deg,
            rgb(46, 207, 185) 17.363%,
            rgba(46, 207, 185, 0.47) 100%
          ) !important;
        }
      }
    }
  }
</style>
