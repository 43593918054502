var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vab-header" }, [
    _c(
      "div",
      { staticClass: "vab-main" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 10 } }, [_c("vab-logo")], 1),
            _c("el-col", { attrs: { span: 14 } }, [
              _c(
                "div",
                { staticClass: "right-panel" },
                [
                  "horizontal" === _vm.layout
                    ? _c(
                        "el-menu",
                        {
                          attrs: {
                            "active-text-color":
                              _vm.variables["menu-color-active"],
                            "background-color":
                              _vm.variables["menu-background"],
                            "default-active": _vm.activeMenu,
                            "menu-trigger": "hover",
                            mode: "horizontal",
                            "text-color": _vm.variables["menu-color"],
                          },
                        },
                        [
                          _vm._l(_vm.handleRoutes, function (route) {
                            return [
                              !route.hidden
                                ? _c("vab-menu", {
                                    key: route.path,
                                    attrs: { item: route, layout: _vm.layout },
                                  })
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "el-popover",
                    {
                      staticClass: "item",
                      attrs: {
                        placement: "bottom",
                        trigger: "hover",
                        width: "260",
                      },
                      model: {
                        value: _vm.userActive,
                        callback: function ($$v) {
                          _vm.userActive = $$v
                        },
                        expression: "userActive",
                      },
                    },
                    [
                      _vm.link
                        ? _c("div", [
                            _vm.link.user
                              ? _c("div", { staticClass: "wechat-code" }, [
                                  _c(
                                    "div",
                                    { staticClass: "img" },
                                    [
                                      _c("el-image", {
                                        attrs: { src: _vm.link.user.code },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "btn" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveImg(
                                                _vm.link.user.code,
                                                "用户端"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 下载二维码 ")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.copy(_vm.link.user.url)
                                            },
                                          },
                                        },
                                        [_vm._v(" 复制链接 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm.link.user_new
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "border-top": "1px solid #eee",
                                      padding: "15px 10px 0 10px",
                                    },
                                  },
                                  [_vm._v(" 新版入口 ")]
                                )
                              : _vm._e(),
                            _vm.link.user_new
                              ? _c("div", { staticClass: "wechat-code" }, [
                                  _c(
                                    "div",
                                    { staticClass: "img" },
                                    [
                                      _c("el-image", {
                                        attrs: { src: _vm.link.user_new.code },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "btn" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveImg(
                                                _vm.link.user_new.code,
                                                "用户端"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 下载二维码 ")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.copy(
                                                _vm.link.user_new.url
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 复制链接 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm.link.pay
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "border-top": "1px solid #eee",
                                      padding: "15px 10px 0 10px",
                                    },
                                  },
                                  [_vm._v(" 付费测评 ")]
                                )
                              : _vm._e(),
                            _vm.link.pay
                              ? _c("div", { staticClass: "wechat-code" }, [
                                  _c(
                                    "div",
                                    { staticClass: "img" },
                                    [
                                      _c("el-image", {
                                        attrs: { src: _vm.link.pay.code },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "btn" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveImg(
                                                _vm.link.pay.code,
                                                "用户端"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 下载二维码 ")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.copy(_vm.link.pay.url)
                                            },
                                          },
                                        },
                                        [_vm._v(" 复制链接 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm.link.parent
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "border-top": "1px solid #eee",
                                      padding: "15px 10px 0 10px",
                                    },
                                  },
                                  [_vm._v(" 家长端 ")]
                                )
                              : _vm._e(),
                            _vm.link.parent
                              ? _c("div", { staticClass: "wechat-code" }, [
                                  _c(
                                    "div",
                                    { staticClass: "img" },
                                    [
                                      _c("el-image", {
                                        attrs: { src: _vm.link.parent.code },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "btn" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveImg(
                                                _vm.link.parent.code,
                                                "家长端"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 下载二维码 ")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.copy(
                                                _vm.link.parent.url
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 复制链接 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _c(
                        "span",
                        { attrs: { slot: "reference" }, slot: "reference" },
                        [
                          _c("vab-icon", {
                            staticClass: "i_c",
                            attrs: { icon: "user-3-line" },
                          }),
                          _vm._v(" 用户端 "),
                          _c("i", {
                            staticClass: "el-icon-arrow-down",
                            class: { active: _vm.userActive },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  !_vm.isNJ
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "item",
                            on: { click: _vm.gotoNotice },
                          },
                          [
                            _c(
                              "el-badge",
                              {
                                staticClass: "item",
                                attrs: {
                                  hidden: _vm.notice <= 0,
                                  value: _vm.notice,
                                },
                              },
                              [
                                _c("vab-icon", {
                                  staticClass: "i_c",
                                  attrs: {
                                    icon: "notification-line",
                                    title: "消息通知",
                                  },
                                }),
                                _vm._v(" 消息通知 "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c("vab-avatar"),
                ],
                2
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }