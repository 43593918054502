var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-list-container" },
    [
      _c(
        "el-card",
        { attrs: { shadow: "never" } },
        [
          _c(
            "div",
            {
              staticClass: "header",
              attrs: { slot: "header" },
              slot: "header",
            },
            [_vm._v("消息通知")]
          ),
          _vm.list.length
            ? [
                _c(
                  "el-collapse",
                  {
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.activeNames,
                      callback: function ($$v) {
                        _vm.activeNames = $$v
                      },
                      expression: "activeNames",
                    },
                  },
                  _vm._l(_vm.list, function (item, index) {
                    return _c(
                      "el-collapse-item",
                      { key: index, attrs: { name: index } },
                      [
                        _c("template", { slot: "title" }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100%",
                                "padding-right": "5px",
                                display: "flex",
                                "justify-content": "space-between",
                              },
                              style: {
                                color: item.is_read == 1 ? "#999" : "#303133",
                              },
                            },
                            [
                              _c("span", [_vm._v(_vm._s(item.title))]),
                              _c("span", [_vm._v(_vm._s(item.created_at))]),
                            ]
                          ),
                        ]),
                        item.type == 2
                          ? _c("div", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: item.content,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("点击下载")]
                              ),
                            ])
                          : _c("div", {
                              style: {
                                color: item.is_read == 1 ? "#999" : "#303133",
                              },
                              domProps: { innerHTML: _vm._s(item.content) },
                            }),
                      ],
                      2
                    )
                  }),
                  1
                ),
              ]
            : _c("el-empty", { attrs: { description: "~暂无消息~" } }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }