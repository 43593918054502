<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      title="新增预警"
      :visible.sync="dialogFormVisible"
      width="500px"
      @close="close"
    >
      <el-form ref="form" label-width="80px" :model="form" :rules="rules">
        <div class="form-row">
          <el-form-item label="姓名" prop="name">
            <el-input v-if="form.id" class="w220" disabled :value="form.name" />
            <el-input
              v-else
              class="w220"
              placeholder="请选择用户"
              readonly
              :value="name"
              @focus="addPerson"
            />
          </el-form-item>
          <el-form-item label="预警来源" prop="mental_warn_type">
            <el-select
              v-model="form.mental_warn_type"
              placeholder="请选择预警来源"
            >
              <el-option
                v-for="item in warnsourceList"
                :key="item.id"
                :label="item.name"
                :value="item.sort"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="预警级别" prop="mental_warn_level">
            <el-radio-group v-model="form.mental_warn_level">
              <el-radio
                v-for="item in warningList"
                :key="item.id"
                :label="item.sort"
              >
                {{ item.name }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="预警时间" prop="mental_time">
            <el-date-picker
              v-model="form.mental_time"
              placeholder="请选择日期"
              type="date"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item label="预警情况" prop="mental_remark">
            <el-input
              v-model="form.mental_remark"
              placeholder="请输入预警情况"
              type="textarea"
            />
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
    <edit ref="edit" @fetch-data="getInfo" />
  </div>
</template>

<script>
  import { getWarnParam, setWarnParam } from '@/api/crisis'
  import Edit from './AddWarnPerson'

  export default {
    name: 'AddSingleMental',
    components: {
      Edit,
    },
    data() {
      return {
        name: '',
        warningList: [], //预警等级列表
        warnsourceList: [], //预警来源列表
        form: {
          name: null,
          mental_warn_type: '',
          mental_warn_level: '',
          mental_time: '',
          mental_remark: '',
        },
        rules: {
          mental_warn_type: [
            { required: true, message: '请选择预警来源', trigger: 'change' },
          ],
          mental_warn_level: [
            { required: true, message: '请选择预警级别', trigger: 'change' },
          ],
          mental_remark: [
            { required: true, message: '请输入预警情况', trigger: 'blur' },
          ],
          mental_time: [
            {
              required: true,
              message: '请选择时间',
              trigger: 'change',
            },
          ],
        },
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      levelType(type) {
        getWarnParam({ type: type }).then((res) => {
          let list = []
          res.data.forEach((i) => {
            i.status == '1' && list.push(i)
          })
          this[type + 'List'] = list
        })
      },
      addPerson() {
        this.$refs['edit'].showEdit()
      },
      getInfo(val) {
        this.form.name = val
        this.name = val.name
      },
      showEdit() {
        this.levelType('warnsource')
        this.levelType('warning')
        this.dialogFormVisible = true
      },
      close() {
        this.name = ''
        this.$refs['form'].resetFields()
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let obj = this.form
            obj.type = 'add'
            obj.style = 'warning'
            if (!obj.name) {
              this.$baseMessage('请选择预警人员', 'error')
              return false
            }
            const { msg } = await setWarnParam(obj)
            this.$baseMessage(msg, 'success')
            this.$emit('fetch-data')
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style lang="scss">
  .w220 {
    width: 220px;
  }
  .box {
    display: flex;
    flex-direction: row;
    height: 400px;
    > div {
      &.left {
        width: 260px;
      }
      &.right {
        flex: 1;
      }
      margin: 0 10px;
      border-radius: 8px;
      border: 1px solid #eee;
      padding: 15px;
    }
  }
</style>
