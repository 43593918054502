<template>
  <div
    v-loading="loading"
    class="paper"
    element-loading-background="transparent"
    element-loading-spinner="el-icon-loading"
    element-loading-text="拼命加载中"
  >
    <div v-if="dataList" class="exram">
      <div class="paper-name">{{ dataList.name }}</div>
      <div class="guide">{{ dataList.guide }}</div>
      <div class="exam-process">
        <div class="u-flex u-flex-items-center u-flex-between">
          <div>
            <span class="exam-num">
              问题{{
                current >= dataList.maxQues ? dataList.maxQues : current + 1
              }}
            </span>
            /{{ dataList.maxQues }}
          </div>
          <div>选择你的答案进入下一题</div>
        </div>
        <div class="exam-bar">
          <div
            class="exam-bar-percent"
            :style="'width:' + (current / dataList.maxQues) * 100 + '%'"
          />
        </div>
      </div>
      <div class="exram-question">
        <div v-for="(list, li) in dataList.quesList" :key="li" class="list">
          <template v-if="current === li">
            <div class="title">{{ list.quesName }}</div>
            <div v-if="list.selectType === 'fillblank'" class="ipt">
              <el-input
                placeholder="请输入..."
                type="digit"
                :v-model="answerArr[li] && answerArr[li].qAnwser"
                :value="answerArr[li] && answerArr[li].qAnwser"
                @focus="bindFocus($event, li)"
              />
            </div>
            <template v-else-if="list.selectType === 'image'">
              <div style="text-align: center">
                <el-image
                  mode="widthFix"
                  :src="list.quesUrl"
                  style="width: 300px"
                />
              </div>
              <div class="imgQues">
                <div
                  v-for="(item, index) in list.quesOptionList"
                  :key="index"
                  class="quesOption"
                  :class="[
                    item.fldOptionIndex ===
                    (answerArr[li] && answerArr[li].qAnwser)
                      ? 'selected'
                      : '',
                    list.quesOptionList.length > 6 ? 'w4' : 'w3',
                  ]"
                  @click="goToPage('next', list, item, li)"
                >
                  <span>{{ item.fldOptionText }}</span>
                </div>
              </div>
            </template>
            <div v-else-if="list.selectType === 'date'" class="ipt">
              <el-date-picker
                v-model="answerArr[li].qAnwser"
                placeholder="请选择日期"
                type="date"
                value-format="yyyy-MM-dd"
                @focus="bindFocus($event, li)"
                @input="bindDate"
              />
            </div>
            <div
              v-else
              class="ques"
              :class="
                list.quesOptionList && list.quesOptionList.length > 5
                  ? 'u-flex u-flex-between two'
                  : ''
              "
            >
              <div
                v-for="(item, q) in list.quesOptionList"
                :key="q"
                class="item"
                :class="
                  item.fldOptionIndex ===
                  (answerArr[li] && answerArr[li].qAnwser)
                    ? 'selected'
                    : ''
                "
                @click="goToPage('next', list, item, li)"
              >
                {{ item.fldOptionText }}
              </div>
            </div>
            <div class="btn">
              <el-button
                v-if="current !== 0"
                size="large"
                style="width: 150px"
                type="primary"
                @click="goToPage('prev')"
              >
                上一题
              </el-button>
              <template
                v-if="
                  list.selectType === 'fillblank' || list.selectType === 'date'
                "
              >
                <el-button
                  v-if="current <= dataList.maxQues"
                  size="large"
                  style="width: 150px"
                  type="primary"
                  @click="goToPage('next', list, null, li)"
                >
                  下一题
                </el-button>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getPaperList, saveAnswer } from '@/api/mmptCategory'

  export default {
    name: 'Paper',
    data() {
      return {
        loading: false,
        dataList: null,
        answerArr: [], //选项
        current: 0,
        isClick: true,
      }
    },
    created() {
      this.getList()
      this.getStorege()
    },
    methods: {
      bindDate(val) {
        this.$forceUpdate()
      },
      bindFocus($event, li) {
        let d = this.dataList.quesList
        this.answerArr[li] = {
          qAnwser: '',
          quesID: d.quesID,
        }
      },
      getStorege() {
        let _this = this
        let data = JSON.parse(
          localStorage.getItem(`answerList_${_this.$route.query.id1}`)
        )
        if (data) {
          let time = localStorage.getItem(`time_${_this.$route.query.id1}`)
          let expireTime = Date.parse(time) + 3600 * 24 * 1000
          let timestamp = Date.parse(new Date()) //当前时间
          if (timestamp > expireTime) {
            _this.removeStoreges()
          } else {
            _this.answerArr = data.list
            _this.current = data.current
          }
        }
      },
      goToPage(type, list, item, li) {
        if (type === 'prev') {
          this.current -= 1
        } else {
          this.radioChange(list, item, li)
        }
      },
      radioChange(list, item, li) {
        let _this = this
        let isHave = false //判断该题是否已做
        let maxQues = _this.dataList.maxQues

        for (let i = 0; i < _this.answerArr.length; i++) {
          if (i == li) {
            isHave = true
          }
        }

        if (list.selectType === 'judge' && item.fldOptionIndex === 2) {
          let p = this.$route.query
          saveAnswer({
            answer: 'over',
            id: p.id1,
            paper_id: p.id2,
            eval_id: p.id3,
          }).then((res) => {
            let data = res.data
            if (data.code === 200) {
              _this.$baseAlert('您已完成该套测评，感谢您的参与', '', '', () => {
                console.log(111)
              })
            }
          })
        } else {
          if (list.selectType === 'fillblank' || list.selectType === 'date') {
            if (isHave) {
              if (!_this.answerArr[li].qAnwser) {
                _this.$baseMessage('请输入您的答案', 'warning')
                return
              }
            } else {
              _this.$baseMessage('请输入您的答案', 'warning')
              return
            }
          } else {
            if (isHave) {
              _this.answerArr[li].qAnwser = item.fldOptionIndex
            } else {
              let newAnswer = {
                quesID: list.quesID,
                qAnwser: item.fldOptionIndex,
              }
              _this.answerArr.push(newAnswer)
            }
          }

          let startTime = localStorage.getItem(`time_${_this.$route.query.id1}`)
          if (!startTime) {
            localStorage.setItem(`time_${_this.$route.query.id1}`, new Date())
          }

          let da = {
            list: _this.answerArr,
            current: _this.current,
          }
          localStorage.setItem(
            `answerList_${_this.$route.query.id1}`,
            JSON.stringify(da)
          )
          if (_this.current === maxQues - 1) {
            let answerList = {}

            if (_this.answerArr.length < maxQues) {
              _this.$baseMessage('您还有题目尚未作答！', 'warning')
              return false
            }
            for (let i = 0; i < _this.answerArr.length; i++) {
              let obj = i + 1 + ''
              answerList[obj] = _this.answerArr[i].qAnwser
            }
            _this.submitTest(answerList)
          } else {
            _this.current += 1
          }
        }
      },
      removeStoreges() {
        let _this = this
        localStorage.removeItem(`answerList_${_this.$route.query.id1}`)
        localStorage.removeItem(`time_${_this.$route.query.id1}`)
      },
      submitTest(answer) {
        let _this = this
        let t = localStorage.getItem(`time_${_this.$route.query.id1}`)

        let p = _this.$route.query
        saveAnswer({
          answer: answer,
          id: p.id1,
          paper_id: p.id2,
          eval_id: p.id3,
          time: Date.parse(new Date(t)) / 1000,
        }).then((res) => {
          if (res.code === 200) {
            _this.removeStoreges()
            let routeData = this.$router.resolve({
              path: './mmptRecord',
              query: { id: p.id1 },
            })
            window.open(routeData.href, '_self')
          }
        })
      },
      getList() {
        let p = this.$route.query
        this.loading = true
        getPaperList({
          id: p.id1,
          paper_id: p.id2,
          eval_id: p.id3,
        }).then((res) => {
          this.dataList = res.data
          this.dataList.quesList.map((item, i) => {
            if (item.selectType === 'date' || item.selectType === 'fillblank') {
              if (!this.answerArr[i]) {
                this.answerArr[i] = {
                  qAnwser: '',
                  quesID: item.quesID,
                }
              }
            }
          })
          this.loading = false
        })
      },
    },
  }
</script>

<style lang="scss">
  .el-icon-loading {
    font-size: 30px !important;
    margin-bottom: 20px;
  }
  .paper {
    max-width: 750px;
    min-height: calc(100% - 30px);
    background: #fff;
    margin: 15px auto;
    border-radius: 10px;
    box-shadow: 0 0 19px 19px rgba(0, 0, 0, 0.02);
    position: relative;
    user-select: none;
    .u-flex {
      display: flex;
    }
    .u-flex-items-center {
      align-items: center;
    }
    .u-flex-between {
      justify-content: space-between;
    }

    &:before {
      content: '';
      position: absolute;
      top: 20px;
      left: 20px;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      box-shadow: 0 0 10px 4px rgba(38, 205, 183, 0.19);
      background: linear-gradient(
        180deg,
        rgba(38, 205, 183, 0.75),
        rgba(38, 205, 183, 0.13) 100%
      );
    }

    &:after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      left: 0;
      height: 100%;
      border-radius: 10px;
      background: linear-gradient(
        180deg,
        rgba(41, 210, 187, 0.1) 0.001%,
        rgba(209, 234, 252, 0.31) 60%,
        #fff 86%
      );
    }

    .exram {
      position: relative;
      z-index: 100;
      padding: 20px 0 50px;
      color: #acb7c2;
      font-size: 14px;
      .paper-name {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        color: #555;
        padding: 20px 0;
      }
      .guide {
        padding: 0 50px 40px;
        line-height: 1.5;
      }

      .exam-process {
        margin: 0 7%;
      }

      .exam-num {
        color: #26cdb7;
        font-size: 18px;
        font-weight: bold;
      }

      .exam-bar {
        position: relative;
        background: #ccdbd9;
        height: 12px;
        border-radius: 10px;
        margin-top: 20px;
        margin-bottom: 40px;

        .exam-bar-percent {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          background: linear-gradient(
            90deg,
            #26cdb7 3%,
            rgba(38, 205, 183, 0.4) 100%
          );
          border-radius: 10px;
        }
      }

      .exram-question {
        .list {
          padding: 0 7%;
          .ipt {
            padding: 0 30px;
          }
        }

        .title {
          color: #666;
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 25px;
        }

        .ques {
          &.two {
            flex-wrap: wrap;

            .item {
              width: 46%;
            }
          }

          .item {
            border-radius: 6px;
            background: rgba(172, 183, 194, 0.2);
            padding: 20px;
            margin-top: 30px;
            font-size: 18px;
            border: 3px solid transparent;
            color: #949da5;

            &.selected,
            &:active {
              border-color: #26cdb7;
              box-shadow: 0 0 13px 4px rgba(0, 161, 67, 0.06);
            }
          }
        }

        .imgQues {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-top: 30px;
          justify-content: space-between;

          .quesOption {
            padding: 0 20px;
            box-sizing: border-box;
            line-height: 50px;
            border-radius: 5px;
            background: #fff;
            box-shadow: 0 0 10px rgba(238, 238, 238, 0.8);
            border: 3px solid transparent;
            margin: 10px;
            text-align: center;

            &.selected,
            &:active {
              border-color: #26cdb7;
              box-shadow: 0 0 13px 4px rgba(0, 161, 67, 0.06);
            }

            &.w3 {
              flex: 1;
              /*width: 30%;*/
            }

            &.w4 {
              width: 18%;
            }
          }
        }
      }
    }
    .btn {
      text-align: center;
      margin-top: 50px;
    }
  }
</style>
