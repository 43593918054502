var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "450px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c("div", { staticClass: "join-team" }, [
        _c(
          "div",
          { staticClass: "img" },
          [_c("el-image", { attrs: { src: _vm.params.code } })],
          1
        ),
        _c(
          "div",
          { staticClass: "btn" },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.saveImg } },
              [_vm._v("下载二维码")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.copy(_vm.params.code_url)
                  },
                },
              },
              [_vm._v(" 复制链接 ")]
            ),
          ],
          1
        ),
      ]),
      _vm.params.m_type * 1 === 2 || _vm.params.m_type * 1 === 3
        ? _c(
            "div",
            { staticClass: "join-team" },
            [
              _vm._v(" 团测码： "),
              _c("el-input", {
                staticStyle: { width: "120px", "margin-right": "10px" },
                attrs: { disabled: "", value: _vm.params.clique_code },
              }),
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.copy(_vm.params.clique_code)
                    },
                  },
                },
                [_vm._v(" 点击复制 ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "tips" }, [
        _vm._v("参与团测：1.手机扫码参与团测；2.复制链接进入团测"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }