var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "群发通知",
        visible: _vm.dialogFormVisible,
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "demo-form",
          attrs: {
            "label-position": _vm.labelPosition,
            "label-width": "90px",
            model: _vm.form,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: "接收用户", prop: "name" },
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _vm._v(" 接收用户 "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                content: "接收用户为输入框中绑定学生的家长",
                                effect: "dark",
                                placement: "top-start",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-warning",
                                staticStyle: { color: "#bbb" },
                              }),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: "",
                      rows: "4",
                      type: "textarea",
                      value: _vm.form.name,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "选择模板", prop: "template_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择消息模板",
                      },
                      model: {
                        value: _vm.form.template_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "template_id", $$v)
                        },
                        expression: "form.template_id",
                      },
                    },
                    _vm._l(_vm.tempList, function (item) {
                      return _c("el-option", {
                        key: item.template_id,
                        attrs: { label: item.title, value: item.template_id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "跳转链接",
                    prop: "url",
                    rules: {
                      type: "url",
                      message: "请输入正确的跳转链接",
                      trigger: ["blur", "change"],
                    },
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入跳转链接" },
                    model: {
                      value: _vm.form.url,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "url", $$v)
                      },
                      expression: "form.url",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }