<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
  export default {
    name: 'App',
    provide() {
      return {
        reload: this.reload,
      }
    },
    data() {
      return {
        isRouterAlive: true,
      }
    },
    methods: {
      reload() {
        this.isRouterAlive = false
        this.$nextTick(function () {
          this.isRouterAlive = true
        })
      },
    },
  }
</script>
<style lang="scss">
  .add-title {
    text-align: center;
    line-height: 50px;
    background: #e8f4ff;
    margin: -20px -20px 20px;
    font-weight: bold;
    font-size: 16px;
  }
  .txt-tips {
    font-size: 12px;
    color: #bbb;
    line-height: initial;
  }
  .img-uploader {
    display: flex;
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 3px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .el-upload__tip {
      flex: 1;
      margin-left: 10px;
      color: #999;
      line-height: normal;
      span {
        color: #e64e1f;
      }
    }
    .el-upload:hover {
      border-color: #92e6db;
    }
    .img,
    .el-icon-plus {
      width: 100px;
      height: 100px;
      display: block;
      line-height: 100px;
    }
  }
  .user {
    display: flex;
    align-items: center;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    span {
      margin-left: 6px;
    }
  }
</style>
