var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "demo-form",
              attrs: { "label-width": "130px", model: _vm.form },
            },
            [
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "组织结构" } },
                    [
                      _c("el-tree", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        ref: "ozTree",
                        attrs: {
                          data: _vm.dataTree,
                          "default-checked-keys": _vm.form.treeOz,
                          "element-loading-text": "拼命加载中",
                          "node-key": "id",
                          props: _vm.defaultProps,
                          "show-checkbox": "",
                        },
                        on: { check: _vm.treeChecked },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ node }) {
                              return _c("span", {}, [
                                _c("span", [_vm._v(_vm._s(node.label))]),
                                _c("span", { staticClass: "orange" }, [
                                  _vm._v(_vm._s(node.data.pep || 0) + " 人"),
                                ]),
                              ])
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("form")
                        },
                      },
                    },
                    [_vm._v(" 同步数据 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }