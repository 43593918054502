import request from '@/utils/request'

export function getStuIndex() {
  return request({
    timeout: 180000,
    url: '/home/first-page',
    method: 'get',
  })
}

export function getNoticeNum() {
  return request({
    url: '/home/notice-num',
    method: 'get',
  })
}

export function getWorkStage() {
  return request({
    url: '/home/work-stage',
    method: 'get',
  })
}

//*****************************自定义导航栏设置*****************************//
//获取h5首页设置
export function getIndexInfo(data) {
  return request({
    url: '/firstpage/index',
    method: 'post',
    data,
  })
}

//保存h5首页设置
export function saveIndexInfo(data) {
  return request({
    url: '/firstpage/save',
    method: 'post',
    data,
  })
}

//获取所选菜单里面列表
export function getH5SubMenu(data) {
  return request({
    url: '/firstpage/get-son',
    method: 'post',
    data,
  })
}
//获取首页icon
export function getIdxIcon(params) {
  return request({
    url: '/firstpage/get-icon-wap',
    method: 'get',
    params,
  })
}
//获取首页icon排版
export function eidtIconNum(params) {
  return request({
    url: '/firstpage/change-sort-wap',
    method: 'get',
    params,
  })
}
//获取个人中心菜单栏
export function getMyMenu(params) {
  return request({
    url: '/firstpage/get-navi-wap',
    method: 'get',
    params,
  })
}
//编辑首页icon
export function editIdxIcon(data) {
  return request({
    url: '/firstpage/change-icon-wap',
    method: 'post',
    data,
  })
}
//编辑个人中心菜单
export function editPerMenu(data) {
  return request({
    url: '/firstpage/change-navi-wap',
    method: 'post',
    data,
  })
}
//获取pc菜单栏
export function getPCMenu(params) {
  return request({
    url: '/firstpage/get-navi-pc',
    method: 'get',
    params,
  })
}
//编辑pc菜单
export function editPCNav(data) {
  return request({
    url: '/firstpage/change-navi-pc',
    method: 'post',
    data,
  })
}
//获取pc底部图片
export function getPCBottomBg(params) {
  return request({
    url: '/firstpage/get-icon-pc',
    method: 'get',
    params,
  })
}
//编辑pc底部图片
export function editPCBottomBg(data) {
  return request({
    url: '/firstpage/change-icon-pc',
    method: 'post',
    data,
  })
}
