<template>
  <el-dialog
    :close-on-click-modal="false"
    title="编辑"
    :visible.sync="dialogFormVisible"
    width="700px"
    @close="close"
  >
    <el-form
      ref="form"
      class="demo-form"
      :disabled="status === 3"
      :label-position="labelPosition"
      label-width="130px"
      :model="form"
      :rules="rules"
    >
      <div class="form-row">
        <el-form-item label="姓名" prop="name">
          <el-input class="w220" disabled :value="form.name" />
        </el-form-item>
        <el-form-item :label="lblPname" prop="pname">
          <el-input
            v-model="form.pname"
            class="w220"
            :placeholder="'请输入' + lblPname"
          />
        </el-form-item>
        <el-form-item :label="lblType + '内容'" prop="mental_remark">
          <el-input
            v-model="form.mental_remark"
            :placeholder="'请输入' + lblType + '内容'"
            rows="5"
            type="textarea"
          />
        </el-form-item>
        <el-form-item label="预警级别" prop="mental_warn_level">
          <el-radio-group v-model="form.mental_warn_level" disabled>
            <el-radio
              v-for="item in warningList"
              :key="item.id"
              :label="item.sort"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="访谈级别" prop="mental_assess_level">
          <el-radio-group v-model="form.mental_assess_level">
            <el-radio
              v-for="item in assessList"
              :key="item.id"
              :label="item.sort"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="false" label="干预级别" prop="mental_interve_level">
          <el-radio-group v-model="form.mental_interve_level">
            <el-radio
              v-for="item in interveList"
              :key="item.id"
              :label="item.sort"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="false" label="是否结案" prop="settle_type">
          <el-switch v-model="switchs" :disabled="disabledSwitch" />
          <div v-if="switchs">
            <el-radio-group
              v-model="form.settle_type"
              :disabled="disabledSwitch"
            >
              <el-radio
                v-for="item in settleList"
                :key="item.id"
                :label="item.id"
              >
                {{ item.name }}
              </el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="上传文件" prop="mental_file">
          <div v-if="hasFile">
            <span style="margin-right: 15px">
              {{ form.mental_file_name }}
            </span>
            <el-button type="text" @click="downloadFile">下载</el-button>
            <el-button style="color: red" type="text" @click="deleteFile">
              删除
            </el-button>
          </div>
          <el-upload
            v-else
            ref="upload"
            accept=".xls,.xlsx,.doc,.docx,.ppt,.zip"
            :action="UploadUrl()"
            :before-upload="beforeUploadFile"
            :file-list="file"
            :http-request="updFile"
            :limit="1"
            :on-change="fileChange"
            :on-error="handleError"
            :on-exceed="exceedFile"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
          >
            <el-button
              v-show="!hasFile"
              slot="trigger"
              size="small"
              type="primary"
            >
              选取文件
            </el-button>
            <el-button v-show="hasFile" disabled size="small" type="primary">
              选取文件
            </el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传
              <span>excel,word,ppt,zip</span>
              文件，且
              <span>不超过2M</span>
            </div>
          </el-upload>
          <div
            v-if="errTxt"
            style="color: red; line-height: initial; margin-top: 10px"
          >
            {{ errTxt }}
          </div>
        </el-form-item>
        <el-form-item :label="lblType + '时间'" prop="mental_time">
          <el-date-picker
            v-model="form.mental_time"
            :picker-options="pickerOptions"
            placeholder="请选择日期"
            type="date"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
      </div>
    </el-form>
    <div v-if="status !== 3" slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submitForm('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { getWarnParam, setWarnParam, uploadWarn } from '@/api/crisis'
  import { mapActions } from 'vuex'

  export default {
    name: 'TeamWarnListEdit',
    inject: ['reload'],
    data() {
      return {
        id: '',
        labelPosition: 'right',
        switchs: 0,
        disabledSwitch: false,
        lblPname: '',
        lblType: '',
        form: {
          pname: '',
          mental_remark: '',
          mental_warn_level: '',
          mental_interve_level: '',
          mental_assess_level: '',
          mental_time: '',
          mental_file_name: '',
          /*settle_type: 0,*/
          name: '',
          uid: '',
        },
        rules: {
          pname: [{ required: true, message: '请输入...', trigger: 'blur' }],
          mental_remark: [
            { required: true, message: '请输入内容', trigger: 'blur' },
          ],
          mental_time: [
            {
              required: true,
              message: '请选择时间',
              trigger: 'change',
            },
          ],
        },
        warningList: [], //预警等级列表
        interveList: [], //干预等级列表
        //settleList: [], //结案状态
        assessList: [], //访谈级别
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now() + 24 * 60 * 60 * 1000
          },
        },
        file: [],
        hasFile: false,
        canSave: true,
        errTxt: '',
        dialogFormVisible: false,
        status: '',
      }
    },
    created() {},
    methods: {
      ...mapActions({
        changeMenuMeta: 'routes/changeMenuMeta',
        changeTabsMeta: 'tabs/changeTabsMeta',
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      showEdit(row, type) {
        this.status = row.end_status * 1
        this.getPath(row.id, type)
        this.dialogFormVisible = true
        this.levelType('warning')
        this.levelType('interve')
        //this.levelType('settle')
        this.levelType('assess')
      },
      close() {
        this.errTxt = ''
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      getPath(id, type) {
        this.$nextTick(async () => {
          this.lblPname = '评估人'
          this.lblType = '评估'
          let p = {
            type: 'get',
            style: 'evaluate',
          }
          if (type === 'edit') {
            p.id = id
          } else {
            p.wid = id
          }
          await setWarnParam(p).then((res) => {
            this.form = res.data
            this.form.style = 'evaluate'
            this.form.type = type
            if (type === 'edit') this.form.id = id
          })
        })
      },
      levelType(type) {
        getWarnParam({ type: type }).then((res) => {
          this[type + 'List'] = res.data
        })
      },
      submitForm(formName) {
        //提交
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            let obj = this.form
            const { msg, code } = await setWarnParam(obj)
            if (code === 200) {
              this.$baseMessage(msg, 'success')
              this.$emit('fetch-data')
              this.close()
            } else {
              this.$baseMessage(msg, 'error')
            }
          } else {
            // eslint-disable-next-line no-console
            console.log('error submit!!')
            return false
          }
        })
      },
      // 文件超出个数限制时的钩子
      exceedFile(files, fileList) {
        this.$baseMessage(
          `只能选择 1 个文件，当前共选择了 ${
            files.length + fileList.length
          } 个`,
          'warning'
        )
      },
      // 文件状态改变时的钩子
      fileChange(file, fileList) {
        let existFile = fileList
          .slice(0, fileList.length - 1)
          .find((f) => f.name === file.name)
        if (existFile) {
          this.$baseMessage('当前文件已经存在!', 'error')
          fileList.pop()
          return false
        }
        if (fileList.length === 1) {
          this.hasFile = true
        }
        this.file = fileList
      },
      // 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
      beforeUploadFile(file) {
        let extension = file.name.substring(file.name.lastIndexOf('.') + 1)
        let size = file.size / 1024 / 1024
        if (
          extension !== 'xls' &&
          extension !== 'xlsx' &&
          extension !== 'doc' &&
          extension !== 'docx' &&
          extension !== 'ppt' &&
          extension !== 'zip'
        ) {
          this.$baseMessage(
            '只能上传后缀是.xls、.xlsx、.doc、.docx、.ppt、.zip的文件',
            'warning'
          )
        }
        if (size > 2) {
          this.$baseMessage('文件大小不得超过2M', 'warning')
          this.handleRemove(file, this.file)
          this.hasFile = false
          return false
        } else {
          this.canSave = false
        }
      },
      downloadFile() {
        window.location.href = this.form.mental_file
      },
      deleteFile() {
        this.$baseConfirm('你确定要删除该文件吗', null, async () => {
          let formData = new FormData() //  用FormData存放上传文件
          formData.append('type', 'delete')
          formData.append('style', this.form.style)
          formData.append('id', this.id)
          formData.append('file', this.form.mental_file_name)
          uploadWarn(formData).then((res) => {
            this.form.mental_file_name = ''
            this.form.mental_file = ''
            this.handleRemove(this.file[0], this.file)
            this.hasFile = false
          })
        })
      },
      updFile() {
        let dataPar = this.form
        let formData = new FormData() //  用FormData存放上传文件
        formData.append('type', 'add')
        formData.append('style', this.form.style)
        formData.append('id', 0)
        if (this.isSid === 'all') formData.append('sid', dataPar.sid)
        this.file.forEach((file) => {
          formData.append('file', file.raw, file.raw.name)
        })

        uploadWarn(formData)
          .then((res) => {
            if (res.code === 200) {
              let d = res.data
              this.$baseMessage(res.msg, 'success')
              this.errTxt = ''
              this.form.mental_file = d.file_url
              this.form.mental_file_name = d.file_name
            }
          })
          .catch((err) => {
            this.handleRemove(this.file[0], this.file)
            this.errTxt = err
            this.hasFile = false
            this.canSave = true
          })
      },
      handleRemove(file, fileList) {
        if (!fileList.length) {
          this.hasFile = false
        }
        this.file = []
        this.errTxt = ''
      },
      // 文件上传成功时的钩子
      handleSuccess(res, file, fileList) {
        this.$baseMessage('校验成功', 'success')
      },
      // 文件上传失败时的钩子
      handleError(err, file, fileList) {
        console.log(err)
        this.$baseMessage('文件校验失败', 'error')
      },
      UploadUrl: function () {
        // 因为action参数是必填项，我们使用二次确认进行文件上传时，直接填上传文件的url会因为没有参数导致api报404，所以这里将action设置为一个返回为空的方法就行，避免抛错
        return ''
      },
    },
  }
</script>
<style lang="scss" scoped>
  .form-row {
    .w220 {
      width: 220px;
    }
    .el-upload__tip {
      span {
        color: #f00;
      }
    }
  }
</style>
