var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info" },
    [
      _vm.psyList && _vm.psyList.length
        ? _c(
            "el-timeline",
            _vm._l(_vm.psyList, function (activity, index) {
              return _c(
                "el-timeline-item",
                {
                  key: index,
                  attrs: { color: "#1cc512", timestamp: activity.time },
                },
                [
                  _c("div", { staticClass: "block green" }, [
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(activity.quest)),
                    ]),
                    _c("div", { staticClass: "btn" }, [_vm._v("查看详情")]),
                  ]),
                ]
              )
            }),
            1
          )
        : _c("no-data", { attrs: { txt: "暂无记录" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }