<template>
  <div class="add-form-container">
    <div class="add-title">{{ title }}</div>
    <el-row :gutter="15">
      <el-col
        :lg="{ span: 16, offset: 4 }"
        :md="{ span: 20, offset: 2 }"
        :sm="{ span: 20, offset: 2 }"
        :xl="{ span: 14, offset: 5 }"
        :xs="24"
      >
        <el-form
          ref="form"
          class="demo-form"
          :label-position="labelPosition"
          label-width="110px"
          :model="form"
          :rules="rules"
        >
          <div v-if="active === 1">
            <div class="form-row">
              <div class="block-title">基本信息</div>
              <el-form-item label="计划标题" prop="title">
                <el-input v-model="form.title" />
              </el-form-item>
              <el-form-item label="时间区间" prop="times">
                <el-date-picker
                  v-model="form.times"
                  align="center"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                  range-separator="-"
                  start-placeholder="开始日期"
                  type="daterange"
                  unlink-panels
                  value-format="yyyy-MM-dd"
                />
              </el-form-item>
              <el-form-item label="计划简述" prop="descr">
                <el-input v-model="form.descr" type="textarea" />
              </el-form-item>
              <el-form-item label="封面" prop="cover">
                <div class="img-uploader">
                  <div class="el-upload el-upload__text" @click="selImg">
                    <img v-if="hasImg" class="img" :src="hasImg" />
                    <i v-else class="el-icon-plus"></i>
                  </div>
                  <div slot="tip" class="el-upload__tip">
                    <div>
                      只能上传
                      <span>.jpg、.png</span>
                      文件，且
                      <span>不超过1M</span>
                    </div>
                    <div>(图片尺寸1:1)</div>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="补充信息" prop="base_check">
                <template slot="label">
                  <el-popover
                    content="勾选则表示用户加入计划前需要填写对应的信息（原始数据会记录相关数据），配置更多的用户个人信息字段请前往【团体测评 > 扩展字段】中设置"
                    placement="top-start"
                    title="补充信息说明"
                    trigger="hover"
                    width="300"
                  >
                    <span slot="reference" class="el-icon-question"></span>
                  </el-popover>
                  补充信息
                </template>
                <el-checkbox-group v-model="form.base_check">
                  <el-checkbox
                    v-for="(item, index) in form.base"
                    :key="index"
                    :label="item.id"
                    name="baseinfo"
                  >
                    {{ item.value }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <!--<el-form-item label="统计维度" prop="rate_check">
              <el-checkbox-group v-model="form.rate_check" :max="10">
                <el-checkbox
                  v-for="(item, index) in form.rate"
                  :key="index"
                  :label="item.id"
                  name="rate"
                >
                  {{ item.value }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>-->
              <el-form-item label="预警方案" prop="plan">
                <el-select v-model="form.plan" placeholder="预警方案">
                  <el-option
                    v-for="item in planList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
                <el-button
                  icon="el-icon-refresh"
                  style="font-size: 15px; padding-left: 10px"
                  type="text"
                  @click="levelType"
                />
                <el-button type="text" @click="addWarnPlan">新增</el-button>
                <el-link
                  :href="doc"
                  style="font-size: 12px; line-height: 1; padding-bottom: 3px"
                  target="_blank"
                  type="danger"
                >
                  【重要提示】预警方案说明
                </el-link>
              </el-form-item>
            </div>
            <div class="form-row switch">
              <div class="block-title">用户登录配置</div>
              <el-form-item label="开启免导入" prop="is_mian">
                <template slot="label">
                  <el-popover
                    content="选择开启免导入用户信息功能则不需要提前录入用户信息，开启功能后默认使用团测码（用户加入计划需要输入与计划绑定的唯一团测码），选择手机验证则用户加入计划需要验证手机号码（收取短信发送费用）真实性才能加入计划！提示：特殊场景使用，一般单位，学校建立个人心理档案的场景下，使用批量导入模式，实现人员信息的真实一致性，确保测评数据真实有效。"
                    placement="top-start"
                    title="免导入使用说明"
                    trigger="hover"
                    width="400"
                  >
                    <span slot="reference" class="el-icon-question"></span>
                  </el-popover>
                  开启免导入
                </template>
                <el-switch
                  v-model="form.is_mian"
                  active-text="是"
                  :active-value="1"
                  inactive-text="否"
                  :inactive-value="0"
                />
              </el-form-item>
              <template v-if="form.is_mian === 1">
                <el-form-item label="登录模式" prop="m_type">
                  <el-radio-group v-model="form.m_type">
                    <el-radio :label="2">团测码</el-radio>
                    <el-radio :label="1">手机号验证</el-radio>
                    <el-radio :label="3">账号验证</el-radio>
                  </el-radio-group>
                  <el-popover
                    content="该功能适用于存在多次普查任务，且同一组织下普查用户可能分不同时间，不同批次的普查场景下，避免重复分发给同一组织下用户，实现团测任务下指定人员参与调查！"
                    placement="top-start"
                    title="账号验证说明"
                    trigger="hover"
                    width="300"
                  >
                    <span slot="reference" class="el-icon-question"></span>
                  </el-popover>
                  <!--<el-switch
                    v-model="form.m_type"
                    :disabled="!!form.id"
                    :active-value="1"
                    active-text="手机号验证"
                    :inactive-value="2"
                    inactive-text="团测码"
                  />-->
                </el-form-item>
                <el-form-item v-if="form.m_type === 1" label="验证方式">
                  <el-radio-group v-model="form.phone_bind">
                    <el-radio :label="2">验证码</el-radio>
                    <el-radio :label="1">导入手机号</el-radio>
                  </el-radio-group>
                  <el-popover
                    content="该功能是选择采用手机号验证可配置的验证模式，参与的用户需要和导入系统内的手机号码一致才能加入团测（避免收集不相关的数据）！"
                    placement="top-start"
                    title="手机号导入说明"
                    trigger="hover"
                    width="300"
                  >
                    <span slot="reference" class="el-icon-question"></span>
                  </el-popover>
                  <!--<el-switch
                    v-model="form.phone_bind"
                    :active-value="1"
                    active-text="导入用户"
                    :inactive-value="2"
                    inactive-text="验证码"
                  />-->
                  <div
                    v-if="form.phone_bind === 1"
                    style="display: flex; margin-top: 10px"
                  >
                    <!--<el-button type="primary">一键导入</el-button>-->
                    <el-upload
                      ref="upload"
                      accept=".xls,.xlsx"
                      :action="UploadUrl()"
                      :before-upload="beforeUploadFile"
                      :http-request="updFile"
                      :limit="1"
                      :on-change="fileChange"
                      :on-success="handleSuccess"
                      :show-file-list="false"
                    >
                      <el-button type="primary">一键导入</el-button>
                    </el-upload>
                    <el-button
                      title="下载模板"
                      type="text"
                      @click="downLoadDoc"
                    >
                      下载模板
                    </el-button>
                  </div>
                </el-form-item>
              </template>
            </div>
            <div v-if="team_pay" class="form-row switch">
              <div class="block-title">支付配置</div>
              <el-form-item label="开启支付" prop="is_pay">
                <template slot="label">
                  <el-popover
                    content="开启支付即表示用户进入团测前需支付相关费用；该功能不可随意修改，请慎用！"
                    placement="top-start"
                    title="开启支付使用说明"
                    trigger="hover"
                    width="400"
                  >
                    <span slot="reference" class="el-icon-question"></span>
                  </el-popover>
                  开启支付
                </template>
                <el-switch
                  v-model="form.is_pay"
                  active-text="是"
                  :active-value="1"
                  inactive-text="否"
                  :inactive-value="0"
                />
              </el-form-item>
              <el-form-item v-if="form.is_pay === 1" label="支付金额">
                <el-input
                  v-model="form.cost"
                  oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                  placeholder="请输入支付金额"
                />
              </el-form-item>
            </div>
            <div class="form-row switch">
              <div class="block-title">量表选择</div>
              <el-tabs :active-name="form.evals_type" @tab-click="tabClick">
                <template v-if="!form.id">
                  <el-tab-pane label="自选量表组合" name="free">
                    <el-table
                      ref="multipleTable"
                      border
                      :data="form.evals"
                      max-height="500"
                      @selection-change="setSelectRows"
                    >
                      <el-table-column
                        align="center"
                        :selectable="checkboxInit"
                        type="selection"
                      />
                      <el-table-column
                        label="量表名"
                        min-width="200"
                        prop="name"
                      />
                      <el-table-column
                        align="center"
                        label="题量"
                        prop="num"
                        width="80"
                      />
                      <el-table-column
                        align="center"
                        label="开放报告"
                        prop="is_open"
                      >
                        <template #default="{ row }">
                          <el-switch
                            v-model="row.is_open"
                            active-text="开"
                            active-value="10"
                            inactive-text="关"
                            inactive-value="20"
                            @change="setRowEvals(row, 'is_open')"
                          />
                        </template>
                      </el-table-column>
                      <el-table-column
                        align="center"
                        label="重复测试"
                        prop="is_repeat"
                      >
                        <template #default="{ row }">
                          <el-switch
                            v-model="row.is_repeat"
                            active-text="开"
                            active-value="10"
                            inactive-text="关"
                            inactive-value="20"
                          />
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-tab-pane>
                  <el-tab-pane label="系统推荐包" name="system">
                    <div slot="label" class="tab-free">
                      系统推荐包
                      <span>推荐使用</span>
                    </div>
                    <my-swiper :list="pack" @click="swiperClick" />
                  </el-tab-pane>
                </template>
                <template v-else>
                  <el-tab-pane
                    v-if="form.evals_type === 'free'"
                    label="自选量表组合"
                    name="free"
                  >
                    <el-table
                      ref="multipleTable"
                      border
                      :data="form.evals"
                      max-height="500"
                      @selection-change="setSelectRows"
                    >
                      <el-table-column
                        align="center"
                        :selectable="checkboxInit"
                        type="selection"
                      />
                      <el-table-column
                        label="量表名"
                        min-width="200"
                        prop="name"
                      />
                      <el-table-column
                        align="center"
                        label="题量"
                        prop="num"
                        width="80"
                      />
                      <el-table-column
                        align="center"
                        label="开放报告"
                        prop="is_open"
                      >
                        <template #default="{ row }">
                          <el-switch
                            v-model="row.is_open"
                            active-text="开"
                            active-value="10"
                            inactive-text="关"
                            inactive-value="20"
                            @change="setRowEvals(row, 'is_open')"
                          />
                        </template>
                      </el-table-column>
                      <el-table-column
                        align="center"
                        label="重复测试"
                        prop="is_repeat"
                      >
                        <template #default="{ row }">
                          <el-switch
                            v-model="row.is_repeat"
                            active-text="开"
                            active-value="10"
                            inactive-text="关"
                            inactive-value="20"
                          />
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-tab-pane>
                  <el-tab-pane
                    v-if="form.evals_type === 'system'"
                    label="系统推荐包"
                    name="system"
                  >
                    <div slot="label" class="tab-free">
                      系统推荐包
                      <span>推荐使用</span>
                    </div>
                    <my-swiper :list="pack" @click="swiperClick" />
                  </el-tab-pane>
                </template>
              </el-tabs>
            </div>
            <div v-if="surveyList" class="form-row">
              <div class="block-title">问卷选择</div>
              <el-table
                ref="multipleSurveyTable"
                border
                :data="surveyList"
                @selection-change="setSurveySelectRows"
              >
                <el-table-column
                  align="center"
                  :selectable="checkboxInit"
                  type="selection"
                />
                <el-table-column label="问卷名" prop="title" />
                <el-table-column
                  align="center"
                  label="状态"
                  prop="status"
                  width="80"
                >
                  <template #default="{ row }">
                    {{ row.status === '10' ? '正常' : '暂停' }}
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="题量"
                  prop="num"
                  width="80"
                />
              </el-table>
            </div>
            <div class="form-row">
              <div class="block-title">组织结构</div>
              <el-select
                v-model="form.origin_id"
                filterable
                placeholder="请选择组织结构"
                @change="selectChange"
                @visible-change="cascaderClicked"
              >
                <el-option
                  v-for="item in form.origin"
                  :key="item.id"
                  :label="item.remark"
                  :value="item.id"
                />
              </el-select>
              <div v-loading="loading" style="min-height: 100px">
                <el-tree
                  v-if="!loading"
                  ref="ozTree"
                  :data="dataTree"
                  :default-checked-keys="form.treeOz"
                  node-key="id"
                  :props="defaultProps"
                  show-checkbox
                  @check="treeChecked"
                >
                  <span slot-scope="{ node }">
                    <span>{{ node.label }}</span>
                    <span class="orange">{{ node.data.pep || 0 }} 人</span>
                  </span>
                </el-tree>
              </div>
            </div>
            <el-form-item>
              <el-button v-if="isNJ" type="primary" @click="submitForm('form')">
                {{ form.id ? '保存修改' : '立即创建' }}
              </el-button>
              <el-button v-else type="primary" @click="nextStep">
                下一步
              </el-button>
              <el-button v-if="!form.id" @click="resetForm('form')">
                重置
              </el-button>
            </el-form-item>
          </div>
          <div v-if="active === 2">
            <div class="form-row" style="margin-bottom: 20px">
              <el-table border :data="sortList">
                <el-table-column align="center" label="类型" prop="type">
                  <template slot-scope="scope">
                    {{
                      scope.row.type === 'paper'
                        ? '量表'
                        : scope.row.type === 'pack'
                        ? '推荐包'
                        : '问卷'
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="名称" prop="name" />
                <el-table-column align="center">
                  <template slot="header">
                    排序
                    <el-tooltip
                      class="item"
                      content="排序请输入数字，越小越靠前，未设置则随机排序"
                      effect="dark"
                      placement="right-start"
                    >
                      <i class="el-icon-info" style="cursor: pointer"></i>
                    </el-tooltip>
                  </template>
                  <template slot-scope="scope">
                    <el-input-number
                      v-model="scope.row.sort"
                      :controls="false"
                      :min="0"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <el-form-item>
              <el-button @click="prevStep">上一步</el-button>
              <el-button type="primary" @click="submitForm('form')">
                {{ form.id ? '保存修改' : '立即创建' }}
              </el-button>
            </el-form-item>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <select-img ref="selectImg" @checkedImg="getImg" />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { handleActivePath } from '@/utils/routes'
  import { isNJ } from '@/utils/utils'
  import {
    getEvalOriginList,
    getDetail,
    setEvals,
    doEdit,
  } from '@/api/teamList'
  import { getWarnParam } from '@/api/crisis'
  import { getWarnPlan } from '@/api/settingAuthority'
  import MySwiper from '@/components/mySwiper'
  import {
    checkUploadPhone,
    doUploadPhone,
    getOrginDoc,
  } from '@/api/userManagementOrganization'
  import SelectImg from '@/components/gallery/index.vue'

  export default {
    name: 'AddTeamTest',
    components: { SelectImg, MySwiper },
    inject: ['reload'],
    data() {
      return {
        doc: 'https://docs.qq.com/doc/DTFhyUHNsUmpKT3BJ',
        loading: true,
        isNJ: isNJ(),
        active: 1,
        tabName: 'free',
        curPackage: null,
        sortList: [],
        selectPaperList: [],
        selectSurveyList: [],
        title: '',
        labelPosition: 'left',
        planList: [],
        surveyList: [],
        pack: [],
        team_pay: false,
        form: {
          open: [],
          plan: 1,
        },
        rules: {
          title: [
            { required: true, message: '请输入计划标题', trigger: 'blur' },
          ],
          times: [
            {
              required: true,
              message: '请选择时间段',
              trigger: 'blur',
            },
          ],
          descr: [
            { required: true, message: '请输入计划简述', trigger: 'blur' },
          ],
        },
        dataTree: [],
        defaultProps: {
          children: 'children',
          label: 'name',
        },
        pickerOptions: {
          // disabledDate(time) {
          //   return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
          // },
        },
        docUrl: '',
        files: [],
        hasImg: '',
      }
    },
    computed: {
      ...mapGetters({
        isSid: 'user/sid',
      }),
    },
    created() {
      this.getDoc()
      this.getPath()
      this.levelType()
      if (this.isSid == 115)
        this.doc =
          'https://pcnrnb6l4q91.feishu.cn/wiki/RedBwwOjTicJhrk2ORpc4MMxno7?from=from_copylink'
    },
    methods: {
      ...mapActions({
        changeTabsMeta: 'tabs/changeTabsMeta',
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      swiperClick(val) {
        this.curPackage = val
      },
      tabClick(tab, event) {
        this.form.evals_type = tab.name
      },
      // 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
      beforeUploadFile(file) {
        console.log(file)
        let extension = file.name.substring(file.name.lastIndexOf('.') + 1)
        let size = file.size / 1024 / 1024
        if (extension !== 'xls' && extension !== 'xlsx') {
          this.$baseMessage('只能上传后缀是.xls、.xlsx的文件', 'warning')
        }
        if (size > 2) {
          this.$baseMessage('文件大小不得超过2M', 'warning')
          return false
        }
      },
      updFile() {
        let formData = new FormData() //  用FormData存放上传文件
        this.files.forEach((file) => {
          formData.append('file', file.raw, file.raw.name)
        })

        checkUploadPhone(formData).then((res) => {
          console.log(res)
          if (res.code === 200) {
            this.$baseMessage('校验成功，正在导入...', 'success')
            doUploadPhone(formData).then((res2) => {
              if (res2.code === 200) {
                this.$baseMessage(res.msg, 'success')
              }
            })
          }
        })
        this.$nextTick(() => {
          this.$refs['upload'].clearFiles()
        })
      },
      handleSuccess(file, fileList) {
        console.log(file)
      },
      UploadUrl: function () {
        return ''
      },
      fileChange(file, fileList) {
        this.files = fileList
      },
      getDoc() {
        getOrginDoc({ type: 'phone' }).then((res) => {
          if (res.code === 200) {
            this.docUrl = res.data
          }
        })
      },
      downLoadDoc() {
        window.location.href = this.docUrl
      },
      prevStep() {
        this.active = 1
        this.$nextTick(() => {
          this.selectPaperList.forEach((i) => {
            this.form.evals_type === 'free' &&
              this.$refs.multipleTable.toggleRowSelection(i, true)
          })
          this.selectSurveyList.forEach((i) => {
            this.$refs.multipleSurveyTable.toggleRowSelection(i, true)
          })
        })
      },
      nextStep() {
        this.$refs['form'].validate(async (valid, error) => {
          if (valid) {
            this.sortList = []
            let sorts = {}
            this.form.sorts.forEach((i) => {
              sorts[i.id + '_' + i.type] = i.sort
            })
            //量表
            if (this.form.evals_type === 'free') {
              this.selectPaperList.forEach((i) => {
                this.sortList.push({
                  id: i.id,
                  name: i.name,
                  type: 'paper',
                  sort: sorts[i.id + '_paper'] || '',
                })
              })
            }
            //包
            if (this.form.evals_type === 'system') {
              let pack = this.curPackage
              this.sortList.push({
                id: pack.id,
                name: pack.name,
                type: 'pack',
                sort: sorts[pack.id + '_pack'] || '',
              })
            }
            //问卷
            this.selectSurveyList.forEach((i) => {
              this.sortList.push({
                id: i.id,
                name: i.title,
                type: 'survey',
                sort: sorts[i.id + '_survey'] || '',
              })
            })
            this.active = 2
          }
        })
      },
      levelType() {
        getWarnParam({ type: 'plan' }).then((res) => {
          this.planList = res.data
        })
      },
      addWarnPlan() {
        if (this.planList.length === 5) {
          this.$baseMessage('最多添加5个预警方案', 'error')
          return false
        } else {
          this.$router.push('/setting/addWarnigPlan')
        }
      },
      cascaderClicked($event) {
        if ($event) {
          const route = this.$route
          this.$nextTick(async () => {
            let rid = route.query.id | 0
            const { data } = await getDetail({ rid })
            this.form.origin = data.origin
          })
        }
      },
      getPath() {
        const route = this.$route
        this.$nextTick(() => {
          let rid = route.query.id
          this.title = rid ? '编辑计划' : '新增计划'
          if (rid) {
            this.getDetailById(rid)
          } else {
            this.getDetailById(0)
          }
          this.loading = false
        })
      },
      getOrigin(datas) {
        this.loading = true
        getEvalOriginList(datas)
          .then((res) => {
            this.dataTree = res.data
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
          })
      },
      async getDetailById(id) {
        const { data, team_pay } = await getDetail({ id })
        this.team_pay = team_pay
        this.form = data
        this.pack = data.pack
        this.surveyList = data.survey_list
        this.form.evals_type = !data.evals_type ? 'free' : data.evals_type
        this.hasImg = data.cover
        console.log(data.cover)
        let curpake = this.pack.filter((o) => o.is_check === true)
        if (id) {
          this.form.id = id
          this.curPackage = curpake[0]
          let p = {
            origin: data.origin_id,
            id: id,
          }
          this.getOrigin(p)

          this.$nextTick(() => {
            //默认选择量表
            //data.evals_check.map((item, index) => {
            data.evals.forEach((row) => {
              if (row.is_check) {
                this.form.evals_type === 'free' &&
                  this.$refs.multipleTable.toggleRowSelection(row, true)
              }
            })
            if (!this.isNJ) {
              this.surveyList.forEach((row) => {
                if (row.is_check) {
                  this.$refs.multipleSurveyTable.toggleRowSelection(row, true)
                }
              })
            }
            //})
          })
        }
      },
      submitForm(formName) {
        //提交
        this.$refs[formName].validate(async (valid, error) => {
          if (valid) {
            let selectEvals = this.isNJ
              ? this.$refs.multipleTable.selection
              : this.selectPaperList
            let evalList = []
            selectEvals.forEach((item) => {
              evalList.push({
                id: item.id,
                is_open: item.is_open,
                is_repeat: item.is_repeat,
              })
            })
            let obj = this.form
            let p = {
              title: obj.title,
              time: obj.times,
              descr: obj.descr,
              baseinfo: obj.base_check,
              rate: obj.rate_check,
              evals: obj.evals_check,
              treeOz: obj.treeOz || [],
              open: obj.open,
              eval_list: evalList,
              plan: obj.plan,
              evals_type: obj.evals_type,
              evals_pack: this.curPackage || null,
              is_mian: obj.is_mian || 0,
              m_type: obj.m_type || 2,
              phone_bind: obj.phone_bind || 2,
              is_pay: obj.is_pay || 0,
              cost: obj.cost || 0,
              cover: obj.cover,
            }
            if (!this.isNJ) {
              p.survey_id = obj.survey_id
              p.sorts = this.sortList
            }

            if (this.form.id) p.id = this.form.id
            if (p.is_pay === 1 && p.cost < 0) {
              this.$baseMessage('支付金额必须大于0', 'error')
              return false
            }
            if (p.evals_type === 'free' && p.evals.length === 0) {
              this.$baseMessage('请选择量表', 'error')
              return false
            }
            if (p.evals_type === 'system' && !p.evals_pack) {
              this.$baseMessage('请选择包', 'error')
              return false
            }
            if (p.treeOz.length === 0) {
              this.$baseMessage('请选择组织结构', 'error')
              return false
            }
            const { msg } = await doEdit(p)
            this.$baseMessage(msg, 'success')

            //关闭当前tab，返回list页并刷新
            await this.delVisitedRoute(handleActivePath(this.$route, true))
            this.reload()
            this.$router.push('/team/list/teamList')
          } else {
            // eslint-disable-next-line no-console
            for (let key in error) {
              this.$message.error(error[key][0].message)
              break
            }
            return false
          }
        })
      },
      selImg() {
        this.$refs['selectImg'].showEdit()
      },
      getImg(val) {
        this.hasImg = val.url
        this.form.cover = val.url
      },
      resetForm(formName) {
        this.$refs.multipleTable.clearSelection()
        this.$refs.ozTree.setCheckedKeys([])
        this.$refs[formName].resetFields()
      },
      checkboxInit(row) {
        //设置量表勾选状态，未上架则禁止勾选
        if (row.status === '10') {
          return 1 //可勾选
        } else {
          return 0
        }
      },
      setSelectRows(val) {
        this.selectPaperList = val
        //选择量表
        const ids = val.map((item) => item.pid)
        this.form.evals_check = ids
      },
      setSurveySelectRows(val) {
        this.selectSurveyList = val
        //选择问卷
        const ids = val.map((item) => item.id)
        this.form.survey_id = ids
      },
      selectChange(val) {
        //组织结构下拉选择
        this.getOrigin({ origin: val })
      },
      setRowEvals(row) {
        //设置是否量表开放报告
        if (row.is_open == '10') {
          this.form.open.push(row.pid)
        } else {
          let index = this.form.open.indexOf(row.pid)
          if (index > -1) {
            this.form.open.splice(index, 1)
          }
        }
        setEvals({
          id: row.pid,
          name: 'is_open',
          data: row.is_open,
          tmid: this.$route.query.id,
        }).then((res) => {
          console.log(res.msg, 'success')
        })
      },
      treeChecked() {
        this.form.treeOz = this.$refs.ozTree.getCheckedKeys(true)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .add-form-container {
    .el-icon-question {
      font-size: 16px;
      color: #aaa;
      margin-left: 5px;
    }
    .demo-form {
      margin-top: 10px;
    }
    ::v-deep {
      .el-form-item__content {
        .el-rate {
          display: inline-block;
          font-size: 0;
          line-height: 1;
          vertical-align: middle;
        }

        .el-transfer__buttons {
          padding: 10px 10px 0 10px;
        }
      }
      .el-tree {
        margin-top: 15px;
        .orange {
          color: #e6a155;
          margin-left: 5px;
          font-weight: bold;
        }
      }
    }
    .switch {
      ::v-deep {
        .el-switch__core {
          width: 50px;
        }
        .el-switch__label * {
          font-size: 12px;
        }
        .el-switch__label--left {
          position: relative;
          left: 44px;
          color: #fff;
          z-index: -1111;
        }
        .el-switch__label--right {
          position: relative;
          right: 44px;
          color: #fff;
          z-index: -1111;
        }
        .el-switch__label--right.is-active,
        .el-switch__label--left.is-active {
          z-index: 1111;
          color: #fff !important;
        }
      }
    }
    .add-title {
      text-align: center;
      line-height: 50px;
      background: #f0fcfa;
      margin: -20px -20px 20px;
      font-weight: bold;
      font-size: 16px;
    }
    .form-row {
      &:not(:first-child) {
        padding-top: 20px;
        margin-bottom: 20px;
      }
      .block-title {
        font-weight: bold;
        padding-left: 10px;
        border-left: 6px solid #26cdb7;
        margin-bottom: 20px;
      }
    }
    .tab-free {
      position: relative;
      span {
        position: absolute;
        color: red;
        font-size: 12px;
        margin-top: -5px;
        margin-left: 5px;
      }
    }
  }
</style>
