<template>
  <div v-if="initData" class="eval-container">
    <div ref="print" class="content">
      <div v-if="initData.tips" class="recreatetips no-print">
        <el-alert effect="dark" :title="initData.tips" type="success">
          <el-button class="btn" type="text" @click="showBox">
            生成报告
          </el-button>
        </el-alert>
      </div>
      <div class="nav-right no-print">
        <a href="javascript:void(0)" @click="jump(0)">封面</a>
        <a href="javascript:void(0)" @click="jump(1)">目录</a>
        <a
          v-for="(menu, mIndex) in mulu"
          :key="mIndex"
          href="javascript:void(0)"
          :title="menu.name"
          @click="jump(menu.page + 1)"
        >
          {{ menu.name }}
        </a>
        <a class="btn-a" href="javascript:void(0)" @click="print('word')">
          <i class="el-icon-printer"></i>
          导出word
        </a>
        <a class="btn-a" href="javascript:void(0)" @click="print('pdf')">
          <i class="el-icon-printer"></i>
          导出PDF
        </a>
        <a class="btn-a" href="javascript:void(0)" @click="showBox">
          <i class="el-icon-refresh"></i>
          重新生成
        </a>
      </div>
      <!--封面-->
      <el-card class="anchor first">
        <div style="font-size: 45px; font-weight: bold; padding: 200px 0 40px">
          {{ basic.title }}
        </div>
        <div class="title">{{ basic.subhead }}</div>
        <div class="bg">
          <div class="time">
            <div>普查时间：{{ basic.times }}</div>
            <div v-if="basic.update_times">
              报告生成时间：{{ basic.update_times }}
            </div>
          </div>
          <el-image v-if="false" :src="require('@/assets/report.svg')" />
        </div>
      </el-card>
      <!--目录-->
      <el-card class="anchor">
        <div class="card-header">
          <span>目录</span>
        </div>
        <div class="catalogue">
          <div v-for="(item, index) in mulu" :key="index" class="li">
            <div class="pra-item">
              <div class="left">
                {{ '0' + (index + 1) + '/' }}
                <span class="p-title">{{ item.name }}</span>
              </div>
              <div class="line"></div>
              <div class="right">{{ item.page }}</div>
            </div>
            <template v-if="item.children">
              <div v-for="(sub, j) in item.children" :key="j" class="sub-item">
                <div class="left">
                  {{ index + 1 + '.' + (j + 1) }}{{ sub.name }}
                </div>
                <div class="line"></div>
                <div class="right">{{ sub.page }}</div>
              </div>
            </template>
          </div>
        </div>
      </el-card>
      <!--前言-->
      <el-card class="anchor">
        <div class="card-page">
          <div class="l">{{ basic.subhead }}</div>
          <div class="c"></div>
          <div class="r">第{{ initData.first.page }}页</div>
        </div>
        <div class="card-header">
          <span>报告前言</span>
        </div>
        <div class="qy">
          <div class="qy-item">
            <div class="qy-title">一、测评意义</div>
            <div class="contxt" v-html="initData.first.purport">
              {{ initData.first.purport }}
            </div>
          </div>
          <div class="qy-item">
            <div class="qy-title">二、报告说明</div>
            <div class="contxt" v-html="initData.first.explain">
              {{ initData.first.explain }}
            </div>
          </div>
        </div>
      </el-card>
      <!--完成概况-->
      <el-card class="anchor">
        <div class="card-page">
          <div class="l">{{ basic.subhead }}</div>
          <div class="c"></div>
          <div class="r">第{{ initData.student.page }}页</div>
        </div>
        <div class="card-header">
          <span>完成概况</span>
        </div>
        <div class="card-box">
          <div class="team-info">
            <div class="team-info-top"><span>完成统计</span></div>
            <div class="team-info-div">
              {{ pepInfo.descr }}
              应测评人数为
              <span>{{ pepInfo.all }}</span>
              人，完成所有量表的人数为
              <span>{{ pepInfo.comp }}</span>
              人，完成率为
              <span>{{ pepInfo.comp_rate }}</span>
              ，参与团测人数为
              <span>{{ pepInfo.add }}</span>
              人，参与率为
              <span>{{ pepInfo.add_rate }}</span>
            </div>
            <div class="tips">
              注：参与团测包含只完成部分量表用户和完成所有量表用户
            </div>
          </div>
          <vab-chart
            ref="pepChart"
            class="no-print"
            :options="initData.pepChart"
            theme="vab-echarts-theme"
          />
          <img class="canvas-img" :src="gkImg.pepChart" />
        </div>
        <div class="card-box">
          <div class="team-info">
            <div class="team-info-top"><span>预警统计</span></div>
            <div class="team-info-div">
              团测报告共分析样本
              <span>{{ pepInfo.join }}</span>
              人，其中
              <span>{{ evalInfo.warn }}</span>
              触发预警，占比
              <span>{{ evalInfo.warn_join_rate }}</span>
              ；其中
              <span>{{ evalInfo.max }}</span>
              <span>{{ evalInfo.max_pep }}</span>
              预警，
              <span>{{ evalInfo.max_pep_descr }}</span>
              ， {{ evalInfo.max_pep_descr2 }}
              <span>{{ evalInfo.max_pep_rate }}</span>
              。
            </div>
          </div>
          <vab-chart
            ref="evalChart"
            class="no-print"
            :options="initData.evalChart"
            theme="vab-echarts-theme"
          />
          <img class="canvas-img" :src="gkImg.evalChart" />
        </div>
      </el-card>
      <!--建档数据-->
      <el-card v-for="(item, tIndex) in tables" :key="tIndex" class="anchor">
        <div class="card-page">
          <div class="l">{{ basic.subhead }}</div>
          <div class="c"></div>
          <div class="r">第{{ item.page }}页</div>
        </div>
        <div class="card-header">
          <span>建档数据</span>
        </div>
        <el-table border :data="item.list">
          <el-table-column min-width="300" prop="name" />
          <el-table-column align="center" label="男" prop="man" />
          <el-table-column align="center" label="女" prop="wom" />
          <el-table-column align="center" label="不详" prop="no" />
          <el-table-column align="center" label="合计（人）" prop="all" />
        </el-table>
      </el-card>
      <!--量表概况-->
      <template v-for="(table, index) in evalTable">
        <!--量表介绍-->
        <el-card :key="index + '.0'" class="anchor">
          <div class="card-page">
            <div class="l">{{ basic.subhead }}</div>
            <div class="c"></div>
            <div class="r">第{{ table.detail.page }}页</div>
          </div>
          <div class="card-header">
            <span>{{ table.title }}</span>
          </div>
          <div class="card-box">
            <div class="team-info">
              <div class="team-info-top"><span>问卷概况</span></div>
              <div class="team-info-div" v-html="table.detail.survey">
                {{ table.detail.survey }}
              </div>
            </div>
          </div>
          <div class="card-box">
            <div class="team-info">
              <div class="team-info-top"><span>调查目的</span></div>
              <div class="team-info-div" v-html="table.detail.purpose">
                {{ table.detail.purpose }}
              </div>
            </div>
          </div>
        </el-card>
        <!--调查统计-->
        <el-card :key="index + '.1'" class="anchor">
          <div class="card-page">
            <div class="l">{{ basic.subhead }}</div>
            <div class="c"></div>
            <div class="r">第{{ table.eval.page }}页</div>
          </div>
          <div class="card-header">
            <span>{{ table.title }}</span>
          </div>
          <div class="card-box">
            <div class="team-info">
              <div class="team-info-title"><span>调查统计</span></div>
              <div class="team-info-top"><span>数据说明</span></div>
              <div class="team-info-div">
                <span>{{ table.eval.name }}</span>
                <span>{{ pepInfo.descr }}</span>
                计划测评
                <span>{{ evalInfo.all }}</span>
                ；
                <span>{{ table.eval.test }}</span>
                人完成测试，完成率
                <span>{{ table.eval.test_rate }}</span>
                ; 共有
                <span>{{ table.eval.warn }}</span>
                人预警，占完成测试人数的
                <span>{{ table.eval.rate }}</span>
                。
              </div>
              <vab-chart
                :ref="'tjCharts_' + index"
                class="no-print"
                :height="
                  tjChartList[index].chartVal.series.length > 2 ? 800 : 400
                "
                :options="tjChartList[index].chartVal"
                theme="vab-echarts-theme"
              />
              <img class="canvas-img" :src="tjChartsImg[index]" />
            </div>
          </div>
        </el-card>
        <!--因子分布-->
        <el-card
          v-if="table.factor.type === 'table'"
          :key="index + '.2'"
          class="anchor"
        >
          <div class="card-page">
            <div class="l">{{ basic.subhead }}</div>
            <div class="c"></div>
            <div class="r">第{{ table.factor.page }}页</div>
          </div>
          <div class="card-header">
            <span>{{ table.title }}</span>
          </div>
          <div class="card-box">
            <div class="team-info">
              <div class="team-info-title">因子分布</div>
              <el-table
                v-if="table.factor.list.length"
                border
                :data="table.factor.list"
                size="mini"
              >
                <el-table-column
                  v-for="(item, i_t) in table.factor.table"
                  :key="i_t"
                  :label="item.name"
                  min-width="100"
                >
                  <template #default="{ row }">
                    <span v-html="row[item.value]"></span>
                  </template>
                </el-table-column>
              </el-table>
              <no-data v-else :txt="'暂无数据'" />
            </div>
          </div>
          <div class="card-box">
            <div class="team-info">
              <div class="team-info-top"><span>数据说明</span></div>
              <div class="team-info-div" v-html="table.factor.descr">
                {{ table.factor.descr }}
              </div>
            </div>
          </div>
        </el-card>
        <template v-else>
          <el-card
            v-for="(yz, z) in table.factor.list"
            :key="index + '.2' + z"
            class="anchor"
          >
            <div class="card-page">
              <div class="l">{{ basic.subhead }}</div>
              <div class="c"></div>
              <div class="r">第{{ yz.page }}页</div>
            </div>
            <div class="card-header">
              <span>{{ table.title }}</span>
            </div>
            <div class="team-info">
              <div class="team-info-title">{{ '因子分布 - ' + yz.title }}</div>
            </div>
            <div class="card-box">
              <vab-chart
                :ref="yzChartsList[index][z].cid"
                class="no-print"
                :options="yzChartsList[index][z].chartVal"
                theme="vab-echarts-theme"
              />
              <img class="canvas-img" :src="yzChartsList[index][z].charImg" />
            </div>
            <div class="card-box">
              <div class="team-info">
                <div class="team-info-top"><span>注解</span></div>
                <div class="team-info-div" v-html="yz.care">
                  {{ yz.care }}
                </div>
              </div>
            </div>
            <div class="card-box">
              <div class="team-info">
                <div class="team-info-top"><span>数据说明</span></div>
                <div class="team-info-div" v-html="yz.explain">
                  {{ yz.explain }}
                </div>
              </div>
            </div>
          </el-card>
        </template>
        <!--预警统计-->
        <el-card :key="index + '.3'" class="anchor">
          <div class="card-page">
            <div class="l">{{ basic.subhead }}</div>
            <div class="c"></div>
            <div class="r">第{{ table.warn.page }}页</div>
          </div>
          <div class="card-header">
            <span>{{ table.title }}</span>
          </div>
          <div class="card-box">
            <div class="team-info">
              <div class="team-info-title">预警统计</div>
            </div>
            <template v-if="table.warn.factor.length">
              <el-table border :data="table.warn.factor" size="mini">
                <template v-if="isNJ">
                  <el-table-column
                    align="center"
                    label="预警等级"
                    prop="name"
                  />
                  <el-table-column
                    align="center"
                    label="预警内容"
                    prop="descr"
                  />
                  <el-table-column
                    align="center"
                    label="样本数"
                    prop="nums"
                    width="100"
                  />
                  <el-table-column
                    align="center"
                    label="占比"
                    prop="rate"
                    width="100"
                  />
                </template>
                <template v-else>
                  <template v-if="table.warn.plan">
                    <el-table-column
                      align="center"
                      label="预警等级"
                      prop="descr"
                    />
                    <el-table-column
                      align="center"
                      label="样本数"
                      prop="nums"
                    />
                    <el-table-column align="center" label="占比" prop="rate" />
                  </template>
                  <template v-else>
                    <el-table-column label="" prop="fac" />
                    <el-table-column
                      align="center"
                      label="预警范围(分)"
                      prop="min"
                    >
                      <template #default="{ row }">
                        {{ row.min + '~' + row.max }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      label="样本总数"
                      prop="example"
                    />
                    <el-table-column align="center" label="占比" prop="rate" />
                  </template>
                </template>
              </el-table>
              <div class="chart-box" style="margin-top: 60px">
                <vab-chart
                  :ref="yjChartsList[index].cid"
                  class="no-print"
                  :height="yjChartsList[index].cHeight"
                  :options="yjChartsList[index].chartVal"
                  theme="vab-echarts-theme"
                />
                <img class="canvas-img" :src="yjChartsList[index].charImg" />
              </div>
            </template>
            <no-data v-else :txt="'暂无数据'" />
          </div>
          <div class="card-box">
            <div class="team-info">
              <div class="team-info-top">
                <span>预警说明</span>
              </div>
              <div class="team-info-div">
                {{ table.warn.descr || '暂无数据' }}
              </div>
            </div>
          </div>
        </el-card>
        <!--维度分析-->
        <template v-if="table.chart.length">
          <el-card
            v-for="(chart, j) in table.chart"
            :key="index + '.4' + j"
            class="anchor"
          >
            <div class="card-page">
              <div class="l">{{ basic.subhead }}</div>
              <div class="c"></div>
              <div class="r">第{{ chart.page }}页</div>
            </div>
            <div class="card-header">
              <span>{{ table.title }}</span>
            </div>
            <div class="card-box">
              <div class="team-info">
                <div class="team-info-title">维度分析</div>
                <div class="team-info-top">
                  <span>{{ chart.fac }}</span>
                </div>
                <div class="team-info-div">{{ chart.descr }}</div>
              </div>
              <div class="chart-box">
                <vab-chart
                  :ref="chart.cid"
                  class="no-print"
                  :height="chart.cHeight"
                  :options="chart.chartVal"
                  theme="vab-echarts-theme"
                />
                <img class="canvas-img" :src="chart.charImg" />
              </div>
            </div>
          </el-card>
        </template>
      </template>
      <!--附录-->
      <el-card v-if="initData.other.list.length" class="anchor">
        <div class="card-page">
          <div class="l">{{ basic.subhead }}</div>
          <div class="c"></div>
          <div class="r">第{{ initData.other.page }}页</div>
        </div>
        <div class="card-header">
          <span>附录</span>
        </div>
        <div class="fl-list">
          <div v-for="(fl, f) in initData.other.list" :key="f" class="fl-item">
            <span>附录{{ f + 1 }}：</span>
            <el-button type="text">
              {{ fl }}
            </el-button>
          </div>
        </div>
      </el-card>
    </div>
    <origin ref="origin" @refresh="refresh" />
  </div>
</template>

<script>
  import { checkToReport, getTeamRecord2 } from '@/api/teamList'
  import getPageTitle from '@/utils/pageTitle'
  import VabChart from '@/extra/VabChart'
  import NoData from '@/components/noData/index'
  import origin from '@/components/reportOrigin'
  import { isNJ } from '@/utils/utils'
  export default {
    name: 'TeamRecord',
    components: { NoData, VabChart, origin },
    data() {
      return {
        isNJ: isNJ(),
        querys: null,
        initData: null,
        basic: null, //基本信息
        pepInfo: null, //完成统计
        evalInfo: null, //预警统计
        tables: null, //学生建档
        evalTable: null, //因子表数据
        mulu: [],
        gkImg: {
          //概况图表
          pepChart: '',
          evalChart: '',
        },
        tjChartList: [], //调查统计图表
        tjChartsImg: [], //生成调查统计图片
        yjChartsList: [], //维度
        yzChartsList: [], //因子分布图表
        loading: false,
        wordUrl: '',
      }
    },
    created() {
      this.querys = this.$route.query
      this.$nextTick(() => {
        this.getNotes()
      })
    },
    methods: {
      jump(index) {
        let obj = document.querySelectorAll('.anchor')
        obj[index].scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        })
      },
      handleMeta(name, meta) {
        document.title = getPageTitle(meta.title, true)
      },
      showBox() {
        checkToReport({ id: this.querys.id, type: 'create' }).then((res) => {
          let c = res.data
          if (c) {
            this.$refs['origin'].showEdit(this.querys)
          } else {
            this.$baseMessage('已经是最新啦', 'success')
          }
        })
      },
      refresh() {
        this.getNotes('update')
      },
      getNotes(type) {
        this.loading = this.$baseLoading(null, '正在生成报告...')
        getTeamRecord2({
          id: this.querys.id,
          type: type || 'normal',
        })
          .then((res) => {
            let d = res.data
            this.initData = d
            this.basic = d.basic
            this.pepInfo = d.student.pep
            this.evalInfo = d.student.eval
            this.tables = d.record
            //this.evalTable = d.state
            this.mulu = d.menus
            this.initData.pepChart = this.getPieChart(d.student.pepChart)
            this.initData.evalChart = this.getPieChart(d.student.evalChart)
            this.wordUrl = d.word_url

            //统计图表
            this.tjChartList = d.state.map((item) => {
              return {
                chartVal: this.getPieChart(item.pepChart),
              }
            })

            //因子分布图表
            this.yzChartsList = d.state.map((item, index) => {
              if (item.factor.type === 'circle') {
                let yz = item.factor.list.map((yinzi, y) => {
                  return {
                    cid: 'yzCharts_' + index + '_' + y,
                    chartVal: this.getPieChart(yinzi.chart),
                    charImg: '',
                  }
                })
                return yz
              }
            })

            //维度图表
            this.evalTable = d.state.map((item, index) => {
              let fi =
                item.range &&
                item.range.map((li, z) => {
                  let c = li.evals
                  let ds = (c && c.datasets) || []
                  ds.forEach((d, s) => {
                    this.$set(d, 'label', { show: true })
                  })
                  let ya = (c && c.yAxis) || []
                  return Object.assign({}, li, {
                    cid: 'wdCharts_' + index + '_' + z,
                    chartVal: c ? this.getChart(c, li.fac) : null,
                    charImg: '',
                    cHeight: 100 + ya.length * 30,
                  })
                })

              return Object.assign({}, item, {
                chart: item.range ? fi : [],
              })
            })

            //预警图表
            this.yjChartsList = d.state.map((item, index) => {
              let c = item.warn.chart ? item.warn.chart : item.warn.evalZhu
              if (item.warn.chart) {
                return {
                  cid: 'yjChart_' + index,
                  chartVal: c ? this.getPieChart(c) : null,
                  cHeight: c.length > 2 ? 800 : 400,
                  charImg: '',
                }
              } else {
                let ds = (c && c.datasets) || []
                ds.forEach((d, s) => {
                  this.$set(d, 'label', { show: true })
                })
                let ya = (c && c.yAxis) || []
                let h = 100 + ya.length * 30
                return {
                  cid: 'yjChart_' + index,
                  chartVal: c ? this.getChart(c) : null,
                  charImg: '',
                  cHeight: h > 400 ? 400 : h,
                }
              }
            })

            this.handleMeta('TeamRecord', {
              title: this.basic.title + '团测报告',
            })
            if (type === 'update') {
              this.$baseAlert(res.msg)
            }
            this.loading.close()
          })
          .then(() => {
            this.$nextTick(() => {
              this.gkImg.pepChart = this.$refs.pepChart.getDataURL()
              this.gkImg.evalChart = this.$refs.evalChart.getDataURL()
              //统计图表
              this.tjChartList.forEach((item, index) => {
                this.tjChartsImg.push(
                  this.$refs['tjCharts_' + index][0].getDataURL()
                )
              })

              //维度图表
              this.evalTable.forEach((item, index) => {
                item.chart.forEach((ran, j) => {
                  this.$set(ran, 'charImg', this.$refs[ran.cid][0].getDataURL())
                })
              })

              //预警图表
              this.yjChartsList.forEach((item) => {
                if (item.chartVal) {
                  this.$set(
                    item,
                    'charImg',
                    this.$refs[item.cid][0].getDataURL()
                  )
                }
              })

              //因子分布图表
              this.yzChartsList.forEach((item) => {
                item &&
                  item.forEach((ran, j) => {
                    this.$set(
                      ran,
                      'charImg',
                      this.$refs[ran.cid][0].getDataURL()
                    )
                  })
              })
            })
          })
          .catch((err) => {
            this.loading.close()
          })
      },
      getPieChart(datas) {
        //饼图
        let option = {
          /*toolbox: {
            show: true,
            feature: {
              saveAsImage: {},
            },
          },*/
          tooltip: {},
          series: datas.map(function (data, idx) {
            let hor = datas.length === 1 ? 50 : 25 + Math.floor(idx % 2) * 50
            let line = Math.floor(idx / 2)
            let ver
            if (datas.length < 3) {
              ver = 50
            } else {
              ver = line * 40 + 30
            }
            return {
              type: 'pie',
              radius: [50, 80],
              center: [hor + '%', ver + '%'],
              animation: false,
              itemStyle: {
                borderColor: '#fff',
                borderWidth: 1,
              },
              label: {
                formatter: '{name|{b}}\n{time|占比: {d}%}',
                minMargin: 5,
                edgeDistance: 10,
                lineHeight: 15,
                rich: {
                  time: {
                    fontSize: 10,
                    color: '#999',
                  },
                },
              },
              labelLine: {
                length: 15,
                length2: 0,
                maxSurfaceAngle: 80,
              },
              data: data,
            }
          }),
        }
        return option
      },
      getChart(data, title) {
        //柱状图
        let chart = {
          /*title: {
            text: title || '',
            subtext: '',
            textStyle: {
              fontSize: 16,
              fontWeight: 'normal',
              color: '#666',
            },
          },*/
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            data: data.legend,
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01],
          },
          yAxis: {
            type: 'category',
            data: data.yAxis,
          },
          series: data.datasets,
          animation: false,
        }
        return chart
      },
      async print(type) {
        if (type == 'word') {
          //导出word时提示：导出word可能会导致文档失真，如需打印建议使用导出PDF（继续or导出PDF）
          this.$confirm(
            '导出word可能会导致文档失真，如需打印建议导出PDF',
            '温馨提示',
            {
              distinguishCancelAndClose: true,
              confirmButtonText: '导出PDF',
              cancelButtonText: '继续',
              type: 'warning',
            }
          )
            .then(async () => {
              await this.$print(this.$refs.print)
            })
            .catch((action) => {
              if (action === 'cancel') {
                this.wordUrl && window.open(this.wordUrl)
              }
            })
        } else if (type == 'pdf') {
          await this.$print(this.$refs.print)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .eval-container {
    .canvas-img {
      display: none;
    }
  }
  .content {
    position: relative;
    width: 800px;
    margin: auto;
    .first {
      height: 1120px;
    }
    .recreatetips {
      position: fixed;
      width: 800px;
      z-index: 100;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      ::v-deep.el-alert.is-dark .el-alert__description {
        display: inline-block;
        margin: 0;
      }
      .btn {
        color: #fff;
        margin-left: 5px;
        font-weight: bold;
        text-decoration-line: underline;
        padding: 0;
        font-size: 14px;
      }
    }
    .canvas-img {
      width: 98%;
    }
    .nav-right {
      position: fixed;
      width: 120px;
      top: 10%;
      bottom: 10%;
      left: 50%;
      margin-left: 400px;
      z-index: 10;
      > a {
        display: block;
        background: #d6d6d6;
        margin-bottom: 8px;
        padding: 0 15px;
        line-height: 40px;
        color: #848484;
        border-radius: 0 5px 5px 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &.btn-a {
          background: #13e24a;
          color: #fff;
          text-align: center;
          box-shadow: 0 0 5px 2px rgba(38, 154, 25, 0.3);
        }
      }
    }
    .el-card {
      position: relative;
      /*height: 1680px;*/
      border: 0;
      padding: 20px;
      &.first {
        background: url('~@/assets/report_bg.png') center center no-repeat;
        background-size: 100% 100%;
        text-align: center;
        color: #6f8483;
        /*background: linear-gradient(
          180.59deg,
          rgba(38, 205, 183, 0.24) 0.343%,
          rgba(255, 255, 255, 0) 97%
        );*/
        /*&:before {
          content: '';
          position: absolute;
          top: 40px;
          right: 40px;
          bottom: 40px;
          left: 40px;
          border: 2px solid rgba(38, 205, 183, 0.83);
          border-radius: 6px;
        }*/
        .time {
          padding: 10px 32%;
          color: rgba(0, 0, 0, 0.5);
          text-align: left;
          div {
            font-size: 16px;
            margin-top: 10px;
          }
        }
        .bg {
          position: absolute;
          bottom: 200px;
          left: 0;
          right: 0;
          .el-image {
            width: 700px;
            height: 700px;
          }
        }
      }
      /*&:last-child {
        height: 1600px;
      }*/
      &.is-always-shadow {
        box-shadow: none;
      }
      .title {
        font-size: 32px;
        text-align: center;
        line-height: 60px;
        color: rgb(111, 132, 131);
      }
      .catalogue {
        .li {
          margin-bottom: 5px;
          .pra-item {
            font-size: 20px;
            .p-title,
            .right {
              font-weight: bold;
            }
          }
          .pra-item,
          .sub-item {
            display: flex;
            align-items: center;
            line-height: 40px;
            .line {
              flex: 1;
              margin: 0 15px;
              border-bottom: 1px dashed #555;
            }
            .right {
              width: 40px;
            }
          }
          .sub-item {
            font-size: 18px;
            padding-left: 40px;
            line-height: 40px;
          }
        }
      }
      .card-page {
        display: none;
        align-items: center;
        padding-bottom: 20px;
        .l {
          font-weight: bold;
        }
        .c {
          flex: 1;
          margin: 0 15px;
          border: 2px solid #26cdb7;
        }
      }
      .card-header {
        position: relative;
        height: 50px;
        margin: 10px 0 30px;
        > span {
          position: absolute;
          left: -40px;
          min-width: 240px;
          background-image: linear-gradient(
            184deg,
            #26cdb7,
            rgba(38, 205, 183, 0.64)
          );
          color: #fff;
          border-radius: 5px 15px;
          font-size: 20px;
          padding: 0 20px;
          line-height: 50px;
          text-align: center;
        }
      }
      .qy {
        &-item {
          margin-bottom: 10px;
          .qy-title {
            font-size: 28px;
            font-weight: bold;
            padding: 10px 0;
          }
        }
      }
      .card-box {
        &:not(:last-child) {
          margin-bottom: 50px;
        }
        &.top {
          border-bottom: 1px dashed #eee;
          padding-bottom: 10px;
        }
      }
      .team-info {
        font-size: 16px;
        &-top {
          > span {
            display: inline-block;
            line-height: 36px;
            padding: 0 20px;
            border-radius: 10px 10px 0 0;
            background-color: #e0f3f1;
            margin-left: 5px;
            font-size: 16px;
          }
        }
        &-title {
          position: relative;
          font-weight: bold;
          padding: 0 20px;
          margin-bottom: 30px;
          font-size: 20px;
          &:before {
            position: absolute;
            content: '';
            top: 0;
            bottom: 0;
            left: 0;
            width: 6px;
            border-radius: 10px;
            background: #26cdb7;
          }
        }
        &-div {
          font-size: 16px;
          border: 1px dashed #bbb;
          border-radius: 5px;
          padding: 25px;
          margin-bottom: 10px;
          line-height: 30px;
          text-align: justify;
          span {
            color: #333;
            font-weight: bold;
          }
        }
        .tips {
          font-size: 14px;
          color: #b7b6b6;
          padding: 10px 20px;
        }
      }
      .card-name {
        position: relative;
        font-size: 20px;
        padding-left: 30px;
        line-height: 50px;
        font-weight: bold;
        &:before {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 20px;
          height: 1px;
          background: #e5e8ff;
        }
      }
      .chart-box {
        /*display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item {
          width: 48%;
          padding: 20px 10px;
          margin-bottom: 50px;
        }
        margin-top: 60px;*/
        padding: 0 10%;
      }
    }
  }
  @media print {
    @page {
      size: auto;
      margin: 0mm;
    }
    .anchor {
      page-break-after: always;
    }
  }
  @media screen and (max-width: 1450px) {
    .content {
      .nav-right {
        left: auto;
        right: 8px;
        margin-left: auto;
        > a {
          border-radius: 5px 0 0 5px;
        }
      }
    }
  }
</style>
<style>
  p {
    margin: 0;
    font-size: 16px !important;
  }
</style>
